const FileUploadUtil = (model: any, mainParameterName: string, fileProperties: string[]) => {
  const formData = new FormData();

  for (let i = 0; i < fileProperties.length; i++) {
    const currentProp = fileProperties[i];
    const fileModel = model[currentProp];
    if (Array.isArray(fileModel)) {
      for (let x = 0; x < fileModel.length; x++) {
        addFile(fileModel[x], currentProp, formData);
      }
    } else {
      addFile(fileModel, currentProp, formData);
    }
  }
  if (mainParameterName != null) {
    const jsonModel = restOf(model, fileProperties);
    formData.append(mainParameterName, JSON.stringify(jsonModel));
  }
  return formData;
};

export const normalizeFileUI = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }

  return e && e.fileList;
};

const addFile = (model: any, fieldName: any, formData: FormData) => {
  if (model instanceof File) {
    formData.append(fieldName, model);
  } else {
    for (const key in model) {
      if (model[key] instanceof File) {
        formData.append(fieldName, model[key]);
      }
    }
  }
};

const restOf = (source: any, excludedPropertyNames: any[]) => {
  const target = {};

  for (const key in source) {
    if (Object.prototype.hasOwnProperty.call(source, key) && excludedPropertyNames.indexOf(key) < 0) {
      target[key] = source[key];
    }
  }

  if (source != null && typeof Object.getOwnPropertySymbols === 'function') {
    for (let i = 0, keys = Object.getOwnPropertySymbols(source); i < keys.length; i++) {
      if (excludedPropertyNames.indexOf(keys[i]) < 0 && Object.prototype.propertyIsEnumerable.call(source, keys[i])) {
        target[keys[i]] = source[keys[i]];
      }
    }
  }
  return target;
};

export default FileUploadUtil;
