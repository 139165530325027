import { Layout, Row, Col, Input, Spin, Form, notification, Button, Typography } from 'antd';
import React, { useState } from 'react';
import PageTitle from '../../components/shared/PageTitle';
import ApiService from '../../api/CommunicationTesterApiService';
import MaskedInput from 'antd-mask-input';

const CommunicationTesterPage = () => {
  const [loading, setLoading] = useState(false);

  const sendSms = (form: any) => {
    setLoading(true);
    ApiService.sms(form.phone)
      .then(() => {
        notification.success({ message: 'SMS Sent' });
      })
      .catch(() => {
        notification.error({ message: 'SMS Failed' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const sendEmail = (form: any) => {
    setLoading(true);
    ApiService.email(form.email)
      .then(() => {
        notification.success({ message: 'Email Sent' });
      })
      .catch(() => {
        notification.error({ message: 'Email Failed' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Layout.Content className="content-padding">
      <Row gutter={24}>
        <Col flex={0}>
          <PageTitle title="Communication Testers" />
        </Col>
        <Col flex={1} style={{ textAlign: 'right' }}></Col>
      </Row>
      <Spin spinning={loading}>
        <Typography.Title level={4}>SMS Test</Typography.Title>
        <Form layout="vertical" onFinish={sendSms}>
          <Row>
            <Col xl={4}>
              <Form.Item
                name="phone"
                label="Phone Number"
                rules={[
                  { required: true, message: 'Required' },
                  {
                    pattern: /^\d{10}$/,
                    message: 'Please a valid 10 digit phone number',
                  },
                ]}
                getValueFromEvent={(e) => {
                  return e.target.value.replace(/[^0-9]/g, '');
                }}
              >
                <MaskedInput mask="(000) 000-0000" type="tel" />
              </Form.Item>
            </Col>
          </Row>

          <Button htmlType="submit" type="primary">
            Send
          </Button>
        </Form>
        <br />

        <Typography.Title level={4}>Email Test</Typography.Title>
        <Form layout="vertical" onFinish={sendEmail}>
          <Row>
            <Col xl={4}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  { required: true, message: 'Required' },
                  {
                    type: 'email',
                    message: 'Valid email address required.',
                  },
                ]}
              >
                <Input type="email" />
              </Form.Item>
            </Col>
          </Row>
          <Button htmlType="submit" type="primary">
            Send
          </Button>
        </Form>
      </Spin>
    </Layout.Content>
  );
};

export default CommunicationTesterPage;
