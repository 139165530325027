// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import AddressDTO from './AddressDTO';

interface PnfMemberInfoDTO { 
    id: string | null;
    isPrimary: boolean;
    primaryId: string | null;
    primaryRelationship: string | null;
    clientId: number | null;
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    emailMatchesPrimary: boolean;
    phone: string | null;
    phoneMatchesPrimary: boolean;
    phoneIsMobile: boolean;
    address: AddressDTO | null;
    addressMatchesPrimary: boolean;
    dateOfBirth: moment.Moment | string | null;
    languageId: number;
    priority: string | null;
    memberId: string | null;
    primaryMemberId: string | null;
    userId: number | null;
    source: string | null;
    confirmedAddress: boolean;
    contactMethods: string[];
    contactTime: string | null;
    detailedVoicemail: boolean | null;
}
const PnfMemberInfoDTO = {
    create: (initValues?: Partial<PnfMemberInfoDTO> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            id: "00000000-0000-0000-0000-000000000000",
            isPrimary: false,
            primaryId: null,
            primaryRelationship: null,
            clientId: null,
            firstName: null,
            lastName: null,
            email: null,
            emailMatchesPrimary: false,
            phone: null,
            phoneMatchesPrimary: false,
            phoneIsMobile: false,
            address: null,
            addressMatchesPrimary: false,
            dateOfBirth: null,
            languageId: 0,
            priority: null,
            memberId: null,
            primaryMemberId: null,
            userId: null,
            source: null,
            confirmedAddress: false,
            contactMethods: [],
            contactTime: null,
            detailedVoicemail: null,
        },
        initValues);
    }
};

export default PnfMemberInfoDTO;