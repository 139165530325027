import { Rule } from 'rc-field-form/lib/interface';

class FormValidation {
  public static GreaterThanOrEqual = (compareToFieldName: string, errorMessage: string): Rule => {
    return ({ getFieldValue }: any) => {
      return {
        validator(rule: any, value: number | null) {
          if (!getFieldValue(compareToFieldName) || (value ?? 0) >= getFieldValue(compareToFieldName)) {
            return Promise.resolve();
          }
          return Promise.reject(errorMessage);
        },
      };
    };
  };

  public static LessThanOrEqual = (compareToFieldName: string, errorMessage: string): Rule => {
    return ({ getFieldValue }: any) => {
      return {
        validator(rule: any, value: number | null) {
          if (!getFieldValue(compareToFieldName) || (value ?? 0) <= getFieldValue(compareToFieldName)) {
            return Promise.resolve();
          }
          return Promise.reject(errorMessage);
        },
      };
    };
  };
}

export default FormValidation;
