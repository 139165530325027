import { AutoComplete, AutoCompleteProps, Col, Form, Input, Row, Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import ProviderApiService from '../../api/ProviderApiService';
import ProviderSearchDTO from '../../models/ProviderSearchDTO';
import { debounce } from 'lodash';
import ProviderType from '../../consts/ProviderType';
import { NamePath } from 'antd/lib/form/interface';
import { Rule } from 'antd/lib/form';
import ProviderAutoCompleteFilterDTO from '../../models/ProviderAutoCompleteFilterDTO';

interface PractitionerAutoCompleteProps {
  onSelect: (selected: ProviderSearchDTO) => void;
  onClear: () => void;
  firstNameFormItem: PractitionerAutoCompleteFormItemProps;
  lastNameFormItem: PractitionerAutoCompleteFormItemProps;
  groupLabel?: string;
  disabled?: boolean;
  initialValues?: any;
}

interface PractitionerAutoCompleteFormItemProps {
  name: NamePath;
  label: React.ReactNode;
  rules: Rule[];
  placeholder?: string;
}

const PractitionerAutoComplete: React.FC<PractitionerAutoCompleteProps & AutoCompleteProps> = (props) => {
  const [providers, setProviders] = useState<ProviderSearchDTO[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState<ProviderSearchDTO | undefined>(undefined);

  const [filter, setFilter] = useState<ProviderAutoCompleteFilterDTO | undefined>(undefined);

  // Grabs the form instance component is wrapped in
  const form = Form.useFormInstance();

  const searchApiCall = (filter: ProviderAutoCompleteFilterDTO, autoSelect?: boolean) => {
    ProviderApiService.autoCompleteProviders(filter).then((res) => {
      setProviders(res);
      setLoading(false);

      if (autoSelect) {
        const selProvider = res.find((p) => p.npi === props.initialValues?.npi);
        if (selProvider) {
          setSelectedProvider(selProvider);
        }
      }
    });
  };

  useEffect(() => {
    if (props.initialValues) {
      if (props.initialValues?.npi) {
        const tmpFilter = ProviderAutoCompleteFilterDTO.create({
          providerTypeId: ProviderType.DOCTOR,
          npi: props.initialValues?.npi,
        });
        searchApiCall(tmpFilter, true);
      }
    }
  }, []);

  useEffect(() => {
    if (filter) {
      debouncedSearch(filter);
    }
  }, [filter]);

  const debouncedSearch = useCallback(debounce(searchApiCall, 500), []);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, []);

  const handleSearch = (searchString: string) => {
    const firstName = form.getFieldValue(props.firstNameFormItem.name);
    const lastName = form.getFieldValue(props.lastNameFormItem.name);
    if (selectedProvider) {
      setSelectedProvider(undefined);
      props.onClear();
    }

    if (!searchString || searchString.length < 2 || !firstName || !lastName) {
      if (providers?.length > 0) {
        setProviders([]);
        setLoading(false);
      }

      if (!searchString) {
        props.onClear();
        setSelectedProvider(undefined);
      }
      return;
    }

    setLoading(true);
    const tmpFilter = ProviderAutoCompleteFilterDTO.create({
      providerTypeId: ProviderType.DOCTOR,
      firstName: firstName,
      lastName: lastName,
    });

    setFilter(tmpFilter);
  };

  const handleSelect = (s: any) => {
    // Override the default action of AutoComplete to set the input to the value prop
    // Instead we want to use the displayValue prop
    form.setFieldValue(props.firstNameFormItem.name, s.firstNameDisplayValue);
    form.setFieldValue(props.lastNameFormItem.name, s.lastNameDisplayValue);
    const selected = providers.find((f) => f.npi == s.key);
    if (selected) {
      setSelectedProvider(selected);
      props.onSelect(selected);
    }
  };

  const transformOptions = (options: any[]) => {
    return options.map((o) => ({
      key: o.npi,
      // value of option must be unique for auto complete for some reason
      // if not unique it will randomly select one of the options with the same value
      // to get around this when we select we will set the value of the field to the "displayValue" prop
      value: `${o.firstName} ${o.lastName} ${o.npi}`,
      firstNameDisplayValue: o.firstName,
      lastNameDisplayValue: o.lastName,
      label: (
        <Row gutter={12} justify="space-between">
          <Col>
            {o.firstName ?? ''} {o.lastName ?? ''}{' '}
          </Col>
          {o.address1 ? (
            <Col>
              {o.address1} {o.city}, {o.stateCode} {o.zipCode}
            </Col>
          ) : undefined}
        </Row>
      ),
    }));
  };

  const opts = loading
    ? [
        ProviderSearchDTO.create({
          id: '',
          name: '',
        }),
      ]
    : providers;

  return (
    <>
      <Col xs={24}>
        <Form.Item label={props.groupLabel} required={!!props.groupLabel}>
          <AutoComplete
            {...props}
            open={props.disabled ? false : undefined}
            style={{ width: '100%', verticalAlign: 'top' }}
            onSelect={(_, selected) => handleSelect(selected)}
            options={transformOptions(opts)}
            dropdownRender={(a) => {
              if (loading) {
                return (
                  <div className="center-spinner">
                    <Spin spinning={loading}>{a}</Spin>
                  </div>
                );
              }
              return a;
            }}
          >
            <Row gutter={[12, 0]} style={{ marginLeft: 0, marginRight: 0 }}>
              <Col xs={12} style={{ paddingLeft: 0, paddingRight: '6px' }}>
                <Form.Item
                  name={props.firstNameFormItem.name}
                  label={props.firstNameFormItem.label}
                  rules={props.firstNameFormItem.rules}
                  style={{ margin: 0 }}
                >
                  <Input
                    disabled={props.disabled}
                    placeholder={props.firstNameFormItem.placeholder}
                    onChange={(e) => handleSearch(e.target?.value)}
                    autoComplete="none"
                  />
                </Form.Item>
              </Col>
              <Col xs={12} style={{ paddingRight: 0, paddingLeft: '6px' }}>
                <Form.Item
                  name={props.lastNameFormItem.name}
                  label={props.lastNameFormItem.label}
                  rules={props.lastNameFormItem.rules}
                  style={{ margin: 0 }}
                >
                  <Input
                    disabled={props.disabled}
                    placeholder={props.lastNameFormItem.placeholder}
                    onChange={(e) => handleSearch(e.target?.value)}
                    autoComplete="none"
                  />
                </Form.Item>
              </Col>
            </Row>
          </AutoComplete>
        </Form.Item>
      </Col>
    </>
  );
};

export default PractitionerAutoComplete;
