// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';

interface PlanInformationDTO { 
    copay: number | null;
    coinsurancePercent: number | null;
    singleDeductible: number | null;
    familyDeductible: number | null;
    singleOutOfPocketMax: number | null;
    familyOutOfPocketMax: number | null;
    singleDeductibleAmountSatisfied: number | null;
    familyDeductibleAmountSatisfied: number | null;
    singleOutOfPocketMaxSatisfied: number | null;
    familyOutOfPocketMaxSatisfied: number | null;
}
const PlanInformationDTO = {
    create: (initValues?: Partial<PlanInformationDTO> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            copay: null,
            coinsurancePercent: null,
            singleDeductible: null,
            familyDeductible: null,
            singleOutOfPocketMax: null,
            familyOutOfPocketMax: null,
            singleDeductibleAmountSatisfied: null,
            familyDeductibleAmountSatisfied: null,
            singleOutOfPocketMaxSatisfied: null,
            familyOutOfPocketMaxSatisfied: null,
        },
        initValues);
    }
};

export default PlanInformationDTO;