import { Alert, Button, Form, FormInstance, Input, Spin } from 'antd';
import React, { FunctionComponent } from 'react';
const FormItem = Form.Item;

interface ChangeUsernameProps {
  formRef?: React.RefObject<FormInstance<any>>;
  loading: boolean;
  formSubmitted: (values: any) => void;
  submitErrors: string[];
}

const ChangeUsernameForm: FunctionComponent<ChangeUsernameProps> = (props) => {
  return (
    <Spin spinning={props.loading}>
      {props.submitErrors.length > 0 ? (
        <Alert
          message="Error"
          description={props.submitErrors.join('\n')}
          type="error"
          showIcon={true}
          style={{ marginBottom: '12px', whiteSpace: 'pre-wrap' }}
        />
      ) : null}
      <Form ref={props.formRef} layout="vertical" onFinish={props.formSubmitted}>
        <FormItem name="newUsername" label="New Email" rules={[{ required: true, message: 'New email is required' }]}>
          <Input />
        </FormItem>
        <FormItem
          name="confirmUsername"
          label="Confirm New Email"
          rules={[
            {
              required: true,
              message: 'Confirm email is required',
            },
            ({ getFieldValue }) => ({
              validator(rule: any, value: string | null) {
                if (!value || getFieldValue('newUsername') === value) {
                  return Promise.resolve();
                }
                return Promise.reject('The emails that you entered do not match');
              },
            }),
          ]}
        >
          <Input />
        </FormItem>
        <FormItem
          name="password"
          label="Confirm Password"
          rules={[{ required: true, message: 'Password is required' }]}
        >
          <Input.Password />
        </FormItem>
        <FormItem>
          <Button type="primary" htmlType="submit" loading={props.loading} shape="round" block>
            Change Email
          </Button>
        </FormItem>
      </Form>
    </Spin>
  );
};

export default ChangeUsernameForm;
