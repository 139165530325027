// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import EducationDocumentDTO from '../models/EducationDocumentDTO';

export class EducationApiService extends BaseApi {

    // get: api/education/documents
    public getDocuments(): Promise<EducationDocumentDTO[]> {
        let url = `api/education/documents`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, EducationDocumentDTO[]>(null, 'get', url, true, false);
    }
}
var service = new EducationApiService();
export default service;
