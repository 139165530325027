// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import ProviderSearchQueryDTO from '../models/ProviderSearchQueryDTO';
import TableRequestDTO from '../models/TableRequestDTO';
import ProviderAutoCompleteFilterDTO from '../models/ProviderAutoCompleteFilterDTO';
import ProviderSearchResultDTO from '../models/ProviderSearchResultDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import ProviderDTO from '../models/ProviderDTO';
import ProviderSearchDTO from '../models/ProviderSearchDTO';

export class ProviderApiService extends BaseApi {

    // post: api/provider/SearchProviders
    public searchProviders(query: ProviderSearchQueryDTO): Promise<ProviderSearchResultDTO> {
        let url = `api/provider/SearchProviders`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ProviderSearchResultDTO>(query, 'post', url, true, false);
    }

    // post: api/provider/GetDataTable
    public getDataTable(tableRequest: TableRequestDTO): Promise<TableResponseDTO<ProviderDTO>> {
        let url = `api/provider/GetDataTable`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<ProviderDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/provider/AutoCompleteProviders
    public autoCompleteProviders(filter: ProviderAutoCompleteFilterDTO): Promise<ProviderSearchDTO[]> {
        let url = `api/provider/AutoCompleteProviders`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ProviderSearchDTO[]>(filter, 'post', url, true, false);
    }
}
var service = new ProviderApiService();
export default service;
