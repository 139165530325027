import { Button, Card, Col, Popover, Row } from 'antd';
import React from 'react';
import Renderers from '../../utils/Renderers';

const ClaimDocMemberSupport = () => {
  return (
    <Card
      title="ClaimDOC Member Support"
      className="ant-card-background ant-card-shadow"
      style={{ marginTop: 15 }}
      bordered={false}
    >
      <Row gutter={[24, 24]}>
        <Col flex="auto">
          <h4>Member Advocacy</h4>
          <p>
            {Renderers.phoneNumberLink('8883307295')}
            <br />
            <Popover content="membersupport@claim-doc.com">
              <Button
                htmlType="button"
                shape="round"
                style={{ marginTop: '.75em' }}
                ghost
                href={'mailto:membersupport@claim-doc.com'}
                rel="noreferrer"
              >
                Email
              </Button>
            </Popover>
          </p>
        </Col>
        <Col flex="auto">
          <h4>Provider Nomination</h4>
          <p>
            {Renderers.phoneNumberLink('8883307295')}
            <br />
          </p>
        </Col>
        <Col flex="auto">
          <h4>Balance Bills</h4>
          <p>
            {Renderers.phoneNumberLink('8883307295')}
            <br />
            <Popover content="balancebills@claim-doc.com">
              <Button
                htmlType="button"
                shape="round"
                style={{ marginTop: '.75em' }}
                ghost
                href={'mailto:balancebills@claim-doc.com'}
                rel="noreferrer"
              >
                Email
              </Button>
            </Popover>
          </p>
        </Col>
      </Row>
    </Card>
  );
};

export default ClaimDocMemberSupport;
