import { Button, Col, Form, FormInstance, Input, Row, Select } from 'antd';
import React, { RefObject, useContext } from 'react';
import MemberDTO from '../../models/MemberDTO';
import RelationDTO from '../../models/RelationDTO';
import DatePicker2, { AcceptedFormats } from '../shared/DatePicker2';
import moment from 'moment';
import NominationSearchFormDTO from '../../models/NominationSearchFormDTO';
import { AuthenticationContext } from '../../auth/AuthenticationContext';
import AccessRole from '../../consts/AccessRole';

const { Option } = Select;

interface NominationSearchFormProps {
  onSearch: () => void;
  onMemberSearch: (results: any) => void;
  formRef: RefObject<FormInstance<any>>;
  clients: RelationDTO[];
  members: MemberDTO[];
 nominationSearchDTO: NominationSearchFormDTO;
}

const NominationSearchForm = (props: NominationSearchFormProps) => {
  const authContext = useContext(AuthenticationContext);
  const isNominationDocEmployee = authContext.user?.accessRoleId === AccessRole.CLAIMDOC;
  const isNominationDocMember = authContext.user?.accessRoleId === AccessRole.MEMBER;

  return (
    <Form
      key={'nominationSearchForm'}
      ref={props.formRef}
      initialValues={{
        clientId: props.nominationSearchDTO.clientId == 0 ? null : props.nominationSearchDTO.clientId,
        fromDate: props.nominationSearchDTO.fromDate,
        toDate: props.nominationSearchDTO.toDate,
        lastName: isNominationDocMember ?  authContext.user?.lastName : null
      }}
      layout="vertical"
      style={{ marginTop: 10 }}
    >
      <Row gutter={[12, 0]}>
        <Col xs={24} xl={12}>
          <Form.Item name="clientId" label="Client" rules={[{ required: true, message: 'Required' }]}>
            <Select
              showSearch
              optionFilterProp="children"
              style={{ width: '100%' }}
            >
              <Option value="" disabled hidden>
                -- Select a client --
              </Option>
              {(props.clients || []).map((r) => (
                <Option key={r.id} value={r.id}>
                  {r.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} xl={12}>
          <Form.Item name="lastName" label="Last Name" rules={[{ required: !isNominationDocEmployee, message: 'Required' }]}>
            <Input disabled={isNominationDocMember} />
          </Form.Item>
        </Col>
        <Col xs={24} xl={12}>
          <Form.Item name="fromDate" label="From Date">
            <DatePicker2
              format={AcceptedFormats}
              placeholder={AcceptedFormats.at(0)}
              disabledDate={(c) => moment('1/1/1900', AcceptedFormats.at(0)) > c}
            />
          </Form.Item>
        </Col>
        <Col xs={24} xl={12}>
          <Form.Item name="toDate" label="To Date">
            <DatePicker2
              format={AcceptedFormats}
              placeholder={AcceptedFormats.at(0)}
              disabledDate={(c) => moment('1/1/1900', AcceptedFormats.at(0)) > c}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[12, 0]}>
        <Col xs={24} xl={12}>
          <Button type="primary" onClick={props.onSearch}>
            Search
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default NominationSearchForm;
