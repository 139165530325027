import { Input, Switch, Table, TableColumnProps } from 'antd';
import React, { useState } from 'react';
import PermissionDTO from '../../../models/PermissionDTO';
import PermissionGroupDetailDTO from '../../../models/PermissionGroupDetailDTO';

interface PermissionGroupPermissionTableProps {
  group: PermissionGroupDetailDTO;
  lookup: { [id: number]: PermissionDTO };
  editable: boolean;
  showDisabled: boolean;
  onPermissionChange: (id: number, enabled: boolean) => void;
  checkedPermissions: { [id: number]: boolean };
}

interface PermissionRow {
  permissionId: number;
  region: string;
  area: string;
  permission: string;
  enabled: boolean;
  editable: boolean;
}

const PermissionGroupPermissionTable = (props: PermissionGroupPermissionTableProps) => {
  const [stringFilter, setStringFilter] = useState<string | undefined>(undefined);
  const tableColumns: TableColumnProps<PermissionRow>[] = [
    {
      title: 'Region',
      dataIndex: 'region',
      sorter: {
        compare: (a, b) => a.region.localeCompare(b.region),
      },
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Area',
      dataIndex: 'area',
      sorter: {
        compare: (a, b) => a.area.localeCompare(b.area),
      },
    },
    {
      title: 'Permission',
      dataIndex: 'permission',
      sorter: {
        compare: (a, b) => a.permission.localeCompare(b.permission),
      },
    },
    {
      title: 'Enabled',
      dataIndex: 'enabled',
      sorter: true,
      // eslint-disable-next-line react/display-name
      render: (v, r) => {
        return !r.editable ? null : (
          <Switch
            disabled={!props.editable}
            checked={props.checkedPermissions[r.permissionId]}
            onChange={(e) => props.onPermissionChange(r.permissionId, e)}
          />
        );
      },
    },
  ];

  const tableData: PermissionRow[] = Object.keys(props.lookup)
    .map((k) => parseInt(k))
    .filter((p) => (props.showDisabled ? true : p in props.checkedPermissions && props.checkedPermissions[p]))

    .map((id: number) => {
      const dto = props.lookup[id];
      const existing = props.group.permissions?.find((p) => p.permission?.id === id);
      return {
        permissionId: id,
        region: dto.region,
        area: dto.area,
        permission: dto.permission,
        enabled: existing != null ? true : false,
        editable: existing?.isManaged ?? true,
      } as PermissionRow;
    })
    .filter((p) =>
      stringFilter ? `${p.region}\t${p.area}\t${p.permission}`.toLocaleLowerCase().includes(stringFilter) : true
    );

  return (
    <>
      <Input.Search
        onSearch={(e) => {
          const term = e ? e.toLocaleLowerCase() : undefined;
          setStringFilter(term);
        }}
        placeholder="Search Permissions"
        allowClear={true}
      />
      <Table
        dataSource={tableData}
        pagination= {{totalBoundaryShowSizeChanger:10 }}
        rowKey={'permissionId'}
        sortDirections={['ascend', 'descend']}
        columns={tableColumns}
      />
    </>
  );
};

export default PermissionGroupPermissionTable;
