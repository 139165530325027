// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';

interface PnfAppointmentDTO { 
    id: string | null;
    familyMemberId: string | null;
    referenceNumber: string | null;
    appointmentDate: moment.Moment | string | null;
    appointmentTimeZone: string | null;
    existing: boolean;
    genderPreference: string | null;
    isUrgent: boolean;
    hasAppointment: string | null;
    appointmentTimeframe: string | null;
}
const PnfAppointmentDTO = {
    create: (initValues?: Partial<PnfAppointmentDTO> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            id: "00000000-0000-0000-0000-000000000000",
            familyMemberId: "00000000-0000-0000-0000-000000000000",
            referenceNumber: null,
            appointmentDate: null,
            appointmentTimeZone: null,
            existing: false,
            genderPreference: null,
            isUrgent: false,
            hasAppointment: null,
            appointmentTimeframe: null,
        },
        initValues);
    }
};

export default PnfAppointmentDTO;