// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';

interface UserPreferenceDTO { 
    languageId: number;
    languageName: string | null;
}
const UserPreferenceDTO = {
    create: (initValues?: Partial<UserPreferenceDTO> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            languageId: 0,
            languageName: null,
        },
        initValues);
    }
};

export default UserPreferenceDTO;