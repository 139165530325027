import { Col, Form, FormInstance, Input, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import StatePicker from '../../shared/StatePicker';
import UsStateDTO from '../../../models/UsStateDTO';
import LookupsApiService from '../../../api/LookupsApiService';
import { MaskedInput } from 'antd-mask-input';

interface OtherCompanyDetailFormProps {
  form: FormInstance<any>;
  initialValues?: any;
}

const OtherCompanyDetailForm: React.FC<OtherCompanyDetailFormProps> = (props) => {
  const [states, setStates] = useState<UsStateDTO[]>([]);

  useEffect(() => {
    props.form.resetFields();
    LookupsApiService.getUsStates().then((res) => {
      setStates(res);
    });
  }, []);

  return (
    <Form form={props.form} initialValues={props.initialValues} layout="vertical">
      <Row gutter={12}>
        <Col xs={24}>
          <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Name is required' }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item name="claimDocId" label="ClaimDOC ID">
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item name="otherCompanyCode" label="ClaimDOC Code">
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="phoneNumber"
            label="Phone"
            rules={[
              {
                pattern: /^\d{10}$/,
                message: 'Please a valid 10 digit phone number',
              },
            ]}
            getValueFromEvent={(e) => {
              return e.target.value.replace(/[^0-9]/g, '');
            }}
          >
            <MaskedInput mask="(000) 000-0000" type="tel" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item name="url" label="Website">
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item name="address1" label="Address 1" rules={[{ required: true, message: 'Required' }]}>
            <Input maxLength={50} />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item name="address2" label="Address 2">
            <Input maxLength={50} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item name="city" label="City" rules={[{ required: true, message: 'Required' }]} required>
            <Input maxLength={50} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={6}>
          <Form.Item name="state" label="State" rules={[{ required: true, message: 'Required' }]} required>
            <StatePicker usStates={states} allowClear={true} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={6}>
          <Form.Item
            name="zipCode"
            label="Zip Code"
            rules={[
              {
                pattern: /^\d{5}(-\d{4})?$/,
                validateTrigger: ['onBlur'],
                message: 'Invalid format',
                required: true,
              },
            ]}
            required
          >
            <Input
              pattern="^\d{5}(-\d{4})?$"
              type="tel"
              maxLength={5}
              onKeyPress={(e) => {
                const isNumber = /^[0-9]$/i.test(e.key);
                if (!isNumber) {
                  e.preventDefault();
                }
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default OtherCompanyDetailForm;
