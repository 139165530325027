import { Col, FormInstance, Row } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react';
import ClaimSearchApiService from '../../api/ClaimSearchApiService';
import ClaimSearchDetailPage from '../../components/claim-search/ClaimSearchDetailPage';
import ClaimSearchForm from '../../components/claim-search/ClaimSearchForm';
import ClaimSearchList from '../../components/claim-search/ClaimSearchList';
import PageTitle from '../../components/shared/PageTitle';
import MemberClaimSearchFormDTO from '../../models/MemberClaimSearchFormDTO';
import MemberDTO from '../../models/MemberDTO';
import RelationDTO from '../../models/RelationDTO';

const ClaimSearchPage = () => {
  const [clients, setClients] = useState<RelationDTO[]>([]);
  const [members, setMembers] = useState<MemberDTO[]>([]);
  const [claimDocClaimID, setClaimDocClaimId] = useState('');
  const [claimSearchDTO, setClaimSearchDTO] = useState<MemberClaimSearchFormDTO>({
    clientId: 0,
    memberId: null,
    fromDate: null,
    toDate: null,
    onlyInProgressClaims: false,
  });

  const [searched, setSearched] = useState(false);
  const [showDetail, setShowDetail] = useState(false);

  const claimSearchFormRef = React.createRef<FormInstance<any>>();

  useEffect(() => {
    ClaimSearchApiService.getClients().then((res) => {
      setClients(res);
    });
  }, []);

  const search = () => {
    setSearched(false);
    claimSearchFormRef.current?.validateFields().then((model) => {
      setClaimSearchDTO(model);
      setSearched(true);
    });
  };

  const onMemberSearch = (results: any) => {
    setMembers(results);
  };

  const returnToSearch = () => {
    setShowDetail(false);
  };

  const openDetail = (claimId: string) => {
    setClaimDocClaimId(claimId);
    setShowDetail(true);
  };

  return showDetail ? (
    <ClaimSearchDetailPage claimDocClaimId={claimDocClaimID} returnToSearch={returnToSearch} />
  ) : (
    <Content className="content-padding">
      <Row>
        <Col flex={1}>
          <PageTitle title="Claim Search" />
        </Col>
        <Col xs={24}>
          <ClaimSearchForm
            formRef={claimSearchFormRef}
            onSearch={search}
            onMemberSearch={onMemberSearch}
            clients={clients}
            members={members}
            claimSearchDTO={claimSearchDTO}
          />
          Note: Only claims received in the last 6 months will be included. For older claims, call ClaimDOC.
          <br />
          {searched ? <ClaimSearchList claimSearchDTO={claimSearchDTO} openDetails={openDetail} /> : null}
        </Col>
      </Row>
    </Content>
  );
};

export default ClaimSearchPage;
