import { Alert, Button, Form, FormInstance, Input, Spin } from 'antd';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { ValidatePassword } from './../../utils/PasswordUtil';

const FormItem = Form.Item;

interface ChangePasswordProps {
  formRef?: React.RefObject<FormInstance<any>>;
  loading: boolean;
  formSubmitted: (values: any) => void;
  submitErrors: string[];
}

const ChangePasswordForm: FunctionComponent<ChangePasswordProps> = (props) => {
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    setErrors(props.submitErrors);
  }, props.submitErrors);

  const passwordBlur = async (e: any) => {
    const res = await ValidatePassword(e);
    setErrors(res || []);
  };

  return (
    <Spin spinning={props.loading}>
      {errors.length > 0 ? (
        <Alert
          message="Error"
          description={errors.join('\n')}
          type="error"
          showIcon={true}
          style={{ marginBottom: '12px', whiteSpace: 'pre-wrap' }}
        />
      ) : null}

      <Form layout="vertical" onFinish={props.formSubmitted} ref={props.formRef}>
        <FormItem
          name="oldPassword"
          label="Current Password"
          rules={[{ required: true, message: 'Current Password is required' }]}
        >
          <Input.Password />
        </FormItem>
        <FormItem
          name="newPassword"
          label="New Password"
          rules={[{ required: true, message: 'New Password is required' }]}
        >
          <Input.Password onBlur={passwordBlur} />
        </FormItem>
        <FormItem
          name="confirmPassword"
          label="Confirm New Password"
          rules={[
            {
              required: true,
              message: 'Confirm password is required',
            },
            ({ getFieldValue }) => ({
              validator(rule: any, value: string | null) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject('The two new passwords that you entered do not match');
              },
            }),
          ]}
        >
          <Input.Password />
        </FormItem>
        <FormItem>
          <Button type="primary" htmlType="submit" disabled={props.loading} shape="round" block>
            Change Password
          </Button>
        </FormItem>
      </Form>
    </Spin>
  );
};

export default ChangePasswordForm;
