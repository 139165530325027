// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';

interface ParentSecurityUserSettingsDTO { 
    id: number;
    firstName: string | null;
    lastName: string | null;
    username: string | null;
    permissionGroupId: number | null;
    division: string | null;
    divisionId: number | null;
    role: string | null;
    registered: boolean;
    lastLoggedInOn: moment.Moment | string | null;
    phoneNumber: string | null;
    requireMfa: boolean;
}
const ParentSecurityUserSettingsDTO = {
    create: (initValues?: Partial<ParentSecurityUserSettingsDTO> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            id: 0,
            firstName: null,
            lastName: null,
            username: null,
            permissionGroupId: null,
            division: null,
            divisionId: null,
            role: null,
            registered: false,
            lastLoggedInOn: null,
            phoneNumber: null,
            requireMfa: false,
        },
        initValues);
    }
};

export default ParentSecurityUserSettingsDTO;