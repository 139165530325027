import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, FormInstance, Input, Row, Select, Space, Switch, Typography } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import React from 'react';
import ClientDetailDTO from '../../models/ClientDetailDTO';
interface InviteAdminsProps {
  submit?: (values: any) => void;
  showAdmin?: boolean;
  form: FormInstance<any>;
  clientOptions?: ClientDetailDTO[];
  readonly?: boolean;
}

const InviteUsers = (props: InviteAdminsProps) => {
  return (
    <>
      <Typography.Title level={3}>Invite Users</Typography.Title>
      <p>
        Enter names, emails, and if you want to send them an email below and an account will be created for these users.
      </p>
      <Form form={props.form} layout="vertical" onFinish={props.submit} initialValues={{ accounts: [{}] }}>
        {(props.clientOptions?.length ?? 0) > 1 && (
          <Row>
            <Col xs={8}>
              <Form.Item
                name="divisionClientId"
                label="Division"
                rules={[{ required: true, message: 'Select a division to add users to' }]}
              >
                <Select>
                  {props.clientOptions?.map((c) => (
                    <Select.Option key={c.id} value={c.id}>
                      {c.name} ({c.subCode})
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        )}

        <Form.List name="accounts">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }, index) => (
                <Space key={key} style={{ display: 'flex' }} align="baseline">
                  <Form.Item
                    {...restField}
                    label={index === 0 ? 'First Name' : undefined}
                    name={[name, 'firstName']}
                    rules={[{ required: true, message: 'Required' }]}
                  >
                    <Input autoFocus={true} />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    label={index === 0 ? 'Last Name' : undefined}
                    name={[name, 'lastName']}
                    rules={[{ required: true, message: 'Required' }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    label={index === 0 ? 'Email' : undefined}
                    name={[name, 'email']}
                    rules={[{ required: true, message: 'Required' }]}
                  >
                    <Input />
                  </Form.Item>
                  {props.showAdmin === true ? (
                    <Form.Item
                      {...restField}
                      label={index === 0 ? 'Is Employer' : undefined}
                      name={[name, 'admin']}
                      valuePropName="checked"
                      style={{ width: 120 }}
                    >
                      <Switch />
                    </Form.Item>
                  ) : null}

                  <Form.Item
                    {...restField}
                    label={index === 0 ? 'Send Email' : undefined}
                    name={[name, 'sendEmail']}
                    valuePropName="checked"
                    style={{ width: 100 }}
                  >
                    <Switch />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    label={index === 0 ? 'Require MFA' : undefined}
                    name={[name, 'requireMfa']}
                    valuePropName="checked"
                    style={{ width: 100 }}
                  >
                    <Switch />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    label={index === 0 ? 'Phone Number' : undefined}
                    name={[name, 'phoneNumber']}
                    rules={[
                      {
                        pattern: /^\d{10}$/,
                        message: 'Please a valid 10 digit phone number',
                      },
                    ]}
                    getValueFromEvent={(e) => {
                      return e.target.value.replace(/[^0-9]/g, '');
                    }}
                  >
                    <MaskedInput mask="(000) 000-0000" type="tel" placeholder="(000) 000-0000" />
                  </Form.Item>
                  <Form.Item label={index === 0 ? <>&nbsp;</> : undefined}>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Form.Item>
                </Space>
              ))}
              <Form.Item>
                <Space direction="horizontal">
                  <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />} disabled={!!props.readonly}>
                    Add User
                  </Button>

                  <Button type="primary" htmlType="submit" disabled={!!props.readonly}>
                    Submit
                  </Button>
                </Space>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </>
  );
};

export default InviteUsers;
