import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  Menu,
  Modal,
  notification,
  Row,
  Space,
  Spin,
  Typography,
} from 'antd';
import Text from 'antd/lib/typography/Text';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, NavLink, Route, Switch, useParams } from 'react-router-dom';
import OtherCompanyApiService from '../../api/OtherCompanyApiService';
import OtherCompanyClientAccessEditor from '../../components/admin/OtherCompany/OtherCompanyClientAccessEditor';
import OtherCompanyClientAccessList from '../../components/admin/OtherCompany/OtherCompanyClientAccessList';
import OtherCompanySecurity from '../../components/admin/OtherCompany/OtherCompanySecurity';
import PageTitle from '../../components/shared/PageTitle';
import Permission from '../../consts/Permission';
import Routes from '../../consts/Routes';
import useHasPermission from '../../hooks/useHasPermission';
import OtherCompanyDetailDTO from '../../models/OtherCompanyDetailDTO';
import HistoryUtil from '../../utils/HistoryUtil';
import Renderers from '../../utils/Renderers';
import OtherCompanyInviteUsers from '../../components/admin/OtherCompany/OtherCompanyInviteUsers';
import parseIntOrDefault from '../../utils/ParseIntOrDefault';
import { CloseCircleOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import FormItem from 'antd/lib/form/FormItem';
import { useForm } from 'antd/lib/form/Form';
import PermissionGuard from '../../components/shared/PermissionGuard';
import PageStayPrompt from '../../utils/PageStayPrompt';
import OtherCompanyDetailForm from '../../components/admin/OtherCompany/OtherCompanyDetailForm';
import confirmModal from '../../utils/Modal';

const Content = Layout.Content;

interface OtherCompanyRouteProps {
  id: string;
  tab?: string;
}

const OtherCompanyDetailPage = () => {
  const routeProps = useParams<OtherCompanyRouteProps>();
  const id = parseIntOrDefault(routeProps.id);

  if (id <= 0) {
    HistoryUtil.replace(Routes.ADMIN_OTHERCOMPANY_LIST);
  }

  const [otherCompanyDetails, setOtherCompanyDetails] = useState<OtherCompanyDetailDTO>(OtherCompanyDetailDTO.create());
  const [selectedTab, setSelectedTab] = useState(routeProps.tab || 'about');
  const [loading, setLoading] = useState(false);
  const [editingNotes, setEditingNotes] = useState(false);
  const [otherCompanyId, setOtherCompanyId] = useState(id);
  const [showEdit, setShowEdit] = useState(false);
  const [form] = useForm();
  const hasPermission = useHasPermission();

  const fetch = (id: number) => {
    setLoading(true);
    OtherCompanyApiService.getOtherCompanyDetails(id)
      .then((res) => {
        setOtherCompanyDetails(res);
      })
      .catch((err) => {
        notification.error({
          message: 'Error loading Other Company',
          description: err?.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const id = parseIntOrDefault(routeProps.id);

    if (id <= 0) {
      HistoryUtil.replace(Routes.ADMIN_OTHERCOMPANY_LIST);
    }

    setOtherCompanyId(id);
    fetch(id);
  }, [routeProps.id]);

  const saveNote = () => {
    setLoading(true);

    const note = form.getFieldValue('note');
    OtherCompanyApiService.saveOtherCompanyNotes(otherCompanyId, note)
      .then(() => {
        notification.success({ message: 'Notes Saved' });
        setOtherCompanyDetails({ ...otherCompanyDetails, notes: note });
        setEditingNotes(false);
      })
      .catch(() => {
        notification.success({ message: 'Failed to Save', description: 'Please refresh and try again.' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setSelectedTab(routeProps.tab || 'about');
  }, [routeProps.tab]);

  const deleteOtherCompany = () => {
    setLoading(true);
    OtherCompanyApiService.deleteOtherCompany(otherCompanyId)
      .then(() => {
        notification.success({ message: 'Other Company Deleted' });
        HistoryUtil.replace(Routes.ADMIN_OTHERCOMPANY_LIST);
      })
      .catch(() => {
        notification.error({ message: 'Failed to delete Other Company.' });
        setLoading(false);
      });
  };

  const handleEditOtherCompany = () => {
    form.validateFields().then((model) => {
      const dto: OtherCompanyDetailDTO = { ...model, id: otherCompanyId };
      OtherCompanyApiService.editOtherCompany(dto)
        .then((res) => {
          if (res) {
            notification.success({ message: 'Saved successfully.' });
          } else {
            notification.error({ message: 'Error occurred while saving.' });
          }
        })
        .catch(() => {
          notification.error({ message: 'Error occurred while saving.' });
        })
        .finally(() => {
          setShowEdit(false);
          setLoading(false);
          fetch(otherCompanyId);
        });
    });
    setLoading(true);
  };

  const detailsRender = () => (
    <Spin spinning={loading}>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} xl={8}>
          <Card className="ant-card-background ant-card-shadow" bordered={false}>
            <Typography.Title level={3}>Details</Typography.Title>
            <Row gutter={[16, 16]}>
              <Col flex={0}>
                <strong>Other Company ID:</strong>
                <br />
                <strong>Other Company Code:</strong>
                <br />
                <strong>Phone:</strong>
                <br />
                <strong>Website:</strong>
              </Col>
              <Col flex={1}>
                {otherCompanyDetails?.claimDocId}
                <br />
                {otherCompanyDetails?.otherCompanyCode}
                <br />

                {otherCompanyDetails?.phoneNumber ? Renderers.phoneNumberLink(otherCompanyDetails?.phoneNumber) : null}
                <br />
                {otherCompanyDetails?.url ? Renderers.urlLink(otherCompanyDetails.url) : null}
                <br />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} sm={12} xl={8}>
          <Card className="ant-card-background ant-card-shadow" bordered={false} style={{ height: '100%' }}>
            <Typography.Title level={3}>Address</Typography.Title>
            <Text style={{ whiteSpace: 'pre-wrap' }}>{Renderers.address(otherCompanyDetails)}</Text>
          </Card>
        </Col>
        <Col xs={24} xl={16}>
          <Card className="ant-card-background ant-card-shadow" bordered={false}>
            <Row align="bottom" style={{ paddingBottom: '0.5em' }}>
              <Col flex={0}>
                <Typography.Title level={3} style={{ marginBottom: 0 }}>
                  Notes
                </Typography.Title>
              </Col>
              <Col flex={1} style={{ textAlign: 'right' }}>
                <PermissionGuard permissionId={Permission.ADMIN_OTHERCOMPANY_DETAILS_EDIT}>
                  <Space direction="horizontal">
                    {!editingNotes ? (
                      <Button icon={<EditOutlined />} type="link" onClick={() => setEditingNotes(true)} />
                    ) : (
                      <>
                        <Button icon={<SaveOutlined />} type="link" onClick={() => saveNote()} />
                        <Button icon={<CloseCircleOutlined />} type="link" onClick={() => setEditingNotes(false)} />
                      </>
                    )}
                  </Space>
                </PermissionGuard>
              </Col>
            </Row>

            {!editingNotes ? (
              <div style={{ minHeight: 140, whiteSpace: 'pre-wrap' }}>{otherCompanyDetails?.notes}</div>
            ) : (
              <>
                <PageStayPrompt when={true} />
                <Form initialValues={{ note: otherCompanyDetails?.notes }} form={form}>
                  <FormItem name="note">
                    <Input.TextArea rows={6} />
                  </FormItem>
                </Form>
              </>
            )}
          </Card>
        </Col>
      </Row>
      <Modal
        title="Edit Other Company"
        open={showEdit}
        okText="Save"
        okButtonProps={{
          disabled: loading,
          loading: loading,
        }}
        cancelButtonProps={{
          disabled: loading,
        }}
        onOk={() => handleEditOtherCompany()}
        onCancel={() => setShowEdit(false)}
      >
        <OtherCompanyDetailForm form={form} initialValues={otherCompanyDetails} />
      </Modal>
      {otherCompanyId > 0 && (
        <Space>
          <PermissionGuard permissionId={Permission.ADMIN_OTHERCOMPANY_DETAILS_EDIT}>
            <Button
              onClick={() =>
                confirmModal('Are you sure you want to delete this Other Company?', () => deleteOtherCompany())
              }
              style={{ marginTop: 15 }}
              danger
            >
              Delete Other Company
            </Button>
          </PermissionGuard>
          <PermissionGuard permissionId={Permission.ADMIN_OTHERCOMPANY_DETAILS_EDIT}>
            <Button style={{ marginTop: 15 }} type="primary" onClick={() => setShowEdit(true)}>
              Edit
            </Button>
          </PermissionGuard>
        </Space>
      )}
    </Spin>
  );

  const tabs = [];

  if (hasPermission(Permission.ADMIN_OTHERCOMPANY_DETAILS_VIEW)) {
    tabs.push(
      <Menu.Item key="about">
        <NavLink to={Routes.generate(Routes.ADMIN_OTHERCOMPANY_DETAILS, { id: otherCompanyId })}>
          Other Company Information
        </NavLink>
      </Menu.Item>
    );
  }

  if (hasPermission(Permission.ADMIN_OTHERCOMPANY_CLIENTS_VIEW)) {
    tabs.push(
      <Menu.Item key="clients">
        <NavLink to={Routes.generate(Routes.ADMIN_OTHERCOMPANY_DETAILS_CLIENTS, { id: otherCompanyId })}>
          Clients
        </NavLink>
      </Menu.Item>
    );
  }

  if (hasPermission(Permission.ADMIN_OTHERCOMPANY_SECURITY_VIEW)) {
    tabs.push(
      <Menu.Item key="security">
        <NavLink to={Routes.generate(Routes.ADMIN_OTHERCOMPANY_DETAILS_SECURITY, { id: otherCompanyId })}>
          Security
        </NavLink>
      </Menu.Item>
    );
  }

  if (hasPermission(Permission.ADMIN_OTHERCOMPANY_INVITEUSERS)) {
    tabs.push(
      <Menu.Item key="invite">
        <NavLink to={Routes.generate(Routes.ADMIN_OTHERCOMPANY_INVITE_USERS, { id: otherCompanyId })}>
          Invite Users
        </NavLink>
      </Menu.Item>
    );
  }

  return (
    <Content className="content-padding">
      <Row>
        <Col xs={24}>
          <Row justify="space-between" style={{ marginBottom: '12px' }}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={Routes.ADMIN_OTHERCOMPANY_LIST}>Other Companies</Link>
              </Breadcrumb.Item>
              {loading ? null : <Breadcrumb.Item>{otherCompanyDetails?.name ?? 'New Other Company'}</Breadcrumb.Item>}
            </Breadcrumb>
          </Row>
          <PageTitle
            title={loading ? '' : otherCompanyDetails?.name ?? 'New Other Company'}
            style={{ marginTop: '0.5em', marginBottom: '0.5em' }}
          />

          <Row>
            <Col xs={24}>
              <Menu mode="horizontal" style={{ marginBottom: 15 }} selectedKeys={[selectedTab]}>
                {tabs}
              </Menu>

              <Switch>
                <Route
                  path={Routes.ADMIN_OTHERCOMPANY_DETAILS_CLIENTS}
                  render={(props) =>
                    props.match.params.clientId ? (
                      <OtherCompanyClientAccessEditor
                        otherCompanyId={otherCompanyId}
                        clientId={props.match.params.clientId}
                      />
                    ) : (
                      <OtherCompanyClientAccessList otherCompanyId={otherCompanyId} />
                    )
                  }
                />
                <Route
                  path={Routes.ADMIN_OTHERCOMPANY_DETAILS_SECURITY}
                  render={() => <OtherCompanySecurity otherCompanyId={otherCompanyId} />}
                />

                <Route
                  path={Routes.ADMIN_OTHERCOMPANY_INVITE_USERS}
                  exact={true}
                  render={() => <OtherCompanyInviteUsers otherCompanyId={otherCompanyId} />}
                />

                <Route path={Routes.ADMIN_OTHERCOMPANY_DETAILS} exact={true} render={detailsRender} />
              </Switch>
            </Col>
          </Row>
        </Col>
      </Row>
    </Content>
  );
};

export default OtherCompanyDetailPage;
