// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import TpaClientFeatureFlagsDTO from './TpaClientFeatureFlagsDTO';
import ClaimDocUserFeatureFlagsDTO from './ClaimDocUserFeatureFlagsDTO';
import EmployerFeatureFlagsDTO from './EmployerFeatureFlagsDTO';
import OtherCompanyClientFeatureFlagsDTO from './OtherCompanyClientFeatureFlagsDTO';
import DpcClientFeatureFlagsDTO from './DpcClientFeatureFlagsDTO';
import BrokerClientFeatureFlagsDTO from './BrokerClientFeatureFlagsDTO';

interface ClientFeatureFlagsDTO { 
    id: number;
    tpaClientFeatureFlags: TpaClientFeatureFlagsDTO[] | null;
    claimDocUserFeatureFlags: ClaimDocUserFeatureFlagsDTO[] | null;
    employerFeatureFlags: EmployerFeatureFlagsDTO[] | null;
    otherCompanyClientFeatureFlags: OtherCompanyClientFeatureFlagsDTO[] | null;
    dpcClientFeatureFlags: DpcClientFeatureFlagsDTO[] | null;
    brokerClientFeatureFlags: BrokerClientFeatureFlagsDTO[] | null;
}
const ClientFeatureFlagsDTO = {
    create: (initValues?: Partial<ClientFeatureFlagsDTO> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            id: 0,
            tpaClientFeatureFlags: [],
            claimDocUserFeatureFlags: [],
            employerFeatureFlags: [],
            otherCompanyClientFeatureFlags: [],
            dpcClientFeatureFlags: [],
            brokerClientFeatureFlags: [],
        },
        initValues);
    }
};

export default ClientFeatureFlagsDTO;