// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import IFormFile from './IFormFile';
import OcrPreviewDTO from './OcrPreviewDTO';

interface OcrUploadDTO { 
    file: any | null;
    previewOptions: OcrPreviewDTO | null;
}
const OcrUploadDTO = {
    create: (initValues?: Partial<OcrUploadDTO> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            file: null,
            previewOptions: null,
        },
        initValues);
    }
};

export default OcrUploadDTO;