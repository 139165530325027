import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import React from 'react';
import useHasPermission from '../../hooks/useHasPermission';
interface PermissionGuardProps {
  permissionId: number;
  showMissingPermAlert?: boolean;
}

const PermissionGuard = (props: React.PropsWithChildren<PermissionGuardProps>) => {
  const hasPermission = useHasPermission();

  if (hasPermission(props.permissionId)) {
    return <>{props.children}</>;
  }

  if (props.showMissingPermAlert === true) {
    return (
      <Tag icon={<ExclamationCircleOutlined />} color="#cd201f">
        Insufficient Permissions
      </Tag>
    );
  }

  return null;
};

export default PermissionGuard;
