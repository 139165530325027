import { Button, Col, Layout, Menu, Modal, notification, Row, Select, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, NavLink, useParams } from 'react-router-dom';
import MailroomApiService from '../../api/MailroomApiService';
import DataTable, { DataTableColumnProps, FilterType } from '../../components/shared/DataTable/DataTable';
import DataTableColumnUtil from '../../components/shared/DataTable/DataTableColumnUtil';
import PageTitle from '../../components/shared/PageTitle';
import PermissionGuard from '../../components/shared/PermissionGuard';
import MailroomDirections from '../../consts/MailroomDirections';
import MailroomFileStatuses from '../../consts/MailroomFileStatuses';
import Permission from '../../consts/Permission';
import Routes from '../../consts/Routes';
import MailroomFileListDTO from '../../models/MailroomFileListDTO';
import TableRequestDTO from '../../models/TableRequestDTO';
import TableResponseDTO from '../../models/TableResponseDTO';

interface MailroomFileListPageRouteProps {
  tab: string;
}

const MailroomFileListPage = () => {
  const routeProps = useParams<MailroomFileListPageRouteProps>();
  const [selectedTab, setSelectedTab] = useState(routeProps.tab);
  const [loading, setLoading] = useState(false);
  const [mailroomFiles, setMailroomFiles] = useState<MailroomFileListDTO[]>([]);
  const [edit, setEdit] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRowIds, setSelectedRowIds] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<MailroomFileListDTO[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<string>();
  const dataTableRef = React.useRef<DataTable<any>>(null);

  useEffect(() => {
    const tab = routeProps.tab;
    if (tab !== selectedTab) {
      setSelectedTab(tab);
      dataTableRef.current?.resetTable();
      dataTableRef.current?.refresh();
      setLoading(false);
    }
  }, [routeProps]);

  const fetchTableData = (
    requestState: TableRequestDTO,
    checkEcho: () => boolean,
    callback: (response: TableResponseDTO<MailroomFileListDTO>) => void
  ) => {
    setLoading(true);
    MailroomApiService.getMailroomFileList(requestState, selectedTab)
      .then((results) => {
        if (!checkEcho()) {
          return;
        }
        setMailroomFiles(results.results || []);
        setLoading(false);
        callback(results);
      })
      .catch((error: any) => {
        setLoading(false);
        notification.error({
          message: error.message,
          description: error.description,
        });
      });
  };

  const tableColumns: DataTableColumnProps<MailroomFileListDTO>[] = [
    {
      title: 'Account',
      dataIndex: 'account',
      sorter: {},
      filterType: FilterType.Text,
    },
    DataTableColumnUtil.DropdownMulti<MailroomFileListDTO>(
      'Status',
      'status',
      [
        { text: MailroomFileStatuses.DONE, value: MailroomFileStatuses.DONE },
        { text: MailroomFileStatuses.FAILED, value: MailroomFileStatuses.FAILED },
        { text: MailroomFileStatuses.JUNK, value: MailroomFileStatuses.JUNK },
        { text: MailroomFileStatuses.MANUAL, value: MailroomFileStatuses.MANUAL },
        { text: MailroomFileStatuses.RUNNING, value: MailroomFileStatuses.RUNNING },
        { text: MailroomFileStatuses.TRANSFERRED, value: MailroomFileStatuses.TRANSFERRED },
      ],
      null,
      {
        initialFilterValue:
          MailroomFileStatuses.FAILED + ',' + MailroomFileStatuses.MANUAL + ',' + MailroomFileStatuses.RUNNING,
      }
    ),
    DataTableColumnUtil.DateTime<MailroomFileListDTO>('Date Uploaded', 'uploadDate', null, {
      defaultSortOrder: 'descend',
    }),
    DataTableColumnUtil.DateTime<MailroomFileListDTO>('Date Processed', 'processedDate'),
    {
      title: 'File Name',
      dataIndex: 'fileName',
      defaultSortOrder: 'ascend',
      sorter: {},
      filterType: FilterType.Text,
    },
    {
      title: 'File Type',
      dataIndex: 'type',
      defaultSortOrder: 'ascend',
      sorter: {},
      filterType: FilterType.Text,
    },
    {
      title: 'File Size',
      dataIndex: 'size',
      defaultSortOrder: 'ascend',
      sorter: {},
      filterType: FilterType.Text,
    },
    {
      title: 'Source',
      dataIndex: 'source',
      defaultSortOrder: 'ascend',
      sorter: {},
      filterType: FilterType.Text,
    },
    {
      title: 'Destination',
      dataIndex: 'destination',
      defaultSortOrder: 'ascend',
      sorter: {},
      filterType: FilterType.Text,
    },
    {
      title: '',
      dataIndex: '',
      width: '40px',
      // eslint-disable-next-line react/display-name
      render: (rowData: MailroomFileListDTO) => (
        <Link
          to={Routes.generate(Routes.MAILROOM_FILE_DETAIL, {
            tab: routeProps.tab,
            id: rowData.id,
          })}
        >
          <Button shape="round" ghost>
            Details
          </Button>
        </Link>
      ),
    },
  ];

  const statusSelect = (
    <Select
      style={{ width: '150px' }}
      onChange={(value: string) => {
        setSelectedStatus(value);
      }}
    >
      <Select.Option value={MailroomFileStatuses.DONE}>{MailroomFileStatuses.DONE}</Select.Option>
      <Select.Option value={MailroomFileStatuses.FAILED}>{MailroomFileStatuses.FAILED}</Select.Option>
      <Select.Option value={MailroomFileStatuses.JUNK}>{MailroomFileStatuses.JUNK}</Select.Option>
      <Select.Option value={MailroomFileStatuses.MANUAL}>{MailroomFileStatuses.MANUAL}</Select.Option>
      <Select.Option value={MailroomFileStatuses.RUNNING}>{MailroomFileStatuses.RUNNING}</Select.Option>
      <Select.Option value={MailroomFileStatuses.TRANSFERRED}>{MailroomFileStatuses.TRANSFERRED}</Select.Option>
    </Select>
  );

  const submitStatusChange = () => {
    MailroomApiService.saveMailroomFilesStatus(selectedRows, selectedStatus ?? '').then(() =>
      dataTableRef.current?.refresh()
    );

    setOpenModal(false);
  };

  const editButtonGroup = () => {
    return edit ? (
      <>
        <Button type="primary" shape="round" disabled={loading} onClick={() => setOpenModal(true)}>
          Set Status
        </Button>
        <Button shape="round" disabled={loading} onClick={() => setEdit(false)}>
          Cancel
        </Button>
      </>
    ) : (
      <PermissionGuard permissionId={Permission.UTILITIES_MAILROOM_FILE_EDIT}>
        <Button type="primary" shape="round" disabled={loading} onClick={() => setEdit(true)}>
          Edit
        </Button>
      </PermissionGuard>
    );
  };

  return (
    <Layout>
      <Layout.Content className="content-padding content-padding-xl">
        <Row className="content-header" justify="space-between">
          <Col xs={24} lg={10} xl={13} xxl={14}>
            <PageTitle title="Mailroom" />
          </Col>
          {selectedTab === MailroomDirections.INCOMING ? (
            <Col>
              <Space direction="horizontal">{editButtonGroup()}</Space>
            </Col>
          ) : null}
        </Row>
        <Modal
          open={openModal}
          title="Select Status"
          onCancel={() => setOpenModal(false)}
          onOk={submitStatusChange}
          okText="Save"
          width="200px"
        >
          {statusSelect}
        </Modal>
        <Menu mode="horizontal" style={{ marginBottom: 15 }} selectedKeys={[selectedTab]}>
          <Menu.Item key={MailroomDirections.INCOMING}>
            <NavLink to={Routes.generate(Routes.MAILROOM_FILE_LIST, { tab: MailroomDirections.INCOMING })}>
              Incoming
            </NavLink>
          </Menu.Item>
          <Menu.Item key={MailroomDirections.OUTGOING}>
            <NavLink to={Routes.generate(Routes.MAILROOM_FILE_LIST, { tab: MailroomDirections.OUTGOING })}>
              Outgoing
            </NavLink>
          </Menu.Item>
        </Menu>
        <DataTable
          ref={dataTableRef}
          serverSide={true}
          globalSearch={true}
          globalSearchPlaceholder="Search by Account, Status, or File name"
          tableProps={{
            rowKey: 'id',
            rowSelection: edit
              ? {
                  selectedRowKeys: selectedRowIds,
                  onChange: (newSelectedRowIds: React.Key[], newSelectedRows: MailroomFileListDTO[]) => {
                    setSelectedRowIds(newSelectedRowIds);
                    setSelectedRows(newSelectedRows);
                  },
                  hideSelectAll: true,
                }
              : undefined,
            loading: loading,
            scroll: { x: 575 },
          }}
          columns={tableColumns}
          data={mailroomFiles}
          fetchData={fetchTableData}
          styleOptions={{
            alternatingRowHighlight: true,
          }}
        />
      </Layout.Content>
    </Layout>
  );
};

export default MailroomFileListPage;
