// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import ShoppableServiceRequestDTO from '../models/ShoppableServiceRequestDTO';
import PricingToolDTO from '../models/PricingToolDTO';
import ProviderSearchQueryDTO from '../models/ProviderSearchQueryDTO';
import ShoppableServiceResponseDTO from '../models/ShoppableServiceResponseDTO';
import CostEstimateDTO from '../models/CostEstimateDTO';
import RelationDTO from '../models/RelationDTO';
import ProviderSearchResultDTO from '../models/ProviderSearchResultDTO';

export class PricingToolApiService extends BaseApi {

    // post: api/pricing-tool/GetShoppableServices
    public getShoppableServices(dto: ShoppableServiceRequestDTO): Promise<ShoppableServiceResponseDTO> {
        let url = `api/pricing-tool/GetShoppableServices`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ShoppableServiceResponseDTO>(dto, 'post', url, true, false);
    }

    // post: api/pricing-tool/GetCostEstimate
    public getCostEstimate(dto: PricingToolDTO): Promise<CostEstimateDTO[]> {
        let url = `api/pricing-tool/GetCostEstimate`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, CostEstimateDTO[]>(dto, 'post', url, true, false);
    }

    // post: api/pricing-tool/GetClients
    public getClients(): Promise<RelationDTO[]> {
        let url = `api/pricing-tool/GetClients`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, RelationDTO[]>(null, 'post', url, true, false);
    }

    // post: api/pricing-tool/SearchProviders
    public searchProviders(query: ProviderSearchQueryDTO): Promise<ProviderSearchResultDTO> {
        let url = `api/pricing-tool/SearchProviders`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ProviderSearchResultDTO>(query, 'post', url, true, false);
    }
}
var service = new PricingToolApiService();
export default service;
