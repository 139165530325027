import { LockOutlined, SearchOutlined } from '@ant-design/icons';
import {
  Button,
  ButtonProps,
  Input,
  List,
  Modal,
  notification,
  Space
  } from 'antd';
import React, { useEffect, useState } from 'react';
import LookupService from '../../../api/LookupsApiService';
import PermissionGroupDetailDTO from '../../../models/PermissionGroupDetailDTO';

interface PermissionGroupHelperButtonProps extends ButtonProps{
  permissionGroupIds?: (number | null )[];
}

const PermissionGroupHelperButton = (props: PermissionGroupHelperButtonProps) => {
  const [isModalVisible, setModalVisability] = useState(false);
  const [permissionGroups, setPermissionGroups] = useState<PermissionGroupDetailDTO[]>([]);
  const [filter, setFilter] = useState<string | undefined>(undefined);

  const btnProps = { ...props };
  btnProps.onClick = () => {
    setModalVisability(true);
  };
  
  useEffect(() => {
    LookupService.getPermissionGroupByPermissionGroupIds(props?.permissionGroupIds ?? [])
      .then((groups) => {
        setPermissionGroups(groups);
      })
      .catch(() => {
        notification.error({ message: 'Failed to fetch permission groups.' });
        setPermissionGroups([]);
      });
  },[props.permissionGroupIds]);

  const groups = permissionGroups.filter(
    (g) => filter === undefined || g.name?.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
  );

  return (
    <>
    
      <Button {...btnProps} htmlType="button" ghost shape="round" />
      <Modal
        title="Permission Groups"
        okButtonProps={{ style: { display: 'none' } }}
        cancelText="Close"
        visible={isModalVisible}
        style={{ top: 230 }}
        width="90vw"
        onCancel={() => setModalVisability(false)}
      >
        <Input.Search
          enterButton={<Button icon={<SearchOutlined />} ghost />}
          onSearch={setFilter}
          placeholder="Search Permission Groups"
        />
        <br />

        <List
          itemLayout="horizontal"
          dataSource={groups}
          style={{ maxHeight: '65vh', overflowY: 'auto', marginTop: 5 }}
          renderItem={(group) => (
            <List.Item>
              <List.Item.Meta
                title={
                  <Space>
                    {group.name}
                    {group.isManaged ? null : <LockOutlined />}
                  </Space>
                }
                description={
                  <div style={{ fontSize: 15 }}>
                    {group.permissions
                      ?.map((p) => `${p.permission?.region}.${p.permission?.area}.${p.permission?.permission}`)
                      .join(', ')}
                  </div>
                }
              />
            </List.Item>
          )}
        />
      </Modal>
    </>
  );
};

export default PermissionGroupHelperButton;
