import { Button, Card, Col, Divider, Row, Spin, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ClaimSearchApiService from '../../api/ClaimSearchApiService';
import MemberClaimDTO from '../../models/MemberClaimDTO';
import PageTitle from '../shared/PageTitle';
import Renderers from '../../utils/Renderers';

const { Title } = Typography;

interface ClaimSearchDetailProps {
  claimDocClaimId: string;
  returnToSearch: () => void;
}

const ClaimSearchDetailPage = (props: ClaimSearchDetailProps) => {
  const [memberClaim, setMemberClaim] = useState<MemberClaimDTO>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    ClaimSearchApiService.getMemberClaimByClaimId(props.claimDocClaimId)
      .then((result) => {
        setMemberClaim(result);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Content className="content-padding">
      <Spin spinning={loading}>
        <Row>
          <Button title="Back" onClick={props.returnToSearch}>
            Back
          </Button>
        </Row>
        <Row>
          <PageTitle title={'Claim Detail'} style={{ marginTop: '0.5em', marginBottom: '0.5em' }} />
        </Row>
        <Row>
          <Col style={{ width: '100%' }}>
            <Card className="ant-card-background ant-card-shadow" bordered={false} bodyStyle={{ fontSize: '16px' }}>
              <Title level={3}>Claim Information</Title>
              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <b>Claim Type:</b> {memberClaim?.claimType}
                  <br />
                  <b>Total Charge:</b> ${memberClaim?.totalCharge.toLocaleString()}
                  <br />
                  <b>Received Date:</b> {Renderers.shortDate(moment(memberClaim?.receivedDate))}
                </Col>
                <Col span={8}>
                  <b>Service From:</b> {Renderers.shortDate(moment(memberClaim?.serviceFrom))}
                  <br />
                  <b>ClaimDOC Allowed Amount: </b>
                  {memberClaim?.claimDocAllowedAmount == 'TBD'
                    ? 'TBD'
                    : '$' + Number(memberClaim?.claimDocAllowedAmount).toLocaleString()}
                  <br />
                  <b>Output Date:</b> {Renderers.shortDate(moment(memberClaim?.outputDate))}
                </Col>
                <Col span={8}>
                  <b>Service Through:</b> {Renderers.shortDate(moment(memberClaim?.serviceThrough))}
                  <br />
                  <b>Status:</b> {memberClaim?.status}
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <br />
        <Row>
          <Col style={{ width: '100%' }}>
            <Card bordered={false} bodyStyle={{ fontSize: '16px' }}>
              <Title level={3}>Additional Claim Information</Title>
              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <b>ClaimDOC Claim ID:</b> {memberClaim?.claimDocClaimId}
                  <br />
                  <b>Adjustment From Claim:</b> {memberClaim?.adjustment}
                  <br />
                  <b>Claim Source:</b> {memberClaim?.claimSource}
                </Col>
                <Col span={8}>
                  <b>Inpatient/Outpatient:</b> {memberClaim?.claimInOutPatient == 'O' ? 'Outpatient' : 'Inpatient'}
                  <br />
                  <b>QPA Applies:</b> {memberClaim?.qpaApplies}
                  <br />
                  <b>QPA Amount: </b>
                  {isNaN(Number(memberClaim?.qpaAmount))
                    ? memberClaim?.qpaAmount
                    : '$' + Number(memberClaim?.qpaAmount).toLocaleString()}
                </Col>
                <Col span={8}>
                  <b>UB Bill Type:</b> {memberClaim?.ubBillType}
                  <br />
                  <b>HCFA Place of Service:</b> {memberClaim?.hcfaPlaceOfService}
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Divider />
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Card bordered={false} style={{ height: '100%' }} bodyStyle={{ fontSize: '16px' }}>
              <Title level={3}>Patient</Title>
              <b>First Name:</b> {memberClaim?.patientFirstName}
              <br />
              <b>Last Name:</b> {memberClaim?.patientLastName}
              <br />
              <b>DOB:</b> {Renderers.shortDate(moment(memberClaim?.patientDOB))}
              <br />
              <b>Relationship:</b> {memberClaim?.patientRelationship}
              <br />
              <b>Employee First Name:</b> {memberClaim?.employeeFirstName}
              <br />
              <b>Employee Last Name:</b> {memberClaim?.employeeLastName}
            </Card>
          </Col>
          <Col span={12}>
            <Card bordered={false} style={{ height: '100%' }} bodyStyle={{ fontSize: '16px' }}>
              <Title level={3}>Provider</Title>
              <b>Office Name:</b> {memberClaim?.providerOfficeName}
              <br />
              <b>Address Line 1:</b> {memberClaim?.providerAddressLine1}
              <br />
              <b>Address Line 2:</b> {memberClaim?.providerAddressLine2}
              <br />
              <b>City, State, and Postal Code:</b> {memberClaim?.providerCity}, {memberClaim?.providerState}{' '}
              {memberClaim?.providerPostalCode}
              <br />
              <b>Tax ID:</b> {memberClaim?.providerTaxId}
              <br />
              <b>NPI:</b> {memberClaim?.providerNPI}
            </Card>
          </Col>
        </Row>
      </Spin>
    </Content>
  );
};

export default ClaimSearchDetailPage;
