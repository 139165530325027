import { Button, Col, Layout, notification, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import PermissionService from '../../api/PermissionApiService';
import PermissionGroupDetails, { ActiveTabType } from '../../components/admin/Permissions/PermissionGroupDetails';
import PermissionGroupList from '../../components/admin/Permissions/PermissionGroupList';
import DataTable from '../../components/shared/DataTable/DataTable';
import PageTitle from '../../components/shared/PageTitle';
import Permission from '../../consts/Permission';
import Routes from '../../consts/Routes';
import useHasPermission from '../../hooks/useHasPermission';
import PermissionGroupDetailDTO from '../../models/PermissionGroupDetailDTO';
import PermissionGroupListDTO from '../../models/PermissionGroupListDTO';
import PermissionGroupPermissionDTO from '../../models/PermissionGroupPermissionDTO';
import HistoryUtil from '../../utils/HistoryUtil';

interface PermissionPageRouteProps {
  id?: string;
  tab?: string;
}

const PermissionsPage = () => {
  const routeParams = useParams<PermissionPageRouteProps>();
  const listRef = React.useRef<DataTable<PermissionGroupListDTO>>(null);

  const hasPermission = useHasPermission();

  const [loading, setLoading] = useState(false);
  const [editingGroup, setEditingGroup] = useState<PermissionGroupDetailDTO | null>(null);

  const editGroup = (id?: number | null) => {
    if (id === null) {
      HistoryUtil.push(Routes.generate(Routes.ADMIN_SECURITY_PERMISSION_GROUPS));
      setEditingGroup(null);
    } else if (id === undefined || id < 0) {
      setEditingGroup(PermissionGroupDetailDTO.create({ isManaged: true }));
    } else {
      setLoading(true);
      PermissionService.getPermissionGroupDetails(id)
        .then((group) => {
          setEditingGroup(group);
        })
        .catch((err) => {
          setEditingGroup(null);
          notification.error({
            message: 'Error',
            description: err.message ?? 'Error loading permission group.',
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const updatePermissionGroup = (
    name: string,
    changes: { [id: number]: boolean },
    role: number,
    cb?: (success: boolean) => void
  ) => {
    if (!editingGroup) {
      return;
    }

    const permissions = Object.keys(changes).map((p) =>
      PermissionGroupPermissionDTO.create({ permissionId: parseInt(p), delete: !changes[p] })
    );

    const group = PermissionGroupDetailDTO.create({
      id: editingGroup.id,
      name: name,
      permissions: permissions,
      roleId: role,
    });

    setLoading(true);
    PermissionService.updatePermissionGroup(group)
      .then((res) => {
        if (cb) {
          cb(true);
        }
        editGroup(res);
      })
      .catch((err) => {
        console.log(err);
        if (cb) {
          cb(false);
        }
      })
      .finally(() => {
        setLoading(false);
        listRef.current?.refresh();
      });
  };

  const deletePermissionGroup = (id: number) => {
    setLoading(true);
    PermissionService.deletePermissionGroup(id)
      .then(() => {
        notification.success({ message: 'Permission Group Deleted' });
        editGroup(null);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        listRef.current?.refresh();
      });
  };

  useEffect(() => {
    switch (routeParams.id) {
      case 'new':
        {
          if (editingGroup?.id != 0) {
            editGroup();
          }
        }
        break;
      case undefined:
        break;
      default:
        {
          const parsedId = Number.parseInt(routeParams.id);
          if (parsedId != editingGroup?.id) {
            editGroup(parsedId);
          }
        }
        break;
    }
  }, [routeParams]);

  return (
    <Layout.Content className="content-padding">
      <Row>
        <Col flex={1}>
          <PageTitle title="Permission Groups" />
        </Col>
        <Col flex={0}>
          {hasPermission(Permission.ADMIN_PERMISSIONGROUPS_AUDIT) ? (
            <NavLink to={Routes.ADMIN_SECURITY_PERMISSION_AUDIT}>
              <Button shape="round">Permission Audit</Button>
            </NavLink>
          ) : null}
        </Col>
      </Row>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Row gutter={16} style={{ flex: 1 }}>
          <Col xs={9}>
            <PermissionGroupList
              formRef={listRef}
              activeRow={routeParams.id}
              selectRow={(id) =>
                HistoryUtil.push(
                  Routes.generate(Routes.ADMIN_SECURITY_PERMISSION_GROUPS, {
                    id: id ?? 'new',
                  })
                )
              }
            />
          </Col>
          <Col xs={15}>
            <Spin spinning={loading}>
              <PermissionGroupDetails
                group={editingGroup}
                onCancel={() => editGroup(null)}
                activeTab={routeParams.tab as ActiveTabType}
                onSave={updatePermissionGroup}
                onDelete={deletePermissionGroup}
              />
            </Spin>
          </Col>
        </Row>
      </div>
    </Layout.Content>
  );
};

export default PermissionsPage;
