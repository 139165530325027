import { GlobalOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { Card, Col, Row, Typography } from 'antd';
import Text from 'antd/lib/typography/Text';
import * as React from 'react';
import ContactType from '../../consts/ContactType';
import Renderers from '../../utils/Renderers';

const { Title } = Typography;

interface ContactInfoProps {
  style?: React.CSSProperties;
  title?: string | null;
  name?: string | null;
  phone?: string | null;
  email?: string | null;
  url?: string | null;
  contactTypeId?: number | undefined;
  card?: boolean;
  addon?: React.ReactElement;
}

const ContactInfo = (props: ContactInfoProps) => {
  const renderContactType = () => {
    const contactTypeId = props.contactTypeId;
    if (contactTypeId == ContactType.CLIENT) return 'Client Contact';
    if (contactTypeId == ContactType.TPA) return 'TPA Contact';
    if (contactTypeId == ContactType.BROKER) return 'Broker Contact';
    if (contactTypeId == ContactType.CLAIMDOC) return 'ClaimDOC Contact';
    return null;
  };

  const content = (
    <>
      <Row align="bottom">
        <Col flex={0}>
          <Title level={4} style={{ marginBottom: 0 }}>
            {renderContactType()}
          </Title>
        </Col>
        <Col flex={1}>{props.addon}</Col>
      </Row>

      {props.title ? (
        <>
          <Text strong>{props.title}&nbsp;</Text>
          {/* Add break if there's no name */}
          {props.name ? null : <br />}
        </>
      ) : null}
      {props.name ? (
        <>
          <Text>{props.name}</Text>
          <br />{' '}
        </>
      ) : null}
      {props.phone ? (
        <>
          <PhoneOutlined />
          &nbsp;
          {Renderers.phoneNumberLink(props.phone)}
          <br />
        </>
      ) : null}
      {props.email ? (
        <>
          <MailOutlined />
          &nbsp;{Renderers.emailLink(props.email)}
          <br />
        </>
      ) : null}
      {props.url ? (
        <>
          <GlobalOutlined />
          &nbsp;
          {Renderers.urlLink(props.url)}
          <br />
        </>
      ) : null}
    </>
  );

  if (props.card === true) {
    return (
      <Card bordered className="ant-card-background" style={{ height: '100%' }}>
        {content}
      </Card>
    );
  } else {
    return <div style={props.style}>{content}</div>;
  }

  return <></>;
};

export default ContactInfo;
