import * as queryString from 'query-string';
import { generatePath } from 'react-router-dom';

class Routes {
  public static BASE_ROUTE = $appPath && $appPath.length > 0 ? '/' + $appPath : '';

  public static HOME_ROUTE = Routes.BASE_ROUTE + '/';
  public static LOGIN = Routes.BASE_ROUTE + '/login';
  public static GATE = Routes.BASE_ROUTE + '/gate';
  public static PROFILE = Routes.BASE_ROUTE + '/profile';
  public static REQUEST_RESET_PASSWORD = Routes.BASE_ROUTE + '/reset-password';
  public static RESET_PASSWORD_LINK = Routes.BASE_ROUTE + '/resetPassword';
  public static REGISTER = Routes.BASE_ROUTE + '/register';
  public static CONFIRM_EMAIL = Routes.BASE_ROUTE + '/confirmEmail';
  public static CONFIRM_EMAIL_CHANGE = Routes.BASE_ROUTE + '/confirmEmailChange';
  public static RESEND_CONFIRMATION_EMAIL = Routes.BASE_ROUTE + '/resend-confirmation-email';
  public static UNAUTHORIZED = Routes.BASE_ROUTE + '/unauthorized';

  public static GUEST_BASE = Routes.BASE_ROUTE + '/guest';
  public static GUEST_PNF = Routes.GUEST_BASE + '/pnf';
  public static GUEST_BASE_ALT = Routes.BASE_ROUTE + '/pnf';

  public static CONTACTS_BASE = Routes.BASE_ROUTE + '/contacts';
  public static CONTACTS_ID = Routes.CONTACTS_BASE + '/:clientId';

  public static CLIENTS_BASE = Routes.BASE_ROUTE + '/clients';

  // Tools
  public static TOOLS_BASE = Routes.BASE_ROUTE + '/tools';
  public static PROVIDER_DIRECTORY = Routes.TOOLS_BASE + '/providers';
  public static PROVIDER_NOMINATION = Routes.TOOLS_BASE + '/provider-nomination';
  public static BALANCE_BILL = Routes.TOOLS_BASE + '/balance-bill';
  public static CLAIM_SEARCH = Routes.TOOLS_BASE + '/claim-search';
  public static NOMINATION_SEARCH = Routes.TOOLS_BASE + '/nomination-search';
  public static DOC_SCAN = Routes.TOOLS_BASE + '/doc-scan';
  public static PRICING_TOOL = Routes.TOOLS_BASE + '/pricing-tool';
  public static IMPLEMENTATION = Routes.TOOLS_BASE + '/implementation';

  // MemberCards
  public static MEMBER_CARDS = Routes.TOOLS_BASE + '/member-cards';
  public static MEMBER_CARDS_DETAIL = Routes.MEMBER_CARDS + '/:id';
  public static MEMBER_CARDS_CREATE = Routes.MEMBER_CARDS + '/create';

  // Admin
  public static ADMIN_BASE = Routes.BASE_ROUTE + '/admin';

  public static ADMIN_CLIENTS = Routes.ADMIN_BASE + '/clients';
  public static ADMIN_CLIENT_DETAILS = Routes.ADMIN_CLIENTS + '/:id/:tab?';
  public static ADMIN_CLIENT_DETAILS_TPAS = Routes.ADMIN_CLIENT_DETAILS + '/tpas/:tpaId?';
  public static ADMIN_CLIENT_DETAILS_DPCS = Routes.ADMIN_CLIENT_DETAILS + '/dpcs/:dpcId?';
  public static ADMIN_CLIENT_DETAILS_OTHERCOMPANIES = Routes.ADMIN_CLIENT_DETAILS + '/other-companies/:otherCompanyId?';
  public static ADMIN_CLIENT_DETAILS_BROKERS = Routes.ADMIN_CLIENT_DETAILS + '/brokers/:brokerId?';
  public static ADMIN_CLIENT_DETAILS_CLAIMDOC = Routes.ADMIN_CLIENT_DETAILS + '/claimdoc';
  public static ADMIN_CLIENT_DETAILS_SECURITY = Routes.ADMIN_CLIENT_DETAILS + '/security';
  public static ADMIN_CLIENT_INVITE_USERS = Routes.ADMIN_CLIENT_DETAILS + '/invite';
  public static ADMIN_CLIENT_CONFIG = Routes.ADMIN_CLIENT_DETAILS + '/config';
  public static ADMIN_CLIENT_DETAILS_DIVISIONS = Routes.ADMIN_CLIENT_DETAILS + '/divisions';

  public static ADMIN_TPA_LIST = Routes.ADMIN_BASE + '/tpas';
  public static ADMIN_TPA_DETAILS = Routes.ADMIN_TPA_LIST + '/:id/:tab?';
  public static ADMIN_TPA_DETAILS_CLIENTS = Routes.ADMIN_TPA_DETAILS + '/clients/:clientId?';
  public static ADMIN_TPA_DETAILS_SECURITY = Routes.ADMIN_TPA_DETAILS + '/security';
  public static ADMIN_TPA_INVITE_USERS = Routes.ADMIN_TPA_DETAILS + '/invite';

  public static ADMIN_DPC_LIST = Routes.ADMIN_BASE + '/dpcs';
  public static ADMIN_DPC_DETAILS = Routes.ADMIN_DPC_LIST + '/:id/:tab?';
  public static ADMIN_DPC_DETAILS_CLIENTS = Routes.ADMIN_DPC_DETAILS + '/clients/:clientId?';
  public static ADMIN_DPC_DETAILS_SECURITY = Routes.ADMIN_DPC_DETAILS + '/security';
  public static ADMIN_DPC_INVITE_USERS = Routes.ADMIN_DPC_DETAILS + '/invite';

  public static ADMIN_OTHERCOMPANY_LIST = Routes.ADMIN_BASE + '/other-companies';
  public static ADMIN_OTHERCOMPANY_DETAILS = Routes.ADMIN_OTHERCOMPANY_LIST + '/:id/:tab?';
  public static ADMIN_OTHERCOMPANY_DETAILS_CLIENTS = Routes.ADMIN_OTHERCOMPANY_DETAILS + '/clients/:clientId?';
  public static ADMIN_OTHERCOMPANY_DETAILS_SECURITY = Routes.ADMIN_OTHERCOMPANY_DETAILS + '/security';
  public static ADMIN_OTHERCOMPANY_INVITE_USERS = Routes.ADMIN_OTHERCOMPANY_DETAILS + '/invite';

  public static ADMIN_BROKER_LIST = Routes.ADMIN_BASE + '/brokers';
  public static ADMIN_BROKER_DETAILS = Routes.ADMIN_BROKER_LIST + '/:id/:tab?';
  public static ADMIN_BROKER_DETAILS_EDIT = Routes.ADMIN_BROKER_DETAILS + '/edit';
  public static ADMIN_BROKER_DETAILS_CLIENTS = Routes.ADMIN_BROKER_DETAILS + '/clients/:clientId?';
  public static ADMIN_BROKER_DETAILS_SECURITY = Routes.ADMIN_BROKER_DETAILS + '/security';
  public static ADMIN_BROKER_INVITE_USERS = Routes.ADMIN_BROKER_DETAILS + '/invite';

  public static ADMIN_USER_LIST = Routes.ADMIN_BASE + '/users/:type';
  public static ADMIN_USER_DETAIL = Routes.ADMIN_USER_LIST + '/:id/:tab?';

  public static ADMIN_GUEST_ACCESS = Routes.ADMIN_BASE + '/guestAccess';
  public static ADMIN_GUEST_ACCESS_DETAILS = Routes.ADMIN_BASE + '/guestAccess/:id';
  public static ADMIN_GUEST_ACCESS_NEW = Routes.ADMIN_BASE + '/guestAccess/new';

  public static ADMIN_PROVIDER_LIST = Routes.ADMIN_BASE + '/providers';
  public static ADMIN_PROVIDER_DETAILS = Routes.ADMIN_PROVIDER_LIST + '/:id';

  public static ADMIN_TOOLS_COMMUNICATION_TESTER = Routes.ADMIN_BASE + '/communication-tester';

  // Support
  public static SUPPORT_BASE = '/support';
  public static SUPPORT_FAILED_MEMBER_REGISTRATIONS = Routes.SUPPORT_BASE + '/failedRegistrations';
  public static SUPPORT_MEMBER_SEARCH = Routes.SUPPORT_BASE + '/member-search';
  public static SUPPORT_MEMBER_DETAIL = Routes.SUPPORT_MEMBER_SEARCH + '/detail';

  // Reports
  public static REPORTS_BASE = Routes.BASE_ROUTE + '/reports';
  public static REPORTS_POWERBI = Routes.REPORTS_BASE + '/:reportName';

  public static ADMIN_SECURITY = Routes.ADMIN_BASE + '/security';
  public static ADMIN_SECURITY_PERMISSION_GROUPS = Routes.ADMIN_SECURITY + '/permissiongroups/:id?/:tab?';
  public static ADMIN_SECURITY_PERMISSION_AUDIT = Routes.ADMIN_SECURITY + '/permission-audit';

  // Education
  public static EDUCATION_BASE = '/education';

  // Documents
  public static DOCUMENTS_BASE = Routes.BASE_ROUTE + '/documents/:tab?';
  public static DOCUMENTS_VIEW = Routes.BASE_ROUTE + '/document-viewer';

  //Mailroom
  public static MAILROOM_BASE = Routes.BASE_ROUTE + '/mailroom';
  public static MAILROOM_FILE_LIST = Routes.MAILROOM_BASE + '/files/:tab';
  public static MAILROOM_FILE_DETAIL = Routes.MAILROOM_FILE_LIST + '/:id';
  public static MAILROOM_ACCOUNT_LIST = Routes.MAILROOM_BASE + '/accounts';
  public static MAILROOM_ACCOUNT_DETAIL = Routes.MAILROOM_ACCOUNT_LIST + '/:id/:tab?';
  public static MAILROOM_ACCOUNT_DETAIL_FILESETUPS = Routes.MAILROOM_ACCOUNT_DETAIL + '/fileSetups';
  public static MAILROOM_ACCOUNT_NEW = Routes.MAILROOM_ACCOUNT_LIST + '/new';

  /**
   * Generated a url from a route and parameters.
   * @param route Route that may contain parameter placeholders.
   * @param params Object where property names equal the parameter placeholders in the route an the property value is what will be injected.
   */
  public static generate(
    route: string,
    params?: { [paramName: string]: string | number | boolean },
    query?: { [name: string]: any }
  ) {
    let path = generatePath(route, params);

    // Add any query string variables to the route if passed
    if (query) {
      const q = queryString.stringify(query);
      if (q) {
        path += `?${q}`;
      }
    }

    return path;
  }
}

export default Routes;
