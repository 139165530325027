// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import PermissionGroupListDTO from './PermissionGroupListDTO';
import UserRelationsDTO from './UserRelationsDTO';

interface UserDetailDTO { 
    id: number;
    userStatusId: number;
    username: string | null;
    userEmailLinked: boolean;
    email: string | null;
    emailConfirmed: boolean;
    firstName: string | null;
    lastName: string | null;
    phoneNumber: string | null;
    phoneNumberConfirmed: boolean;
    mfaEnabled: boolean;
    lockedOut: boolean;
    createdOn: moment.Moment | string | null;
    lastLoggedInOn: moment.Moment | string | null;
    claimDocPermissionGroups: PermissionGroupListDTO[] | null;
    relationships: UserRelationsDTO | null;
    requireMfa: boolean;
    isActive: boolean;
}
const UserDetailDTO = {
    create: (initValues?: Partial<UserDetailDTO> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            id: 0,
            userStatusId: 0,
            username: null,
            userEmailLinked: false,
            email: null,
            emailConfirmed: false,
            firstName: null,
            lastName: null,
            phoneNumber: null,
            phoneNumberConfirmed: false,
            mfaEnabled: false,
            lockedOut: false,
            createdOn: null,
            lastLoggedInOn: null,
            claimDocPermissionGroups: [],
            relationships: null,
            requireMfa: false,
            isActive: false,
        },
        initValues);
    }
};

export default UserDetailDTO;