import { useHistory, useLocation } from 'react-router';

// eslint-disable-next-line @typescript-eslint/ban-types
function useUrlQuery<T extends { [K in keyof T]?: string | undefined } = {}>(): [T, (name: string) => void] {
  const res = {} as T;

  const location = useLocation();
  const history = useHistory();

  const params = new URLSearchParams(location.search);
  params.forEach((v, k) => {
    res[k] = v;
  });

  const removeParam = (paramName: string) => {
    params.delete(paramName);
    const newSeach = params.toString();
    history.replace({ search: newSeach });
  };

  return [res, removeParam];
}

export default useUrlQuery;
