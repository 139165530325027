import { Layout } from 'antd';
import React from 'react';
import { Route, Switch } from 'react-router';
import Authorize from '../../auth/Authorize';
import ClientDetailPage from '../../components/admin/Clients/ClientDetailPage';
import ClientListPage from '../../components/admin/Clients/ClientListPage';
import Permission from '../../consts/Permission';
import Routes from '../../consts/Routes';

const ClientsPage = () => {
  return (
    <Layout.Content className="content-padding">
      <Switch>
        <Route path={Routes.ADMIN_CLIENTS} exact={true}>
          <Authorize requiredPermissions={[Permission.ADMIN_CLIENT_LIST_VIEW]}>
            <ClientListPage />
          </Authorize>
        </Route>
        <Route path={Routes.ADMIN_CLIENT_DETAILS}>
          <Authorize requiredPermissions={[Permission.ADMIN_CLIENT_DETAILS_VIEW]}>
            <ClientDetailPage />
          </Authorize>
        </Route>
      </Switch>
    </Layout.Content>
  );
};

export default ClientsPage;
