import { EditOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PnfDTO from '../../../../models/PnfDTO';
import { formatPhoneNumber } from '../../../../utils/FormatUtils';
import { PnfSteps } from '../../ProviderNomination';
import { firstColProps, secondColProps } from './Review';
import UserApiService from '../../../../api/UserApiService';
import FamilyRelationshipTypes from '../../../../consts/FamilyRelationshipTypes';
import SpouseRelationships from '../../../../consts/SpouseRelationships';
import PnfMemberInfoDTO from '../../../../models/PnfMemberInfoDTO';

export const ContactMethods = {
  call: 'Phone',
  email: 'Email',
  text: 'Text',
};

interface ContactInfoProps {
  pnf: PnfDTO;
  goToStep?: (step: PnfSteps) => void;
  editMember?: (id: string) => void;
}

const ContactInfo = (props: ContactInfoProps) => {
  const info = props.pnf.primaryMember;
  const [familyRelationship, setFamilyRelationship] = useState<number | undefined>(undefined);

  if (!info) {
    return null;
  }

  useEffect(() => {
    UserApiService.getFamilyRelationship().then((res) => {
      setFamilyRelationship(res);
    });
  }, []);

  const familyMembers = props.pnf.familyMembers ?? [];

  const showCallAddons = info.contactMethods?.includes(ContactMethods.call) ?? false;

  const renderDateOfBirth = (member: PnfMemberInfoDTO) => {
    if (
      familyRelationship == FamilyRelationshipTypes.SPOUSE &&
      member.primaryRelationship == SpouseRelationships.EMPLOYEE
    ) {
      return;
    }

    return (
      <Row>
        <Col {...firstColProps}>Date of Birth:</Col>
        <Col {...secondColProps}>{moment(member.dateOfBirth).format('MM/DD/YYYY')}</Col>
      </Row>
    );
  };

  const renderAddress = (member: PnfMemberInfoDTO) => {
    if (
      member.addressMatchesPrimary ||
      (familyRelationship == FamilyRelationshipTypes.SPOUSE &&
        member.primaryRelationship == SpouseRelationships.EMPLOYEE)
    ) {
      return;
    }
    return (
      <>
        <Row>
          <Col {...firstColProps}>Address:</Col>
          <Col {...secondColProps}>{member.address?.address1}</Col>
        </Row>
        {(member.address?.address2 ?? '').length > 0 ? (
          <Row>
            <Col {...firstColProps}>Address 2:</Col>
            <Col {...secondColProps}>{member.address?.address2}</Col>
          </Row>
        ) : null}
        <Row>
          <Col {...firstColProps}>City/State/Zip:</Col>
          <Col {...secondColProps}>
            {member.address?.city}, {member.address?.state} {member.address?.zipCode}
          </Col>
        </Row>
      </>
    );
  };

  const relationship =
    familyRelationship && familyRelationship == FamilyRelationshipTypes.SPOUSE ? 'Spouse' : 'Primary';

  return (
    <>
      <h2>Contact Info</h2>

      <Card className="ant-card-background ant-card-shadow" bordered={false}>
        <Row gutter={[12, 12]} justify="space-between" align="middle">
          <Col flex={1}>
            <strong>{info.firstName + ' ' + info.lastName}</strong>
            <Row>
              <Col {...firstColProps}>Date of Birth:</Col>
              <Col {...secondColProps}>{moment(info.dateOfBirth).format('MM/DD/YYYY')}</Col>
            </Row>
            <Row>
              <Col {...firstColProps}>Email Address:</Col>
              <Col {...secondColProps}>{info.email}</Col>
            </Row>
            <Row>
              <Col {...firstColProps}>Phone Number:</Col>
              <Col {...secondColProps}>
                {formatPhoneNumber(info.phone ?? '')} {info.phoneIsMobile ? '(Mobile)' : null}
              </Col>
            </Row>
            <Row>
              <Col {...firstColProps}>Preferred Language:</Col>
              <Col {...secondColProps}>{info.languageId == 1 ? 'English' : 'Spanish'}</Col>
            </Row>
            <Row>
              <Col {...firstColProps}>Address:</Col>
              <Col {...secondColProps}>{info.address?.address1}</Col>
            </Row>
            {(info.address?.address2 ?? '').length > 0 ? (
              <Row>
                <Col {...firstColProps}>Address 2:</Col>
                <Col {...secondColProps}>{info.address?.address2}</Col>
              </Row>
            ) : null}
            <Row>
              <Col {...firstColProps}>City/State/Zip:</Col>
              <Col {...secondColProps}>
                {info.address?.city}, {info.address?.state} {info.address?.zipCode}
              </Col>
            </Row>
            <Row>
              <Col {...firstColProps}>Preferred Contact Method:</Col>
              <Col {...secondColProps}>{info.contactMethods ? info.contactMethods.join(', ') : null}</Col>
            </Row>
            {showCallAddons ? (
              <Row>
                <Col {...firstColProps}>Preferred Contact Time:</Col>
                <Col {...secondColProps}>
                  {info.contactTime} {info.detailedVoicemail ? '(Detailed Voicemail)' : undefined}
                </Col>
              </Row>
            ) : null}
          </Col>
          <Col flex={0}>
            {props.goToStep ? (
              <Button
                type="link"
                icon={<EditOutlined />}
                onClick={() => {
                  if (props.goToStep) {
                    props.goToStep(PnfSteps.Contact);
                  }
                }}
                size="large"
              />
            ) : null}
          </Col>
        </Row>
      </Card>

      {familyMembers.length > 0 ? (
        <>
          <br />
          <h2>Family Members</h2>

          {familyMembers.map((m) => {
            return (
              <Card
                key={`fm_${m.id}`}
                className="ant-card-background ant-card-shadow"
                bordered={false}
                style={{ marginBottom: 16 }}
              >
                <Row gutter={[12, 12]} justify="space-between" align="middle">
                  <Col flex={1}>
                    <strong>
                      {m.firstName} {m.lastName} ({m.primaryRelationship})
                    </strong>
                    {renderDateOfBirth(m)}
                    <Row>
                      <Col {...firstColProps}>Email Address:</Col>
                      <Col {...secondColProps}>
                        {m.emailMatchesPrimary ? '[Same As ' + relationship + ']' : <>{m.email}</>}
                      </Col>
                    </Row>
                    <Row>
                      <Col {...firstColProps}>Phone Number:</Col>
                      <Col {...secondColProps}>
                        {m.phoneMatchesPrimary ? (
                          '[Same As ' + relationship + ']'
                        ) : (
                          <>
                            {formatPhoneNumber(m.phone ?? '')} {m.phoneIsMobile ? '(Mobile)' : null}
                          </>
                        )}
                      </Col>
                    </Row>
                    {renderAddress(m)}
                  </Col>
                  <Col flex={0}>
                    {props.editMember ? (
                      <Button
                        icon={<EditOutlined />}
                        size="large"
                        type="link"
                        onClick={() => {
                          if (props.editMember) {
                            props.editMember(m.id ?? '');
                            if (props.goToStep) {
                              props.goToStep(PnfSteps.Family);
                            }
                          }
                        }}
                      />
                    ) : null}
                  </Col>
                </Row>
              </Card>
            );
          })}
        </>
      ) : null}
    </>
  );
};

export default ContactInfo;
