// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';

interface SupportMemberSearchFilterDTO { 
    clientId: number;
    email: string | null;
    firstName: string | null;
    lastName: string | null;
}
const SupportMemberSearchFilterDTO = {
    create: (initValues?: Partial<SupportMemberSearchFilterDTO> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            clientId: 0,
            email: null,
            firstName: null,
            lastName: null,
        },
        initValues);
    }
};

export default SupportMemberSearchFilterDTO;