import { DownOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Layout, Popover, Space } from 'antd';
import React, { useContext } from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import { AuthenticationContext } from '../auth/AuthenticationContext';
import PinWrapper from '../auth/PinWrapper';
import HeaderAccountContent from '../components/layout/HeaderAccountContent';
import HeaderContactContent from '../components/layout/HeaderContactContent';
import Routes from '../consts/Routes';
import GuestHome from '../pages/guest/GuestHome';
import PnfPage from '../pages/guest/PnfPage';
import { RedirectAs404 } from '../utils/RouteErrors';

const { Header, Content } = Layout;

const GuestLayout = () => {
  const authContext = useContext(AuthenticationContext);

  const user = authContext?.user;
  const isAuthenticated = user != null && user.isGuestAccount;
  const username =
    isAuthenticated && user?.guestAccountType === 'INDIVIDUAL'
      ? 'Guest'
      : isAuthenticated && user?.guestAccountType === 'CLIENT'
      ? 'Guest Account'
      : undefined;

  return (
    <Layout className="layout-fullscreen">
      <Header>
        <Link to={Routes.HOME_ROUTE} className="header-logo">
          <img src={process.env.PUBLIC_URL + '/claimdoc-logo_simple.svg'} alt="ClaimDOC" />
          <img src={process.env.PUBLIC_URL + '/claimdoc-logo_icon.svg'} alt="ClaimDOC" className="header-logo-icon" />
        </Link>
        <Space>
          <Popover
            placement="bottomRight"
            content={HeaderContactContent}
            trigger="click"
            overlayClassName="header-contact-content"
          >
            <Button type="link" style={{ height: 'unset' }}>
              <PhoneOutlined /> ClaimDOC Assistance <DownOutlined />
            </Button>
          </Popover>
          {isAuthenticated ? (
            <Popover
              placement="bottomRight"
              content={HeaderAccountContent(true)}
              trigger="click"
              overlayClassName="header-account-content"
            >
              <Button type="link" style={{ height: 'unset' }}>
                <UserOutlined /> {username} <DownOutlined />
              </Button>
            </Popover>
          ) : null}
        </Space>
      </Header>
      <Layout>
        <Content id="app-main" className="page-content">
          <div className="full-height">
            <PinWrapper>
              <Switch>
                <Route path={Routes.GUEST_BASE} exact={true} component={GuestHome} />
                <Route path={Routes.GUEST_BASE_ALT} exact={true} component={GuestHome} />
                <Route path={Routes.GUEST_PNF} component={PnfPage} />

                {/* This needs to be the last item */}
                <Route component={RedirectAs404} />
              </Switch>
            </PinWrapper>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default GuestLayout;
