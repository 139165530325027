// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import PermissionDTO from './PermissionDTO';

interface PermissionGroupPermissionDTO { 
    isManaged: boolean;
    permissionId: number;
    permission: PermissionDTO | null;
    delete: boolean;
}
const PermissionGroupPermissionDTO = {
    create: (initValues?: Partial<PermissionGroupPermissionDTO> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            isManaged: false,
            permissionId: 0,
            permission: null,
            delete: false,
        },
        initValues);
    }
};

export default PermissionGroupPermissionDTO;