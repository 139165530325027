import { notification } from 'antd';
import React, { useEffect, useState } from 'react';
import DpcApiService from '../../../api/DpcApiService';
import Permission from '../../../consts/Permission';
import Routes from '../../../consts/Routes';
import useHasPermission from '../../../hooks/useHasPermission';
import ClientListDTO from '../../../models/ClientListDTO';
import AccessList, { AccessData } from '../../shared/EntityAccess/AccessList';

interface DpcAccessListProps {
  dpcId: number;
}

const DpcClientAccessList = (props: DpcAccessListProps) => {
  const [loading, setLoading] = useState(true);
  const [entityList, setEntityList] = useState<ClientListDTO[]>([]);
  const hasPermission = useHasPermission();

  const fetch = (id: number) => {
    setLoading(true);
    DpcApiService.getClientsForDpc(id)
      .then((res) => {
        setEntityList(res);
      })
      .catch(() => {
        // TODO: notification
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteAccess = (id: number) => {
    setLoading(true);
    DpcApiService.deleteDpcClientAccess(id, props.dpcId)
      .then((results) => {
        if (results.succeeded) {
          notification.success({ message: 'DPC successfully removed from client.' });
          fetch(props.dpcId);
        } else {
          throw '';
        }
      })
      .catch(() => {
        notification.error({ message: 'An error occurred while trying to remove DPC from client.' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetch(props.dpcId);
  }, []);

  const listData = entityList.map((t) => {
    return {
      id: t.id,
      name: t.name,
      location: t.location,
      phoneNumber: t.phoneNumber,
      isActive: t.isActive,
      endDate: t.endDate,
      startDate: t.startDate,
    } as AccessData;
  });

  return (
    <AccessList
      abbreviation="Client"
      addText="Assign new Client to DPC"
      fullName="client"
      tableData={listData}
      onDelete={deleteAccess}
      editUrl={(id?: number) =>
        Routes.generate(Routes.ADMIN_DPC_DETAILS_CLIENTS, { id: props.dpcId, clientId: id?.toString() ?? 'new' })
      }
      loading={loading}
      readOnly={!hasPermission(Permission.ADMIN_DPC_CLIENTS_EDIT)}
    />
  );
};

export default DpcClientAccessList;
