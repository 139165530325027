// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import RelationDTO from './RelationDTO';

interface UserRelationsDTO { 
    tpas: RelationDTO[] | null;
    dpcs: RelationDTO[] | null;
    otherCompanies: RelationDTO[] | null;
    brokers: RelationDTO[] | null;
    clients: RelationDTO[] | null;
    claimDoc: RelationDTO[] | null;
}
const UserRelationsDTO = {
    create: (initValues?: Partial<UserRelationsDTO> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            tpas: [],
            dpcs: [],
            otherCompanies: [],
            brokers: [],
            clients: [],
            claimDoc: [],
        },
        initValues);
    }
};

export default UserRelationsDTO;