// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';

interface PinAccessDTO { 
    id: number;
    pin: string | null;
    pinType: string | null;
    expirationDate: moment.Moment | string | null;
    note: string | null;
    clientId: number | null;
    clientName: string | null;
    userEmail: string | null;
    parentPinId: number | null;
    parentPinName: string | null;
    permissionGroupId: number | null;
    permissionGroupName: string | null;
    createdBy: string | null;
    createdOn: moment.Moment | string;
    updatedBy: string | null;
    updatedOn: moment.Moment | string;
    canEditExpiration: boolean;
}
const PinAccessDTO = {
    create: (initValues?: Partial<PinAccessDTO> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            id: 0,
            pin: null,
            pinType: null,
            expirationDate: null,
            note: null,
            clientId: null,
            clientName: null,
            userEmail: null,
            parentPinId: null,
            parentPinName: null,
            permissionGroupId: null,
            permissionGroupName: null,
            createdBy: null,
            createdOn: new Date(0).toISOString(),
            updatedBy: null,
            updatedOn: new Date(0).toISOString(),
            canEditExpiration: false,
        },
        initValues);
    }
};

export default PinAccessDTO;