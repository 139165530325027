import { notification } from 'antd';
import React, { useEffect, useState } from 'react';
import ClientService from '../../../api/ClientApiService';
import Permission from '../../../consts/Permission';
import Routes from '../../../consts/Routes';
import useHasPermission from '../../../hooks/useHasPermission';
import DpcListDTO from '../../../models/DpcListDTO';
import AccessList, { AccessData } from '../../shared/EntityAccess/AccessList';

interface DpcAccessListProps {
  clientId: number;
  readonly?: boolean;
}

const DpcAccessList = (props: DpcAccessListProps) => {
  const [loading, setLoading] = useState(true);
  const [dpcList, setDpcList] = useState<DpcListDTO[]>([]);
  const hasPermission = useHasPermission();

  const fetch = (id: number) => {
    setLoading(true);
    ClientService.getDpcsForClient(id)
      .then((res) => {
        setDpcList(res);
      })
      .catch(() => {
        // TODO: notification
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteDpcClientAccess = (dpcId: number) => {
    setLoading(true);
    ClientService.deleteDpcClientAccess(props.clientId, dpcId)
      .then((results) => {
        if (results.succeeded) {
          notification.success({ message: 'DPC successfully removed from client.' });
          fetch(props.clientId);
        } else {
          throw '';
        }
      })
      .catch(() => {
        notification.error({ message: 'An error occurred while trying to remove DPC from client.' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetch(props.clientId);
  }, []);

  const listData = dpcList.map((t) => {
    return {
      id: t.id,
      name: t.name,
      location: t.location,
      phoneNumber: t.phoneNumber,
      isActive: t.isActive,
      endDate: t.endDate,
      startDate: t.startDate,
    } as AccessData;
  });

  return (
    <AccessList
      abbreviation="DPC"
      addText="Assign New DPC"
      fullName="dpc"
      tableData={listData}
      onDelete={deleteDpcClientAccess}
      editUrl={(id?: number) =>
        Routes.generate(Routes.ADMIN_CLIENT_DETAILS_DPCS, { id: props.clientId, dpcId: id?.toString() ?? 'new' })
      }
      readOnly={!hasPermission(Permission.ADMIN_CLIENT_DPC_EDIT) || !!props.readonly}
      loading={loading}
    />
  );
};

export default DpcAccessList;
