import { LoadingOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Form, Input, Row } from 'antd';
import React, { useState } from 'react';
import AccountApiService from '../../api/AccountApiService';
import Routes from '../../consts/Routes';
import HistoryUtil from '../../utils/HistoryUtil';
import LoginLayout from './LoginLayout';

const FormItem = Form.Item;

const RequestResetPasswordPage = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [resetSuccess, setResetSuccess] = useState(false);

  const goToLogin = () => {
    HistoryUtil.push(Routes.generate(Routes.LOGIN));
  };

  const handleSubmit = (model: any) => {
    setLoading(true);
    setError(null);
    setResetSuccess(false);

    if (model.emailAddress && /@claim-doc.com\s*$/.test(model.emailAddress)) {
      if (typeof window !== undefined) {
        let url = 'https://passwordreset.microsoftonline.com/';
        const prefix = url.indexOf('?') > 0 ? '&' : '?';
        url += `${prefix}userName=${encodeURIComponent(model.emailAddress)}`;

        window.location.href = url;
      }
    } else {
      AccountApiService.forgotPassword(model.emailAddress).then(
        () => {
          setLoading(false);
          setResetSuccess(true);
          setError(null);
        },
        (error) => {
          setLoading(false);
          setResetSuccess(false);
          setError(error);
        }
      );
    }
  };

  let alert: JSX.Element | null = null;

  if (error !== null) {
    let message = 'An error occurred while trying to send the reset password email.';
    if (error.message) {
      message = error.message;
    }
    alert = (
      <Alert
        message="Error"
        description={message}
        type="error"
        showIcon={true}
        style={{ marginBottom: '12px', whiteSpace: 'pre-wrap' }}
      />
    );
  }
  if (resetSuccess) {
    const message = 'An email with a link to reset your password was sent to the email you provided.';
    alert = (
      <Alert message="Success" description={message} type="success" showIcon={true} style={{ marginBottom: '12px' }} />
    );
  }

  return (
    <LoginLayout
      cardTitle="Reset Password"
      prompt={
        <>
          Please enter the email associated with your account. <br />
          You should receive an email titled <b>ClaimDOC - Password Reset</b> from{' '}
          <b>claimdocportalnotification@claim-doc.com</b> within 10 minutes.
        </>
      }
    >
      <Row justify="center">
        <Col xs={24} sm={18}>
          {alert}
          <Form layout="vertical" onFinish={handleSubmit} requiredMark={false}>
            <FormItem
              name="emailAddress"
              label="Email Address"
              rules={[{ required: true, message: 'A valid Email Address is required', type: 'email' }]}
            >
              <Input placeholder="Email" />
            </FormItem>
            <FormItem>
              <Button type="primary" htmlType="submit" size="large" disabled={loading} shape="round" block>
                {loading ? (
                  <span>
                    <LoadingOutlined /> Processing...
                  </span>
                ) : (
                  <span>Reset Password</span>
                )}
              </Button>
            </FormItem>
            <FormItem>
              <Button onClick={goToLogin} htmlType="button" size="large" disabled={loading} shape="round" block ghost>
                Back to Login
              </Button>
            </FormItem>
          </Form>
        </Col>
      </Row>
    </LoginLayout>
  );
};

export default RequestResetPasswordPage;
