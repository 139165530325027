import { Button, Col, Layout, notification, Row, Space } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ClientApiService from '../../../api/ClientApiService';
import DataManagementApiService from '../../../api/DataManagementApiService';
import Permission from '../../../consts/Permission';
import Routes from '../../../consts/Routes';
import useHasPermission from '../../../hooks/useHasPermission';
import ClientListDTO from '../../../models/ClientListDTO';
import TableRequestDTO from '../../../models/TableRequestDTO';
import TableResponseDTO from '../../../models/TableResponseDTO';
import HistoryUtil from '../../../utils/HistoryUtil';
import MobileUtil from '../../../utils/MobileUtil';
import Renderers from '../../../utils/Renderers';
import DataTable, { FilterType } from '../../shared/DataTable/DataTable';
import DataTableColumnUtil from '../../shared/DataTable/DataTableColumnUtil';
import PageTitle from '../../shared/PageTitle';
import PermissionGuard from '../../shared/PermissionGuard';
import { GetListViewLink } from './ClientDetailPage';

const isMobile = () => MobileUtil.getMediaQuery()?.matches;

const Content = Layout.Content;

const ClientListPage = () => {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<ClientListDTO[]>([]);
  const hasPermission = useHasPermission();
  const dataTableRef = React.useRef<DataTable<any>>(null);

  const goToClientDetails = (clientId: number) => {
    HistoryUtil.push(
      Routes.generate(Routes.ADMIN_CLIENT_DETAILS, {
        id: clientId,
      })
    );
  };

  const fetchClientTableData = (
    requestState: TableRequestDTO,
    checkEcho: () => boolean,
    callback: (response: TableResponseDTO<ClientListDTO>) => void
  ) => {
    setLoading(true);

    ClientApiService.getClientList(requestState)
      .then((results) => {
        if (!checkEcho()) {
          return;
        }

        setTableData(results.results || []);
        setLoading(false);

        callback(results);
      })
      .catch((error: any) => {
        setLoading(false);
        notification.error({
          message: error.message,
          description: error.description,
        });
      });
  };
  const syncClients = () => {
    setLoading(true);
    DataManagementApiService.executeClientSync()
      .then(() => {
        notification.success({ message: 'Sync Complete' });
      })
      .catch(() => {
        notification.error({ message: 'Sync Failed' });
      })
      .finally(() => {
        setLoading(false);
        dataTableRef.current?.refresh();
      });
  };

  return (
    <Content>
      <Row>
        <Col xs={24}>
          <Row className="content-header" justify="space-between">
            <Col xs={24} lg={10} xl={13} xxl={14}>
              <PageTitle title="Clients" />
            </Col>
            <Col>
              <Space direction="horizontal">
                <PermissionGuard permissionId={Permission.ETL_CLIENT_EXECUTESYNC}>
                  <Button type="primary" shape="round" onClick={syncClients} disabled={loading}>
                    Sync Clients
                  </Button>
                </PermissionGuard>
              </Space>
            </Col>
          </Row>
          <DataTable
            serverSide={true}
            globalSearch={true}
            ref={dataTableRef}
            globalSearchPlaceholder="Search clients..."
            tableProps={{
              rowKey: 'id',
              size: 'large',
              loading: loading,
              sortDirections: ['ascend', 'descend'],
              scroll: { x: 575 },
              onRow: (record: ClientListDTO) => {
                return {
                  onDoubleClick: () => {
                    if (!isMobile()) {
                      goToClientDetails(record.id);
                    }
                  },
                  onClick: () => {
                    if (isMobile()) {
                      goToClientDetails(record.id);
                    }
                  },
                };
              },
            }}
            columns={[
              { ...DataTableColumnUtil.Text<ClientListDTO>('Client Id', 'claimDocId'), filterType: FilterType.NONE },
              { ...DataTableColumnUtil.Text<ClientListDTO>('Code', 'clientCode'), filterType: FilterType.NONE },
              {
                title: 'Name',
                dataIndex: 'name',
                defaultSortOrder: 'ascend',
                sorter: {},
              },
              {
                title: 'Location',
                dataIndex: 'location',
                sorter: {},
                renderDataTransform: (value: any) => (value != null && value.trim().length > 1 ? value : ''),
              },
              {
                title: 'Phone Number',
                dataIndex: 'phoneNumber',
                render: (v) => Renderers.phoneNumber(v),
              },
              {
                title: 'Term. Date',
                dataIndex: 'termDate',
                sorter: {},
                render: (v) => Renderers.shortDate(v),
              },
              {
                title: '',
                dataIndex: '',
                width: '40px',
                // eslint-disable-next-line react/display-name
                render: (rowData: ClientListDTO) => (
                  <Link to={GetListViewLink(rowData.id, hasPermission)}>
                    <Button shape="round" ghost>
                      Details
                    </Button>
                  </Link>
                ),
              },
            ]}
            data={tableData}
            fetchData={fetchClientTableData}
            styleOptions={{
              alternatingRowHighlight: true,
            }}
          />
        </Col>
      </Row>
    </Content>
  );
};

export default ClientListPage;
