// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';


export class OAuth2ApiService extends BaseApi {

    // post: api/oauth2/token
    public getToken(): Promise<void> {
        let url = `api/oauth2/token`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, void>(null, 'post', url, true, false);
    }

    // post: api/oauth2/authorize
    public authorize(): Promise<void> {
        let url = `api/oauth2/authorize`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, void>(null, 'post', url, true, false);
    }

    // post: api/oauth2/CreateServiceAccount?username=${encodeURIComponent(username)}&displayName=${encodeURIComponent(displayName)}&email=${encodeURIComponent(email)}&permissionGroupId=${permissionGroupId}
    public createServiceAccount(username: string, displayName: string, email: string, permissionGroupId: number): Promise<string> {
        let url = `api/oauth2/CreateServiceAccount`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (username != null) {
            url += `${prefix}username=${encodeURIComponent(username)}`;
            prefix = '&';
        }
        if (displayName != null) {
            url += `${prefix}displayName=${encodeURIComponent(displayName)}`;
            prefix = '&';
        }
        if (email != null) {
            url += `${prefix}email=${encodeURIComponent(email)}`;
            prefix = '&';
        }
        if (permissionGroupId != null) {
            url += `${prefix}permissionGroupId=${permissionGroupId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, string>(null, 'post', url, true, false);
    }
}
var service = new OAuth2ApiService();
export default service;
