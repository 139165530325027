import { Card, Col, notification, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import ContactsApiService from '../../api/ContactsApiService';
import ContactType from '../../consts/ContactType';
import ContactDTO from '../../models/ContactDTO';
import Renderers from '../../utils/Renderers';

interface ContactViewProps {
  clientId: number;
  showContacts: boolean;
}

const ContactView = (props: ContactViewProps) => {
  const [loading, setLoading] = useState(false);
  const [brokerContact, setBrokerContact] = useState<ContactDTO | undefined>(undefined);
  const [tpaContact, setTpaContact] = useState<ContactDTO | undefined>(undefined);
  const [claimDocContacts, setClaimDocContacts] = useState<ContactDTO[] | undefined>(undefined);

  const renderContact = (title: string | undefined, contact: ContactDTO | undefined) => {
    if (!contact) {
      return null;
    }
    return (
      <Col flex="auto" key={`contact_${title}`}>
        {/* cover={<img src={contact.image ?? undefined} />} */}
        <Card title={title}>
          {contact.title != null && <h4>{contact.title}</h4>}
          {contact.name != null && (
            <div>
              {contact.name}
              <br />
            </div>
          )}
          {contact.phone != null && (
            <div>
              {Renderers.phoneNumberLink(contact.phone)}
              <br />
            </div>
          )}
          {contact.email != null && (
            <div>
              {Renderers.emailLink(contact.email)}
              <br />
            </div>
          )}
          {contact.url != null && (
            <div>
              <a href={contact.url} target="_blank" rel="noreferrer">
                {contact.url}
              </a>
            </div>
          )}
        </Card>
      </Col>
    );
  };

  useEffect(() => {
    setLoading(true);

    const brokerPromise = props.showContacts
      ? ContactsApiService.getContactByClientId(props.clientId, ContactType.BROKER)
      : Promise.resolve([]);
    const tpaPromise = props.showContacts
      ? ContactsApiService.getContactByClientId(props.clientId, ContactType.TPA)
      : Promise.resolve([]);
    const claimDocPromise = props.showContacts
      ? ContactsApiService.getContactByClientId(props.clientId, ContactType.CLAIMDOC)
      : Promise.resolve([]);

    Promise.all([brokerPromise, tpaPromise, claimDocPromise])
      .then((results) => {
        const [broker, tpa, claimDoc] = results;
        setBrokerContact(broker.length > 0 ? broker[0] : undefined);
        setTpaContact(tpa.length > 0 ? tpa[0] : undefined);
        setClaimDocContacts(claimDoc.sort((a, b) => a.displayOrder - b.displayOrder));
        setLoading(false);
      })
      .catch((err) => {
        notification.error({ message: err.message, description: err.description });
      });
  }, [props.clientId, props.showContacts]);

  return (
    <Spin spinning={loading}>
      <Row gutter={[12, 12]}>
        {renderContact('Broker', brokerContact)}
        {renderContact('Third Party Administrator', tpaContact)}
        {(claimDocContacts?.length ?? 0) > 0
          ? claimDocContacts?.map((contact) => renderContact('ClaimDOC Account Management', contact))
          : null}
      </Row>
    </Spin>
  );
};

export default ContactView;
