import { Layout } from 'antd';
import React from 'react';
import { Route, Switch } from 'react-router';
import Authorize from '../../auth/Authorize';
import Permission from '../../consts/Permission';
import Routes from '../../consts/Routes';
import MailroomAccountDetailPage from './MailroomAccountDetailPage';
import MailroomAccountListPage from './MailroomAccountListPage';

const MailroomAccountPage = () => {
  return (
    <Layout.Content className="content-padding">
      <Switch>
        <Route path={Routes.MAILROOM_ACCOUNT_LIST} exact={true}>
          <Authorize requiredPermissions={[Permission.UTILITIES_MAILROOM_ACCOUNT_VIEW]}>
            <MailroomAccountListPage />
          </Authorize>
        </Route>
        <Route path={Routes.MAILROOM_ACCOUNT_DETAIL}>
          <Authorize requiredPermissions={[Permission.UTILITIES_MAILROOM_ACCOUNT_VIEW]}>
            <MailroomAccountDetailPage />
          </Authorize>
        </Route>
        <Route path={Routes.MAILROOM_ACCOUNT_NEW}>
          <Authorize requiredPermissions={[Permission.UTILITIES_MAILROOM_ACCOUNT_EDIT]}>
            <MailroomAccountDetailPage />
          </Authorize>
        </Route>
      </Switch>
    </Layout.Content>
  );
};

export default MailroomAccountPage;
