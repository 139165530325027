import { Button, Col, Layout, Menu, notification, Row, Space } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, NavLink, useParams } from 'react-router-dom';
import UserApiService from '../../api/UserApiService';
import DataTable, { DataTableColumnProps, FilterType } from '../../components/shared/DataTable/DataTable';
import PageTitle from '../../components/shared/PageTitle';
import Permission from '../../consts/Permission';
import Routes from '../../consts/Routes';
import useHasPermission from '../../hooks/useHasPermission';
import TableRequestDTO from '../../models/TableRequestDTO';
import TableResponseDTO from '../../models/TableResponseDTO';
import UserListDTO from '../../models/UserListDTO';
import CreateServiceAccountButton from '../../components/admin/Users/CreateServiceAccountModal';
import HistoryUtil from '../../utils/HistoryUtil';
import MobileUtil from '../../utils/MobileUtil';
import DataTableColumnUtil from '../../components/shared/DataTable/DataTableColumnUtil';
import DataManagementApiService from '../../api/DataManagementApiService';
import UserTabTypes from '../../consts/UserTabTypes';
import RoleName from '../../consts/RoleName';

const Content = Layout.Content;

const isMobile = () => MobileUtil.getMediaQuery()?.matches;

interface UserListPageRouteProps {
  type: string;
}

const UserListPage = () => {
  const routeProps = useParams<UserListPageRouteProps>();
  const [selectedTab, setSelectedTab] = useState(routeProps.type);
  const [loading, setLoading] = useState(false);
  const dataTableRef = React.useRef<DataTable<any>>(null);
  const [tableData, setTableData] = useState<UserListDTO[]>([]);
  const hasPermission = useHasPermission();

  useEffect(() => {
    const tab = routeProps.type;
    if (tab !== selectedTab) {
      setSelectedTab(tab);
      dataTableRef.current?.resetTable();
      dataTableRef.current?.refresh();
    }
  }, [routeProps]);

  const fetchUserTableData = (
    requestState: TableRequestDTO,
    checkEcho: () => boolean,
    callback: (response: TableResponseDTO<UserListDTO>) => void
  ) => {
    setLoading(true);

    UserApiService.getUserList(selectedTab, requestState)
      .then((results) => {
        setLoading(false);
        if (!checkEcho()) {
          return;
        }

        setTableData(results.results ?? []);

        callback(results);
      })
      .catch((error: any) => {
        setLoading(false);
        notification.error({
          message: error.message,
          description: error.description,
        });
      });
  };

  const goToDetails = (id: number) => {
    HistoryUtil.push(
      Routes.generate(Routes.ADMIN_USER_DETAIL, {
        id: id,
        type: routeProps.type,
      })
    );
  };

  const syncMembers = () => {
    setLoading(true);
    DataManagementApiService.executeFamilyUnitSync()
      .then(() => {
        notification.success({ message: 'Sync Complete' });
      })
      .catch(() => {
        notification.error({ message: 'Sync Failed' });
      })
      .finally(() => {
        setLoading(false);
        dataTableRef.current?.refresh();
      });
  };

  const syncClaimDocMembers = () => {
    setLoading(true);
    DataManagementApiService.executeClaimDocMemberSync()
      .then(() => {
        notification.success({ message: 'Sync Complete' });
      })
      .catch(() => {
        notification.error({ message: 'Sync Failed' });
      })
      .finally(() => {
        setLoading(false);
        dataTableRef.current?.refresh();
      });
  };

  const tableColumns: DataTableColumnProps<UserListDTO>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      defaultSortOrder: 'ascend',
      sorter: {},
      filterType: FilterType.Text,
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      defaultSortOrder: 'ascend',
      sorter: {},
      filterType: FilterType.Text,
    },
    {
      title: 'Email',
      dataIndex: 'username',
      defaultSortOrder: 'ascend',
      sorter: {},
      filterType: FilterType.Text,
    },
    {
      title: 'Date Created',
      dataIndex: 'createdOn',
      sorter: {},
      renderDataTransform: (value: any) => (value != null ? moment(value).format('MM/DD/YYYY hh:mma') : null),
      filterType: FilterType.DateRange,
    },
    DataTableColumnUtil.BooleanYesNo('Registered', 'registered', undefined, FilterType.BooleanRadio),
    {
      title: 'Last Logged In',
      dataIndex: 'lastLoggedInOn',
      sorter: {},
      renderDataTransform: (value: any) => (value != null ? moment(value).format('MM/DD/YYYY hh:mma') : null),
      filterType: FilterType.DateRange,
    },
  ];

  if (selectedTab === UserTabTypes.BROKERS) {
    tableColumns.splice(1, 0, {
      title: 'HubSpot IDs',
      dataIndex: 'hubSpotIds',
      render: (v: any[]) => v.join(','),
      filterType: FilterType.Text,
    });
  }

  if (selectedTab === UserTabTypes.MEMBERS) {
    tableColumns.push({
      title: 'Relationship',
      dataIndex: 'relationship',
    });
  }

  if (selectedTab !== UserTabTypes.ALL && selectedTab !== UserTabTypes.CLAIMDOC) {
    tableColumns.push({
      title: 'Parent',
      dataIndex: 'parentEntity',
      sorter: {},
      filterType: FilterType.Text,
    });
  }

  if (selectedTab === UserTabTypes.ALL) {
    tableColumns.push({
      title: 'Role',
      dataIndex: 'role',
      defaultSortOrder: 'ascend',
      sorter: {},
      filterType: FilterType.DropdownMulti,
      dropdownFilterOptions: [
        { text: RoleName.API, value: RoleName.API },
        { text: RoleName.BROKER, value: RoleName.BROKER },
        { text: RoleName.CLAIMDOC, value: RoleName.CLAIMDOC },
        { text: RoleName.DPC, value: RoleName.DPC },
        { text: RoleName.EMPLOYER, value: RoleName.EMPLOYER },
        { text: RoleName.MEMBER, value: RoleName.MEMBER },
        { text: RoleName.OTHERCOMPANY, value: RoleName.OTHERCOMPANY },
        { text: RoleName.TPA, value: RoleName.TPA },
      ],
    });

    tableColumns.push({
      title: 'Status',
      dataIndex: 'status',
      defaultSortOrder: 'ascend',
      sorter: {},
    });
  }

  if (hasPermission(Permission.ADMIN_USERS_DETAILS_VIEW)) {
    tableColumns.push({
      title: '',
      dataIndex: '',
      width: '40px',
      // eslint-disable-next-line react/display-name
      render: (rowData: UserListDTO) => (
        <Link
          to={Routes.generate(Routes.ADMIN_USER_DETAIL, {
            id: rowData.id,
            type: routeProps.type,
          })}
        >
          <Button shape="round" ghost>
            Details
          </Button>
        </Link>
      ),
    });
  }

  return (
    <Content className="content-padding">
      <Row>
        <Col xs={24}>
          <Row className="content-header">
            <Col flex={1}>
              <PageTitle title="Users" />
            </Col>
            <Col flex={0}>
              <Space direction="horizontal">
                {selectedTab === UserTabTypes.ALL && hasPermission(Permission.ADMIN_USERS_SERVICEACCOUNTMANAGER) ? (
                  <CreateServiceAccountButton />
                ) : null}
                {selectedTab === UserTabTypes.MEMBERS && hasPermission(Permission.ETL_FAMILYUNIT_EXECUTESYNC) ? (
                  <Button shape="round" onClick={syncMembers}>
                    Sync Member Data
                  </Button>
                ) : null}
                {selectedTab == UserTabTypes.CLAIMDOC && hasPermission(Permission.ETL_USERS_SYNC_CLAIMDOC) ? (
                  <Button shape="round" onClick={syncClaimDocMembers}>
                    Sync ClaimDOC Users
                  </Button>
                ) : null}
              </Space>
            </Col>
          </Row>

          <Menu mode="horizontal" style={{ marginBottom: 15 }} selectedKeys={[selectedTab]}>
            <Menu.Item key={UserTabTypes.ALL}>
              <NavLink to={Routes.generate(Routes.ADMIN_USER_LIST, { type: UserTabTypes.ALL })}>All Users</NavLink>
            </Menu.Item>
            <Menu.Item key={UserTabTypes.EMPLOYERS}>
              <NavLink to={Routes.generate(Routes.ADMIN_USER_LIST, { type: UserTabTypes.EMPLOYERS })}>
                Employers
              </NavLink>
            </Menu.Item>
            <Menu.Item key={UserTabTypes.MEMBERS}>
              <NavLink to={Routes.generate(Routes.ADMIN_USER_LIST, { type: UserTabTypes.MEMBERS })}>Employees</NavLink>
            </Menu.Item>
            <Menu.Item key={UserTabTypes.TPAS}>
              <NavLink to={Routes.generate(Routes.ADMIN_USER_LIST, { type: UserTabTypes.TPAS })}>TPA Users</NavLink>
            </Menu.Item>
            <Menu.Item key={UserTabTypes.DPCS}>
              <NavLink to={Routes.generate(Routes.ADMIN_USER_LIST, { type: UserTabTypes.DPCS })}>DPC Users</NavLink>
            </Menu.Item>
            <Menu.Item key={UserTabTypes.OTHERCOMPANIES}>
              <NavLink to={Routes.generate(Routes.ADMIN_USER_LIST, { type: UserTabTypes.OTHERCOMPANIES })}>
                Other Company Users
              </NavLink>
            </Menu.Item>
            <Menu.Item key={UserTabTypes.BROKERS}>
              <NavLink to={Routes.generate(Routes.ADMIN_USER_LIST, { type: UserTabTypes.BROKERS })}>
                Broker Users
              </NavLink>
            </Menu.Item>
            <Menu.Item key={UserTabTypes.CLAIMDOC}>
              <NavLink to={Routes.generate(Routes.ADMIN_USER_LIST, { type: UserTabTypes.CLAIMDOC })}>
                ClaimDOC Users
              </NavLink>
            </Menu.Item>
          </Menu>

          <DataTable
            ref={dataTableRef}
            serverSide={true}
            globalSearch={true}
            globalSearchPlaceholder="Search users..."
            tableProps={{
              rowKey: 'id',
              size: 'large',
              loading: loading,
              sortDirections: ['ascend', 'descend'],
              pagination: {},
              scroll: { x: 575 },
              onRow: (record: UserListDTO) => {
                return {
                  onDoubleClick: () => {
                    if (!isMobile()) {
                      goToDetails(record.id);
                    }
                  },
                  onClick: () => {
                    if (isMobile()) {
                      goToDetails(record.id);
                    }
                  },
                };
              },
            }}
            columns={tableColumns}
            data={tableData}
            fetchData={fetchUserTableData}
            styleOptions={{
              alternatingRowHighlight: true,
            }}
          />
        </Col>
      </Row>
    </Content>
  );
};

export default UserListPage;
