import { Col, Form, FormInstance, Input, Row, Select, Switch, Typography } from 'antd';
import MaskedInput from 'antd-mask-input';
import moment from 'moment-timezone';
import React, { RefObject, useState } from 'react';
import ProviderGenderPreferences from '../../../../consts/ProviderGenderPreferences';
import HasAppointmentOptions from '../../../../consts/ProviderNominationHasAppointmentOptions';
import ProviderNominationProviderAutoFillOptions from '../../../../consts/ProviderNominationProviderAutoFillOptions';
import ProviderType from '../../../../consts/ProviderType';
import PnfAppointmentDTO from '../../../../models/PnfAppointmentDTO';
import PnfProviderInfoDTO from '../../../../models/PnfProviderInfoDTO';
import ProviderSearchDTO from '../../../../models/ProviderSearchDTO';
import UsStateDTO from '../../../../models/UsStateDTO';
import Guid from '../../../../utils/Guid';
import NpiAutoComplete from '../../../shared/NpiAutoComplete';
import PractitionerAutoComplete from '../../../shared/PractitionerAutoComplete';
import ProviderAutoComplete from '../../../shared/ProviderAutoComplete';
import StatePicker from '../../../shared/StatePicker';
import AppointmentFormList from './AppointmentFormList';
import { MemberLookup } from './AppointmentFormListItem';

interface ProviderFormProps {
  formRef: RefObject<FormInstance<any>>;
  dto: PnfProviderInfoDTO;
  familyMembers: MemberLookup[];
  usStates: UsStateDTO[];
  isPrimary: boolean;
  onChange?: (values: any) => void;
}

const ProviderForm = (props: ProviderFormProps) => {
  const [providerType, setProviderType] = useState(props.dto?.providerTypeId || 0);
  const [selectedProviderNpi, setSelectedProviderNpi] = useState<string | undefined>(props.dto.npi || undefined);
  const [autoFilledFrom, setAutoFilledFrom] = useState<string | undefined>(props.dto.autoFillOption || undefined);

  const defaultTimezone = moment.tz.guess();

  const valuesChanged = (changes: any) => {
    if (props.onChange) {
      props.onChange(changes);
    }
  };

  const handleProviderSelected = (provider?: ProviderSearchDTO, from?: string) => {
    setSelectedProviderNpi(provider?.npi ?? undefined);
    setAutoFilledFrom(from);
    const changes = {
      address: {
        address1: provider?.address1,
        address2: provider?.address2,
        city: provider?.city,
        state: provider?.stateCode,
        zipCode: provider?.zipCode,
      },
      phoneNumber: provider?.phoneNumber,
      confirmedNpi: !!provider?.npi,
      autoFillOption: from,
    };

    if (from === ProviderNominationProviderAutoFillOptions.NPI) {
      if (provider?.providerTypeId === ProviderType.CLINIC) {
        changes['facilityName'] = provider?.name;
      }
      if (provider?.providerTypeId === ProviderType.DOCTOR) {
        changes['practitionerFirstName'] = provider?.firstName;
        changes['practitionerLastName'] = provider?.lastName;
      }
    }

    if (
      from === ProviderNominationProviderAutoFillOptions.FACILITY_NAME ||
      from === ProviderNominationProviderAutoFillOptions.PRACTITIONER_NAME
    ) {
      changes['npi'] = provider?.npi;
    }

    if (!provider) {
      changes['npi'] = undefined;
      changes['practitionerFirstName'] = undefined;
      changes['practitionerLastName'] = undefined;
      changes['facilityName'] = undefined;
    }

    props.formRef.current?.setFieldsValue(changes);
    valuesChanged(changes);
  };

  const handleProviderTypeChange = (providerType?: number) => {
    setProviderType(providerType ?? 0);
    setSelectedProviderNpi(undefined);
    setAutoFilledFrom(undefined);
    const changes = {
      facilityName: undefined,
      practitionerFirstName: undefined,
      practitionerLastName: undefined,
      address: {
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipCode: '',
      },
      npi: null,
      phoneNumber: '',
    };
    props.formRef.current?.setFieldsValue(changes);
    valuesChanged(changes);
  };

  const initialValues = {
    ...props.dto,
    appointments:
      (props.dto.appointments?.length ?? 0) > 0
        ? props.dto.appointments
        : [
            PnfAppointmentDTO.create({
              id: Guid.NewGuid(),
              familyMemberId: null,
              appointmentTimeZone: defaultTimezone,
              genderPreference: ProviderGenderPreferences.NONE,
              hasAppointment: HasAppointmentOptions.NO,
            }),
          ],
  };

  return (
    <>
      <Form
        ref={props.formRef}
        layout="vertical"
        initialValues={initialValues}
        onValuesChange={valuesChanged}
        style={{ marginTop: 10 }}
      >
        <Row gutter={[12, 0]}>
          <Col xs={24} sm={12}>
            <Form.Item name="providerTypeId" label="Provider Type" rules={[{ required: true, message: 'Required' }]}>
              <Select
                showSearch
                optionFilterProp="children"
                allowClear={true}
                placeholder="Choose a provider type"
                onSelect={handleProviderTypeChange}
                onClear={handleProviderTypeChange}
              >
                <Select.Option value={ProviderType.CLINIC}>Facility</Select.Option>
                <Select.Option value={ProviderType.DOCTOR}>Practitioner</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={0}>
            <Form.Item name="confirmedNpi" valuePropName="checked">
              <Switch />
            </Form.Item>
            <Form.Item name="autoFillOption">
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item name="npi" label="Provider NPI">
              {!!selectedProviderNpi && autoFilledFrom !== ProviderNominationProviderAutoFillOptions.NPI ? (
                <Input disabled />
              ) : (
                <NpiAutoComplete
                  formItemName="npi"
                  onSelect={(v) => handleProviderSelected(v, ProviderNominationProviderAutoFillOptions.NPI)}
                  onClear={() => handleProviderSelected(undefined)}
                  providerType={providerType}
                  disabled={!!selectedProviderNpi && autoFilledFrom !== ProviderNominationProviderAutoFillOptions.NPI}
                  disableSearchOnNpiOnly={true}
                  maxLength={10}
                  initialValues={props.dto}
                />
              )}
            </Form.Item>
          </Col>
          {providerType === ProviderType.CLINIC ? (
            <>
              <Col xs={24}>
                <Form.Item name="facilityName" label="Facility Name" rules={[{ required: true, message: 'Required' }]}>
                  <ProviderAutoComplete
                    formItemName="facilityName"
                    onSelect={(v) => handleProviderSelected(v, ProviderNominationProviderAutoFillOptions.FACILITY_NAME)}
                    onClear={() => handleProviderSelected(undefined)}
                    providerType={providerType}
                    disabled={!!selectedProviderNpi && autoFilledFrom === ProviderNominationProviderAutoFillOptions.NPI}
                    initialValues={props.dto}
                  />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item label="Practitioner">
                  <Row gutter={[12, 0]} style={{ marginLeft: 0, marginRight: 0 }}>
                    <Col xs={12} style={{ paddingLeft: 0, paddingRight: '6px' }}>
                      <Form.Item name="practitionerFirstName" style={{ margin: 0 }}>
                        <Input maxLength={256} placeholder="First Name" autoComplete="none" />
                      </Form.Item>
                    </Col>
                    <Col xs={12} style={{ paddingRight: 0, paddingLeft: '6px' }}>
                      <Form.Item name="practitionerLastName" style={{ margin: 0 }}>
                        <Input maxLength={256} placeholder="Last Name" autoComplete="none" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </>
          ) : (
            <>
              <PractitionerAutoComplete
                onSelect={(v) => handleProviderSelected(v, ProviderNominationProviderAutoFillOptions.PRACTITIONER_NAME)}
                onClear={() => handleProviderSelected(undefined)}
                groupLabel="Practitioner"
                disabled={!!selectedProviderNpi && autoFilledFrom === ProviderNominationProviderAutoFillOptions.NPI}
                firstNameFormItem={{
                  name: 'practitionerFirstName',
                  label: '',
                  rules: [{ required: true, message: 'Required' }],
                  placeholder: 'First Name',
                }}
                lastNameFormItem={{
                  name: 'practitionerLastName',
                  label: '',
                  rules: [{ required: true, message: 'Required' }],
                  placeholder: 'Last Name',
                }}
                initialValues={props.dto}
              />
              <Col xs={24}>
                <Form.Item name="facilityName" label="Facility Name">
                  <Input maxLength={256} />
                </Form.Item>
              </Col>
            </>
          )}
          <Col xs={24} xl={24}>
            <Form.Item
              name={['address', 'address1']}
              label="Address 1"
              rules={[{ required: true, message: 'Required' }]}
            >
              <Input maxLength={50} disabled={!!selectedProviderNpi} />
            </Form.Item>
          </Col>
          <Col xs={24} xl={24}>
            <Form.Item name={['address', 'address2']} label="Address 2">
              <Input maxLength={50} disabled={!!selectedProviderNpi} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item name={['address', 'city']} label="City" rules={[{ required: true, message: 'Required' }]}>
              <Input maxLength={50} disabled={!!selectedProviderNpi} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={6}>
            <Form.Item name={['address', 'state']} label="State" rules={[{ required: true, message: 'Required' }]}>
              <StatePicker
                usStates={props.usStates}
                allowClear={selectedProviderNpi ? false : true}
                showSearch={selectedProviderNpi ? false : true}
                open={selectedProviderNpi ? false : undefined}
                disabled={!!selectedProviderNpi}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={6}>
            <Form.Item
              name={['address', 'zipCode']}
              label="Zip Code"
              rules={[
                { pattern: /^\d{5}(-\d{4})?$/, validateTrigger: ['onBlur'], message: 'Invalid format', required: true },
              ]}
            >
              <Input
                pattern="^\d{5}(-\d{4})?$"
                type="tel"
                maxLength={5}
                placeholder="#####"
                disabled={!!selectedProviderNpi}
                onKeyPress={(e) => {
                  const isNumber = /^[0-9]$/i.test(e.key);
                  if (!isNumber) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="phoneNumber"
              label="Phone Number"
              rules={[
                { required: true, message: 'Required' },
                {
                  pattern: /^\d{10}$/,
                  message: 'Please a valid 10 digit phone number',
                },
              ]}
              getValueFromEvent={(e) => {
                return e.target.value.replace(/[^0-9]/g, '');
              }}
            >
              <MaskedInput
                mask="(000) 000-0000"
                type="tel"
                placeholder="(201) 555-5555"
                disabled={!!selectedProviderNpi}
              />
            </Form.Item>
          </Col>
          <Col xs={24} xl={24}>
            <Form.Item name="note" label="Note">
              <Input.TextArea maxLength={1500} showCount />
            </Form.Item>
          </Col>
        </Row>
        <br />
        <Typography.Paragraph strong={true}>
          Please select all family members requesting this provider. <br />
          <Typography.Text type="secondary">
            Note: If any family members have appointments with this provider or need to schedule an appointment within
            the next 30 days, please provide appointment information.
          </Typography.Text>
        </Typography.Paragraph>

        <AppointmentFormList formRef={props.formRef} familyMembers={props.familyMembers} />
      </Form>
    </>
  );
};

export default ProviderForm;
