// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import MailroomFileDetailDTO from '../models/MailroomFileDetailDTO';
import MailroomAccountDetailDTO from '../models/MailroomAccountDetailDTO';
import MailroomFileListDTO from '../models/MailroomFileListDTO';
import MailroomFileSetupListDTO from '../models/MailroomFileSetupListDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import MailroomAccountListDTO from '../models/MailroomAccountListDTO';

export class MailroomApiService extends BaseApi {

    // post: api/mailroom/GetFileList?tab=${encodeURIComponent(tab)}
    public getMailroomFileList(tableRequest: TableRequestDTO, tab: string): Promise<TableResponseDTO<MailroomFileListDTO>> {
        let url = `api/mailroom/GetFileList`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (tab != null) {
            url += `${prefix}tab=${encodeURIComponent(tab)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<MailroomFileListDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/mailroom/GetFileDetail?id=${id}
    public getMailroomFileDetail(id: number): Promise<MailroomFileDetailDTO> {
        let url = `api/mailroom/GetFileDetail`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (id != null) {
            url += `${prefix}id=${id}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, MailroomFileDetailDTO>(null, 'post', url, true, false);
    }

    // post: api/mailroom/SaveFileDetail
    public saveMailroomFileDetail(dto: MailroomFileDetailDTO): Promise<number> {
        let url = `api/mailroom/SaveFileDetail`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, number>(dto, 'post', url, true, false);
    }

    // post: api/mailroom/GetAccountList
    public getMailroomAccountList(tableRequest: TableRequestDTO): Promise<TableResponseDTO<MailroomAccountListDTO>> {
        let url = `api/mailroom/GetAccountList`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<MailroomAccountListDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/mailroom/GetAccountDetail?id=${id}
    public getMailroomAccountDetail(id: number): Promise<MailroomAccountDetailDTO> {
        let url = `api/mailroom/GetAccountDetail`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (id != null) {
            url += `${prefix}id=${id}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, MailroomAccountDetailDTO>(null, 'post', url, true, false);
    }

    // post: api/mailroom/SaveAccountDetail
    public saveMailroomAccountDetail(connection: MailroomAccountDetailDTO): Promise<number> {
        let url = `api/mailroom/SaveAccountDetail`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, number>(connection, 'post', url, true, false);
    }

    // post: api/mailroom/SaveFilesStatus?status=${encodeURIComponent(status)}
    public saveMailroomFilesStatus(ids: MailroomFileListDTO[], status: string): Promise<boolean> {
        let url = `api/mailroom/SaveFilesStatus`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (status != null) {
            url += `${prefix}status=${encodeURIComponent(status)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, boolean>(ids, 'post', url, true, false);
    }

    // post: api/mailroom/GetFileSetupList?accountId=${accountId}
    public getMailroomFileSetupList(tableRequest: TableRequestDTO, accountId: number): Promise<TableResponseDTO<MailroomFileSetupListDTO>> {
        let url = `api/mailroom/GetFileSetupList`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (accountId != null) {
            url += `${prefix}accountId=${accountId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<MailroomFileSetupListDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/mailroom/RunFileSetup?includeProcessedFiles=${includeProcessedFiles}
    public runMailroomFileSetup(fileSetup: MailroomFileSetupListDTO, includeProcessedFiles: boolean): Promise<boolean> {
        let url = `api/mailroom/RunFileSetup`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (includeProcessedFiles != null) {
            url += `${prefix}includeProcessedFiles=${includeProcessedFiles}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, boolean>(fileSetup, 'post', url, true, false);
    }

    // post: api/mailroom/RerunMailroomFile?fileId=${fileId}
    public rerunMailroomFile(fileId: number): Promise<boolean> {
        let url = `api/mailroom/RerunMailroomFile`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (fileId != null) {
            url += `${prefix}fileId=${fileId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, boolean>(null, 'post', url, true, false);
    }

    // post: api/mailroom/RegenerateMailroomAccountPassword?username=${encodeURIComponent(username)}
    public regenerateMailroomAccountPassword(username: string): Promise<string> {
        let url = `api/mailroom/RegenerateMailroomAccountPassword`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (username != null) {
            url += `${prefix}username=${encodeURIComponent(username)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, string>(null, 'post', url, true, false);
    }

    // post: api/mailroom/ProvisionMailroomAccount?username=${encodeURIComponent(username)}&baseUrl=${encodeURIComponent(baseUrl)}&accountId=${accountId}
    public provisionMailroomAccount(username: string, baseUrl: string, accountId: number): Promise<void> {
        let url = `api/mailroom/ProvisionMailroomAccount`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (username != null) {
            url += `${prefix}username=${encodeURIComponent(username)}`;
            prefix = '&';
        }
        if (baseUrl != null) {
            url += `${prefix}baseUrl=${encodeURIComponent(baseUrl)}`;
            prefix = '&';
        }
        if (accountId != null) {
            url += `${prefix}accountId=${accountId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, void>(null, 'post', url, true, false);
    }
}
var service = new MailroomApiService();
export default service;
