import { useContext } from 'react';
import { AuthenticationContext } from '../auth/AuthenticationContext';

function useHasPermissions() {
  const authContext = useContext(AuthenticationContext);

  return (id: number[]) => {
    for (let i = 0, len = id?.length ?? 0; i < len; i++) {
        if (id && authContext.user?.userHasPermission(id[i])) {
            return true
        }
      }
    return false;
  };
}

export default useHasPermissions;