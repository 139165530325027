import { notification } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ClientService from '../../../api/ClientApiService';
import Permission from '../../../consts/Permission';
import Role from '../../../consts/Role';
import Routes from '../../../consts/Routes';
import useHasPermission from '../../../hooks/useHasPermission';
import ClientAccessDTO from '../../../models/ClientAccessDTO';
import LookupTableDTO from '../../../models/LookupTableDTO';
import TableRequestDTO from '../../../models/TableRequestDTO';
import TableResponseDTO from '../../../models/TableResponseDTO';
import UserClientAccessDTO from '../../../models/UserClientAccessDTO';
import AccessEditor, { AccessEditorProps } from '../../shared/EntityAccess/AccessEditor';

interface OtherCompanyAccessListProps {
  clientId: number;
  otherCompanyId?: string;
  readonly?: boolean;
}

const otherCompanyIdFromProp = (id?: string) => {
  const parsed = parseInt(id ?? '');
  return isNaN(parsed) ? undefined : parsed;
};

const OtherCompanyAccessEditor = (props: OtherCompanyAccessListProps) => {
  const [loading, setLoading] = useState(true);
  const [clientAccess, setClientAccess] = useState<ClientAccessDTO>(ClientAccessDTO.create());
  const [otherCompanyId, setOtherCompanyId] = useState<number | undefined>(
    otherCompanyIdFromProp(props.otherCompanyId)
  );
  const [selectedOtherCompanyId, setSelectedOtherCompanyId] = useState<number | undefined>(undefined);
  const [OtherCompanyUsers, setOtherCompanyUsers] = useState<UserClientAccessDTO[]>([]);
  const hasPermission = useHasPermission();

  useEffect(() => {
    setOtherCompanyId(otherCompanyIdFromProp(props.otherCompanyId));
  }, [props.otherCompanyId]);

  useEffect(() => {
    ClientService.getOtherCompanyClientAccess(props.clientId, otherCompanyId)
      .then((res) => {
        setClientAccess(res);
        setLoading(false);
      })
      .catch((err) => {
        notification.error({ message: 'Failed to fetch', description: err.message });
      });
  }, [otherCompanyId]);

  const fetchTableData = (
    requestState: TableRequestDTO,
    checkEcho: () => boolean,
    callback: (response: TableResponseDTO<UserClientAccessDTO>) => void
  ) => {
    setLoading(true);
    ClientService.getOtherCompanyUserClientAccesses(props.clientId, otherCompanyId ?? 0, requestState)
      .then((results) => {
        if (!checkEcho()) {
          return;
        }
        setOtherCompanyUsers(results.results || []);

        setLoading(false);
        callback(results);
      })
      .catch((error: any) => {
        setLoading(false);
        notification.error({
          message: error.message,
          description: error.description,
        });
      });
  };

  const saveChanges = (values: ClientAccessDTO, cb?: (success: boolean, id?: number) => void) => {
    setLoading(true);
    // Save handler expects client ID to be in the DTO.
    values.id = props.clientId;
    ClientService.saveOtherCompanyClientAccess(otherCompanyId ?? selectedOtherCompanyId ?? 0, values)
      .then((res) => {
        if (res.succeeded) {
          notification.success({ message: 'Other Company Access Saved' });
          ClientService.getOtherCompanyClientAccess(props.clientId, otherCompanyId ?? selectedOtherCompanyId ?? 0).then(
            (access) => {
              setClientAccess(access);
              if (cb) {
                cb(res.succeeded, otherCompanyId ?? selectedOtherCompanyId ?? 0);
              }
            }
          );
        } else {
          throw '';
        }
      })
      .catch(() => {
        notification.error({ message: 'Failed to save Other Company Access' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const entitySelected = (id: number) => {
    ClientService.getOtherCompanyClientAccess(props.clientId, id)
      .then((res) => {
        setClientAccess({ ...clientAccess, users: res.users });
        setSelectedOtherCompanyId(id);
      })
      .catch((err) => {
        notification.error({ message: 'Failed to fetch', description: err.message });
      });
  };

  const otherCompanyProps = {
    entityId: clientAccess.id,
    entityName: clientAccess.name,

    showDates: true,
    startDate: clientAccess.startDate ? moment(clientAccess.startDate) : undefined,
    endDate: clientAccess.endDate ? moment(clientAccess.endDate) : undefined,

    userData: OtherCompanyUsers,

    entityList: clientAccess?.entities?.map((t) => {
      return {
        id: t.id,
        name: t.name,
      } as LookupTableDTO;
    }),

    entitySelected: entitySelected,
    fetchData: fetchTableData,

    loading: loading,
    backUrl: Routes.generate(Routes.ADMIN_CLIENT_DETAILS_OTHERCOMPANIES, { id: props.clientId }),
    editUrl: (id: number) =>
      Routes.generate(Routes.ADMIN_CLIENT_DETAILS_OTHERCOMPANIES, { id: props.clientId, otherCompanyId: id }),
    listText: 'Back to Other Companies',
    entitySearch: {
      entitySearchLabel: 'Search For Other Company',
      entitySearchPlaceholder: 'Select Other Company',
    },
    showCancel: true,

    userAdd: {
      userAddSearchLabel: 'Select which Other Company users have access to this client:',
      userAddSearchPlaceholder: 'Select Other Company User',
      userAddText: 'Add Other Company User',
    },

    saveButtonText: 'Save Other Company',
    readOnly: !hasPermission(Permission.ADMIN_CLIENT_OTHERCOMPANY_EDIT) || !!props.readonly,
    dataTableTitle: 'User Access Permissions',

    roleId: Role.OTHERCOMPANY,

    onSave: saveChanges,
  } as AccessEditorProps;

  return <AccessEditor {...otherCompanyProps} />;
};

export default OtherCompanyAccessEditor;
