import { notification, Spin } from 'antd';
import Link from 'antd/lib/typography/Link';
import React, { useEffect, useState } from 'react';
import DocumentApiService from '../../api/DocumentApiService';
import Routes from '../../consts/Routes';
import useUrlQuery from '../../hooks/useUrlQuery';

interface RouteProps {
  p?: string; // PNF ref #
  b?: string; // BB Id
  d?: string; // BB doc id
  mobileview?: string;
}

const DocumentViewer = () => {
  const [routeQuery] = useUrlQuery<RouteProps>();

  const [refNum, setRefNum] = useState<string | undefined>();
  const [bbId, setBbId] = useState<number | undefined>();
  const [bbDocId, setBbDocId] = useState<number | undefined>();

  const [base64Content, setBase64Content] = useState<string>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (routeQuery.p && routeQuery.p !== refNum) {
      setRefNum(routeQuery.p);
    } else if (
      routeQuery.b &&
      tryParseInt(routeQuery.b, -1) !== bbId &&
      routeQuery.d &&
      tryParseInt(routeQuery.d, -1) !== bbDocId
    ) {
      setBbId(tryParseInt(routeQuery.b, -1));
      setBbDocId(tryParseInt(routeQuery.d, -1));
    }
  }, [routeQuery]);

  useEffect(() => {
    if (routeQuery.p && refNum) {
      getPnf(refNum);
    }
  }, [refNum]);

  useEffect(() => {
    if (routeQuery.b && bbId && bbDocId) {
      getBb(bbId, bbDocId);
    }
  }, [bbId, bbDocId]);

  const getPnf = (referenceNumber: string) => {
    DocumentApiService.getPNFDocument(referenceNumber)
      .then((res) => {
        if (res == null || res.endsWith('base64,')) {
          notification.error({ message: 'An error occurred while downloading your document.' });
          return;
        }

        setBase64Content(res);
      })
      .catch((err) => {
        notification.error({ message: err.message });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getBb = (balanceBillId: number, documentId: number) => {
    DocumentApiService.getBalanceBillDocument(balanceBillId, documentId)
      .then((res) => {
        if (res == null || res.endsWith('base64,')) {
          notification.error({ message: 'An error occurred while downloading your document.' });
          return;
        }

        setBase64Content(res);
      })
      .catch((err) => {
        notification.error({ message: err.message });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getIFrameContent = (): React.ReactNode => {
    if (handleSafariView()) {
      return;
    }

    let blobUrl = '';
    if (base64Content) {
      blobUrl = getUrlForBlob(base64Content || '');
    }

    const content = (
      <div style={{ textAlign: 'center', padding: '20px' }}>
        {base64Content ? (
          <div className="document-viewer-wrapper">
            <object data={base64Content} type="application/pdf" className="document-viewer">
              <div>
                Your browser does not support viewing PDFs.
                <br />
                <a href={blobUrl} download>
                  Download your document.
                </a>
              </div>
            </object>
          </div>
        ) : (
          <div>
            An error occurred while loading your document.
            <br />
            <Link href={Routes.generate(Routes.HOME_ROUTE)}>Go to Homepage</Link>
          </div>
        )}
      </div>
    );

    return content;
  };

  const handleSafariView = (): boolean => {
    const isChrome = navigator.userAgent.indexOf('Chrome') > -1;
    const isSafari = navigator.userAgent.indexOf('Safari') > -1;
    const isSafariWebView = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Version)/i.test(navigator.userAgent);

    if ((!isChrome && isSafari) || isSafariWebView) {
      openBlobInBrowser();
      return true;
    }

    return false;
  };

  const openBlobInBrowser = () => {
    const url = getUrlForBlob(base64Content || '');
    window.location.href = url;
  };

  const getUrlForBlob = (base64String: string): string => {
    if (base64Content == null || base64Content.indexOf(';base64,') === -1 || base64Content.indexOf('data:') === -1) {
      notification.error({ message: 'An error occurred while loading your document.' });
      return '';
    }
    const noHeaders = base64String?.substring(base64String.indexOf(';base64,') + 8) || '';
    const binary = window.atob(noHeaders);
    const view = new Uint8Array(new ArrayBuffer(binary.length));
    for (let i = 0; i < binary.length; i++) {
      view[i] = binary.charCodeAt(i);
    }

    const contentType = base64String?.substring(base64String.indexOf('data:') + 5, base64String.indexOf(';base64,'));
    const blob = new Blob([view], { type: contentType });
    const url = URL.createObjectURL(blob);
    return url;
  };

  return (
    <>
      {!loading ? (
        getIFrameContent()
      ) : (
        <Spin spinning={loading} tip="Fetching your document...">
          <div style={{ height: '200px' }}></div>
        </Spin>
      )}
    </>
  );
};

export default DocumentViewer;
