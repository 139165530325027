import { Card } from 'antd';
import React, { CSSProperties } from 'react';
import './CardButton.less';

interface CardButtonProps {
  title?: string;
  icon?: React.ReactNode;
  extra?: React.ReactNode;
  onClick?: () => void;
  minWidth?: number;
  maxWidth?: number;
  minHeight?: number;
  style?: CSSProperties;
}

const CardButton = (props: CardButtonProps) => {
  return (
    <Card
      hoverable
      className="ant-card-background"
      bordered={false}
      onClick={props.onClick}
      style={{
        textAlign: 'center',
        height: '100%',
        width: '100%',
        minHeight: props.minHeight,
        maxWidth: props.maxWidth,
        minWidth: props.minWidth,
        ...props.style,
      }}
      bodyStyle={{ height: '100%' }}
    >
      <div className="card-button">
        <div className="card-button-content">
          {props.icon ? <div className="item icon">{props.icon}</div> : null}
          {props.title ? <div className="item title">{props.title}</div> : null}
        </div>
        {props.extra ? <div className="item extra">{props.extra}</div> : null}
      </div>
    </Card>
  );
};

export default CardButton;
