import { CloseCircleOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  Menu,
  notification,
  Row,
  Space,
  Spin,
  Typography,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import Text from 'antd/lib/typography/Text';
import React, { useEffect, useState } from 'react';
import { Link, NavLink, Route, Switch, useParams } from 'react-router-dom';
import BrokerApiService from '../../api/BrokerApiService';
import BrokerClientAccessEditor from '../../components/admin/Brokers/BrokerClientAccessEditor';
import BrokerClientAccessList from '../../components/admin/Brokers/BrokerClientAccessList';
import BrokerInviteUsers from '../../components/admin/Brokers/BrokerInviteUsers';
import BrokerSecurity from '../../components/admin/Brokers/BrokerSecurity';
import PageTitle from '../../components/shared/PageTitle';
import PermissionGuard from '../../components/shared/PermissionGuard';
import Permission from '../../consts/Permission';
import Routes from '../../consts/Routes';
import useHasPermission from '../../hooks/useHasPermission';
import BrokerDetailDTO from '../../models/BrokerDetailDTO';
import HistoryUtil from '../../utils/HistoryUtil';
import PageStayPrompt from '../../utils/PageStayPrompt';
import parseIntOrDefault from '../../utils/ParseIntOrDefault';
import Renderers from '../../utils/Renderers';
import confirmModal from '../../utils/Modal';

const Content = Layout.Content;

interface BrokerRouteProps {
  id: string;
  tab?: string;
}

const BrokerDetailPage = () => {
  const routeProps = useParams<BrokerRouteProps>();
  const id = parseIntOrDefault(routeProps.id);

  if (id <= 0) {
    HistoryUtil.replace(Routes.ADMIN_TPA_LIST);
  }

  const [brokerDetails, setBrokerDetails] = useState<BrokerDetailDTO>(BrokerDetailDTO.create());
  const [selectedTab, setSelectedTab] = useState(routeProps.tab || 'about');
  const [loading, setLoading] = useState(false);
  const [editingNotes, setEditingNotes] = useState(false);
  const [form] = useForm();
  const [brokerId, setBrokerId] = useState(id);
  const hasPermission = useHasPermission();

  const fetch = (id: number) => {
    setLoading(true);
    BrokerApiService.getBrokerDetails(id)
      .then((res) => {
        setBrokerDetails(res);
      })
      .catch((err) => {
        notification.error({
          message: 'Error loading Broker',
          description: err?.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const saveNote = () => {
    setLoading(true);

    const note = form.getFieldValue('note');
    BrokerApiService.saveBrokerNotes(brokerId, note)
      .then(() => {
        notification.success({ message: 'Notes Saved' });
        setBrokerDetails({ ...brokerDetails, notes: note });
        setEditingNotes(false);
      })
      .catch(() => {
        notification.success({ message: 'Failed to Save', description: 'Please refresh and try again.' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const id = parseIntOrDefault(routeProps.id);

    if (id <= 0) {
      HistoryUtil.replace(Routes.ADMIN_BROKER_LIST);
    }

    setBrokerId(id);
    fetch(id);
  }, [routeProps.id]);

  useEffect(() => {
    setSelectedTab(routeProps.tab || 'about');
  }, [routeProps.tab]);

  const deleteBroker = () => {
    BrokerApiService.deleteBroker(brokerId)
      .then(() => {
        notification.success({ message: 'Broker deleted' });
        HistoryUtil.replace(Routes.ADMIN_BROKER_LIST);
      })
      .catch(() => {
        setLoading(false);
        notification.error({ message: 'Failed to delete broker' });
      });
  };

  const detailsRender = () => (
    <Spin spinning={loading}>
      <Row gutter={[16, 16]}>
        <Col xl={8}>
          <Card className="ant-card-background ant-card-shadow" bordered={false}>
            <Typography.Title level={3}>Details</Typography.Title>
            <Row gutter={[16, 16]}>
              <Col flex={0}>
                <strong>Broker ID:</strong>
                <br />
                <strong>Broker Code:</strong>
                <br />
                <strong>Phone:</strong>
                <br />
                <strong>Website:</strong>
              </Col>
              <Col flex={1}>
                {brokerDetails?.brokerId}
                <br />
                {brokerDetails?.brokerCode}
                <br />

                {brokerDetails?.phoneNumber ? Renderers.phoneNumberLink(brokerDetails?.phoneNumber) : null}
                <br />
                {brokerDetails?.domain ? Renderers.urlLink(brokerDetails.domain) : null}
                <br />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xl={6}>
          <Card className="ant-card-background ant-card-shadow" bordered={false} style={{ height: '100%' }}>
            <Typography.Title level={3}>Address</Typography.Title>
            <Text style={{ whiteSpace: 'pre-wrap' }}>{Renderers.address(brokerDetails)}</Text>
          </Card>
        </Col>
        <Col xl={14}>
          <Card className="ant-card-background ant-card-shadow" bordered={false}>
            <Row align="bottom">
              <Col flex={0}>
                <Typography.Title level={3} style={{ marginBottom: 0 }}>
                  Notes
                </Typography.Title>
              </Col>
              <Col flex={1}>
                <PermissionGuard permissionId={Permission.ADMIN_TPA_DETAILS_EDIT}>
                  <Space direction="horizontal">
                    {!editingNotes ? (
                      <Button icon={<EditOutlined />} type="link" onClick={() => setEditingNotes(true)} />
                    ) : (
                      <>
                        <Button icon={<SaveOutlined />} type="link" onClick={() => saveNote()} />
                        <Button icon={<CloseCircleOutlined />} type="link" onClick={() => setEditingNotes(false)} />
                      </>
                    )}
                  </Space>
                </PermissionGuard>
              </Col>
            </Row>

            {!editingNotes ? (
              <div style={{ minHeight: 140, whiteSpace: 'pre-wrap' }}>{brokerDetails?.notes}</div>
            ) : (
              <>
                <PageStayPrompt when={true} />
                <Form initialValues={{ note: brokerDetails?.notes }} form={form}>
                  <FormItem name="note">
                    <Input.TextArea rows={6} />
                  </FormItem>
                </Form>
              </>
            )}
          </Card>
        </Col>
      </Row>
      {brokerId && (
        <PermissionGuard permissionId={Permission.ADMIN_BROKER_DETAILS_EDIT}>
          <Button
            onClick={() => confirmModal('Are you sure you want to delete this Broker?', () => deleteBroker())}
            style={{ marginTop: 15 }}
            danger
          >
            Delete Broker
          </Button>
        </PermissionGuard>
      )}
    </Spin>
  );

  const tabs = [];

  if (hasPermission(Permission.ADMIN_BROKER_DETAILS_VIEW)) {
    tabs.push(
      <Menu.Item key="about">
        <NavLink to={Routes.generate(Routes.ADMIN_BROKER_DETAILS, { id: brokerId })}>Broker Information</NavLink>
      </Menu.Item>
    );
  }

  if (hasPermission(Permission.ADMIN_BROKER_CLIENTS_VIEW)) {
    tabs.push(
      <Menu.Item key="clients">
        <NavLink to={Routes.generate(Routes.ADMIN_BROKER_DETAILS_CLIENTS, { id: brokerId })}>Clients</NavLink>
      </Menu.Item>
    );
  }

  if (hasPermission(Permission.ADMIN_BROKER_SECURITY_VIEW)) {
    tabs.push(
      <Menu.Item key="security">
        <NavLink to={Routes.generate(Routes.ADMIN_BROKER_DETAILS_SECURITY, { id: brokerId })}>Security</NavLink>
      </Menu.Item>
    );
  }

  if (hasPermission(Permission.ADMIN_BROKER_INVITEUSERS)) {
    tabs.push(
      <Menu.Item key="invite">
        <NavLink to={Routes.generate(Routes.ADMIN_BROKER_INVITE_USERS, { id: brokerId })}>Invite Users</NavLink>
      </Menu.Item>
    );
  }

  return (
    <Content className="content-padding">
      <Row>
        <Col xs={24}>
          <Row justify="space-between" style={{ marginBottom: '12px' }}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={Routes.ADMIN_BROKER_LIST}>Brokers</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{brokerDetails?.name ?? 'New Broker'}</Breadcrumb.Item>
            </Breadcrumb>
          </Row>
          <PageTitle
            title={brokerDetails?.name ?? 'New Broker'}
            style={{ marginTop: '0.5em', marginBottom: '0.5em' }}
          />

          <Row>
            <Col xs={24}>
              <Menu mode="horizontal" style={{ marginBottom: 15 }} selectedKeys={[selectedTab]}>
                {tabs}
              </Menu>

              <Switch>
                <Route
                  path={Routes.ADMIN_BROKER_DETAILS_CLIENTS}
                  render={(props) =>
                    props.match.params.clientId ? (
                      <BrokerClientAccessEditor brokerId={brokerId} clientId={props.match.params.clientId} />
                    ) : (
                      <BrokerClientAccessList brokerId={brokerId} />
                    )
                  }
                />

                <Route
                  path={Routes.ADMIN_BROKER_DETAILS_SECURITY}
                  render={() => <BrokerSecurity brokerId={brokerId} />}
                />

                <Route
                  path={Routes.ADMIN_BROKER_INVITE_USERS}
                  exact={true}
                  render={() => <BrokerInviteUsers brokerId={brokerId} />}
                />

                <Route path={Routes.ADMIN_BROKER_DETAILS_EDIT} exact={true} render={detailsRender} />
                <Route path={Routes.ADMIN_BROKER_DETAILS} exact={true} render={detailsRender} />
              </Switch>
            </Col>
          </Row>
        </Col>
      </Row>
    </Content>
  );
};

export default BrokerDetailPage;
