import { notification } from 'antd';
import React, { useState } from 'react';
import AccountApiService from '../../api/AccountApiService';
import ChangePasswordForm from '../../components/profile/ChangePasswordForm';
import ResultDTO from '../../models/ResultDTO';
import LoginLayout from './LoginLayout';

interface ForcePasswordChangeProps {
  onChange: () => void;
}

const ForcePasswordChangePage: React.FC<ForcePasswordChangeProps> = (props) => {
  const [loading, setLoading] = useState(false);

  const changePassword = (model: any) => {
    setLoading(true);

    model.requestedRoleId = 0;
    model.requestUrl = null;

    AccountApiService.changePassword(model)
      .then((response: ResultDTO | null) => {
        if (response?.succeeded) {
          setLoading(false);
          notification.success({ message: 'Password updated successfully.', duration: 3 });
          props.onChange();
        } else {
          setLoading(false);
          notification.destroy();
          notification.error({ message: response?.errors.join('\n') });
        }
      })
      .catch((results: any) => {
        setLoading(false);
        notification.destroy();
        notification.error({ message: results?.errors.join('\n') });
      });
  };

  return (
    <LoginLayout cardTitle="Change Password" prompt="Please change your password to continue using Portal.">
      <ChangePasswordForm submitErrors={[]} loading={loading} formSubmitted={changePassword} />
    </LoginLayout>
  );
};

export default ForcePasswordChangePage;
