import { QuestionOutlined, ShopOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Col, notification, Row, Space } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PinAccessApiService from '../../../api/PinAccessApiService';
import Permission from '../../../consts/Permission';
import PinTypes from '../../../consts/PinTypes';
import Routes from '../../../consts/Routes';
import useHasPermissions from '../../../hooks/userHasPermissions';
import PinAccessDTO from '../../../models/PinAccessDTO';
import TableRequestDTO from '../../../models/TableRequestDTO';
import TableResponseDTO from '../../../models/TableResponseDTO';
import HistoryUtil from '../../../utils/HistoryUtil';
import MobileUtil from '../../../utils/MobileUtil';
import DataTable, { FilterType } from '../../shared/DataTable/DataTable';
import DataTableColumnUtil from '../../shared/DataTable/DataTableColumnUtil';
import PageTitle from '../../shared/PageTitle';

const isMobile = () => MobileUtil.getMediaQuery()?.matches;

const GuestAccessListPage = () => {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<PinAccessDTO[]>([]);

  const hasPermissions = useHasPermissions();
  const hasPinAccess = hasPermissions([
    Permission.ADMIN_EMPLOYERPINACCESSUNLINKED_EDIT,
    Permission.ADMIN_EMPLOYERPINACCESSLINKED_EDIT,
  ]);

  const goToDetail = (id: number) => {
    HistoryUtil.push(
      Routes.generate(Routes.ADMIN_GUEST_ACCESS_DETAILS, {
        id: id,
      })
    );
  };

  const fetchTableData = (
    requestState: TableRequestDTO,
    checkEcho: () => boolean,
    callback: (response: TableResponseDTO<PinAccessDTO>) => void
  ) => {
    setLoading(true);

    PinAccessApiService.getPinAccessList(requestState)
      .then((results) => {
        if (!checkEcho()) {
          return;
        }

        setTableData(results.results || []);
        setLoading(false);

        callback(results);
      })
      .catch((error: any) => {
        setLoading(false);
        notification.error({
          message: error.message,
          description: error.description,
        });
      });
  };

  return (
    <Content>
      <Row>
        <Col xs={24}>
          <Row className="content-header" justify="space-between">
            <Col xs={24} lg={10} xl={13} xxl={14}>
              <PageTitle title="Employer PIN Access" />
            </Col>
            <Col>
              {hasPinAccess ? (
                <Link to={Routes.generate(Routes.ADMIN_GUEST_ACCESS_NEW)}>
                  <Button type="primary" shape="round" style={{ marginRight: '16px' }}>
                    New PIN
                  </Button>
                </Link>
              ) : null}
            </Col>
          </Row>
          <DataTable
            serverSide={true}
            globalSearch={true}
            globalSearchPlaceholder="Search PINs..."
            tableProps={{
              rowKey: 'id',
              size: 'large',
              loading: loading,
              sortDirections: ['ascend', 'descend'],
              scroll: { x: 575 },
              onRow: (record: PinAccessDTO) => {
                return {
                  onDoubleClick: () => {
                    if (!isMobile()) {
                      goToDetail(record.id);
                    }
                  },
                  onClick: () => {
                    if (isMobile()) {
                      goToDetail(record.id);
                    }
                  },
                };
              },
            }}
            columns={[
              // DataTableColumnUtil.Text<PinAccessDTO>('Id', 'id'),
              {
                ...DataTableColumnUtil.Text<PinAccessDTO>(
                  'Known Client / Unlinked Client / User Account',
                  'clientName'
                ),
                // eslint-disable-next-line react/display-name
                render: (v, r) => {
                  if (r.userEmail) {
                    return (
                      <Space direction="horizontal">
                        <UserOutlined />
                        {r.userEmail}
                      </Space>
                    );
                  }

                  if (r.clientName) {
                    return (
                      <Space direction="horizontal">
                        {r.clientId ? <ShopOutlined /> : <QuestionOutlined />}
                        {r.clientName}
                      </Space>
                    );
                  }

                  return null;
                },
              },
              DataTableColumnUtil.DropdownMulti<PinAccessDTO>('PIN Type', 'pinType', [
                { text: PinTypes.ACCOUNTPIN, value: PinTypes.ACCOUNTPIN },
                { text: PinTypes.CLIENTPIN, value: PinTypes.CLIENTPIN },
                { text: PinTypes.UNLINKEDPIN, value: PinTypes.UNLINKEDPIN },
              ]),
              DataTableColumnUtil.Text<PinAccessDTO>('PIN', 'pin'),
              {
                title: 'Parent PIN',
                dataIndex: 'parentPinId',
                width: '40px',
                sorter: true,
                filterType: FilterType.Text,
                // eslint-disable-next-line react/display-name
                render: (v, rowData: PinAccessDTO) =>
                  (rowData?.parentPinId ?? false) && hasPinAccess ? (
                    <Link to={Routes.generate(Routes.ADMIN_GUEST_ACCESS_DETAILS, { id: rowData.parentPinId ?? 0 })}>
                      {rowData.parentPinId}
                    </Link>
                  ) : null,
              },
              DataTableColumnUtil.Text<PinAccessDTO>(
                'Parent Known Client / Unlinked Client / User Account',
                'parentPinName'
              ),
              // DataTableColumnUtil.Text<PinAccessDTO>('User Email', 'userEmail'),
              DataTableColumnUtil.Date<PinAccessDTO>('Expiration', 'expirationDate'),
              // DataTableColumnUtil.DateTime<PinAccessDTO>('Created On', 'createdOn'),
              // DataTableColumnUtil.DateTime<PinAccessDTO>('Last Updated', 'updatedOn'),
              {
                title: '',
                dataIndex: '',
                width: '40px',
                // eslint-disable-next-line react/display-name
                render: (rowData: PinAccessDTO) => (
                  <>
                    {hasPinAccess ? (
                      <Link to={Routes.generate(Routes.ADMIN_GUEST_ACCESS_DETAILS, { id: rowData.id })}>
                        <Button shape="round" ghost>
                          Details
                        </Button>
                      </Link>
                    ) : null}
                  </>
                ),
              },
            ]}
            data={tableData}
            fetchData={fetchTableData}
            styleOptions={{
              alternatingRowHighlight: true,
            }}
          />
        </Col>
      </Row>
    </Content>
  );
};

export default GuestAccessListPage;
