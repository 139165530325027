import { Button, Empty, Table, Tabs, Tag } from 'antd';
import { ColumnType } from 'antd/lib/table';
import moment from 'moment';
import React from 'react';
import { NavLink } from 'react-router-dom';
import Permission from '../../../consts/Permission';
import Routes from '../../../consts/Routes';
import useHasPermission from '../../../hooks/useHasPermission';
import RelationAccessDTO from '../../../models/RelationAccessDTO';
import RelationDTO from '../../../models/RelationDTO';
import UserDetailDTO from '../../../models/UserDetailDTO';
import UserRelationsDTO from '../../../models/UserRelationsDTO';
import PermissionGuard from '../../shared/PermissionGuard';
/* eslint-disable react/display-name */
interface UserDetailRelationshipsProps {
  detail: UserDetailDTO;
}

const UserDetailRelationships = (props: UserDetailRelationshipsProps) => {
  const relations = props.detail.relationships ?? UserRelationsDTO.create();
  const hasPermission = useHasPermission();

  const tpas = relations.tpas ?? [];
  const dpcs = relations.dpcs ?? [];
  const otherCompanies = relations.otherCompanies ?? [];
  const brokers = relations.brokers ?? [];
  const clients = relations.clients ?? [];
  const claimdoc = relations.claimDoc ?? [];

  const topTableColumns = (editUrl: (id: any) => string, editPermission: number): ColumnType<RelationDTO>[] => [
    { title: 'Name', dataIndex: 'name', key: 'name', sortDirections: ['ascend', 'descend'], sorter: {} },
    {
      title: 'Base Permission Group',
      dataIndex: 'primaryPermissionGroupName',
      key: 'primaryPermissionGroupName',
      sortDirections: ['ascend', 'descend'],
      sorter: {},
    },
    {
      title: 'User Addon Permission Group',
      dataIndex: 'addonPermissionGroupName',
      key: 'addonPermissionGroupName',
      sortDirections: ['ascend', 'descend'],
      sorter: {},
    },
    { title: 'Client Count', render: (v, r) => (r.access ?? []).length },
    {
      title: '',
      render: (v, r) => (
        <PermissionGuard permissionId={editPermission}>
          <NavLink to={editUrl(r.id)}>
            <Button type="ghost" shape="round" style={{ width: 100 }}>
              Edit
            </Button>
          </NavLink>
        </PermissionGuard>
      ),
    },
  ];

  const clientColumns: ColumnType<RelationDTO>[] = [
    { title: 'Name', dataIndex: 'name', key: 'name', sortDirections: ['ascend', 'descend'], sorter: {} },
    {
      title: 'Role',
      dataIndex: 'isEmployer',
      key: 'isEmployer',
      render: (v) => (v ? 'Employer' : 'Employee'),
      sortDirections: ['ascend', 'descend'],
      sorter: {},
    },
    {
      title: 'Base Permission Group',
      dataIndex: 'primaryPermissionGroupName',
      key: 'primaryPermissionGroupName',
      sortDirections: ['ascend', 'descend'],
      sorter: {},
    },
    {
      title: 'User Addon Permission Group',
      dataIndex: 'addonPermissionGroupName',
      key: 'addonPermissionGroupName',
      sortDirections: ['ascend', 'descend'],
      sorter: {},
    },
    {
      title: '',
      render: (v, r) => (
        <PermissionGuard permissionId={Permission.ADMIN_CLIENT_SECURITY_VIEW}>
          <NavLink to={Routes.generate(Routes.ADMIN_CLIENT_DETAILS_SECURITY, { id: r.id })}>
            <Button type="ghost" shape="round" style={{ width: 100 }}>
              {hasPermission(Permission.ADMIN_CLIENT_SECURITY_EDIT) ? 'Edit' : 'View'}
            </Button>
          </NavLink>
        </PermissionGuard>
      ),
    },
  ];

  const claimDocColumns: ColumnType<RelationDTO>[] = [
    { title: 'Name', dataIndex: 'name', key: 'name', sortDirections: ['ascend', 'descend'], sorter: {} },
    {
      title: 'Permission Group',
      dataIndex: 'primaryPermissionGroupName',
      key: 'primaryPermissionGroupName',
      sortDirections: ['ascend', 'descend'],
      sorter: {},
    },
    {
      title: '',
      render: (v, r) => (
        <PermissionGuard permissionId={Permission.ADMIN_CLIENT_CLAIMDOC_ACCESS_VIEW}>
          <NavLink to={Routes.generate(Routes.ADMIN_CLIENT_DETAILS_CLAIMDOC, { id: r.id })}>
            <Button type="ghost" shape="round" style={{ width: 100 }}>
              {hasPermission(Permission.ADMIN_CLIENT_CLAIMDOC_ACCESS_EDIT) ? 'Edit' : 'View'}
            </Button>
          </NavLink>
        </PermissionGuard>
      ),
    },
  ];

  const hasAny = tpas.length > 0 || brokers.length > 0 || clients.length > 0 || claimdoc.length > 0;

  if (!hasAny) {
    return <Empty />;
  }

  const expandedRowRender = (type: string) => (record: RelationDTO) => {
    if ((record.access ?? []).length === 0) {
      return <div style={{ textAlign: 'center' }}>No Client Access</div>;
    }

    const columns: ColumnType<RelationAccessDTO>[] = [
      { title: 'Client', dataIndex: 'clientName', key: 'clientName' },
      {
        title: 'Active',
        key: 'active',
        render: (value: boolean) => (value ? <Tag color="green">Active</Tag> : <Tag color="red">Expired</Tag>),
      },
      {
        title: 'Start Date',
        dataIndex: 'startDate',
        sorter: (a: any, b: any) => {
          a = a.startDate || '';
          b = b.startDate || '';
          return a.localeCompare(b);
        },
        render: (value: moment.Moment | string | null) => {
          return value != null ? moment(stripOffset(value)).format('MM/DD/YYYY') : null;
        },
      },
      {
        title: 'End Date',
        dataIndex: 'endDate',
        sorter: (a: any, b: any) => {
          a = a.endDate || '';
          b = b.endDate || '';
          return a.localeCompare(b);
        },
        render: (value: moment.Moment | string | null) => {
          return value != null ? moment(stripOffset(value)).format('MM/DD/YYYY') : null;
        },
      },
      { title: 'Permission Group', dataIndex: 'permissionGroupName', key: 'permissionGroupName' },
      {
        title: '',
        render: (v, r) => {
          let editLink: string;
          let hasEditPermission = false;
          if (type === 'tpa') {
            editLink = Routes.generate(Routes.ADMIN_TPA_DETAILS_CLIENTS, { id: record.id, clientId: r.clientId });
            hasEditPermission = hasPermission(Permission.ADMIN_CLIENT_TPA_EDIT);
          } else {
            editLink = Routes.generate(Routes.ADMIN_BROKER_DETAILS_CLIENTS, { id: record.id, clientId: r.clientId });
            hasEditPermission = hasPermission(Permission.ADMIN_CLIENT_BROKER_EDIT);
          }

          return (
            <PermissionGuard permissionId={Permission.ADMIN_CLIENT_CLAIMDOC_ACCESS_VIEW}>
              <NavLink to={editLink}>
                <Button type="ghost" shape="round" style={{ width: 100 }}>
                  {hasEditPermission ? 'Edit' : 'View'}
                </Button>
              </NavLink>
            </PermissionGuard>
          );
        },
      },
    ];

    return <Table columns={columns} dataSource={record.access ?? []} pagination={false} />;
  };

  const tabs = [];

  if (tpas.length > 0) {
    tabs.push(
      <Tabs.TabPane tab="TPA" key="tpas">
        <Table
          columns={topTableColumns(
            (id) => Routes.generate(Routes.ADMIN_TPA_DETAILS_SECURITY, { id: id }),
            Permission.ADMIN_TPA_SECURITY_VIEW
          )}
          expandable={{ expandedRowRender: expandedRowRender('tpa'), defaultExpandAllRows: false }}
          dataSource={tpas}
        />
      </Tabs.TabPane>
    );
  }

  if (dpcs.length > 0) {
    tabs.push(
      <Tabs.TabPane tab="DPC" key="dpcs">
        <Table
          columns={topTableColumns(
            (id) => Routes.generate(Routes.ADMIN_DPC_DETAILS_SECURITY, { id: id }),
            Permission.ADMIN_DPC_SECURITY_VIEW
          )}
          expandable={{ expandedRowRender: expandedRowRender('dpc'), defaultExpandAllRows: false }}
          dataSource={dpcs}
        />
      </Tabs.TabPane>
    );
  }

  if (otherCompanies.length > 0) {
    tabs.push(
      <Tabs.TabPane tab="Other Company" key="other-company">
        <Table
          columns={topTableColumns(
            (id) => Routes.generate(Routes.ADMIN_OTHERCOMPANY_DETAILS_SECURITY, { id: id }),
            Permission.ADMIN_OTHERCOMPANY_SECURITY_VIEW
          )}
          expandable={{ expandedRowRender: expandedRowRender('othercompany'), defaultExpandAllRows: false }}
          dataSource={otherCompanies}
        />
      </Tabs.TabPane>
    );
  }

  if (brokers.length > 0) {
    tabs.push(
      <Tabs.TabPane tab="Broker" key="broker">
        <Table
          columns={topTableColumns(
            (id) => Routes.generate(Routes.ADMIN_BROKER_DETAILS_SECURITY, { id: id }),
            Permission.ADMIN_BROKER_SECURITY_VIEW
          )}
          expandable={{ expandedRowRender: expandedRowRender('broker'), defaultExpandAllRows: false }}
          dataSource={brokers}
        />
      </Tabs.TabPane>
    );
  }

  if (clients.length > 0) {
    tabs.push(
      <Tabs.TabPane tab="Clients" key="client">
        <Table columns={clientColumns} dataSource={clients} />
      </Tabs.TabPane>
    );
  }

  if (claimdoc.length > 0) {
    tabs.push(
      <Tabs.TabPane tab="ClaimDOC" key="claimdoc">
        <Table columns={claimDocColumns} dataSource={claimdoc} pagination={{ showSizeChanger: true }} />
      </Tabs.TabPane>
    );
  }

  return (
    <>
      <Tabs type="card">{tabs}</Tabs>
    </>
  );
};

export default UserDetailRelationships;
