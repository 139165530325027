import { DownloadOutlined } from '@ant-design/icons';
import { Button, Col, Layout, notification, Row, Steps, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useContext, useEffect, useState } from 'react';
import PlanForm from '../../components/pricing-tool/PlanForm';
import PageTitle from '../../components/shared/PageTitle';
import useIsWidthBroken from '../../hooks/useIsWidthBroken';
import PricingToolDTO from '../../models/PricingToolDTO';
import PlanInformationDTO from '../../models/PlanInformationDTO';
import CostEstimateResults from '../../components/pricing-tool/CostEstimateResults';
import PricingToolProviderSearch from '../../components/pricing-tool/PricingToolProviderSearch';
import ShoppableServiceForm from '../../components/pricing-tool/ShoppableServiceForm';
import ProviderDTO from '../../models/ProviderDTO';
import ShoppableServiceDTO from '../../models/ShoppableServiceDTO';
import ProviderSearchQueryDTO from '../../models/ProviderSearchQueryDTO';
import RelationDTO from '../../models/RelationDTO';
import { AuthenticationContext } from '../../auth/AuthenticationContext';
import PricingToolApiService from '../../api/PricingToolApiService';
import Role from '../../consts/Role';
import CostEstimateDTO from '../../models/CostEstimateDTO';
import PricingToolInstructions from '../../components/pricing-tool/PricingToolInstructions';
import { Content } from 'antd/lib/layout/layout';
import { Link } from 'react-router-dom';
import Routes from '../../consts/Routes';
import ShoppableServiceRequestDTO from '../../models/ShoppableServiceRequestDTO';
import ShoppableServiceResponseDTO from '../../models/ShoppableServiceResponseDTO';

export enum PricingSteps {
  Instructions,
  Service,
  Provider,
  Plan,
  Results,
}

interface StepItem {
  key: number;
  title: string;
  description?: string;
  onClick?: (step: number) => void;
}

const PricingToolPage = () => {
  const [dto, setDto] = useState<PricingToolDTO>(
    PricingToolDTO.create({
      planInformation: PlanInformationDTO.create(),
    })
  );
  const [estimateError, setEstimateError] = useState<string | undefined>();

  const [currStep, setCurrStep] = useState(PricingSteps.Instructions);
  const [form] = useForm();

  const [clients, setClients] = useState<RelationDTO[]>([]);
  const [estimates, setEstimates] = useState<CostEstimateDTO[]>([]);
  const [services, setServices] = useState<ShoppableServiceDTO[]>([]);
  const [queryChanged, setQueryChanged] = useState(false);
  const [acknowledgement, setAcknowledgement] = useState(false);

  const [hasSeenPlanStep, setHasSeenPlanStep] = useState(false);

  const authCtx = useContext(AuthenticationContext);
  const role = authCtx.user?.accessRoleId || -1;

  useEffect(() => {
    if (role !== Role.MEMBER) {
      PricingToolApiService.getClients().then((clients) => {
        setClients(clients);
      });
    }
  }, []);

  useEffect(() => {
    if (currStep === PricingSteps.Plan) {
      setHasSeenPlanStep(true);
    }
  }, [currStep]);

  const isMobile = useIsWidthBroken('lg');

  const steps: StepItem[] = [
    {
      key: PricingSteps.Instructions,
      title: 'Introduction',
    },
    {
      key: PricingSteps.Service,
      title: 'Search for a Service',
    },
    {
      key: PricingSteps.Provider,
      title: 'Search for a Provider',
    },
    {
      key: PricingSteps.Plan,
      title: 'Confirm Your Plan',
    },
    {
      key: PricingSteps.Results,
      title: 'Pricing Estimate',
    },
  ];

  const renderStepsDisplay = () => (
    <>
      {currStep !== PricingSteps.Results && (
        <div>
          {dto.service?.display && (
            <div>
              <Typography.Title level={4}>Service</Typography.Title>
              <b>{dto.service?.display?.toUpperCase()}</b>
              <br />
              <br />
            </div>
          )}
          {dto.provider?.name && (
            <>
              <Typography.Title level={4}>Provider</Typography.Title>
              <b>{dto.provider?.name}</b>
              <br />
              {dto.provider?.address1} <br />
              {dto.provider?.city}&nbsp;
              {dto.provider?.state}&nbsp;
              {dto.provider?.zipcode}
            </>
          )}
        </div>
      )}

      <Steps
        labelPlacement={isMobile ? 'vertical' : 'horizontal'}
        direction={isMobile ? 'horizontal' : 'vertical'}
        current={currStep}
        items={steps.map((s) => ({
          key: s.key,
          title: s.title,
          description: <br />,
          disabled: isStepDisabled(s.key),
        }))}
        onChange={handleStepChange}
      ></Steps>
    </>
  );

  const renderStep = (currStep: number) => {
    switch (currStep) {
      case PricingSteps.Instructions:
        return <PricingToolInstructions />;
      case PricingSteps.Service:
        return (
          <ShoppableServiceForm
            initialRequest={dto.serviceRequest ?? undefined}
            cursor={dto.serviceCursor ?? undefined}
            services={services}
            selected={dto.service?.billingCode ? [dto.service?.billingCode] : []}
            onSearch={handleServiceSearch}
            onSelect={handleServiceChange}
          />
        );
      case PricingSteps.Provider:
        return (
          <PricingToolProviderSearch
            onSelect={handleProviderChange}
            onSearch={handleProviderSearch}
            initialProvider={dto.provider || undefined}
            initialQuery={dto.providerSearchQuery || undefined}
          />
        );
      case PricingSteps.Plan:
        return <PlanForm form={form} role={role} clients={clients} initialValues={dto.planInformation} />;
      case PricingSteps.Results:
        return (
          <CostEstimateResults
            dto={dto}
            estimates={estimates}
            error={estimateError}
            onFetchEstimates={(newEstimates, errorMsg) => {
              setEstimates(newEstimates);
              setEstimateError(errorMsg);
              setQueryChanged(false);
            }}
            shouldQuery={queryChanged}
            onAcknowledgementAccept={() => setAcknowledgement(true)}
            acknowledgementAccepted={acknowledgement}
            goToStep={handleStepChange}
          />
        );
    }
  };

  const renderFooter = (currStep: number) => {
    const nextStepText = currStep === PricingSteps.Plan ? 'Get Estimate' : 'Next';
    return (
      <Row justify="end" gutter={12} style={{ padding: '25px 0px', flexWrap: 'nowrap' }}>
        {currStep === PricingSteps.Provider && (
          <Col style={{ margin: 'auto', marginLeft: '0' }}>
            Can’t find your provider? Start the Pave the Way® process by{' '}
            <Link to={Routes.generate(Routes.PROVIDER_NOMINATION)} style={{ color: '#D2292D' }}>
              nominating your provider of choice.
            </Link>{' '}
            You’ll be notified when the Pave the Way® process is complete. If the provider partners with the Plan, the
            pricing tool will update with your provider’s information.
          </Col>
        )}
        {currStep !== PricingSteps.Instructions && (
          <Col>
            <Button
              shape="round"
              size="large"
              onClick={() => handleStepChange(currStep - 1)}
              className="pricing-tool-button"
            >
              Back
            </Button>
          </Col>
        )}
        {currStep !== PricingSteps.Results ? (
          <Col>
            <Button
              type="primary"
              shape="round"
              size="large"
              disabled={isNextButtonDisabled(currStep + 1)}
              onClick={() => handleStepChange(currStep + 1)}
              className="pricing-tool-button"
            >
              {nextStepText}
            </Button>
          </Col>
        ) : (
          <>
            <Col>
              <Button
                type="primary"
                shape="round"
                size="large"
                onClick={() => {
                  window.print();
                }}
                className="pricing-tool-button"
              >
                <DownloadOutlined /> Print
              </Button>
            </Col>
          </>
        )}
      </Row>
    );
  };

  const isStepDisabled = (step: number): boolean => {
    if (step === PricingSteps.Provider) {
      return !dto.service;
    }

    if (step === PricingSteps.Plan) {
      return !dto.provider;
    }

    if (step === PricingSteps.Results) {
      const selectedService = !!dto.service;
      const selectedProvider = !!dto.provider;
      const hasClientId = role !== Role.MEMBER ? !!dto.clientId : hasSeenPlanStep;
      return !(selectedService && selectedProvider && hasClientId);
    }

    return false;
  };

  const isNextButtonDisabled = (step: number): boolean => {
    if (step === PricingSteps.Provider) {
      return !dto.service;
    }

    if (step === PricingSteps.Plan) {
      return !dto.provider;
    }

    if (step === PricingSteps.Results) {
      const selectedService = !!dto.service;
      const selectedProvider = !!dto.provider;
      return !(selectedService && selectedProvider);
    }

    return false;
  };

  const handleStepChange = (requestedStep: number) => {
    if (requestedStep < currStep) {
      setCurrStep(requestedStep);
      return;
    }

    switch (currStep) {
      case PricingSteps.Service: {
        if (!dto.service?.billingCode) {
          notification.error({ message: 'Select a service' });
          return;
        }
        setCurrStep(requestedStep);
        break;
      }
      case PricingSteps.Provider: {
        if (!dto.provider) {
          notification.error({ message: 'Select a provider' });
          return;
        }
        setCurrStep(requestedStep);
        break;
      }
      case PricingSteps.Plan: {
        form.validateFields().then((model) => {
          const { clientId, ...pricingDto } = model;
          const tmpDto = PricingToolDTO.create({
            ...dto,
            clientId: clientId,
            planInformation: PlanInformationDTO.create({ ...pricingDto }),
          });
          if (
            !dto.planInformation ||
            !deepEqual(dto.planInformation, tmpDto.planInformation) ||
            dto.clientId !== tmpDto.clientId
          ) {
            setQueryChanged(true);
          }
          setDto(tmpDto);
          setCurrStep(requestedStep);
        });
        break;
      }
      default: {
        setCurrStep(requestedStep);
      }
    }
  };

  const handleServiceSearch = (
    request: ShoppableServiceRequestDTO,
    response: ShoppableServiceResponseDTO,
    addToList: boolean
  ) => {
    const tmpDto = PricingToolDTO.create({
      ...dto,
      serviceRequest: request,
      serviceCursor: response.cursor,
      service: null,
    });

    if (addToList) {
      setServices([...services, ...(response.services ?? [])]);
    } else {
      setServices(response.services ?? []);
    }

    setDto(tmpDto);
    setQueryChanged(true);
  };

  const handleServiceChange = (services: ShoppableServiceDTO[]) => {
    if (services.at(0)?.billingCode !== dto.service?.billingCode) {
      const tmpDto = PricingToolDTO.create({ ...dto, service: services.at(0) });
      setDto(tmpDto);
      setQueryChanged(true);
    }
  };

  const handleProviderSearch = (query: ProviderSearchQueryDTO, clearProvider?: boolean) => {
    const tmpDto = PricingToolDTO.create({ ...dto, providerSearchQuery: query });
    if (clearProvider) {
      tmpDto.provider = null;
    }
    setDto(tmpDto);
  };

  const handleProviderChange = (provider: ProviderDTO | undefined) => {
    if (provider?.npi !== dto.provider?.npi) {
      const tmpDto = PricingToolDTO.create({ ...dto, provider: provider });
      setDto(tmpDto);
      setQueryChanged(true);
    }
  };

  return (
    <Layout>
      <Content>
        <Row>
          <Col
            xs={{ span: 24, order: 2 }}
            lg={{ span: 16, order: 1 }}
            className="content-padding content-padding-xl"
            style={{ backgroundColor: 'white' }}
          >
            <>
              <PageTitle title={steps.at(currStep)?.title || ''} />
              {renderStep(currStep)}
              {renderFooter(currStep)}
              {currStep === PricingSteps.Instructions && (
                <>
                  <div style={{ height: '40vh' }}>
                    {/* Float the legal disclaimer to the bottom of the introduction */}
                  </div>
                  <p
                    style={{
                      fontSize: '11px',
                    }}
                  >
                    The content provided is for general informational and educational purposes only. This content,
                    including, but not limited to, healthcare services cost look-up tools and related payer-specific
                    cost estimates; quality and performance dashboards and related facility-specific ratings, standards
                    and other metrics; decision aids, tools, calculators and infographics; and such other information
                    provided to assist consumers in making informed healthcare decisions does not constitute and should
                    not be construed as legal, financial, medical or any other type of professional advice, guidance or
                    counsel. <br /> <br />
                    Although ClaimDOC has made reasonable attempts to ensure that the information on this Site is
                    accurate, ClaimDOC does not guarantee that the information is accurate, complete, or up-to-date, and
                    it makes no representation or warranty to that effect. Further, ClaimDOC does not promise that any
                    content, service, or feature of the website will be error-free, continuous, or uninterrupted.
                    Information is provided on an “as-is” and “as available” basis. ClaimDOC expressly disclaims all
                    liability and responsibility arising from your reliance on the content of this Site to the extent
                    permitted by applicable law. Neither ClaimDOC nor any party involved in the creation, production or
                    hosting of this website will be liable for any indirect, special, incidental, or consequential
                    damages of any kind arising from your access to, or use of, this Site, to the extent permitted by
                    applicable law. ClaimDOC disclaims all warranties of any kind, whether express or implied, including
                    but not limited to the warranties of merchantability, non-infringement, and fitness for particular
                    purpose, with respect to this Site or links to other sites, to the extent permitted by applicable
                    law. Further, reference to certain payers, providers, health plans, healthcare services, and
                    healthcare products, does not constitute the ClaimDOC’s endorsement or recommendation of such
                    entities, services or products.
                  </p>
                </>
              )}
            </>
          </Col>
          <Col
            xs={{ span: 24, order: 1 }}
            lg={{ span: 8, order: 1 }}
            className="content-padding content-padding-pnfsteps"
            style={{ backgroundColor: '#eeefef' }}
          >
            <div>{renderStepsDisplay()}</div>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default PricingToolPage;
