import { Alert, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AccountApiService from '../../api/AccountApiService';
import Routes from '../../consts/Routes';
import useUrlQuery from '../../hooks/useUrlQuery';
import ResultDTO from '../../models/ResultDTO';
import LoginLayout from './LoginLayout';

interface ConfirmEmailChangePageProps {
  code?: string;
  userId?: string;
  email?: string;
}

const ConfirmEmailChangePage = () => {
  const [loading, setLoaing] = useState(false);
  const [confirmSuccess, setConfirmSuccess] = useState(false);
  const [error, setError] = useState<any>(null);

  const [urlQuery] = useUrlQuery<ConfirmEmailChangePageProps>();

  useEffect(() => {
    const code = urlQuery.code;
    const email = urlQuery.email;
    const parsedId = parseInt(urlQuery.userId ?? '');

    if (code && email && parsedId && !isNaN(parsedId) && parsedId > 0) {
      setLoaing(true);
      AccountApiService.confirmEmailChange(parsedId, code, email).then(
        (response: ResultDTO) => {
          if (response.succeeded) {
            setLoaing(false);
            setConfirmSuccess(true);
            setError(null);
          } else {
            setLoaing(false);
            setError({ message: response.errors.join('\n') });
            setConfirmSuccess(false);
          }
        },
        (error) => {
          setLoaing(false);
          setError(error);
          setConfirmSuccess(false);
        }
      );
    } else {
      setLoaing(false);
      setError({ message: 'Invalid link.' });
      setConfirmSuccess(false);
    }
  }, []); // Only do this on component mount

  let alert: JSX.Element | null = null;

  if (error !== null) {
    let message = 'Invalid link. Please request another and try again.';
    if (error.message) {
      message = error.message;
    }
    alert = (
      <Alert
        message="Error"
        description={message}
        type="error"
        showIcon={true}
        style={{ marginBottom: '12px', whiteSpace: 'pre-wrap' }}
      />
    );
  }
  if (confirmSuccess) {
    const message = 'Username/email change confirmed successfully!';
    alert = (
      <Alert message="Success" description={message} type="success" showIcon={true} style={{ marginBottom: '12px' }} />
    );
  }

  return (
    <LoginLayout cardTitle="Email Change Confirmation">
      {alert}
      <div
        style={{
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {!loading ? (
          <Link to={Routes.LOGIN}>
            <Button htmlType="button" size="large" disabled={loading} shape="round">
              Go to Login
            </Button>
          </Link>
        ) : null}
      </div>
    </LoginLayout>
  );
};

export default ConfirmEmailChangePage;
