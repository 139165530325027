import { Col, Layout, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import ActionLogApiController from '../../api/ActionLogApiService';
import DataTable, { DataTableColumnProps } from '../../components/shared/DataTable/DataTable';
import PageTitle from '../../components/shared/PageTitle';
import DataTableColumnUtil from '../../components/shared/DataTable/DataTableColumnUtil';
import FailedMemberRegistrationDTO from '../../models/FailedMemberRegistrationDTO';

const Content = Layout.Content;

const FailedMemberRegistrationsPage = () => {
  const [tableData, setTableData] = useState<FailedMemberRegistrationDTO[]>([]);
  const [loading, setLoading] = useState(false);
  const dataTableRef = React.useRef<DataTable<FailedMemberRegistrationDTO>>(null);

  useEffect(() => {
    ActionLogApiController.failedMemberRegistrations()
      .then((res) => {
        setTableData(res);
      })
      .finally(() => {
        setLoading(false);
        dataTableRef.current?.refresh();
      });
  }, []);

  const tableColumns = [
    { ...DataTableColumnUtil.Number('Id', 'id'), width: 100 },
    { ...DataTableColumnUtil.DateTime('Timestamp', 'date'), width: 200 },
    { ...DataTableColumnUtil.Text('Last Name', 'lastName') },
    { ...DataTableColumnUtil.Text('Email', 'email') },
    { ...DataTableColumnUtil.Text('Phone', 'phone') },
    { ...DataTableColumnUtil.Text('Role', 'role') },
    { ...DataTableColumnUtil.Text('Reason', 'reason') },
  ] as DataTableColumnProps<FailedMemberRegistrationDTO>[];

  return (
    <Content className="content-padding">
      <Row>
        <Col xs={24}>
          <Row className="content-header" justify="space-between">
            <Col xs={24} lg={10} xl={13} xxl={14}>
              <PageTitle title="Failed Member Registrations" />
            </Col>
            <Col></Col>
          </Row>
          <DataTable
            ref={dataTableRef}
            serverSide={false}
            globalSearch={true}
            globalSearchPlaceholder="Search..."
            tableProps={{
              rowKey: 'id',
              size: 'large',
              loading: loading,
              sortDirections: ['ascend', 'descend'],
              scroll: { x: 575 },
            }}
            columns={tableColumns}
            data={tableData}
            styleOptions={{
              alternatingRowHighlight: true,
            }}
          />
        </Col>
      </Row>
    </Content>
  );
};

export default FailedMemberRegistrationsPage;
