import { LoadingOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Select, Spin } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import AccountApiService from '../../api/AccountApiService';
import AccessRole from '../../consts/AccessRole';
import Routes from '../../consts/Routes';
import UserSecurityDTO from '../../models/UserSecurityDTO';
import LoginLayout from './LoginLayout';

const AccessGatePage = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<UserSecurityDTO>(UserSecurityDTO.create());
  const [form] = Form.useForm();
  const [accessRoleId, setAccessRoleId] = useState<number>(0);
  const [accessEntityId, setAccessEntityId] = useState<number | undefined>(undefined);

  useEffect(() => {
    AccountApiService.getCurrentUser().then((res) => {
      setUser(res);
      setLoading(false);
      const first = Object.keys(res.accessEntityCollection)[0];
      if (first) {
        const id = parseInt(first);
        setAccessRoleId(id);
        form.setFieldsValue({ accessRoleId: id });
      }
    });
  }, []);

  useEffect(() => {
    setAccessEntityId(undefined);
    if (accessRoleId) {
      const first = user.accessEntityCollection[accessRoleId][0];
      if (first) {
        setAccessEntityId(first.id);
        form.setFieldsValue({ accessEntityId: first.id });
      }
    }
  }, [accessRoleId]);

  const handleSubmit = () => {
    const myAccessRoleId = accessRoleId;
    let myAccessEntityId = accessEntityId;

    if (myAccessRoleId != AccessRole.CLAIMDOC && !myAccessEntityId) {
      const list = user.accessEntityCollection[myAccessRoleId ?? 0];
      myAccessEntityId = list[0].id;
    }

    setLoading(true);
    AccountApiService.setAccessRole(myAccessRoleId ?? 0, myAccessEntityId)
      .then(() => {
        const urlParams = new URLSearchParams(location.search);
        const returnUrl = urlParams.get('returnUrl');
        window.location.href = returnUrl ? Routes.BASE_ROUTE + returnUrl : Routes.HOME_ROUTE;
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  let entityLabel = undefined;
  switch (accessRoleId) {
    case AccessRole.TPA:
      entityLabel = 'Which TPA?';
      break;
    case AccessRole.MEMBER:
    case AccessRole.EMPLOYER:
      entityLabel = 'Which Employer?';
      break;
    case AccessRole.BROKER:
      entityLabel = 'Which Brokerage?';
      break;
  }

  const multiRole = Object.keys(user.accessEntityCollection).length > 1;
  const multiTenant =
    accessRoleId &&
    accessRoleId !== AccessRole.CLAIMDOC &&
    user.accessEntityCollection[accessRoleId] &&
    user.accessEntityCollection[accessRoleId].length > 1;

  const prompt = multiRole ? 'Please select the role to continue with:' : undefined;

  if (user.id > 0 && !multiRole && !multiTenant) {
    return <Redirect to={Routes.HOME_ROUTE} />;
  }

  return (
    <LoginLayout pageTitle="Gate" cardTitle={prompt ?? ''}>
      <Spin spinning={loading}>
        <Row justify="center">
          <Col xs={24} sm={18}>
            <Form form={form} layout="vertical" onFinish={handleSubmit} requiredMark={false}>
              {multiRole ? (
                <FormItem name="accessRoleId" label="Access Role" rules={[{ required: true, message: 'Required.' }]}>
                  <Select value={accessRoleId} onChange={setAccessRoleId} autoFocus style={{ width: '100%' }}>
                    {user.accessEntityCollection[AccessRole.BROKER] ? (
                      <Select.Option value={AccessRole.BROKER}>Broker</Select.Option>
                    ) : null}
                    {user.accessEntityCollection[AccessRole.CLAIMDOC] ? (
                      <Select.Option value={AccessRole.CLAIMDOC}>ClaimDOC Employee (not demo mode)</Select.Option>
                    ) : null}
                    {user.accessEntityCollection[AccessRole.DPC] ? (
                      <Select.Option value={AccessRole.DPC}>DPC</Select.Option>
                    ) : null}
                    {user.accessEntityCollection[AccessRole.EMPLOYER] ? (
                      <Select.Option value={AccessRole.EMPLOYER}>Employer</Select.Option>
                    ) : null}
                    {user.accessEntityCollection[AccessRole.MEMBER] ? (
                      <Select.Option value={AccessRole.MEMBER}>Member</Select.Option>
                    ) : null}
                    {user.accessEntityCollection[AccessRole.OTHERCOMPANY] ? (
                      <Select.Option value={AccessRole.OTHERCOMPANY}>Other Company</Select.Option>
                    ) : null}
                    {user.accessEntityCollection[AccessRole.TPA] ? (
                      <Select.Option value={AccessRole.TPA}>TPA</Select.Option>
                    ) : null}
                  </Select>
                </FormItem>
              ) : null}

              {multiTenant ? (
                <FormItem name="accessEntityId" label={entityLabel} rules={[{ required: true, message: 'Required.' }]}>
                  <Select autoFocus value={accessEntityId} onChange={setAccessEntityId} style={{ width: '100%' }}>
                    {user.accessEntityCollection[accessRoleId].map((a) => (
                      <Select.Option key={`opt_${a.id}`} value={a.id}>
                        {a.name}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem>
              ) : null}

              <FormItem>
                <Button type="primary" htmlType="submit" size="large" disabled={loading} shape="round" block>
                  {loading ? (
                    <span>
                      <LoadingOutlined /> Loading...
                    </span>
                  ) : (
                    <span>Continue</span>
                  )}
                </Button>
              </FormItem>
            </Form>
          </Col>
        </Row>
      </Spin>
    </LoginLayout>
  );
};

export default AccessGatePage;
