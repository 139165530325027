import { notification, Spin } from 'antd';
import React, { useContext, useState } from 'react';
import PricingToolApiService from '../../api/PricingToolApiService';
import ProviderDTO from '../../models/ProviderDTO';
import ProviderSearchQueryDTO from '../../models/ProviderSearchQueryDTO';
import ProviderSearchResultDTO from '../../models/ProviderSearchResultDTO';
import { ProviderQuery, PricingToolProviderSearchFilters } from './PricingToolProviderSearchFilters';
import PricingToolProviderSearchResults from './PricingToolProviderSearchResults';
import AccessRole from '../../consts/AccessRole';
import { AuthenticationContext } from '../../auth/AuthenticationContext';

const MY_LOCATION = 'My location';
const MAP_LOCATION = 'Map location';
const DEFAULT_PAGE_NUMBER = 1;

interface PricingToolProviderSearchProps {
  onSelect: (provider: ProviderDTO | undefined) => void;
  onSearch: (query: ProviderSearchQueryDTO, clearProvider?: boolean) => void;
  initialProvider?: ProviderDTO;
  initialQuery?: ProviderSearchQueryDTO;
}

const PricingToolProviderSearch: React.FC<PricingToolProviderSearchProps> = (props) => {
  const authCtx = useContext(AuthenticationContext);
  const isServicePartnerRole = [AccessRole.BROKER, AccessRole.TPA, AccessRole.DPC, AccessRole.OTHERCOMPANY].includes(
    authCtx.user?.accessRoleId ?? -1
  );

  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState<ProviderDTO | undefined>(props.initialProvider);
  const [searchResults, setSearchResults] = useState<ProviderDTO[] | undefined>(undefined);
  const [sortOrder, setSortOrder] = useState(1);
  const [currQueryDTO, setCurrQueryDTO] = useState<ProviderSearchQueryDTO | undefined>(undefined);

  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number | undefined>(undefined);
  const [pageNumber, setPageNumber] = useState<number | undefined>(
    isServicePartnerRole ? props.initialQuery?.pageNumber ?? DEFAULT_PAGE_NUMBER : undefined
  );

  const searchProviders = async (locationField: string, query: ProviderQuery) => {
    let pageNum: number | undefined;
    if (!query.auto) {
      setSelected(undefined);
      props.onSelect(undefined);

      if (isServicePartnerRole) {
        pageNum = DEFAULT_PAGE_NUMBER;
        setPageNumber(DEFAULT_PAGE_NUMBER);
      }
    } else {
      pageNum = props.initialQuery?.pageNumber ?? DEFAULT_PAGE_NUMBER;
    }

    setSearchResults(undefined);
    setSortOrder(query.sortBy);

    const queryDTO = generateSearchQuery(locationField, query, pageNum);
    props.onSearch(queryDTO, !query.auto);

    searchProvidersApiCall(queryDTO);
    setCurrQueryDTO(queryDTO);
  };

  const searchProvidersApiCall = (queryDTO: ProviderSearchQueryDTO) => {
    setLoading(true);
    PricingToolApiService.searchProviders(queryDTO)
      .then((res) => {
        processSearchResult(res);
      })
      .catch((err) => {
        notification.error({
          message: err.message,
          description: err.description,
        });
        setLoading(false);
      });
  };

  const generateSearchQuery = (
    locationField: string,
    query: ProviderQuery,
    pageNum?: number
  ): ProviderSearchQueryDTO => {
    let lat: number | undefined;
    let long: number | undefined;
    let address: string | undefined;
    if ((locationField === MY_LOCATION || locationField === MAP_LOCATION) && query.relativeLocation) {
      lat = query.relativeLocation[1];
      long = query.relativeLocation[0];
      address = undefined;
    } else {
      lat = undefined;
      long = undefined;
      address = locationField;
    }
    return ProviderSearchQueryDTO.create({
      distance: query.searchRadius,
      latitude: lat,
      longitude: long,
      address: address,
      providerTypeId: query.providerType,
      providerSpecialty: query.providerSpecialty,
      filterString: query.providersFilter,
      sortBy: query.sortBy,
      agreementCode: query.agreementType,
      agreementType: query.agreementSubType,
      pageNumber: pageNum,
    });
  };

  const processSearchResult = (res: ProviderSearchResultDTO | null) => {
    if (res) {
      res.results = res.results ?? [];
      setLoading(false);
      setTotalRecords(res.totalResults);
      setPageSize(res.pageSize ?? undefined);
      setSearchResults(
        res.results.map((e: ProviderDTO) => {
          return {
            ...e,
            // We only care to show the first 5 digits
            zipcode: e.zipcode?.substring(0, 5) ?? '',
          } as ProviderDTO;
        })
      );
    } else {
      setLoading(false);
    }
  };

  const onPageChange = (page: number) => {
    if (currQueryDTO) {
      const newQueryDto = { ...currQueryDTO, pageNumber: page };
      searchProvidersApiCall(newQueryDto);
      setCurrQueryDTO(newQueryDto);
      props.onSearch(newQueryDto);
    }

    setSelected(undefined);
    setSearchResults(undefined);
    setPageNumber(page);
  };

  return (
    <Spin spinning={loading}>
      <PricingToolProviderSearchFilters
        isMobile={false}
        searchProviders={searchProviders}
        resultsCount={0}
        initialQuery={props.initialQuery}
      />
      <PricingToolProviderSearchResults
        isMobile={false}
        providers={searchResults}
        selected={selected}
        sortBy={sortOrder}
        resultClick={(p) => {
          props.onSelect(p);
          setSelected(p);
        }}
        totalCount={totalRecords}
        pagination={
          isServicePartnerRole
            ? {
                currentPage: pageNumber ?? 0,
                pageSize: pageSize ?? 0,
                onPageChange: onPageChange,
              }
            : undefined
        }
      />
    </Spin>
  );
};

export default PricingToolProviderSearch;
