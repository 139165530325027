import { Button, Form, Input, Modal, notification, Result, Spin, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useState } from 'react';
import OAuth2ApiService from '../../../api/OAuth2ApiService';
import PermissionGroupPicker from '../../shared/PermissionGroupPicker';
interface CreateServiceAccountModalProps {
  open: boolean;
  onClose: () => void;
}

const CreateServiceAccountModal = (props: CreateServiceAccountModalProps) => {
  const [pendingChanges, setPendingChanges] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [form] = useForm();

  const closeModal = () => {
    if (pendingChanges) {
      Modal.confirm({ title: 'Confirm', content: 'Unsaved changes, are you sure?', onOk: props.onClose });
    } else {
      props.onClose();
    }
  };

  const submit = (values: any) => {
    setLoading(true);
    OAuth2ApiService.createServiceAccount(values.username, values.displayName, values.email, values.permissionGroupId)
      .then((res) => {
        setClientSecret(res);
        setPendingChanges(false);
      })
      .catch((error) => {
        notification.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Modal
        visible={props.open}
        title="Create Service Account"
        onCancel={closeModal}
        maskClosable={false}
        destroyOnClose={true}
        onOk={() => (clientSecret == null ? form.submit() : props.onClose())}
      >
        {clientSecret != null ? (
          <>
            <Result
              status="success"
              title="Service Account Created"
              subTitle="Copy this secret, as you will have to regenerate it if you do not."
              extra={[
                <Typography.Title key="secret" level={3} copyable>
                  {clientSecret}
                </Typography.Title>,
              ]}
            />
          </>
        ) : (
          <Spin spinning={loading}>
            <Form
              form={form}
              layout="vertical"
              onFinish={submit}
              onValuesChange={() => {
                if (!pendingChanges) {
                  setPendingChanges(true);
                }
              }}
            >
              <Form.Item
                label="Service Name"
                name="username"
                rules={[
                  {
                    required: true,
                    message: 'Required',
                  },
                  {
                    pattern: /^(\S*?)-(\S*?)$/g,
                    message: 'Service name must be dash (-) separated.',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Display Name"
                name="displayName"
                rules={[
                  {
                    required: true,
                    message: 'Required',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item label="Email">
                <Form.Item
                  name="email"
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: 'Required',
                    },
                    {
                      type: 'email',
                      message: 'Valid email address required.',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <div>
                  <Typography.Text type="secondary">If any emails are generated, send to this account.</Typography.Text>
                </div>
              </Form.Item>

              <Form.Item
                label="Permission Group"
                name="permissionGroupId"
                rules={[
                  {
                    required: true,
                    message: 'Required',
                  },
                ]}
              >
                <PermissionGroupPicker selfLoad />
              </Form.Item>
            </Form>
          </Spin>
        )}
      </Modal>
    </>
  );
};

const CreateServiceAccountButton = () => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Button shape="round" onClick={() => setModalOpen(true)}>
        Create Service Account
      </Button>
      <CreateServiceAccountModal open={modalOpen} onClose={() => setModalOpen(false)} />
    </>
  );
};

export default CreateServiceAccountButton;
