import confirm from 'antd/lib/modal/confirm';

const confirmModal = (title: string, onConfirm: () => void | null, condition?: boolean | null, okText?: string) => {
  if (condition === false) {
    onConfirm();
  } else {
    confirm({
      title: title,
      onOk() {
        onConfirm();
      },
      okText: okText ? okText : 'OK',
    });
  }
};

export default confirmModal;
