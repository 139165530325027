// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import FailedMemberRegistrationDTO from '../models/FailedMemberRegistrationDTO';

export class ActionLogApiService extends BaseApi {

    // post: api/actionlog/FailedMemberRegistrations
    public failedMemberRegistrations(): Promise<FailedMemberRegistrationDTO[]> {
        let url = `api/actionlog/FailedMemberRegistrations`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, FailedMemberRegistrationDTO[]>(null, 'post', url, true, false);
    }
}
var service = new ActionLogApiService();
export default service;
