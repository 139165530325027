import { Layout } from 'antd';
import ProviderNomination from '../../components/nominate-providers/ProviderNomination';
import PnfDTO from '../../models/PnfDTO';
import React, { useContext, useState } from 'react';
import ProviderNominationService from '../../api/ProviderNominationsApiService';
import useDraftSaving from '../../components/nominate-providers/useDraftSaving';
import { AuthenticationContext } from '../../auth/AuthenticationContext';
import AccessRole from '../../consts/AccessRole';
import moment from 'moment';
import PnfAppointmentRefNumberDTO from '../../models/PnfAppointmentRefNumberDTO';
import PnfProviderInfoDTO from '../../models/PnfProviderInfoDTO';
interface ProviderNominationPageProps {
  provider?: (PnfProviderInfoDTO | undefined)[];
  isImplementationPage?: boolean;
}

const ProviderNominationPage = (props: ProviderNominationPageProps) => {
  const [checkingDraft, draftDto, lastSaveDisplay, promptModal, saveDraft] = useDraftSaving();
  const authContext = useContext(AuthenticationContext);
  const onBehalf = authContext?.user?.accessRoleId !== AccessRole.MEMBER ?? true;
  const [submittedPnf, setSubmittedPnf] = useState<PnfDTO | undefined>(undefined);

  const submitPnf = async (pnf: PnfDTO, cb?: (success: boolean, error?: any) => void) => {
    let success = false;
    let error: any = null;

    try {
      SetAppointmentTimeZones(pnf);

      const res = await ProviderNominationService.submit(pnf);
      error = res.errors ? { message: res.errors.join(', ') } : null;
      success = res.succeeded;

      SetAppointmentReferenceNumbers(pnf, res.appointmentReferenceNumbers ?? []);

      setSubmittedPnf({ ...pnf, referenceNumber: res.headerReferenceNumber });
    } catch {
      success = false;
      setSubmittedPnf(undefined);
    }

    if (cb) {
      cb(success, error);
    }
  };

  return (
    <Layout>
      <Layout.Content style={{ backgroundColor: '#eeefef' }}>
        {checkingDraft ? null : (
          <div style={{ position: 'relative' }}>
            <div style={{ position: 'absolute', top: 5, right: 5, textAlign: 'right' }}>{lastSaveDisplay()}</div>
            <ProviderNomination
              onBehalf={onBehalf}
              onSubmit={submitPnf}
              dto={draftDto}
              pnfUpdated={saveDraft}
              submittedPnf={submittedPnf}
              provider={props.provider}
              isImplementationPage={props.isImplementationPage}
            />
          </div>
        )}

        {promptModal}
      </Layout.Content>
    </Layout>
  );
};

export default ProviderNominationPage;

export function SetAppointmentTimeZones(pnf: PnfDTO) {
  // Convert appointment times to the selected timezone and then call .format to prevent conversion to UTC
  pnf.providers?.forEach((p) => {
    p.appointments?.forEach((a) => {
      if (a.appointmentDate) {
        let newDate = moment.isMoment(a.appointmentDate) ? a.appointmentDate : moment(a.appointmentDate);
        newDate.set('seconds', 0);

        // Set the appointment date time zone to the selected without conversion
        if (a.appointmentTimeZone) {
          newDate = newDate.tz(a.appointmentTimeZone, true);
        }

        a.appointmentDate = newDate?.format();
      }
    });
  });
}

export function SetAppointmentReferenceNumbers(pnf: PnfDTO, apptRefNumbers: PnfAppointmentRefNumberDTO[]) {
  pnf.providers?.forEach((p) => {
    p.appointments?.forEach((a) => {
      const refNumberDto = apptRefNumbers.find((r) => r.appointmentId === a.id);
      if (refNumberDto) {
        a.referenceNumber = refNumberDto.referenceNumber;
      }
    });
  });
}
