// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Constants.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
export default class UserTabTypes {
    public static readonly ALL: string = 'all';
    public static readonly EMPLOYERS: string = 'employers';
    public static readonly MEMBERS: string = 'members';
    public static readonly TPAS: string = 'tpas';
    public static readonly DPCS: string = 'dpcs';
    public static readonly OTHERCOMPANIES: string = 'others';
    public static readonly BROKERS: string = 'brokers';
    public static readonly CLAIMDOC: string = 'claimdoc';
}
