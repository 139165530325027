// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';

interface UserClientAccessDTO { 
    userId: number;
    userName: string | null;
    firstName: string | null;
    lastName: string | null;
    registered: boolean;
    tpaOrBrokerId: number;
    hasAccess: boolean;
    permissionGroupId: number | null;
    requireMfa: boolean;
    lastLoggedInOn: moment.Moment | string | null;
}
const UserClientAccessDTO = {
    create: (initValues?: Partial<UserClientAccessDTO> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            userId: 0,
            userName: null,
            firstName: null,
            lastName: null,
            registered: false,
            tpaOrBrokerId: 0,
            hasAccess: false,
            permissionGroupId: null,
            requireMfa: false,
            lastLoggedInOn: null,
        },
        initValues);
    }
};

export default UserClientAccessDTO;