import { LoadingOutlined } from '@ant-design/icons';
import { Alert } from 'antd';
import React from 'react';

interface OcrLoadingProps {
  loading: boolean;
}

const OcrLoading: React.FC<OcrLoadingProps> = (props) => {
  return (
    <>
      {props.loading && (
        <Alert
          message="Your file is being processed."
          description="This may take a few minutes..."
          type="info"
          showIcon
          icon={<LoadingOutlined />}
          style={{ animation: 'fadeIn .5s' }}
        />
      )}
    </>
  );
};

export default OcrLoading;
