import { Menu } from 'antd';
import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { AuthenticationContext } from '../../auth/AuthenticationContext';
import Routes from '../../consts/Routes';
import HistoryUtil from '../../utils/HistoryUtil';

const HeaderAccountContent = (isGuest?: boolean) => {
  const authContext = useContext(AuthenticationContext);

  const isMultiRole = () => {
    const currentUser = authContext.user;
    return currentUser?.multiRole ?? false;
  };

  const logoutUser = async () => {
    if (await authContext.logout()) {
      if (isGuest) {
        HistoryUtil.push('/guest');
      } else {
        HistoryUtil.push('/login');
      }
    }
  };

  return (
    <Menu selectedKeys={[]}>
      {isGuest === true ? null : (
        <Menu.Item key="profile">
          <NavLink to={Routes.PROFILE}>
            <span>Profile</span>
          </NavLink>
        </Menu.Item>
      )}

      {isMultiRole() ? (
        <Menu.Item key="role">
          <NavLink to={Routes.GATE}>
            <span>Change Role</span>
          </NavLink>
        </Menu.Item>
      ) : null}
      <Menu.Item key="logout" onClick={logoutUser}>
        Logout
      </Menu.Item>
    </Menu>
  );
};

export default HeaderAccountContent;
