// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import ClientAccessDTO from '../models/ClientAccessDTO';
import ParentSecuritySettingsDTO from '../models/ParentSecuritySettingsDTO';
import InviteUserDTO from '../models/InviteUserDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import TpaListDTO from '../models/TpaListDTO';
import TpaDetailDTO from '../models/TpaDetailDTO';
import SaveTpaDetailResultDTO from '../models/SaveTpaDetailResultDTO';
import ClientListDTO from '../models/ClientListDTO';
import ResultDTO from '../models/ResultDTO';
import UserClientAccessDTO from '../models/UserClientAccessDTO';
import ParentSecurityUserSettingsDTO from '../models/ParentSecurityUserSettingsDTO';

export class TpaApiService extends BaseApi {

    // post: api/tpa/GetTpaList
    public getTpaList(tableRequest: TableRequestDTO): Promise<TableResponseDTO<TpaListDTO>> {
        let url = `api/tpa/GetTpaList`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<TpaListDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/tpa/GetTpaDetails?clientId=${clientId}
    public getTpaDetails(clientId: number): Promise<TpaDetailDTO> {
        let url = `api/tpa/GetTpaDetails`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (clientId != null) {
            url += `${prefix}clientId=${clientId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TpaDetailDTO>(null, 'post', url, true, false);
    }

    // post: api/tpa/SaveTpaNotes?tpaId=${tpaId}
    public saveTpaNotes(tpaId: number, notes: string): Promise<SaveTpaDetailResultDTO> {
        let url = `api/tpa/SaveTpaNotes`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (tpaId != null) {
            url += `${prefix}tpaId=${tpaId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveTpaDetailResultDTO>(notes, 'post', url, true, false);
    }

    // post: api/tpa/GetClientsForTpa?tpaId=${tpaId}
    public getClientsForTpa(tpaId: number): Promise<ClientListDTO[]> {
        let url = `api/tpa/GetClientsForTpa`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (tpaId != null) {
            url += `${prefix}tpaId=${tpaId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ClientListDTO[]>(null, 'post', url, true, false);
    }

    // post: api/tpa/DeleteTpaClientAccess?clientId=${clientId}&tpaId=${tpaId}
    public deleteTpaClientAccess(clientId: number, tpaId: number): Promise<ResultDTO> {
        let url = `api/tpa/DeleteTpaClientAccess`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (clientId != null) {
            url += `${prefix}clientId=${clientId}`;
            prefix = '&';
        }
        if (tpaId != null) {
            url += `${prefix}tpaId=${tpaId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ResultDTO>(null, 'post', url, true, false);
    }

    // post: api/tpa/GetTpaUserClientAccesses?tpaId=${tpaId}&clientId=${clientId}
    public getTpaUserClientAccesses(tpaId: number, clientId: number, tableRequest: TableRequestDTO): Promise<TableResponseDTO<UserClientAccessDTO>> {
        let url = `api/tpa/GetTpaUserClientAccesses`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (tpaId != null) {
            url += `${prefix}tpaId=${tpaId}`;
            prefix = '&';
        }
        if (clientId != null) {
            url += `${prefix}clientId=${clientId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<UserClientAccessDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/tpa/SaveTpaClientAccess?tpaId=${tpaId}
    public saveTpaClientAccess(tpaId: number, dto: ClientAccessDTO): Promise<ResultDTO> {
        let url = `api/tpa/SaveTpaClientAccess`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (tpaId != null) {
            url += `${prefix}tpaId=${tpaId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ResultDTO>(dto, 'post', url, true, false);
    }

    // post: api/tpa/SearchClientsNotLinkedToTpa?tpaId=${tpaId}&searchText=${encodeURIComponent(searchText)}
    public searchClientsNotLinkedToTpa(tpaId: number, searchText: string): Promise<ClientListDTO[]> {
        let url = `api/tpa/SearchClientsNotLinkedToTpa`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (tpaId != null) {
            url += `${prefix}tpaId=${tpaId}`;
            prefix = '&';
        }
        if (searchText != null) {
            url += `${prefix}searchText=${encodeURIComponent(searchText)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ClientListDTO[]>(null, 'post', url, true, false);
    }

    // get: api/tpa/GetSecuritySettings?id=${id}
    public getSecuritySettings(id: number): Promise<ParentSecuritySettingsDTO> {
        let url = `api/tpa/GetSecuritySettings`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (id != null) {
            url += `${prefix}id=${id}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ParentSecuritySettingsDTO>(null, 'get', url, true, false);
    }

    // post: api/tpa/GetUserSecuritySettings?tpaId=${tpaId}
    public getUserSecuritySettings(tpaId: number, tableRequest: TableRequestDTO): Promise<TableResponseDTO<ParentSecurityUserSettingsDTO>> {
        let url = `api/tpa/GetUserSecuritySettings`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (tpaId != null) {
            url += `${prefix}tpaId=${tpaId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<ParentSecurityUserSettingsDTO>>(tableRequest, 'post', url, true, false);
    }

    // delete: api/tpa/RemoveUserSecurity?tpaId=${tpaId}&userId=${userId}
    public removeUserSecurity(tpaId: number, userId: number): Promise<boolean> {
        let url = `api/tpa/RemoveUserSecurity`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (tpaId != null) {
            url += `${prefix}tpaId=${tpaId}`;
            prefix = '&';
        }
        if (userId != null) {
            url += `${prefix}userId=${userId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, boolean>(null, 'delete', url, true, false);
    }

    // post: api/tpa/SaveSecuritySettings?id=${id}
    public saveSecuritySettings(id: number, settings: ParentSecuritySettingsDTO): Promise<boolean> {
        let url = `api/tpa/SaveSecuritySettings`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (id != null) {
            url += `${prefix}id=${id}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, boolean>(settings, 'post', url, true, false);
    }

    // post: api/tpa/InviteUsers?tpaId=${tpaId}
    public inviteUsers(tpaId: number, users: InviteUserDTO[]): Promise<boolean> {
        let url = `api/tpa/InviteUsers`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (tpaId != null) {
            url += `${prefix}tpaId=${tpaId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, boolean>(users, 'post', url, true, false);
    }

    // post: api/tpa/SimpleUserList?tpaId=${tpaId}
    public simpleUserList(tpaId: number, tableRequest: TableRequestDTO): Promise<TableResponseDTO<ParentSecurityUserSettingsDTO>> {
        let url = `api/tpa/SimpleUserList`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (tpaId != null) {
            url += `${prefix}tpaId=${tpaId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<ParentSecurityUserSettingsDTO>>(tableRequest, 'post', url, true, false);
    }

    // delete: api/tpa/${tpaId}
    public deleteTpa(tpaId: number): Promise<boolean> {
        let url = `api/tpa/${tpaId}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, boolean>(null, 'delete', url, true, false);
    }
}
var service = new TpaApiService();
export default service;
