// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';

interface AddressDTO { 
    id: number;
    address1: string | null;
    address2: string | null;
    city: string | null;
    state: string | null;
    zipCode: string | null;
}
const AddressDTO = {
    create: (initValues?: Partial<AddressDTO> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            id: 0,
            address1: null,
            address2: null,
            city: null,
            state: null,
            zipCode: null,
        },
        initValues);
    }
};

export default AddressDTO;