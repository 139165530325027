import { ArrowRightOutlined } from '@ant-design/icons';
import { Button, Checkbox } from 'antd';
import { ButtonShape } from 'antd/lib/button';
import moment from 'moment';
import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';

class Renderers extends React.Component<unknown & RouteComponentProps<any>, unknown> {
  public static shortDate(value: string | moment.Moment | null, format?: string) {
    if (!value) {
      return;
    }

    const date = moment.isMoment(value) ? value : this.getMoment(value);

    if (!date.isValid()) {
      return '';
    }

    return <span>{date.format(format || 'M/D/YYYY')}</span>;
  }

  public static shortTime(value: string | moment.Moment) {
    if (!value) {
      return;
    }

    let date = moment.isMoment(value) ? value : this.getMoment(value);

    if (!date.isValid()) {
      date = moment(value, 'HH:mm:ss');

      if (!date.isValid()) {
        return '';
      }
    }

    return <span>{date.format('h:mm a')}</span>;
  }

  public static address(
    value?: {
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
    } | null,
    isHtml?: boolean
  ) {
    const html = [];
    let str = '';
    if (value?.address1) {
      if (isHtml) {
        html.push(<span>{value?.address1}</span>);
        html.push(<br />);
      } else {
        str += value?.address1 + '\n';
      }
    }
    if (value?.address2) {
      if (isHtml) {
        html.push(<span>{value?.address2}</span>);
        html.push(<br />);
      } else {
        str += value?.address2 + '\n';
      }
    }
    if (value?.city || value?.state || value?.zipCode) {
      if (isHtml) {
        html.push(
          <span>
            {value?.city}, {value?.state} {value?.zipCode}
          </span>
        );
      } else {
        str += `${value?.city}, ${value?.state} ${value?.zipCode}`;
      }
    }
    return isHtml ? html : str;
  }

  public static longDate(value: string | moment.Moment) {
    if (!value) {
      return;
    }

    const date = moment.isMoment(value) ? value : this.getMoment(value);

    if (!date.isValid()) {
      return '';
    }

    return <span>{date.format('ddd, MMM D YYYY')}</span>;
  }

  public static dateAndTime(value: string | moment.Moment) {
    if (!value) {
      return;
    }

    const date = moment.isMoment(value) ? value : this.getMoment(value);

    if (!date.isValid()) {
      return '';
    }

    return <span>{date.format('M/D/YYYY h:mm a')}</span>;
  }

  public static currency(value: string) {
    if (!value || isNaN(value as any)) {
      return;
    }

    try {
      const num = parseFloat(value);
      return <span>${num.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}</span>;
    } catch (error) {
      return;
    }
  }

  public static percentage(value: string) {
    if (!value || isNaN(value as any)) {
      return;
    }

    try {
      const num = +parseFloat(value).toFixed(2);

      return <span>{num.toString().replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}%</span>;
    } catch (error) {
      return;
    }
  }

  public static decimal(value: string | number, decimalPlaces: number) {
    if ((!value && value !== 0) || isNaN(value as any)) {
      return;
    }

    try {
      const num = typeof value === 'number' ? value : parseFloat(value);
      return <span>{num.toFixed(decimalPlaces)}</span>;
    } catch (error) {
      return;
    }
  }

  public static booleanYesNo(value: boolean) {
    return value ? 'Yes' : 'No';
  }

  public static booleanYesEmpty(value: boolean) {
    return value ? 'Yes' : '';
  }

  public static booleanCheckbox(value: boolean) {
    return <Checkbox defaultChecked={value} disabled={true} />;
  }

  public static linkButtons(links: string | { link: string; icon: JSX.Element }[]) {
    if (!links || links.length === 0) {
      return;
    }

    const isString = typeof links === 'string';
    const strVal = links as string;
    const arrVal = links as { link: string; icon: JSX.Element }[];
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {isString
          ? strVal
            ? this.linkButton(strVal)
            : ''
          : arrVal.map((link, index) => this.linkButton(link.link, link.icon, undefined, index))}
      </div>
    );
  }

  public static linkButton(link: string, icon?: JSX.Element, shape?: ButtonShape, index?: number) {
    shape = shape ? shape : !icon ? 'circle' : undefined;
    if (!icon) {
      icon = <ArrowRightOutlined />;
    }

    return (
      <Link to={link} key={index || -1} style={{ flex: 1 }}>
        {shape ? <Button shape={shape} size="small" icon={icon} /> : icon}
      </Link>
    );
  }

  public static phoneNumber(phoneNumberString: string, noFormat?: boolean) {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    if (noFormat === true) {
      return cleaned;
    }
    const match = cleaned.match(/^(1)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return (match[1] != undefined ? '+1 ' : '') + '(' + match[2] + ') ' + match[3] + '-' + match[4];
    }
    return null;
  }

  public static phoneNumberLink(phoneNumberString: string) {
    const formatted = Renderers.phoneNumber(phoneNumberString);

    if (formatted == null) {
      return null;
    }

    const cleaned = Renderers.phoneNumber(phoneNumberString, true);

    return <a href={'tel:' + cleaned}>{formatted}</a>;
  }

  public static emailLink(email: string, text?: string) {
    return (
      <a href={'mailto:' + email} rel="noreferrer">
        {text ?? email}
      </a>
    );
  }

  public static urlLink(url: string, text?: string) {
    return (
      <a target="_blank" href={url.indexOf('http') >= 0 ? url : 'https://' + url} rel="noreferrer">
        {text ?? url}
      </a>
    );
  }

  // Use UTC constructor for UTC dates, otherwise moment converts to the local time zone.
  private static getMoment(str: string) {
    if (str.includes('+00:00') || str.includes('Z')) {
      return moment.utc(str);
    } else {
      return moment(str);
    }
  }
}

export default Renderers;
