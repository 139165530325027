import Icon from '@ant-design/icons';
import { Col, Row } from 'antd';
import React from 'react';
import SvgIcons from '../../consts/SvgIcons';

interface MapLegendProps {
  mobile: boolean;
}

function MapLegend({ mobile }: MapLegendProps) {
  return (
    <Col>
      <Row gutter={12} align="middle" justify="space-between">
        {mobile ? <Col flex={1}></Col> : null}
        <Col>
          <h4 style={{ margin: '0 0 5px' }}>Numbers over bubbles indicate the number of providers in that area</h4>
        </Col>
      </Row>
      <Row gutter={12} align="middle" justify="space-between" style={{ marginBottom: 10 }}>
        {mobile ? <Col flex={1}></Col> : null}
        <Col>
          <Row align="middle">
            <div
              style={{
                width: '12px',
                height: '12px',
                marginRight: '.25em',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  width: '12px',
                  height: '12px',
                  backgroundColor: '#D2292D',
                  borderRadius: '50%',
                }}
              >
                <div
                  style={{
                    width: '50%',
                    height: '50%',
                    marginTop: '25%',
                    marginLeft: '25%',
                    backgroundColor: '#ffffff',
                    borderRadius: '50%',
                  }}
                />
              </div>
            </div>
            <h4 style={{ margin: 0, fontSize: 12, fontWeight: 600 }}>Search Center</h4>
          </Row>
        </Col>
        <Col>
          <Row align="middle">
            <Icon
              style={{ fontSize: '7px', marginRight: '.5em' }}
              component={SvgIcons.MarkerClinic}
              aria-label="Facility"
            />
            <h4 style={{ margin: 0, fontSize: 12, fontWeight: 600 }}>Facility</h4>
          </Row>
        </Col>
        <Col>
          <Row align="middle">
            <Icon
              style={{ fontSize: '7px', marginRight: '.5em' }}
              component={SvgIcons.MarkerIndividual}
              aria-label="Practitioner"
            />
            <h4 style={{ margin: 0, fontSize: 12, fontWeight: 600 }}>Practitioner</h4>
          </Row>
        </Col>
      </Row>
    </Col>
  );
}

export default React.memo(MapLegend);
