// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Constants.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
export default class MailroomFileStatuses {
    public static readonly DONE: string = 'Done';
    public static readonly FAILED: string = 'Failed';
    public static readonly MANUAL: string = 'Manual';
    public static readonly JUNK: string = 'Junk';
    public static readonly RUNNING: string = 'Running';
    public static readonly TRANSFERRED: string = 'Transferred';
}
