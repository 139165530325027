import { Button, Card, Col, Divider, Row, Spin, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react';
import PageTitle from '../shared/PageTitle';
import NominationSearchApiService from '../../api/NominationSearchApiService';
import PnfDTO from '../../models/PnfDTO';

const { Title } = Typography;

interface NominationSearchDetailProps {
  claimDocClaimId: string;
  returnToSearch: () => void;
}

const NominationSearchDetailPage = (props: NominationSearchDetailProps) => {
  const [memberClaim, setMemberClaim] = useState<PnfDTO>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    NominationSearchApiService.getMemberNominationByNominationId(props.claimDocClaimId)
      .then((result) => {
        setMemberClaim(result);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Content className="content-padding">
      <Spin spinning={loading}>
        <Row>
          <Button title="Back" onClick={props.returnToSearch}>
            Back
          </Button>
        </Row>
        <Row>
          <PageTitle title={'Nomination Detail'} style={{ marginTop: '0.5em', marginBottom: '0.5em' }} />
        </Row>
        <Row>
          <Col style={{ width: '100%' }}>
            <Card className="ant-card-background ant-card-shadow" bordered={false} bodyStyle={{ fontSize: '16px' }}>
              <Title level={3}>Nomination Information</Title>
              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <b>Reference Number:</b> {memberClaim?.referenceNumber}
                  <br />
                  {/* <b>Total Charge:</b> ${memberClaim?.totalCharge.toLocaleString()}
                  <br />
                  <b>Received Date:</b> {Renderers.shortDate(moment(memberClaim?.receivedDate))} */}
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <br />
        <Row>
        </Row>
        <Divider />
        <Row gutter={[16, 16]}>
        </Row>
      </Spin>
    </Content>
  );
};

export default NominationSearchDetailPage;
