// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import OcrPreviewColDTO from './OcrPreviewColDTO';

interface OcrPreviewDTO { 
    columnNames: string[];
    data: OcrPreviewColDTO[] | null;
}
const OcrPreviewDTO = {
    create: (initValues?: Partial<OcrPreviewDTO> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            columnNames: [],
            data: [],
        },
        initValues);
    }
};

export default OcrPreviewDTO;