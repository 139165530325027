import { Button } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { ColumnType, DataTableColumnProps, FilterType } from './DataTable';
import DataTableCellRendererUtil, { Address } from './DataTableCellRendererUtil';

export interface ColumnButton<T> {
  text: string;
  onClick: (rowData: T) => void;
  /** Default size is 'small' */
  size?: SizeType;
}

class DataTableColumnUtil {
  public static Text<T>(
    title: string,
    dataIndex: string,
    width?: string | number | null,
    props?: DataTableColumnProps<T>
  ): DataTableColumnProps<T> {
    const res = props || ({} as DataTableColumnProps<T>);
    res.filterType = FilterType.Text;
    res.columnType = ColumnType.Text;
    res.title = title;
    res.dataIndex = dataIndex;
    res.sorter = res.sorter === undefined ? true : res.sorter;
    if (width !== null && width !== undefined) {
      res.width = width;
    }
    return res;
  }

  public static Currency<T>(
    title: string,
    dataIndex: string,
    width?: string | number | null,
    props?: DataTableColumnProps<T>
  ): DataTableColumnProps<T> {
    const res = props || ({} as DataTableColumnProps<T>);
    res.filterType = FilterType.Text;
    res.columnType = ColumnType.Number;
    res.title = title;
    res.dataIndex = dataIndex;
    res.sorter = res.sorter === undefined ? (a: any, b: any) => a[dataIndex] - b[dataIndex] : res.sorter;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    res.render = (value: number, record: T) => (value || value === 0 ? FORMAT.currency2(value) : '');
    res.align = 'right';
    if (width !== null && width !== undefined) {
      res.width = width;
    }
    return res;
  }

  public static Number<T>(
    title: string,
    dataIndex: string,
    precision?: 0 | 2 | 5,
    props?: DataTableColumnProps<T>
  ): DataTableColumnProps<T> {
    const res = props || ({} as DataTableColumnProps<T>);
    res.filterType = FilterType.Text;
    res.columnType = ColumnType.Number;
    res.title = title;
    res.dataIndex = dataIndex;
    res.sorter = res.sorter === undefined ? (a: any, b: any) => a[dataIndex] - b[dataIndex] : res.sorter;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    res.render = (value: number, record: T) =>
      value || value === 0
        ? precision === 0
          ? FORMAT.quantity0(value)
          : precision === 2
          ? FORMAT.quantity2(value)
          : precision === 5
          ? FORMAT.quantity5(value)
          : value
        : '';
    res.align = 'right';
    return res;
  }

  public static BooleanYesNo<T>(
    title: string,
    dataIndex: string,
    width?: string | number | null,
    filterType?: FilterType,
    props?: DataTableColumnProps<T>
  ): DataTableColumnProps<T> {
    return DataTableColumnUtil.Boolean(
      title,
      dataIndex,
      width,
      filterType,
      DataTableCellRendererUtil.BooleanYesNo,
      props
    );
  }

  public static BooleanYesEmpty<T>(
    title: string,
    dataIndex: string,
    width?: string | number | null,
    filterType?: FilterType,
    props?: DataTableColumnProps<T>
  ): DataTableColumnProps<T> {
    return DataTableColumnUtil.Boolean(
      title,
      dataIndex,
      width,
      filterType,
      DataTableCellRendererUtil.BooleanYesEmpty,
      props
    );
  }

  public static BooleanCheckbox<T>(
    title: string,
    dataIndex: string,
    width?: string | number | null,
    filterType?: FilterType,
    props?: DataTableColumnProps<T>
  ): DataTableColumnProps<T> {
    return DataTableColumnUtil.Boolean(
      title,
      dataIndex,
      width,
      filterType,
      DataTableCellRendererUtil.BooleanCheckbox,
      props
    );
  }

  public static Boolean<T>(
    title: string,
    dataIndex: string,
    width?: string | number | null,
    filterType?: FilterType,
    render?: (value: any, record: T) => React.ReactNode,
    props?: DataTableColumnProps<T>
  ): DataTableColumnProps<T> {
    const res = props || ({} as DataTableColumnProps<T>);
    res.filterType = filterType ? filterType : FilterType.BooleanCheckbox;
    res.columnType = ColumnType.Boolean;
    res.render = render;
    res.title = title;
    res.dataIndex = dataIndex;
    res.align = props?.align ? props.align : 'center';
    res.sorter = res.sorter === undefined ? true : res.sorter;
    if (width !== null && width !== undefined) {
      res.width = width;
    }
    return res;
  }

  public static Date<T>(
    title: string | React.ReactElement,
    dataIndex: string,
    width?: string | number | null,
    props?: DataTableColumnProps<T>
  ): DataTableColumnProps<T> {
    const res = props || ({} as DataTableColumnProps<T>);
    res.filterType = res.filterType || FilterType.DateRange;
    res.columnType = ColumnType.Date;
    res.render = DataTableCellRendererUtil.ShortDate;
    res.title = title;
    res.dataIndex = dataIndex;
    res.sorter = res.sorter === undefined ? true : res.sorter;
    res.align = 'center';
    if (width !== null && width !== undefined) {
      res.width = width;
    }
    return res;
  }
  public static DateTime<T>(
    title: string | React.ReactElement,
    dataIndex: string,
    width?: string | number | null,
    props?: DataTableColumnProps<T>
  ): DataTableColumnProps<T> {
    const res = props || ({} as DataTableColumnProps<T>);
    res.filterType = res.filterType || FilterType.DateRange;
    res.columnType = ColumnType.Date;
    res.render = DataTableCellRendererUtil.DateAndTime;
    res.title = title;
    res.dataIndex = dataIndex;
    res.sorter = res.sorter === undefined ? true : res.sorter;
    res.align = props?.align ? props.align : 'center';
    if (width !== null && width !== undefined) {
      res.width = width;
    }
    return res;
  }

  public static Address<T>(
    title: string | React.ReactElement,
    dataIndex: string,
    transform: (rowData: T) => Address,
    width?: string | number | null,
    props?: DataTableColumnProps<T>
  ): DataTableColumnProps<T> {
    const res = props || ({} as DataTableColumnProps<T>);
    res.filterType = res.filterType !== undefined ? res.filterType : FilterType.Text;
    res.columnType = ColumnType.Text;
    res.renderDataTransform = DataTableCellRendererUtil.Address(transform);
    res.title = title;
    res.dataIndex = dataIndex;
    res.sorter = res.sorter === undefined ? true : res.sorter;
    if (width !== null && width !== undefined) {
      res.width = width;
    }
    return res;
  }

  public static Buttons<T>(
    // title: string,
    dataIndex: string,
    buttons: ColumnButton<T>[],
    width?: string | number | null,
    props?: DataTableColumnProps<T>
  ): DataTableColumnProps<T> {
    const res = props || ({} as DataTableColumnProps<T>);
    res.dataIndex = dataIndex;
    // eslint-disable-next-line
    res.render = (text, record) => (
      <div className="table-row-button-group">
        {buttons.map((b, index) => (
          <Button key={index} onClick={() => b.onClick(record)} size={b.size ? b.size : 'small'}>
            {b.text}
          </Button>
        ))}
      </div>
    );
    // res.title = title;
    res.align = 'center';
    if (width !== null && width !== undefined) {
      res.width = width;
    }
    return res;
  }

  public static Link<T>(
    title: string,
    textField: string,
    transform: (value: any, record: T) => any,
    props?: DataTableColumnProps<T>
  ): DataTableColumnProps<T> {
    const res = props || ({} as DataTableColumnProps<T>);
    res.renderDataTransform = (text, record) => transform(text, record);
    res.title = title;
    // eslint-disable-next-line
    res.render = (text: any, record: T, index: number) => (
      <Link to={transform(text, record)}>{(record as any)[textField]}</Link>
    );
    return res;
  }

  public static DropdownMulti<T>(
    title: string,
    dataIndex: string,
    filterOptions: { text: string; value: string }[] | undefined,
    width?: string | number | null,
    props?: DataTableColumnProps<T>
  ): DataTableColumnProps<T> {
    return this.Dropdown(FilterType.DropdownMulti, filterOptions, title, dataIndex, width, props);
  }

  public static DropdownSingle<T>(
    title: string,
    dataIndex: string,
    filterOptions: { text: string; value: string }[] | undefined,
    width?: string | number | null,
    props?: DataTableColumnProps<T>
  ): DataTableColumnProps<T> {
    return this.Dropdown(FilterType.DropdownSingle, filterOptions, title, dataIndex, width, props);
  }

  private static Dropdown<T>(
    filterType: FilterType,
    filterOptions: { text: string; value: string }[] | undefined,
    title: string,
    dataIndex: string,
    width?: string | number | null,
    props?: DataTableColumnProps<T>
  ): DataTableColumnProps<T> {
    const res = props || ({} as DataTableColumnProps<T>);
    res.filterType = filterType;
    res.dropdownFilterOptions = filterOptions;
    res.columnType = ColumnType.Text;
    res.title = title;
    res.dataIndex = dataIndex;
    res.sorter = res.sorter === undefined ? true : res.sorter;
    if (width !== null && width !== undefined) {
      res.width = width;
    }
    return res;
  }
}

export default DataTableColumnUtil;
