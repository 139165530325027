import { notification } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import BrokerApiService from '../../../api/BrokerApiService';
import Permission from '../../../consts/Permission';
import Role from '../../../consts/Role';
import Routes from '../../../consts/Routes';
import useHasPermission from '../../../hooks/useHasPermission';
import ClientAccessDTO from '../../../models/ClientAccessDTO';
import ClientListDTO from '../../../models/ClientListDTO';
import LookupTableDTO from '../../../models/LookupTableDTO';
import TableRequestDTO from '../../../models/TableRequestDTO';
import TableResponseDTO from '../../../models/TableResponseDTO';
import UserClientAccessDTO from '../../../models/UserClientAccessDTO';
import AccessEditor, { AccessEditorProps } from '../../shared/EntityAccess/AccessEditor';

interface BrokerClientAccessEditorProps {
  brokerId: number;
  clientId?: string;
}

const getClientIdFromProps = (props: BrokerClientAccessEditorProps) => {
  if (props.clientId) {
    const parsed = parseInt(props.clientId ?? '');
    return isNaN(parsed) ? null : parsed;
  }

  return null;
};

const BrokerClientAccessEditor = (props: BrokerClientAccessEditorProps) => {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<UserClientAccessDTO[]>([]);
  const [entitySearchList, setEntitySearchList] = useState<ClientListDTO[]>([]);
  const [entityLookup, setEntityLookup] = useState<ClientListDTO[]>([]);
  const [clientId, setClientId] = useState<number | null>(getClientIdFromProps(props));

  const hasPermission = useHasPermission();

  useEffect(() => {
    Promise.all([
      BrokerApiService.getClientsForBroker(props.brokerId),
      BrokerApiService.searchClientsNotLinkedToBroker(props.brokerId, ''),
    ])
      .then((res) => {
        const [lookups, searchList] = res;
        setEntityLookup(lookups);
        setEntitySearchList(searchList);
      })
      .catch(() => {
        // TODO: notification
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const fetchTableData = (
    requestState: TableRequestDTO,
    checkEcho: () => boolean,
    callback: (response: TableResponseDTO<any>) => void
  ) => {
    setLoading(true);
    BrokerApiService.getBrokerUserClientAccesses(props.brokerId, clientId ?? 0, requestState)
      .then((results) => {
        if (!checkEcho()) {
          return;
        }
        setUsers(results.results || []);

        setLoading(false);
        callback(results);
      })
      .catch((error: any) => {
        setLoading(false);
        notification.error({
          message: error.message,
          description: error.description,
        });
      });
  };

  const addListData = entitySearchList.map((x) => {
    return {
      id: x.id,
      name: x.name,
    } as LookupTableDTO;
  });

  const saveChanges = (dto: ClientAccessDTO, cb?: (success: boolean, id?: number) => void) => {
    setLoading(true);

    dto.id = clientId;
    BrokerApiService.saveBrokerClientAccess(props.brokerId, dto)
      .then((res) => {
        if (res.succeeded) {
          notification.success({ message: 'Access Saved' });
          BrokerApiService.getClientsForBroker(props.brokerId).then((lookups) => {
            setEntityLookup(lookups);
            if (cb) {
              cb(res.succeeded, res.id ?? undefined);
            }
          });
        } else {
          throw '';
        }
      })
      .catch(() => {
        notification.error({ message: 'Failed to save Access' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editorProps = {
    entityId: undefined,
    entityName: undefined,

    showDates: true,
    startDate: undefined,
    endDate: undefined,
    userData: users,

    entityList: addListData,

    entitySelected: (id) => {
      setClientId(id);
    },

    loading: loading,
    backUrl: Routes.generate(Routes.ADMIN_BROKER_DETAILS_CLIENTS, { id: props.brokerId }),
    editUrl: (id: number) => Routes.generate(Routes.ADMIN_BROKER_DETAILS_CLIENTS, { id: props.brokerId, clientId: id }),
    listText: 'Back to Clients',
    entitySearch: {
      entitySearchLabel: 'Search For Client',
      entitySearchPlaceholder: 'Select Client',
    },
    showCancel: true,

    userAdd: {
      userAddSearchLabel: 'Assign Broker User To Client',
      userAddSearchPlaceholder: 'Select Broker User',
      userAddText: 'Add Broker User',
    },

    saveButtonText: 'Save Broker Access',
    readOnly: !hasPermission(Permission.ADMIN_BROKER_CLIENTS_EDIT),
    fetchData: fetchTableData,
    dataTableTitle: 'User Access Permissions',

    roleId: Role.BROKER,

    onSave: saveChanges,
  } as AccessEditorProps;

  if (clientId) {
    const entity = entityLookup.find((x) => x.id === clientId);
    if (entity) {
      editorProps.entityId = clientId;
      editorProps.entityName = entity.name ?? '';
      editorProps.startDate = entity.startDate ? moment(entity.startDate) : undefined;
      editorProps.endDate = entity.endDate ? moment(entity.endDate) : undefined;
    }
  }

  return <AccessEditor {...editorProps} />;
};

export default BrokerClientAccessEditor;
