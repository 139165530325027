import { EditOutlined, LockOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Col, Descriptions, Form, Layout, Modal, notification, Row, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import AccountApiService from '../../api/AccountApiService';
import ManageMFA from '../../components/account/MFA/ManageMFA';
import ChangePasswordForm from '../../components/profile/ChangePasswordForm';
import PageTitle from '../../components/shared/PageTitle';
import ProfileDTO from '../../models/ProfileDTO';
import ResultDTO from '../../models/ResultDTO';
import Renderers from '../../utils/Renderers';
import ChangeUsernameForm from '../../components/profile/ChangeUsernameForm';
import UserPreferencesForm from '../../components/profile/UserPreferencesForm';
import LookupTableDTO from '../../models/LookupTableDTO';
import LookupsApiService from '../../api/LookupsApiService';
import UserPreferenceDTO from '../../models/UserPreferenceDTO';

const Content = Layout.Content;

const ProfilePage = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState<ProfileDTO | null>(null);
  const [userPreferences, setUserPreferences] = useState<UserPreferenceDTO>(UserPreferenceDTO.create());

  const [passwordError, setPasswordError] = useState<any>(null);
  const [passwordFormOpen, setPasswordFormOpen] = useState(false);

  const [mfaOpen, setMfaOpen] = useState(false);
  const [emailFormOpen, setEmailFormOpen] = useState(false);
  const [preferenceFormOpen, setPreferenceFormOpen] = useState(false);

  const [languages, setLanguages] = useState<LookupTableDTO[]>([]);

  useEffect(() => {
    refreshPageData();
  }, []);

  const refreshPageData = () => {
    setLoading(true);
    const profilePrm = AccountApiService.getProfileDTO();
    const preferencePrm = AccountApiService.getUserPreferences();
    const langPrm = LookupsApiService.getLanguages();

    Promise.all([profilePrm, preferencePrm, langPrm])
      .then((res) => {
        const [profile, preference, lang] = res;
        setProfile(profile);
        setUserPreferences(preference);
        setLanguages(lang);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const changeUsername = (model: any) => {
    setLoading(true);

    AccountApiService.requestUsernameChange(model)
      .then((response: ResultDTO | null) => {
        if (response?.succeeded) {
          setPasswordError(null);

          notification.success({ message: 'Username/email change requested.', duration: 3 });
        } else {
          notification.destroy();
          notification.error({ message: response?.errors.join('\n') });
        }
      })
      .catch((results: any) => {
        notification.destroy();
        notification.error({ message: results?.errors.join('\n') });
      })
      .finally(() => {
        setLoading(false);
        setEmailFormOpen(false);
      });
  };

  const changePassword = (model: any) => {
    setLoading(true);

    model.requestedRoleId = 0;
    model.requestUrl = null;

    AccountApiService.changePassword(model)
      .then((response: ResultDTO | null) => {
        if (response?.succeeded) {
          setLoading(false);
          setPasswordError(null);
          setPasswordFormOpen(false);
          notification.success({ message: 'Password updated successfully.', duration: 3 });
        } else {
          setLoading(false);
          notification.destroy();
          notification.error({ message: response?.errors.join('\n') });
        }
      })
      .catch((results: any) => {
        setLoading(false);
        notification.destroy();
        notification.error({ message: results?.errors.join('\n') });
      });
  };

  const handlePreferenceSubmit = () => {
    form.validateFields().then((model) => {
      const dto = UserPreferenceDTO.create({ ...model });
      setLoading(true);
      AccountApiService.saveUserPreferences(dto)
        .then(() => {
          notification.success({ message: 'Preferences saved successfully.' });
        })
        .catch(() => {
          notification.error({ message: 'An error occurred while saving your preferences' });
        })
        .finally(() => {
          setPreferenceFormOpen(false);
          refreshPageData();
        });
    });
  };

  const renderHeader = (title: string) => {
    const headerStyle = {
      width: '100%',
    };
    return (
      <Row style={headerStyle}>
        <Typography.Title level={4} style={{ fontWeight: 'bold' }}>
          {title}
        </Typography.Title>
      </Row>
    );
  };

  let alert: JSX.Element | null = null;

  if (passwordError !== null) {
    let message = '';
    if (passwordError.message) {
      message = passwordError.message;
    }
    alert = (
      <Alert
        message="Error"
        description={message}
        type="error"
        showIcon={true}
        style={{ marginBottom: '12px', whiteSpace: 'pre-wrap' }}
      />
    );
  }

  const actionCardProps = { xxl: 5, xl: 8, lg: 10, md: 12, sm: 12, xs: 24, style: { marginBottom: 15 } };
  const actionIconProps = { style: { fontSize: 50, marginBottom: 15 } };
  const descriptionProps = { style: { display: 'block' }, labelStyle: { fontWeight: 'bold' } };

  return (
    <Content className="content-padding">
      <PageTitle title="Edit Profile" />
      {alert}
      <Spin spinning={loading}>
        {!profile ? null : (
          <>
            {renderHeader('Account')}
            <Descriptions layout="horizontal" style={{ marginBottom: '24px' }}>
              <Descriptions.Item label="Email Address" {...descriptionProps}>
                {profile.username}
              </Descriptions.Item>
              {profile.mfaEligible ? (
                <>
                  <Descriptions.Item label="Phone Number" {...descriptionProps}>
                    {Renderers.phoneNumber(profile.phoneNumber ?? '')}
                  </Descriptions.Item>
                  <Descriptions.Item label="MFA Status" {...descriptionProps}>
                    {profile.multiFactorEnabled ? 'Enabled' : 'Disabled'}
                  </Descriptions.Item>
                </>
              ) : null}
            </Descriptions>
            {!profile.isClaimDOC ? (
              <>
                {renderHeader('Preferences')}
                <Descriptions layout="horizontal" style={{ marginBottom: '24px' }}>
                  <Descriptions.Item label="Language" {...descriptionProps}>
                    {userPreferences.languageName}
                  </Descriptions.Item>
                </Descriptions>
              </>
            ) : null}

            <Row gutter={12}>
              {!profile.isClaimDOC ? (
                <>
                  <Col {...actionCardProps}>
                    <Card style={{ textAlign: 'center' }}>
                      <EditOutlined {...actionIconProps} />
                      <br />
                      <Button disabled={loading} shape="round" onClick={() => setPreferenceFormOpen(true)}>
                        Change Preferences
                      </Button>
                    </Card>
                    <Modal
                      title="Change Preferences"
                      open={preferenceFormOpen}
                      onCancel={() => setPreferenceFormOpen(false)}
                      footer={null}
                      destroyOnClose
                    >
                      <UserPreferencesForm
                        form={form}
                        languages={languages}
                        onSubmit={handlePreferenceSubmit}
                        loading={loading}
                        initialValues={userPreferences}
                      />
                    </Modal>
                  </Col>
                  <Col {...actionCardProps}>
                    <Card style={{ textAlign: 'center' }}>
                      <MailOutlined {...actionIconProps} />
                      <br />
                      <Button htmlType="button" disabled={loading} shape="round" onClick={() => setEmailFormOpen(true)}>
                        Change Email
                      </Button>
                    </Card>
                    <Modal
                      title="Change Email"
                      open={emailFormOpen}
                      onCancel={() => setEmailFormOpen(false)}
                      footer={null}
                      destroyOnClose
                    >
                      <ChangeUsernameForm submitErrors={[]} loading={loading} formSubmitted={changeUsername} />
                    </Modal>
                  </Col>
                  <Col {...actionCardProps}>
                    <Card style={{ textAlign: 'center' }}>
                      <LockOutlined {...actionIconProps} />
                      <br />
                      <Button
                        htmlType="button"
                        disabled={loading}
                        shape="round"
                        onClick={() => setPasswordFormOpen(true)}
                      >
                        Change Password
                      </Button>
                    </Card>

                    <Modal
                      title="Change Password"
                      open={passwordFormOpen}
                      onCancel={() => setPasswordFormOpen(false)}
                      footer={null}
                      destroyOnClose
                    >
                      <ChangePasswordForm submitErrors={[]} loading={loading} formSubmitted={changePassword} />
                    </Modal>
                  </Col>
                </>
              ) : null}

              {profile.mfaEligible ? (
                <Col {...actionCardProps}>
                  <Card style={{ textAlign: 'center' }}>
                    <PhoneOutlined {...actionIconProps} />
                    <br />
                    <Button htmlType="button" disabled={loading} shape="round" onClick={() => setMfaOpen(true)}>
                      MFA Setup
                    </Button>
                  </Card>

                  <Modal
                    open={mfaOpen}
                    onCancel={() => setMfaOpen(false)}
                    maskClosable={false}
                    destroyOnClose
                    footer={null}
                  >
                    <ManageMFA
                      twoFactorEnabled={profile.multiFactorEnabled}
                      currentPhone={profile.phoneNumber}
                      reload={refreshPageData}
                    />
                  </Modal>
                </Col>
              ) : null}
            </Row>
          </>
        )}
      </Spin>
    </Content>
  );
};

export default ProfilePage;
