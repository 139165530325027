// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import UserDetailDTO from '../models/UserDetailDTO';
import ParentSecurityUserSettingsDTO from '../models/ParentSecurityUserSettingsDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import UserListDTO from '../models/UserListDTO';

export class UserApiService extends BaseApi {

    // post: api/user/GetUserList?userType=${encodeURIComponent(userType)}
    public getUserList(userType: string, tableRequest: TableRequestDTO): Promise<TableResponseDTO<UserListDTO>> {
        let url = `api/user/GetUserList`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (userType != null) {
            url += `${prefix}userType=${encodeURIComponent(userType)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<UserListDTO>>(tableRequest, 'post', url, true, false);
    }

    // get: api/user/GetUserDetails?id=${id}
    public getUserDetail(id: number): Promise<UserDetailDTO> {
        let url = `api/user/GetUserDetails`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (id != null) {
            url += `${prefix}id=${id}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, UserDetailDTO>(null, 'get', url, true, false);
    }

    // post: api/user/SaveUserDetails
    public saveUserDetail(user: UserDetailDTO): Promise<boolean> {
        let url = `api/user/SaveUserDetails`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, boolean>(user, 'post', url, true, false);
    }

    // post: api/user/ResetUsersMfa?userId=${userId}
    public resetUsersMfa(userId: number): Promise<boolean> {
        let url = `api/user/ResetUsersMfa`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (userId != null) {
            url += `${prefix}userId=${userId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, boolean>(null, 'post', url, true, false);
    }

    // get: api/user/GetIsPrimary?userId=${userId}
    public getIsPrimary(userId?: number | null): Promise<boolean> {
        let url = `api/user/GetIsPrimary`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (userId != null) {
            url += `${prefix}userId=${userId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, boolean>(null, 'get', url, true, false);
    }

    // get: api/user/GetFamilyRelationship?userId=${userId}
    public getFamilyRelationship(userId?: number | null): Promise<number> {
        let url = `api/user/GetFamilyRelationship`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (userId != null) {
            url += `${prefix}userId=${userId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, number>(null, 'get', url, true, false);
    }

    // post: api/user/SaveInvitedUserEdit
    public saveInvitedUserEdit(user: ParentSecurityUserSettingsDTO): Promise<boolean> {
        let url = `api/user/SaveInvitedUserEdit`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, boolean>(user, 'post', url, true, false);
    }
}
var service = new UserApiService();
export default service;
