import { DatePicker } from 'antd';
import { DatePickerProps, MonthPickerProps, RangePickerProps, WeekPickerProps } from 'antd/lib/date-picker';
import { PickerTimeProps } from 'antd/lib/date-picker/generatePicker';
import { Moment } from 'moment';
import * as React from 'react';
import MobileUtil from '../../utils/MobileUtil';

declare type TimePickerProps = Omit<PickerTimeProps<Moment>, 'picker'>;

interface DatePickerBaseState {
  isMobile: boolean;
}

class DatePickerBase<P> extends React.Component<P, DatePickerBaseState> {
  private mediaQuery: MediaQueryList;
  constructor(props: P) {
    super(props);
    this.mediaQuery = MobileUtil.getMediaQuery();
    this.state = { isMobile: this.mediaQuery.matches };
  }

  componentDidMount() {
    this.mediaQuery.addEventListener('change', this.onMediaChange);
  }

  componentWillUnmount() {
    this.mediaQuery.removeEventListener('change', this.onMediaChange);
  }

  onMediaChange = (e: MediaQueryListEvent) => {
    if (this.state.isMobile !== e.matches) {
      this.setState({ isMobile: e.matches });
    }
  };

  render() {
    return this.renderPicker();
  }

  renderPicker(): React.ReactNode {
    return null;
  }
}

class DatePickerClass extends DatePickerBase<DatePickerProps> {
  constructor(props: DatePickerProps) {
    super(props);
  }
  renderPicker() {
    return <DatePicker {...this.props} inputReadOnly={this.state.isMobile} />;
  }
}

class WeekPickerClass extends DatePickerBase<WeekPickerProps> {
  constructor(props: WeekPickerProps) {
    super(props);
  }
  renderPicker() {
    return <DatePicker.WeekPicker {...this.props} inputReadOnly={this.state.isMobile} />;
  }
}

class MonthPickerClass extends DatePickerBase<MonthPickerProps> {
  constructor(props: MonthPickerProps) {
    super(props);
  }
  renderPicker() {
    return <DatePicker.MonthPicker {...this.props} inputReadOnly={this.state.isMobile} />;
  }
}

class YearPickerClass extends DatePickerBase<MonthPickerProps> {
  constructor(props: MonthPickerProps) {
    super(props);
  }
  renderPicker() {
    return <DatePicker.MonthPicker {...this.props} inputReadOnly={this.state.isMobile} />;
  }
}

class RangePickerClass extends DatePickerBase<RangePickerProps> {
  constructor(props: RangePickerProps) {
    super(props);
  }
  renderPicker() {
    return <DatePicker.RangePicker {...this.props} inputReadOnly={this.state.isMobile} />;
  }
}

class TimePickerClass extends DatePickerBase<TimePickerProps> {
  constructor(props: TimePickerProps) {
    super(props);
  }
  renderPicker() {
    return <DatePicker.TimePicker {...this.props} inputReadOnly={this.state.isMobile} />;
  }
}

class QuarterPickerClass extends DatePickerBase<TimePickerProps> {
  constructor(props: TimePickerProps) {
    super(props);
  }
  renderPicker() {
    return <DatePicker.QuarterPicker {...this.props} inputReadOnly={this.state.isMobile} />;
  }
}

class DatePicker2 extends DatePickerClass {
  static WeekPicker = WeekPickerClass;
  static MonthPicker = MonthPickerClass;
  static YearPicker = YearPickerClass;
  static RangePicker = RangePickerClass;
  static TimePicker = TimePickerClass;
  static QuarterPicker = QuarterPickerClass;
}

export const AcceptedFormats = ['MM/DD/YYYY', 'M/D/YYYY', 'MM/D/YYYY', 'M/D/YYYY','MMDDYYYY','MMDD','MM'];

export default DatePicker2;
