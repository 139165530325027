import { Button, Checkbox, Col, Form, FormInstance, Row, Select, Spin } from 'antd';
import React, { RefObject, useContext, useEffect, useMemo, useState } from 'react';
import ClaimSearchApiService from '../../api/ClaimSearchApiService';
import MemberDTO from '../../models/MemberDTO';
import RelationDTO from '../../models/RelationDTO';
import DatePicker2, { AcceptedFormats } from '../shared/DatePicker2';
import moment from 'moment';
import MemberClaimSearchFormDTO from '../../models/MemberClaimSearchFormDTO';
import debounce from 'lodash/debounce';
import { AuthenticationContext } from '../../auth/AuthenticationContext';
import AccessRole from '../../consts/AccessRole';

const { Option } = Select;

interface ClaimSearchFormProps {
  onSearch: () => void;
  onMemberSearch: (results: any) => void;
  formRef: RefObject<FormInstance<any>>;
  clients: RelationDTO[];
  members: MemberDTO[];
  claimSearchDTO: MemberClaimSearchFormDTO;
}

const ClaimSearchForm = (props: ClaimSearchFormProps) => {
  const [clientId, setClientId] = useState(props.claimSearchDTO.clientId);
  const [searching, setSearching] = useState(false);

  const authContext = useContext(AuthenticationContext);
  const isClaimDocEmployee = authContext.user?.accessRoleId === AccessRole.CLAIMDOC;

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, []);

  const onLastNameSearch = (value: string) => {
    props.onMemberSearch([]);
    if (value.length >= 2) {
      setSearching(true);
      ClaimSearchApiService.getMembersByLastName(clientId, value)
        .then((results) => {
          props.onMemberSearch(results);
        })
        .finally(() => {
          setSearching(false);
        });
    }
  };

  const debouncedSearch = useMemo(() => debounce(onLastNameSearch, 400), [clientId]);

  const onClientSelect = (value: number) => {
    setClientId(value);
    props.formRef.current?.setFieldsValue({ memberId: '' });
    props.onMemberSearch([]);
  };

  return (
    <Form
      key={'claimSearchForm'}
      ref={props.formRef}
      initialValues={{
        clientId: props.claimSearchDTO.clientId == 0 ? null : props.claimSearchDTO.clientId,
        memberId: props.claimSearchDTO.memberId,
        fromDate: props.claimSearchDTO.fromDate,
        toDate: props.claimSearchDTO.toDate,
      }}
      layout="vertical"
      style={{ marginTop: 10 }}
    >
      <Row gutter={[12, 0]}>
        <Col xs={24} xl={12}>
          <Form.Item name="clientId" label="Client" rules={[{ required: true, message: 'Required' }]}>
            <Select
              showSearch
              optionFilterProp="children"
              style={{ width: '100%' }}
              onSelect={(value: number) => onClientSelect(value)}
            >
              <Option value="" disabled hidden>
                -- Select a client --
              </Option>
              {(props.clients || []).map((r) => (
                <Option key={r.id} value={r.id}>
                  {r.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} xl={12}>
          <Form.Item name="memberId" label="Last Name" rules={[{ required: !isClaimDocEmployee, message: 'Required' }]}>
            <Select
              showSearch
              optionFilterProp="children"
              onSearch={debouncedSearch}
              filterOption={false}
              disabled={clientId == 0}
              notFoundContent={searching ? <Spin delay={0} size="small" /> : null}
            >
              {props.members.map((m) => (
                <Option key={m.memberId} value={m.memberId}>
                  {m.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} xl={12}>
          <Form.Item name="fromDate" label="From Date">
            <DatePicker2
              format={AcceptedFormats}
              placeholder={AcceptedFormats.at(0)}
              disabledDate={(c) => moment('1/1/1900', AcceptedFormats.at(0)) > c}
            />
          </Form.Item>
        </Col>
        <Col xs={24} xl={12}>
          <Form.Item name="toDate" label="To Date">
            <DatePicker2
              format={AcceptedFormats}
              placeholder={AcceptedFormats.at(0)}
              disabledDate={(c) => moment('1/1/1900', AcceptedFormats.at(0)) > c}
            />
          </Form.Item>
        </Col>
        {isClaimDocEmployee && (
          <Col xs={24} xl={4}>
            <Form.Item name="onlyInProgressClaims" label="Only Show In Progress Claims" valuePropName="checked">
              <Checkbox />
            </Form.Item>
          </Col>
        )}
      </Row>
      <Row gutter={[12, 0]}>
        <Col xs={24} xl={12}>
          <Button type="primary" onClick={props.onSearch}>
            Search
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ClaimSearchForm;
