// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import ClientAccessDTO from '../models/ClientAccessDTO';
import ParentSecuritySettingsDTO from '../models/ParentSecuritySettingsDTO';
import InviteUserDTO from '../models/InviteUserDTO';
import OtherCompanyDetailDTO from '../models/OtherCompanyDetailDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import OtherCompanyListDTO from '../models/OtherCompanyListDTO';
import SaveOtherCompanyDetailResultDTO from '../models/SaveOtherCompanyDetailResultDTO';
import ClientListDTO from '../models/ClientListDTO';
import ResultDTO from '../models/ResultDTO';
import UserClientAccessDTO from '../models/UserClientAccessDTO';
import ParentSecurityUserSettingsDTO from '../models/ParentSecurityUserSettingsDTO';

export class OtherCompanyApiService extends BaseApi {

    // post: api/othercompany/GetOtherCompanyList
    public getOtherCompanyList(tableRequest: TableRequestDTO): Promise<TableResponseDTO<OtherCompanyListDTO>> {
        let url = `api/othercompany/GetOtherCompanyList`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<OtherCompanyListDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/othercompany/GetOtherCompanyDetails?clientId=${clientId}
    public getOtherCompanyDetails(clientId: number): Promise<OtherCompanyDetailDTO> {
        let url = `api/othercompany/GetOtherCompanyDetails`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (clientId != null) {
            url += `${prefix}clientId=${clientId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, OtherCompanyDetailDTO>(null, 'post', url, true, false);
    }

    // post: api/othercompany/SaveOtherCompanyNotes?otherCompanyId=${otherCompanyId}
    public saveOtherCompanyNotes(otherCompanyId: number, notes: string): Promise<SaveOtherCompanyDetailResultDTO> {
        let url = `api/othercompany/SaveOtherCompanyNotes`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (otherCompanyId != null) {
            url += `${prefix}otherCompanyId=${otherCompanyId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveOtherCompanyDetailResultDTO>(notes, 'post', url, true, false);
    }

    // post: api/othercompany/GetClientsForOtherCompany?otherCompanyId=${otherCompanyId}
    public getClientsForOtherCompany(otherCompanyId: number): Promise<ClientListDTO[]> {
        let url = `api/othercompany/GetClientsForOtherCompany`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (otherCompanyId != null) {
            url += `${prefix}otherCompanyId=${otherCompanyId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ClientListDTO[]>(null, 'post', url, true, false);
    }

    // post: api/othercompany/DeleteOtherCompanyClientAccess?clientId=${clientId}&otherCompanyId=${otherCompanyId}
    public deleteOtherCompanyClientAccess(clientId: number, otherCompanyId: number): Promise<ResultDTO> {
        let url = `api/othercompany/DeleteOtherCompanyClientAccess`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (clientId != null) {
            url += `${prefix}clientId=${clientId}`;
            prefix = '&';
        }
        if (otherCompanyId != null) {
            url += `${prefix}otherCompanyId=${otherCompanyId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ResultDTO>(null, 'post', url, true, false);
    }

    // post: api/othercompany/GetOtherCompanyUserClientAccesses?otherCompanyId=${otherCompanyId}&clientId=${clientId}
    public getOtherCompanyUserClientAccesses(otherCompanyId: number, clientId: number, tableRequest: TableRequestDTO): Promise<TableResponseDTO<UserClientAccessDTO>> {
        let url = `api/othercompany/GetOtherCompanyUserClientAccesses`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (otherCompanyId != null) {
            url += `${prefix}otherCompanyId=${otherCompanyId}`;
            prefix = '&';
        }
        if (clientId != null) {
            url += `${prefix}clientId=${clientId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<UserClientAccessDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/othercompany/SaveOtherCompanyClientAccess?otherCompanyId=${otherCompanyId}
    public saveOtherCompanyClientAccess(otherCompanyId: number, dto: ClientAccessDTO): Promise<ResultDTO> {
        let url = `api/othercompany/SaveOtherCompanyClientAccess`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (otherCompanyId != null) {
            url += `${prefix}otherCompanyId=${otherCompanyId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ResultDTO>(dto, 'post', url, true, false);
    }

    // post: api/othercompany/SearchClientsNotLinkedToOtherCompany?otherCompanyId=${otherCompanyId}&searchText=${encodeURIComponent(searchText)}
    public searchClientsNotLinkedToOtherCompany(otherCompanyId: number, searchText: string): Promise<ClientListDTO[]> {
        let url = `api/othercompany/SearchClientsNotLinkedToOtherCompany`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (otherCompanyId != null) {
            url += `${prefix}otherCompanyId=${otherCompanyId}`;
            prefix = '&';
        }
        if (searchText != null) {
            url += `${prefix}searchText=${encodeURIComponent(searchText)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ClientListDTO[]>(null, 'post', url, true, false);
    }

    // get: api/othercompany/GetSecuritySettings?id=${id}
    public getSecuritySettings(id: number): Promise<ParentSecuritySettingsDTO> {
        let url = `api/othercompany/GetSecuritySettings`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (id != null) {
            url += `${prefix}id=${id}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ParentSecuritySettingsDTO>(null, 'get', url, true, false);
    }

    // post: api/othercompany/GetUserSecuritySettings?otherCompanyId=${otherCompanyId}
    public getUserSecuritySettings(otherCompanyId: number, tableRequest: TableRequestDTO): Promise<TableResponseDTO<ParentSecurityUserSettingsDTO>> {
        let url = `api/othercompany/GetUserSecuritySettings`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (otherCompanyId != null) {
            url += `${prefix}otherCompanyId=${otherCompanyId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<ParentSecurityUserSettingsDTO>>(tableRequest, 'post', url, true, false);
    }

    // delete: api/othercompany/RemoveUserSecurity?otherCompanyId=${otherCompanyId}&userId=${userId}
    public removeUserSecurity(otherCompanyId: number, userId: number): Promise<boolean> {
        let url = `api/othercompany/RemoveUserSecurity`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (otherCompanyId != null) {
            url += `${prefix}otherCompanyId=${otherCompanyId}`;
            prefix = '&';
        }
        if (userId != null) {
            url += `${prefix}userId=${userId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, boolean>(null, 'delete', url, true, false);
    }

    // post: api/othercompany/SaveSecuritySettings?id=${id}
    public saveSecuritySettings(id: number, settings: ParentSecuritySettingsDTO): Promise<boolean> {
        let url = `api/othercompany/SaveSecuritySettings`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (id != null) {
            url += `${prefix}id=${id}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, boolean>(settings, 'post', url, true, false);
    }

    // post: api/othercompany/InviteUsers?otherCompanyId=${otherCompanyId}
    public inviteUsers(otherCompanyId: number, users: InviteUserDTO[]): Promise<boolean> {
        let url = `api/othercompany/InviteUsers`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (otherCompanyId != null) {
            url += `${prefix}otherCompanyId=${otherCompanyId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, boolean>(users, 'post', url, true, false);
    }

    // post: api/othercompany/SimpleUserList?otherCompanyId=${otherCompanyId}
    public simpleUserList(otherCompanyId: number, tableRequest: TableRequestDTO): Promise<TableResponseDTO<ParentSecurityUserSettingsDTO>> {
        let url = `api/othercompany/SimpleUserList`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (otherCompanyId != null) {
            url += `${prefix}otherCompanyId=${otherCompanyId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<ParentSecurityUserSettingsDTO>>(tableRequest, 'post', url, true, false);
    }

    // delete: api/othercompany/${otherCompanyId}
    public deleteOtherCompany(otherCompanyId: number): Promise<boolean> {
        let url = `api/othercompany/${otherCompanyId}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, boolean>(null, 'delete', url, true, false);
    }

    // post: api/othercompany/EditOtherCompany
    public editOtherCompany(dto: OtherCompanyDetailDTO): Promise<boolean> {
        let url = `api/othercompany/EditOtherCompany`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, boolean>(dto, 'post', url, true, false);
    }
}
var service = new OtherCompanyApiService();
export default service;
