import { Button, Card, Col, Row, Space } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import moment from 'moment';
import React from 'react';
import { LanguageLookup, ProviderNominationProps } from '../../ProviderNomination';
import PersonalInfoForm from '../PersonalInfoForm';
import ProviderNominationPriorityOptions from '../../../../consts/ProviderNominationPriorityOptions';

interface ContactInfoProps extends ProviderNominationProps {
  isPrimary: boolean
}

const ContactInfo = (props: ContactInfoProps) => {
  return (
    <>
      <h2>{LanguageLookup.Steps.Contact.Title(props.isPrimary)}</h2>
      <Card className="ant-card-background ant-card-shadow active" bordered={false}>
        {props.pnf.primaryMember ? (
          <PersonalInfoForm
            formRef={props.formRef}
            pnfMemberDTO={props.pnf.primaryMember}
            pnf={props.pnf}
            isContactInfo={true}
            usStates={props.usStates}
            onChange={props.onChange}
            onBehalf={props.onBehalf}
            clients={props.relations}
          />
        ) : null}
      </Card>

      <br />

      <Row justify="end">
        <Col flex={1} style={{ textAlign: 'right' }}>
          <Space direction="horizontal">
            {props.onBehalf ?
            <Button size="large" shape="round" onClick={props.onBack}>
              Back
            </Button> : null}
            <Button type="primary" size="large" shape="round" onClick={props.onContinue}>
              Continue
            </Button>
          </Space>
        </Col>
      </Row>
      {DEBUG ? (
        <Space style={{ position: 'absolute', left: 0, bottom: 50 }}>
          <Button onClick={() => setDebugPIValues(props.formRef.current)}>Fill Personal Form</Button>
        </Space>
      ) : null}
    </>
  );
};

//#region Debug Helpers
const setDebugPIValues = (form: FormInstance | null) => {
  if (DEBUG) {
    form?.setFieldsValue({
      // clientId: 862,
      firstName: 'Jordan',
      lastName: 'McKillip',
      email: 'jmckillip@qci.com',
      phone: '5153334444',
      phoneIsMobile: true,
      detailedVoicemail: true,
      address: {
        address1: '4300 Westown Pkwy',
        city: 'West Des Moines',
        state: 'IA',
        zipCode: '50266',
      },
      dateOfBirth: moment('1/1/1990'),
      contactMethods: ['Email'],
      priority: ProviderNominationPriorityOptions.YES,
    });
  }
};
//#endregion

export default ContactInfo;
