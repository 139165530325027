// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import ContactDTO from '../models/ContactDTO';

export class ContactsApiService extends BaseApi {

    // get: api/Contacts/ContactByClientId?clientId=${clientId}&contactTypeId=${contactTypeId}
    public getContactByClientId(clientId: number, contactTypeId: number): Promise<ContactDTO[]> {
        let url = `api/Contacts/ContactByClientId`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (clientId != null) {
            url += `${prefix}clientId=${clientId}`;
            prefix = '&';
        }
        if (contactTypeId != null) {
            url += `${prefix}contactTypeId=${contactTypeId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ContactDTO[]>(null, 'get', url, true, false);
    }

    // get: api/Contacts/ClientIdbyUserId?userId=${userId}
    public getClientIdByUserId(userId: number): Promise<number> {
        let url = `api/Contacts/ClientIdbyUserId`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (userId != null) {
            url += `${prefix}userId=${userId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, number>(null, 'get', url, true, false);
    }
}
var service = new ContactsApiService();
export default service;
