// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Constants.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
export default class Role {
    public static readonly BROKER: number = 1;
    public static readonly TPA: number = 2;
    public static readonly EMPLOYER: number = 3;
    public static readonly MEMBER: number = 4;
    public static readonly CLAIMDOC: number = 5;
    public static readonly API: number = 6;
    public static readonly GUEST: number = 7;
    public static readonly DPC: number = 8;
    public static readonly OTHERCOMPANY: number = 9;
}
