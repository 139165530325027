import { Button, Card, Col, Form, Row, Select } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import React, { useContext, useEffect, useState } from 'react';
import PnfServicePartnerDTO from '../../../../models/PnfServicePartnerDTO';
import { ProviderNominationProps } from '../../ProviderNomination';
import ServicePartnerForm from '../ServicePartner/ServicePartnerForm';
import FeatureFlagDTO from '../../../../models/FeatureFlagDTO';
import Role from '../../../../consts/Role';
import { AuthenticationContext } from '../../../../auth/AuthenticationContext';
import { HasConfigurationValue, HasConfigurationValues } from '../../../../utils/ConfigurationUtil';
import FeatureFlag from '../../../../consts/FeatureFlag';
import FeatureFlagApiService from '../../../../api/FeatureFlagApiService';

interface ServicePartnerInfoProps extends ProviderNominationProps {
  servicePartnerFormRef: FormInstance<any>;
  showServiceProvider?: boolean;
}
const { Option } = Select;
const ServicePartnerInfo = (props: ServicePartnerInfoProps) => {
  const [clientSelected, setClientSelected] = useState(props.pnf.memberAssociationClientId);
  const [hasNotifyDefaultValue, setHasNotifyDefaultValue] = useState(false);
  const [hasFeatureFlags, setHasFeatureFlags] = useState(false);
  const [featureFlags, setFeatureFlags] = useState<FeatureFlagDTO[]>();
  const authContext = useContext(AuthenticationContext);
  const user = authContext.user;
  const roleId = user?.accessRoleId;

  useEffect(() => {
    if (props.pnf.memberAssociationClientId) {
      setClientSelected(props.pnf.memberAssociationClientId);
      props.servicePartnerFormRef.setFieldValue('clientId', props.pnf.memberAssociationClientId);
    }

    hasClientFeatureFlags(props.pnf.memberAssociationClientId ?? 0);
    loadClaimDocFeatureFlags(props.pnf.memberAssociationClientId ?? 0);
  }, [props.pnf.memberAssociationClientId]);

  const loadClaimDocFeatureFlags = (clientId: number) => {
    return FeatureFlagApiService.getClientFeatureFlagsWithRoleId(clientId ?? 0, roleId ?? 0).then((res) => {
      if (roleId == Role.CLAIMDOC) {
        const featureFlags = res.claimDocUserFeatureFlags?.find((x) => x.clientId == clientId)?.featureFlags ?? [];
        setFeatureFlags(featureFlags);
        setHasNotifyDefaultValue(
          HasConfigurationValues(featureFlags, [
            FeatureFlag.SERVICE_PROVIDERS_ACKNOWLEDGEMENT_OF_RECEIPT_MEMBER,
            FeatureFlag.SERVICE_PROVIDERS_ACKNOWLEDGEMENT_OF_RECEIPT_SUBMITTER,
          ])
        );
      }
    });
  };

  const hasClientFeatureFlags = (clientId: number) => {
    return FeatureFlagApiService.hasClientFeatureFlags(
      clientId,
      ['Allow Selection'],
      roleId == Role.CLAIMDOC ? '' : 'Nomination Details (Dependent on Acknowledgement of Receipt)'
    ).then((res) => {
      setHasFeatureFlags(res);
    });
  };

  const handleClientChange = (clientId?: number) => {
    if (props.servicePartnerFormRef) {
      props.servicePartnerFormRef.resetFields([
        'notifyMember',
        'notifySubmitter',
        'pdfAttachMember',
        'pdfAttachSubmitter',
        'accessToDocumentsMember',
        'accessToDocumentsSubmitter',
        'followUpCommunicationMember',
        'followUpCommunicationSubmitter',
      ]);
    }
    setClientSelected(clientId ?? 0);
    hasClientFeatureFlags(clientId ?? 0);
    loadClaimDocFeatureFlags(clientId ?? 0);
  };

  const valuesChanged = (changes: any) => {
    if (props.onChange) {
      props.onChange(changes);
    }
  };

  const renderClient = () => {
    return (
      <>
        <Col xs={24} xl={12}>
          <Form.Item name="clientId" label="Client" rules={[{ required: true, message: 'Required' }]}>
            <Select
              showSearch
              value={clientSelected}
              optionFilterProp="children"
              style={{ width: '100%' }}
              onSelect={handleClientChange}
              onClear={handleClientChange}
            >
              <Option value="" disabled hidden>
                -- Select a client --
              </Option>
              {(props.relations || []).map((r) => (
                <Option key={r.id} value={r.id}>
                  {r.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </>
    );
  };

  const renderServiceProviderForm = () => {
    if (props.onBehalf && clientSelected && hasFeatureFlags) {
      if (roleId == Role.CLAIMDOC) {
        if (
          HasConfigurationValue(
            featureFlags,
            FeatureFlag.SERVICE_PROVIDERS_NOMINATION_DETAILS_DEPENDENT_ON_ACKNOWLEDGEMENT_OF_RECEIPT_ALLOW_SELECTION
          ) &&
          hasNotifyDefaultValue
        ) {
          return renderForm();
        } else if (
          (!HasConfigurationValues(featureFlags, [
            FeatureFlag.SERVICE_PROVIDERS_ACKNOWLEDGEMENT_OF_RECEIPT_MEMBER,
            FeatureFlag.SERVICE_PROVIDERS_ACKNOWLEDGEMENT_OF_RECEIPT_SUBMITTER,
            FeatureFlag.SERVICE_PROVIDERS_ACKNOWLEDGEMENT_OF_RECEIPT_HIDE_QUESTION,
          ]) &&
            HasConfigurationValue(
              featureFlags,
              FeatureFlag.SERVICE_PROVIDERS_ACKNOWLEDGEMENT_OF_RECEIPT_ALLOW_SELECTION
            )) ||
          (!HasConfigurationValues(featureFlags, [
            FeatureFlag.SERVICE_PROVIDERS_NOMINATION_ACCESSIBLE_IN_DOCUMENTS_MEMBER,
            FeatureFlag.SERVICE_PROVIDERS_NOMINATION_ACCESSIBLE_IN_DOCUMENTS_SUBMITTER,
            FeatureFlag.SERVICE_PROVIDERS_NOMINATION_ACCESSIBLE_IN_DOCUMENTS_HIDE_QUESTION,
          ]) &&
            HasConfigurationValue(
              featureFlags,
              FeatureFlag.SERVICE_PROVIDERS_NOMINATION_ACCESSIBLE_IN_DOCUMENTS_ALLOW_SELECTION
            )) ||
          (!HasConfigurationValues(featureFlags, [
            FeatureFlag.SERVICE_PROVIDERS_FOLLOW_UP_COMMUNICATION_MEMBER,
            FeatureFlag.SERVICE_PROVIDERS_FOLLOW_UP_COMMUNICATION_SUBMITTER,
            FeatureFlag.SERVICE_PROVIDERS_FOLLOW_UP_COMMUNICATION_HIDE_QUESTION,
          ]) &&
            HasConfigurationValue(featureFlags, FeatureFlag.SERVICE_PROVIDERS_FOLLOW_UP_COMMUNICATION_ALLOW_SELECTION))
        ) {
          return renderForm();
        }
      } else {
        return renderForm();
      }
    }
  };

  const renderForm = () => {
    return (
      <>
        <h2>Service Partner</h2>
        <Card className="ant-card-background ant-card-shadow active" bordered={false}>
          <ServicePartnerForm
            clientId={clientSelected ?? props.pnf.memberAssociationClientId ?? 0}
            formRef={props.servicePartnerFormRef}
            dto={props.pnf.servicePartner ?? ({} as PnfServicePartnerDTO)}
            onChange={props.onChange}
          />
        </Card>
      </>
    );
  };

  return (
    <Form form={props.servicePartnerFormRef} layout="vertical" onValuesChange={valuesChanged} style={{ marginTop: 10 }}>
      {renderClient()}
      {renderServiceProviderForm()}
      <br />

      <Row justify="end">
        <Col flex={1} style={{ textAlign: 'right' }}>
          <Button type="primary" size="large" shape="round" onClick={props.onContinue}>
            Continue
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ServicePartnerInfo;
