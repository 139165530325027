import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useRef, useState } from 'react';
import PageTitle from '../../components/shared/PageTitle';
import { Button, Col, Divider, Form, Input, Row, Select, Spin, notification } from 'antd';
import RelationDTO from '../../models/RelationDTO';
import SupportApiService from '../../api/SupportApiService';
import DataTable, { DataTableColumnProps } from '../../components/shared/DataTable/DataTable';
import { useForm } from 'antd/lib/form/Form';
import SupportMemberSearchFilterDTO from '../../models/SupportMemberSearchFilterDTO';
import SupportMemberSearchDTO from '../../models/SupportMemberSearchDTO';
import DataTableColumnUtil from '../../components/shared/DataTable/DataTableColumnUtil';
import TableRequestDTO from '../../models/TableRequestDTO';
import SupportMemberSearchRequestDTO from '../../models/SupportMemberSearchRequestDTO';
import TableResponseDTO from '../../models/TableResponseDTO';
import { Link } from 'react-router-dom';
import Routes from '../../consts/Routes';
import MemberSupportAccountTypes from '../../consts/MemberSupportAccountTypes';

const { Option } = Select;

const LOCAL_STORAGE_FILTER_KEY = 'PORTAL|SUPPORT_MEMBER_SEARCH_FILTERS';

const MemberSearchPage = () => {
  const [formRef] = useForm();
  const tableRef = useRef<DataTable<SupportMemberSearchDTO>>(null);

  const [clients, setClients] = useState<RelationDTO[]>([]);

  const [loadingFilters, setLoadingFilters] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);

  const [isInitLoad, setIsInitLoad] = useState(true);

  useEffect(() => {
    fetchClients(true);
  }, []);

  const fetchClients = (isInitialLoad: boolean) => {
    setLoadingFilters(true);
    SupportApiService.getClients()
      .then((res) => {
        setClients(res);

        if (isInitialLoad) {
          getFiltersFromLocalStorage();
          tableRef?.current?.refresh();
        }
      })
      .catch(() => {
        notification.error({ message: 'Error while fetching clients' });
      })
      .finally(() => {
        setLoadingFilters(false);
      });
  };

  const fetchMembers = (
    tableRequest: TableRequestDTO,
    checkEcho: () => boolean,
    callback: (response: TableResponseDTO<SupportMemberSearchDTO>) => void
  ) => {
    if (isInitLoad) {
      setIsInitLoad(false);
      return [];
    }

    formRef.validateFields().then((model) => {
      const requestDTO = SupportMemberSearchRequestDTO.create({
        tableRequest: tableRequest,
        filter: SupportMemberSearchFilterDTO.create({ ...model }),
      });

      setLoading(true);
      setHasSearched(true);
      SupportApiService.searchMembers(requestDTO)
        .then((res) => {
          if (!checkEcho()) {
            return;
          }

          callback(res);
        })
        .catch(() => {
          notification.error({ message: 'Error while fetching members' });
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  const storeFiltersToLocalStorage = () => {
    const filterModel = formRef.getFieldsValue();
    localStorage.setItem(LOCAL_STORAGE_FILTER_KEY, JSON.stringify(filterModel));
  };

  const getFiltersFromLocalStorage = () => {
    const filterString = localStorage.getItem(LOCAL_STORAGE_FILTER_KEY);
    if (filterString) {
      formRef.setFieldsValue(JSON.parse(filterString));
    }
  };

  const handleSearch = () => {
    storeFiltersToLocalStorage();
    tableRef?.current?.refresh();
  };

  const memberTableColumns: DataTableColumnProps<SupportMemberSearchDTO>[] = [
    DataTableColumnUtil.Text('Last Name', 'lastName', undefined, { defaultSortOrder: 'ascend' }),
    DataTableColumnUtil.Text('First Name', 'firstName'),
    DataTableColumnUtil.Text('Email', 'email'),
    DataTableColumnUtil.DropdownMulti('Account Type', 'accountType', [
      { text: MemberSupportAccountTypes.FULLACCOUNT, value: MemberSupportAccountTypes.FULLACCOUNT },
      { text: MemberSupportAccountTypes.GUESTPIN, value: MemberSupportAccountTypes.GUESTPIN },
    ]),
    {
      title: '',
      dataIndex: '',
      width: '40px',
      // eslint-disable-next-line react/display-name
      render: (rowData) => {
        let routeProps: any;
        if (rowData.accountType === MemberSupportAccountTypes.FULLACCOUNT) {
          routeProps = { clientId: rowData.clientId, userId: rowData.userId };
        }

        if (rowData.accountType === MemberSupportAccountTypes.GUESTPIN) {
          routeProps = { clientId: rowData.clientId, pinId: rowData.pinId };
        }

        return (
          <Link to={Routes.generate(Routes.SUPPORT_MEMBER_DETAIL, undefined, routeProps)}>
            <Button shape="round" ghost>
              Details
            </Button>
          </Link>
        );
      },
    },
  ];

  const colProps = { xs: 24, sm: 12 };
  const initialValues = {
    clientId: '',
  };

  return (
    <Content className="content-padding">
      <Spin spinning={loadingFilters}>
        <PageTitle title="Client Member Search" />
        <Form form={formRef} initialValues={initialValues} layout="vertical">
          <Row gutter={6}>
            <Col {...colProps}>
              <Form.Item name="clientId" label="Client" rules={[{ required: true, message: 'Required.' }]}>
                <Select allowClear showSearch optionFilterProp="children">
                  <Option value="" hidden disabled>
                    -- Select a client --
                  </Option>
                  {clients.map((c) => (
                    <Option key={c.id} value={c.id}>
                      {c.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col {...colProps}>
              <Form.Item name="email" label="Email">
                <Input />
              </Form.Item>
            </Col>
            <Col {...colProps}>
              <Form.Item name="firstName" label="First Name">
                <Input />
              </Form.Item>
            </Col>
            <Col {...colProps}>
              <Form.Item name="lastName" label="Last Name">
                <Input />
              </Form.Item>
            </Col>
            <Col {...colProps}>
              <Button type="primary" onClick={handleSearch}>
                Search
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
      <Divider />
      <DataTable
        ref={tableRef}
        serverSide
        columns={memberTableColumns}
        fetchData={fetchMembers}
        tableProps={{
          id: 'memberId',
          loading: loading,
          locale: {
            emptyText: hasSearched ? 'No members found' : 'Search for members using the above filters',
          },
        }}
      />
    </Content>
  );
};

export default MemberSearchPage;
