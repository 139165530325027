import { Button, Col, Layout, notification, Result, Row, Spin, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useState } from 'react';
import Service from '../../api/BalanceBillApiService';
import BalanceBillForm from '../../components/balance-bill/BalanceBillForm';
import PageTitle from '../../components/shared/PageTitle';
import SubmitBalanceBillDTO from '../../models/SubmitBalanceBillDTO';
import FileUploadUtil from '../../utils/HasFileUploadUtil';

const BalanceBillPage = () => {
  const [loading, setLoading] = useState(false);
  const [form] = useForm();
  const [submitted, setSubmitted] = useState(false);

  const submit = (values: any) => {
    const dto = values as SubmitBalanceBillDTO;
    setLoading(true);
    const request = FileUploadUtil(dto, 'request', ['files']);
    Service.submitBalanceBill(request)
      .then((response: boolean) => {
        setSubmitted(response);
        if (response) {
          form.resetFields();
        } else {
          throw new Error('Failed to submit');
        }
      })
      .catch(() => {
        notification.error({ message: 'Failed to submit' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Layout style={{ height: '100%' }}>
      <Layout.Content style={{ backgroundColor: '#eeefef' }}>
        <div style={{ position: 'relative' }}>
          <Row style={{ height: '100%' }}>
            <Col
              xs={{ span: 24, order: 2 }}
              lg={{ span: 16, order: 1 }}
              xl={{ span: 14, order: 1 }}
              className="content-padding content-padding-xl"
              style={{ backgroundColor: 'white' }}
            >
              <PageTitle title="Balance Billing" />

              <Spin spinning={loading}>
                {submitted ? (
                  <Result
                    status="success"
                    title="Balance Bill Submitted"
                    extra={[
                      <Button type="primary" shape="round" key="another" onClick={() => setSubmitted(false)}>
                        Submit Another Balance Bill
                      </Button>,
                    ]}
                    subTitle={
                      <>
                        Thank you for submitting the CDMAA and the HIPAA Release. The Team will add these to your file
                        and begin the defense process with your healthcare provider. A Team member will contact you if
                        anything further is needed before we initiate the dispute. Please note – this process takes time
                        and it can take up to 45 days for a healthcare provider to respond to a dispute. We will contact
                        you with updates as they become available but please feel free to call or email the team with
                        questions at any time. And,&nbsp;
                        <b>
                          please remember to send the Team any further correspondence or billing statements you may
                          receive.
                        </b>
                      </>
                    }
                  />
                ) : (
                  <BalanceBillForm onSave={submit} form={form} />
                )}
              </Spin>
            </Col>
            <Col
              xs={{ span: 24, order: 1 }}
              lg={{ span: 8, order: 1 }}
              xl={{ span: 10, order: 1 }}
              className="content-padding content-padding-pnfsteps"
              style={{ backgroundColor: '#eeefef' }}
            >
              <div>
                <h2>Submit your Balance Bill to ClaimDOC</h2>
                <Typography.Text type="secondary">
                  When you submit a Balance Bill to ClaimDOC&apos;s Balance Bill Team, they will quickly review your
                  file and determine the next steps in defending you from paying more than what you should be paying for
                  your healthcare.
                  <br />
                  <br />
                  If you are new to the process, the team will start by sending you an authorization and release form.
                  These forms allow ClaimDOC to advocate on your behalf regarding the disputed medical bill.
                  <br />
                  <br />
                  The Team will then review your bill and provide guidance on what to expect next. This next step
                  involves educating your healthcare provider about your insurance and the ClaimDOC program. Your
                  healthcare provider is then informed of the appropriate ways to resolve the payment dispute, none of
                  which include billing you for more than your normal cost-sharing amounts (deductible, coinsurance,
                  co-payments).
                  <br />
                  <br /> The Team will continue to work with your healthcare provider until the dispute is fully
                  resolved.
                </Typography.Text>
                <br />
                <br />
                <h3>Informational Video</h3>
                <div style={{ position: 'relative', minHeight: 225 }}>
                  <iframe
                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                    title="ClaimDOC Balance Bill Process"
                    frameBorder={0}
                    allowFullScreen
                    src="https://player.vimeo.com/video/593307199?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=8d2bb0468e"
                  ></iframe>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default BalanceBillPage;

// // Example of how to embed document if desired
// <iframe
//               src="https://claimdoc.na4.documents.adobe.com/public/esignWidget?wid=CBFCIBAA3AAABLblqZhAKtIfl6hAf2qJsM0aULILJgLEm3N191P3WKuiQK_ogV3kHzCZxwWS6so8zWjf1d3g*&hosted=false"
//               width="100%"
//               height="100%"
//               frameBorder="0"
//               style={{ border: 0, overflow: 'hidden', minHeight: 500, minWidth: 600 }}
//             ></iframe>
