import { Button, Row, Typography, notification } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import DocumentApiService from '../../api/DocumentApiService';
import Routes from '../../consts/Routes';
import useUrlQuery from '../../hooks/useUrlQuery';
import PNFListDTO from '../../models/PNFListDTO';
import TableRequestDTO from '../../models/TableRequestDTO';
import TableResponseDTO from '../../models/TableResponseDTO';
import HistoryUtil from '../../utils/HistoryUtil';
import DataTable, { DataTableColumnProps } from '../shared/DataTable/DataTable';
import { AcceptedFormats } from '../shared/DatePicker2';
import useHasPermission from '../../hooks/useHasPermission';
import Permission from '../../consts/Permission';

interface RouteProps {
  mobileview: string;
}

const PNFList = () => {
  const hasPermission = useHasPermission();
  const [routeQuery] = useUrlQuery<RouteProps>();

  const [loading, setLoading] = useState(false);
  const [pnfs, setPNFs] = useState<PNFListDTO[]>([]);
  const dtRef = React.useRef<DataTable<any>>(null);

  const fetchTableData = (
    requestState: TableRequestDTO,
    checkEcho: () => boolean,
    callback: (response: TableResponseDTO<PNFListDTO>) => void
  ) => {
    setLoading(true);
    DocumentApiService.getPNFList(requestState)
      .then((results) => {
        if (!checkEcho()) {
          return;
        }
        setPNFs(results.results || []);
        setLoading(false);
        callback(results);
      })
      .catch((error: any) => {
        setLoading(false);
        notification.error({
          message: error.message,
          description: error.description,
        });
      });
  };

  const openPNF = (referenceNumber: string) => {
    if (routeQuery.mobileview) {
      HistoryUtil.push(Routes.generate(Routes.DOCUMENTS_VIEW, {}, { p: referenceNumber, mobileview: 1 }));
    } else {
      window.open(Routes.generate(Routes.DOCUMENTS_VIEW, {}, { p: referenceNumber }));
    }
  };

  const getTableColumns = (): DataTableColumnProps<PNFListDTO>[] => {
    const columns: DataTableColumnProps<PNFListDTO>[] = [
      {
        title: 'PNF-ID',
        dataIndex: 'referenceNumber',
        defaultSortOrder: 'descend',
        sorter: {},
      },
      {
        title: 'Date Submitted',
        dataIndex: 'submittedOn',
        defaultSortOrder: 'ascend',
        sorter: {},
        renderDataTransform: (value: any) => (value != null ? moment(value).format(AcceptedFormats.at(0)) : null),
      },
      {
        title: 'Patient Name',
        dataIndex: 'patientName',
        defaultSortOrder: 'ascend',
        sorter: {},
      },
      {
        title: 'Provider Name',
        dataIndex: 'providerName',
        defaultSortOrder: 'ascend',
        sorter: {},
      },
      {
        title: 'Facility Name',
        dataIndex: 'facilityName',
        defaultSortOrder: 'ascend',
        sorter: {},
      },
    ];

    if (hasPermission(Permission.DOCUMENTS_PNF_DISPLAYSTATUS)) {
      columns.push({
        title: 'Status',
        dataIndex: 'processingStatus',
        sorter: {},
      });
    }

    columns.push({
      title: '',
      dataIndex: '',
      width: '40px',
      // eslint-disable-next-line react/display-name
      render: (rowData: PNFListDTO) => (
        <Button shape="round" ghost onClick={() => openPNF(rowData.referenceNumber ?? '')}>
          View Document
        </Button>
      ),
    });

    return columns;
  };

  return (
    <>
      {hasPermission(Permission.DOCUMENTS_PNF_DISPLAYSTATUS) && (
        <Row style={{ marginTop: '10px', marginBottom: '20px' }}>
          <Typography.Text>
            Thank you for participating in the Pave the Way program and submitting your provider nominations. Please
            note that the information shown here pertains to the completion status of your nominations and does not
            indicate whether the provider is currently partnered with the health plan. Rest assured, your dedicated
            Member Advocate will keep you informed about the status of your specific providers and guide you through the
            next steps. Should you have any questions, feel free to contact your Member Advocate or call us at
            888-330-7295. We&apos;re here to assist you!
          </Typography.Text>
        </Row>
      )}
      <DataTable
        ref={dtRef}
        serverSide={true}
        globalSearch={true}
        globalSearchPlaceholder="Search PNFs"
        tableProps={{
          rowKey: 'id',
          loading: loading,
          scroll: { x: 575 },
        }}
        columns={getTableColumns()}
        data={pnfs}
        fetchData={fetchTableData}
        styleOptions={{
          alternatingRowHighlight: true,
        }}
      />
      {hasPermission(Permission.DOCUMENTS_PNF_DISPLAYSTATUS) && (
        <Row style={{ marginTop: '20px' }}>
          <Typography.Text>
            Your healthcare provider is registered in the National Provider Identifier (NPI) database, and we utilize
            the NPI for tracking purposes. ClaimDOC may reference your provider using the details reflecting in NPI
            database. If there are variations in the information compared to what you have submitted, please be assured
            that we have recorded those details and are in the process of contacting the relevant parties.
          </Typography.Text>
        </Row>
      )}
    </>
  );
};

export default PNFList;
