import { Select, SelectProps } from 'antd';
import { SelectValue } from 'antd/lib/select';
import React, { useState, useEffect } from 'react';
import LookupsApiService from '../../api/LookupsApiService';
import PermissionGroupDetailDTO from '../../models/PermissionGroupDetailDTO';

interface PermissionGroupPickerProps<T extends SelectValue> extends SelectProps<T> {
  permissionGroups?: PermissionGroupDetailDTO[];
  selfLoad?: boolean;
}

function PermissionGroupPicker<T extends SelectValue>(props: PermissionGroupPickerProps<T>) {
  const [permissionGroupState, setPermissionGroups] = useState<PermissionGroupDetailDTO[]>(
    props.permissionGroups ?? []
  );
  const { permissionGroups, ...selectProps } = props;

  useEffect(() => {
    setPermissionGroups(permissionGroups ?? []);
  }, [permissionGroups]);

  useEffect(() => {
    if (props.selfLoad === true) {
      LookupsApiService.getPermissionGroups().then((res) => {
        setPermissionGroups(res);
      });
    }
  }, []);

  return (
    <Select showSearch optionFilterProp="children" {...selectProps}>
      {permissionGroupState.map((g) => {
        return (
          <Select.Option key={g.id ?? ''} value={g.id ?? ''} label={g.name as any}>
            {g.name}
          </Select.Option>
        );
      })}
    </Select>
  );
}

export default PermissionGroupPicker;
