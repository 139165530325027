import { Button, Col, Layout, notification, Row } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import MailroomApiService from '../../api/MailroomApiService';
import DataTable, { DataTableColumnProps } from '../../components/shared/DataTable/DataTable';
import DataTableColumnUtil from '../../components/shared/DataTable/DataTableColumnUtil';
import PageTitle from '../../components/shared/PageTitle';
import PermissionGuard from '../../components/shared/PermissionGuard';
import Permission from '../../consts/Permission';
import Routes from '../../consts/Routes';
import MailroomAccountListDTO from '../../models/MailroomAccountListDTO';
import TableRequestDTO from '../../models/TableRequestDTO';
import TableResponseDTO from '../../models/TableResponseDTO';
import HistoryUtil from '../../utils/HistoryUtil';
import MobileUtil from '../../utils/MobileUtil';

const Content = Layout.Content;

const MailroomAccountListPage = () => {
  const [tableData, setTableData] = useState<MailroomAccountListDTO[]>([]);
  const [loading, setLoading] = useState(false);
  const dataTableRef = React.useRef<DataTable<any>>(null);

  const isMobile = () => MobileUtil.getMediaQuery()?.matches;

  const tableColumns = [
    DataTableColumnUtil.Text('Account', 'account'),
    DataTableColumnUtil.Text('Partner', 'partner'),
    DataTableColumnUtil.Text('Partner Code', 'partnerCode'),
    DataTableColumnUtil.Text('Type', 'accountType'),
    {
      title: '',
      dataIndex: '',
      width: '40px',
      // eslint-disable-next-line react/display-name
      render: (rowData: MailroomAccountListDTO) => (
        <Link
          to={Routes.generate(Routes.MAILROOM_ACCOUNT_DETAIL, {
            id: rowData.id,
          })}
        >
          <Button shape="round" ghost>
            Details
          </Button>
        </Link>
      ),
    },
  ] as DataTableColumnProps<any>[];

  const goToMailroomAccountDetails = (id: number) => {
    HistoryUtil.push(
      Routes.generate(Routes.MAILROOM_ACCOUNT_DETAIL, {
        id: id,
      })
    );
  };

  const fetchMailroomAccountTableData = (
    requestState: TableRequestDTO,
    checkEcho: () => boolean,
    callback: (response: TableResponseDTO<MailroomAccountListDTO>) => void
  ) => {
    setLoading(true);

    MailroomApiService.getMailroomAccountList(requestState)
      .then((results) => {
        if (!checkEcho()) {
          return;
        }

        setLoading(false);
        setTableData(results.results || []);

        callback(results);
      })
      .catch((error: any) => {
        setLoading(false);
        notification.error({
          message: error.message,
          description: error.description,
        });
      });
  };

  return (
    <Content className="content-padding">
      <Row>
        <Col xs={24}>
          <Row className="content-header" justify="space-between">
            <Col xs={24} lg={10} xl={13} xxl={14}>
              <PageTitle title="Mailroom Accounts" />
            </Col>
            <Col>
              <PermissionGuard permissionId={Permission.UTILITIES_MAILROOM_ACCOUNT_EDIT}>
                <Link to={Routes.generate(Routes.MAILROOM_ACCOUNT_NEW)}>
                  <Button type="primary" shape="round" style={{ marginRight: '16px' }}>
                    New Mailroom Account
                  </Button>
                </Link>
              </PermissionGuard>
            </Col>
          </Row>
          <DataTable
            ref={dataTableRef}
            serverSide={true}
            globalSearch={true}
            globalSearchPlaceholder="Search by Account, Partner, or Partner Code..."
            tableProps={{
              rowKey: 'id',
              size: 'large',
              loading: loading,
              sortDirections: ['ascend', 'descend'],
              scroll: { x: 575 },
              onRow: (record: MailroomAccountListDTO) => {
                return {
                  onDoubleClick: () => {
                    if (!isMobile()) {
                      goToMailroomAccountDetails(record.id);
                    }
                  },
                  onClick: () => {
                    if (isMobile()) {
                      goToMailroomAccountDetails(record.id);
                    }
                  },
                };
              },
            }}
            columns={tableColumns}
            data={tableData}
            fetchData={fetchMailroomAccountTableData}
            styleOptions={{
              alternatingRowHighlight: true,
            }}
          />
        </Col>
      </Row>
    </Content>
  );
};

export default MailroomAccountListPage;
