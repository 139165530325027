// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import FeatureFlagGroupDTO from '../models/FeatureFlagGroupDTO';
import ClientFeatureFlagsDTO from '../models/ClientFeatureFlagsDTO';

export class FeatureFlagApiService extends BaseApi {

    // get: api/featureflag/getFeatureFlagByGroup?groupName=${encodeURIComponent(groupName)}
    public getFeatureFlagByGroup(groupName: string): Promise<FeatureFlagGroupDTO[]> {
        let url = `api/featureflag/getFeatureFlagByGroup`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (groupName != null) {
            url += `${prefix}groupName=${encodeURIComponent(groupName)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, FeatureFlagGroupDTO[]>(null, 'get', url, true, false);
    }

    // post: api/featureflag/GetClientFeatureFlagsWithId?clientId=${clientId}&roleId=${roleId}
    public getClientFeatureFlagsWithRoleId(clientId: number, roleId: number): Promise<ClientFeatureFlagsDTO> {
        let url = `api/featureflag/GetClientFeatureFlagsWithId`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (clientId != null) {
            url += `${prefix}clientId=${clientId}`;
            prefix = '&';
        }
        if (roleId != null) {
            url += `${prefix}roleId=${roleId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ClientFeatureFlagsDTO>(null, 'post', url, true, false);
    }

    // post: api/featureflag/HasClientFeatureFlags?clientId=${clientId}&notAllowedSubGroups=${encodeURIComponent(notAllowedSubGroups)}
    public hasClientFeatureFlags(clientId: number, featureFlags: string[], notAllowedSubGroups: string): Promise<boolean> {
        let url = `api/featureflag/HasClientFeatureFlags`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (clientId != null) {
            url += `${prefix}clientId=${clientId}`;
            prefix = '&';
        }
        if (notAllowedSubGroups != null) {
            url += `${prefix}notAllowedSubGroups=${encodeURIComponent(notAllowedSubGroups)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, boolean>(featureFlags, 'post', url, true, false);
    }
}
var service = new FeatureFlagApiService();
export default service;
