const parseIntOrDefault = (val: string | undefined | null, defaultVal?: number): number => {
  const returnValue = defaultVal ?? 0;

  if (!val) {
    return returnValue;
  }

  // Check that it's an integer
  if (!/^-?\d+$/.test(val)) {
    return returnValue;
  }

  const parsed = parseInt(val);
  if (!isNaN(parsed)) {
    return parsed;
  }

  return returnValue;
};

export default parseIntOrDefault;
