import Icon, { RightOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Select, Spin, Tooltip } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import React, { useContext, useEffect, useState } from 'react';
import LookupsApiService from '../../api/LookupsApiService';
import ProviderMapSortBy from '../../consts/ProviderMapSortBy';
import ProviderType from '../../consts/ProviderType';
import SvgIcons from '../../consts/SvgIcons';
import ProviderSearchQueryDTO from '../../models/ProviderSearchQueryDTO';
import ProviderSpecialtyDTO from '../../models/ProviderSpecialtyDTO';
import { GetLongLatLocation } from '../../pages/tools/ProviderMapPage';
import { AuthenticationContext } from '../../auth/AuthenticationContext';
import AccessRole from '../../consts/AccessRole';

const MY_LOCATION = 'My location';
const MAP_LOCATION = 'Map location';

interface ProviderQuery {
  locationField: string;
  relativeLocation: number[] | undefined;
  searchRadius: number;
  providerType: number | undefined;
  providerSpecialty: string[] | undefined;
  providersFilter: string | undefined;
  agreementType: string | undefined;
  agreementSubType: string | undefined;
  sortBy: number;
  auto: boolean;
}

interface ProviderSearchProps {
  isMobile: boolean;
  searchProviders: (locationField: string, query: ProviderQuery) => void;
  resultsCount: number;
  initialQuery?: ProviderSearchQueryDTO;
}

const SortByFormat = (val: number | undefined, pastTense?: boolean): string => {
  const sort = 'Sort' + (pastTense ? 'ed' : '');
  switch (val) {
    case ProviderMapSortBy.TYPE:
      return sort + ' by Type';
    case ProviderMapSortBy.SPECIALTY:
      return sort + ' by Specialty';
    case ProviderMapSortBy.NAME:
      return sort + ' by Name';
    default:
      return sort + ' by Distance';
  }
};

const commonSelectProps = {
  style: { width: '100%' },
  size: 'large' as SizeType,
};
const PricingToolProviderSearchFilters = (props: ProviderSearchProps) => {
  const authCtx = useContext(AuthenticationContext);
  const userRole = authCtx.user?.accessRoleId ?? -1;

  let defaultDistance = 5;
  if ([AccessRole.CLAIMDOC, AccessRole.MEMBER].includes(userRole)) {
    defaultDistance = 10;
  }
  const reqProviderType = [AccessRole.TPA, AccessRole.BROKER, AccessRole.DPC, AccessRole.OTHERCOMPANY].includes(
    userRole
  );

  const [searchQuery, setSearchQuery] = useState<ProviderQuery>({
    locationField: props.initialQuery?.address
      ? props.initialQuery?.address || ''
      : props.initialQuery?.latitude
      ? MY_LOCATION
      : '',
    relativeLocation:
      props.initialQuery?.longitude && props.initialQuery?.latitude
        ? [props.initialQuery?.longitude, props.initialQuery?.latitude]
        : undefined,
    searchRadius: props.initialQuery?.distance ?? defaultDistance,
    providerType: ProviderType.DOCTOR,
    providerSpecialty: props.initialQuery?.providerSpecialty,
    providersFilter: props.initialQuery?.filterString || undefined,
    sortBy: tryParseInt(props.initialQuery?.sortBy, ProviderMapSortBy.DISTANCE),
    agreementType: undefined,
    agreementSubType: undefined,
    auto: !!props.initialQuery,
  });
  const [providerSpecialties, setProviderSpecialties] = useState<string[]>([]);
  const [expanded, setExpanded] = useState(props.isMobile ? true : false);
  const [autoCollapsedEnabled, setAutoCollapsedEnabled] = useState(true);

  useEffect(() => {
    getLookups();
  }, []);

  useEffect(() => {
    if (searchQuery.auto) {
      onQuery();
      setSearchQuery({ ...searchQuery, auto: false });
    }
  }, [searchQuery]);

  const onQuery = async () => {
    if (isSearchDisabled()) {
      return;
    }
    setAutoCollapsedEnabled(true);
    props.searchProviders(searchQuery.locationField, searchQuery);
  };

  const getLookups = async () => {
    let specs: ProviderSpecialtyDTO[] = [];
    try {
      specs = await LookupsApiService.getProviderSpecialties();
    } catch {
      specs = [];
    }

    setProviderSpecialties(specs.map((e) => e.name ?? '').filter((e) => e.trim().length > 0));
  };

  const isSearchDisabled = () => {
    return (
      searchQuery.relativeLocation === undefined &&
      (searchQuery.locationField.trim().length === 0 ||
        searchQuery.locationField === MY_LOCATION ||
        searchQuery.locationField === MAP_LOCATION)
    );
  };

  const smartReplace = (initial: string, current: string): string => {
    if (current.includes(initial)) {
      return current.split(initial).join('');
    }
    return '';
  };

  const handleLocationFieldClicked = () => {
    if (searchQuery.locationField === MY_LOCATION || searchQuery.locationField === MAP_LOCATION) {
      setSearchQuery({ ...searchQuery, relativeLocation: undefined, locationField: '' });
    }
  };

  const searchRadiusFormat = (val: number): string => 'Within ' + val + ' miles';

  const providerTypeFormat = (val: number | undefined): string | undefined => {
    switch (val) {
      case ProviderType.CLINIC:
        return 'Facilities';
      case ProviderType.DOCTOR:
        return 'Practitioners';
      default:
        return undefined;
    }
  };

  useEffect(() => {
    if (!props.isMobile) {
      setExpanded(true);
      setAutoCollapsedEnabled(false);
      return;
    }
    if (props.resultsCount > 0 && expanded && autoCollapsedEnabled) {
      setExpanded(false);
      setAutoCollapsedEnabled(false);
    }
  }, [props.resultsCount, props.isMobile, expanded]);

  return (
    <div
      onKeyUp={(e) => {
        if (e.key == 'Enter') {
          onQuery();
        }
      }}
    >
      <Row
        style={{ display: props.isMobile ? 'block' : 'none', marginBottom: 12 }}
        onClick={() => setExpanded(!expanded)}
      >
        <span className={expanded ? 'expanded-arrow' : 'collapsed-arrow'} style={{ cursor: 'pointer' }}>
          <RightOutlined />
        </span>
        &nbsp;
        <span style={{ cursor: 'pointer' }}>Search</span>
      </Row>
      <Row style={{ display: !expanded && props.isMobile ? 'none' : undefined }}>
        <Col style={{ width: '100%' }}>
          <Spin spinning={providerSpecialties.length === 0}>
            <Row gutter={[12, 12]}>
              <Col xs={24} sm={24} md={16}>
                <Input
                  placeholder="Address/Zip Code"
                  allowClear
                  value={searchQuery.locationField}
                  size="large"
                  onChange={(e) => {
                    if (searchQuery.relativeLocation !== undefined) {
                      setSearchQuery({ ...searchQuery, relativeLocation: undefined });
                    }
                    if (searchQuery.locationField === MY_LOCATION || searchQuery.locationField === MAP_LOCATION) {
                      setSearchQuery({
                        ...searchQuery,
                        locationField: smartReplace(searchQuery.locationField, e.target.value),
                        relativeLocation: undefined,
                      });
                    } else {
                      setSearchQuery({ ...searchQuery, locationField: e.target.value, relativeLocation: undefined });
                    }
                  }}
                  onClick={handleLocationFieldClicked}
                  suffix={
                    <Icon
                      component={SvgIcons.UsrLocation}
                      onClick={() => {
                        GetLongLatLocation((result: number[] | null) => {
                          if (result) {
                            setSearchQuery({
                              ...searchQuery,
                              relativeLocation: result ?? undefined,
                              locationField: MY_LOCATION,
                            });
                          }
                        });
                      }}
                    />
                  }
                />
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Select
                  value={searchRadiusFormat(searchQuery.searchRadius)}
                  {...commonSelectProps}
                  onSelect={(val: any) => {
                    setSearchQuery({ ...searchQuery, searchRadius: val });
                  }}
                >
                  <Select.Option value={5}>Within 5 miles</Select.Option>
                  <Select.Option value={10}>Within 10 miles</Select.Option>
                  <Select.Option value={20}>Within 20 miles</Select.Option>
                  <Select.Option value={50}>Within 50 miles</Select.Option>
                  <Select.Option value={100}>Within 100 miles</Select.Option>
                </Select>
              </Col>
            </Row>
            <Row gutter={[12, 12]} style={{ marginTop: 12, marginBottom: 12 }}>
              <Col xs={24} sm={24} md={12}>
                <Select
                  allowClear={!reqProviderType}
                  value={providerTypeFormat(searchQuery.providerType)}
                  placeholder="Provider Type"
                  {...commonSelectProps}
                  disabled={true}
                >
                  <Select.Option value={ProviderType.DOCTOR}>Practitioners</Select.Option>
                  <Select.Option value={ProviderType.CLINIC}>Facilities</Select.Option>
                </Select>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Select
                  showSearch
                  optionFilterProp="prop"
                  notFoundContent={<h3 style={{ marginBottom: 0, paddingBottom: 0 }}>No results</h3>}
                  allowClear
                  value={searchQuery.providerSpecialty}
                  placeholder="Provider Specialty"
                  mode="multiple"
                  maxTagCount="responsive"
                  maxTagPlaceholder={(v: any[]) => {
                    if (!v || v?.length < 1) {
                      return <></>;
                    }
                    const allValues = v
                      .map((x) => x.label)
                      ?.reduce((prev?: string, curr?: string) => (prev ? prev : '') + ', ' + (curr ? curr : ''));
                    return <Tooltip title={allValues}>+ {v.length}...</Tooltip>;
                  }}
                  {...commonSelectProps}
                  onChange={(v: string[]) => {
                    setSearchQuery({ ...searchQuery, providerSpecialty: v });
                  }}
                  onClear={() => {
                    setSearchQuery({ ...searchQuery, providerSpecialty: undefined });
                  }}
                >
                  {providerSpecialties.map((v) => (
                    <Select.Option key={v} value={v} prop={v}>
                      {v}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </Row>
            <Row gutter={[12, 12]}>
              <Col xs={24} sm={24} md={16}>
                <Input
                  allowClear
                  placeholder="Filter Providers..."
                  value={searchQuery.providersFilter ?? ''}
                  onChange={(e) => setSearchQuery({ ...searchQuery, providersFilter: e.target.value })}
                  style={{ width: '100%' }}
                  size="large"
                />
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Select
                  value={SortByFormat(searchQuery.sortBy)}
                  style={{ width: '100%', marginBottom: 12 }}
                  size="large"
                  onSelect={(val: any) => {
                    setSearchQuery({ ...searchQuery, sortBy: val });
                  }}
                >
                  <Select.Option value={1}>Sort by Distance</Select.Option>
                  <Select.Option value={2}>Sort by Type</Select.Option>
                  <Select.Option value={3}>Sort by Specialty</Select.Option>
                  <Select.Option value={4}>Sort by Name</Select.Option>
                </Select>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col flex={1}>
                <Button
                  type="primary"
                  size="large"
                  block
                  shape="round"
                  disabled={isSearchDisabled()}
                  onClick={onQuery}
                  style={{ marginBottom: 12 }}
                >
                  Search
                </Button>
              </Col>
            </Row>
          </Spin>
        </Col>
      </Row>
    </div>
  );
};

export { PricingToolProviderSearchFilters, ProviderQuery, MY_LOCATION, MAP_LOCATION, SortByFormat };
