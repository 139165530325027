import { PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Collapse,
  Form,
  FormInstance,
  Input,
  Image,
  notification,
  Row,
  Space,
  Spin,
  Tag,
  Tooltip,
  Upload,
  Select,
} from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { UploadFile } from 'antd/lib/upload/interface';
import moment from 'moment';
import React, { CSSProperties, useContext, useEffect, useMemo, useState } from 'react';
import BalanceBillApiService from '../../api/BalanceBillApiService';
import { AuthenticationContext } from '../../auth/AuthenticationContext';
import ReleaseStatuses from '../../consts/ReleaseStatuses';
import Role from '../../consts/Role';
import FamilyMemberDTO from '../../models/FamilyMemberDTO';
import MemberDTO from '../../models/MemberDTO';
import RelationDTO from '../../models/RelationDTO';
import debounce from 'lodash/debounce';
import { useForm } from 'antd/lib/form/Form';
import ClaimDocMemberSupport from '../contact/ClaimDocMemberSupport';

const { Option } = Select;

const BalanceBillForm = (props: { onSave: (values: any) => void; form: FormInstance }) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingMembers, setLoadingMembers] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState();
  const [familyMembers, setFamilyMembers] = useState<FamilyMemberDTO[]>([]);

  const [clients, setClients] = useState<RelationDTO[]>([]);
  const [members, setMembers] = useState<MemberDTO[]>([]);

  const [selectedMemberId, setSelectedMemberId] = useState<string>();

  const [clientMemberForm] = useForm();

  const authCtx = useContext(AuthenticationContext);
  const role = authCtx.user?.accessRoleId || -1;

  const checkedMembers = familyMembers.filter((m) => m.checked);
  const completeMembers = checkedMembers.filter((m) => m.releaseStatus === ReleaseStatuses.COMPLETE);
  const formDisabled = checkedMembers.length <= 0 || completeMembers.length !== checkedMembers.length;
  const dateFormat = 'MM/DD/YYYY hh:mma';

  useEffect(() => {
    //pass
  }, []);

  useEffect(() => {
    if (role === Role.MEMBER) {
      getFamilyMembers();
    } else {
      getClients();
    }
  }, [role]);

  useEffect(() => {
    if (selectedMemberId) {
      getFamilyMembers(selectedMemberId);
    } else {
      setFamilyMembers([]);
    }
  }, [selectedMemberId]);

  const getClients = () => {
    BalanceBillApiService.getClients()
      .then((res) => {
        setClients(res);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getClientMembersApiCall = (searchString: string) => {
    const clientId = clientMemberForm.getFieldsValue()?.clientId;
    if (clientId) {
      setLoadingMembers(true);
      BalanceBillApiService.getClientMembers(clientId, searchString).then((res) => {
        setMembers(res);
        setLoadingMembers(false);
      });
    }
  };

  const debouncedMemberSearch = useMemo(() => debounce(getClientMembersApiCall, 400), []);

  useEffect(() => {
    return () => {
      debouncedMemberSearch.cancel();
    };
  }, []);

  const handleSearchMembers = (searchString?: string) => {
    if (!searchString || searchString.length < 3) {
      setMembers([]);
      return;
    }

    debouncedMemberSearch(searchString);
  };

  const handleClearMembers = () => {
    setMembers([]);
    setSelectedMemberId(undefined);
  };

  const getFamilyMembers = (memberId?: string) => {
    setLoading(true);
    BalanceBillApiService.familyMembers(memberId)
      .then((res) => {
        res.forEach((m) => {
          if (m.releaseCompleted) {
            m.releaseCompleted = moment.utc(m.releaseCompleted).local().format(dateFormat);
          }
          if (m.releaseSent) {
            m.releaseSent = moment(m.releaseSent).local().format(dateFormat);
          }
        });
        setFamilyMembers(res);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClientChange = () => {
    setSelectedMemberId(undefined);
    setFamilyMembers([]);
    setMembers([]);
    clientMemberForm.setFieldsValue({ memberId: undefined });
  };

  function getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const handlePreview = async (file: any) => {
    file.preview = file.preview ? file.preview : await getBase64(file.originFileObj);
    setPreviewVisible(true);
    setPreviewImage(file.preview);
  };

  const handleFrontUploadChange = ({ fileList }: { fileList: any }) => {
    setFileList([...fileList]);
  };

  const beforeFrontUpload = (file: UploadFile) => {
    setFileList([...fileList, file]);
    return false;
  };

  const refreshStatus = () => {
    setLoading(true);
    BalanceBillApiService.familyMembers(selectedMemberId)
      .then((res) => {
        const current = [...familyMembers];
        current.forEach((member) => {
          const lookup = res.find((m) => m.claimDocId === member.claimDocId);
          member.releaseStatus = lookup?.releaseStatus ?? null;
          member.releaseCompleted = lookup?.releaseCompleted
            ? moment.utc(lookup?.releaseCompleted).local().format(dateFormat)
            : null;
          member.releaseSent = lookup?.releaseSent ? moment.utc(lookup?.releaseSent).local().format(dateFormat) : null;
        });
        setFamilyMembers(current);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const sendAgreements = () => {
    const ids = checkedMembers
      .filter((m) => m.claimDocId && m.releaseStatus !== ReleaseStatuses.COMPLETE)
      .map((m) => m.claimDocId || '');
    if (ids.length <= 0) {
      return;
    }

    const formValues = props.form.getFieldsValue();
    const values: string[] = [];
    ids.forEach((id) => {
      if (formValues[`memberEmail_${id}`]) {
        values.push(`${id}|${formValues[`memberEmail_${id}`]}`);
      } else {
        values.push(id);
      }
    });

    setLoading(true);
    BalanceBillApiService.sendAgreements(values.join(','))
      .then(refreshStatus)
      .catch(() => {
        notification.error({ message: 'Failed to send agreements' });
        setLoading(false);
      });
  };

  const memberStatusRender = (member: FamilyMemberDTO, index: number) => {
    let statusRender = null;
    const tagStyle: CSSProperties = { float: 'right' };

    switch (member.releaseStatus) {
      case ReleaseStatuses.COMPLETE:
        statusRender = (
          <Tag style={tagStyle} color="green">
            Complete
          </Tag>
        );
        break;
      case ReleaseStatuses.SENT:
        statusRender = (
          <Tag style={tagStyle} color="yellow">
            Waiting On Signature
          </Tag>
        );
        break;
      default:
        statusRender = (
          <Tag style={tagStyle} color="red">
            Missing
          </Tag>
        );
        break;
    }

    return (
      <Collapse.Panel
        key={index}
        showArrow={false}
        className="collapse"
        collapsible="icon"
        header={
          <Row gutter={[12, 12]} style={{ width: '100%', flexWrap: 'nowrap' }} key={`member_${index}`}>
            <Col flex={0}>
              <Checkbox
                checked={checkedMembers.findIndex((c) => c.claimDocId === member.claimDocId) > -1}
                onChange={(e) => {
                  const current = [...familyMembers];
                  const m = current.find((c) => c.claimDocId === member.claimDocId);
                  current.forEach((m) => (m.checked = false));
                  if (!m) {
                    return;
                  }
                  m.checked = e.target.checked;
                  setFamilyMembers(current);
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {member.name}
              </Checkbox>
            </Col>
            <Col span={6} style={{ marginLeft: 'auto' }}>
              {statusRender}
            </Col>
          </Row>
        }
      >
        <>
          <Row style={{ width: '100%' }}>
            <Col>
              {member.releaseStatus && member.releaseStatus !== ReleaseStatuses.MISSING ? (
                <>
                  <Row>
                    <b>Release Sent:</b>&emsp;{member.releaseSent}
                  </Row>
                  <Row>
                    <b>Release Completed:</b>&emsp;{member.releaseCompleted}
                  </Row>
                </>
              ) : (
                <p>
                  An authorization and release must be signed {member.over18 ? 'by' : 'for'} this member before the
                  defense process can continue.
                </p>
              )}
              {member.releaseStatus === ReleaseStatuses.COMPLETE && (
                <>
                  <br />
                  <Row style={{ margin: 'auto' }}>
                    <Button
                      type="primary"
                      shape="round"
                      onClick={() => {
                        const current = [...familyMembers];
                        const m = current.find((m) => m.claimDocId === member.claimDocId);
                        if (!m) {
                          return;
                        }
                        m.releaseStatus = ReleaseStatuses.MISSING;
                        setFamilyMembers(current);
                      }}
                    >
                      Send New Release
                    </Button>
                  </Row>
                </>
              )}
              <br />
            </Col>
          </Row>
          {member.over18 && member.releaseStatus !== ReleaseStatuses.COMPLETE && (
            <Row>
              <Col>
                <Form.Item
                  name={`memberEmail_${member.claimDocId}`}
                  label={`Send Email For ${member.name} to:`}
                  initialValue={member.email}
                >
                  <Input allowClear={true}></Input>
                </Form.Item>
              </Col>
            </Row>
          )}
        </>
      </Collapse.Panel>
    );
  };

  return (
    <Content>
      <Spin spinning={loading}>
        <Form form={clientMemberForm} layout="vertical">
          {role !== Role.MEMBER && (
            <Row gutter={4}>
              <Col xs={24} md={24} lg={12}>
                <Form.Item name="clientId" label="Client">
                  <Select
                    optionFilterProp="children"
                    allowClear
                    showSearch
                    placeholder="Select a client..."
                    onChange={() => handleClientChange()}
                  >
                    {clients.map((c) => (
                      <Option key={c.id} value={c.id}>
                        {c.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={24} lg={12}>
                <Form.Item name="memberId" label="Member Last Name">
                  <Select
                    optionFilterProp="children"
                    allowClear
                    showSearch
                    placeholder="Search for a member..."
                    onSearch={(v) => handleSearchMembers(v)}
                    onSelect={(v) => setSelectedMemberId(v)}
                    onClear={() => handleClearMembers()}
                    notFoundContent={loadingMembers ? <Spin spinning={true} /> : null}
                  >
                    {members.map((m) => (
                      <Option key={m.memberId} value={m.memberId}>
                        {m.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          )}
        </Form>
        {!loading && familyMembers?.length <= 0 ? (
          <>
            {role !== Role.MEMBER ? (
              'Please select a member.'
            ) : (
              <>
                No members found. Please contact support.
                <ClaimDocMemberSupport />
              </>
            )}
          </>
        ) : (
          <Form
            form={props.form}
            onFinish={async (values) => {
              if (!props.onSave) {
                return;
              }

              if (fileList.length == 0) {
                notification.error({ message: 'Please upload your Balance Bill.' });
                return;
              }

              values.files = fileList.map((f) => f.originFileObj);
              values.claimDocIdsOnBill = checkedMembers.map((m) => m.claimDocId);

              props.onSave(values);
            }}
            layout="vertical"
          >
            <Row gutter={[12, 12]}>
              <Col span={10}>
                <Space direction="horizontal">
                  <b>Members Being Billed</b>
                  <Tooltip title="Refresh Status">
                    <Button shape="circle" icon={<ReloadOutlined />} onClick={refreshStatus} />
                  </Tooltip>
                </Space>
              </Col>
              <Col style={{ marginLeft: 'auto', marginRight: '6px' }}>
                <Space direction="horizontal" style={{ height: '100%' }}>
                  <b>Authorization Status</b>
                </Space>
              </Col>
            </Row>
            <Collapse activeKey={checkedMembers.map((m) => familyMembers.indexOf(m))}>
              {familyMembers.map(memberStatusRender)}
            </Collapse>

            {checkedMembers.length > 0 && checkedMembers.length !== completeMembers.length ? (
              <Alert
                type="error"
                description={
                  <>
                    <p>
                      A ClaimDOC Member Authorization Agreement and/or HIPAA Release has not yet been recorded for you
                      or your dependents. For Balance Bill services to proceed, an authorization and release need to be
                      signed and forwarded to ClaimDOC. Please note that each patient over the age of 18 is required to
                      sign their own authorization form.
                      <br />
                      <br />
                      Please click the button below to have the CDMAA and/or HIPAA Release drafted and sent for
                      signature to the email address indicated above.
                    </p>
                    <Button type="primary" shape="round" size="large" onClick={sendAgreements}>
                      Send Missing Agreements
                    </Button>
                  </>
                }
                style={{ marginTop: 15 }}
              />
            ) : null}
            <Row style={{ marginTop: 15 }}>
              <Col flex={0}>
                <Form.Item label="Upload your Balance Bill">
                  <Upload
                    accept=".jpg, .JPG, .jpeg, .JPEG, .pdf, .PDF, .png, .PNG"
                    maxCount={10}
                    listType="picture-card"
                    multiple
                    fileList={fileList}
                    beforeUpload={beforeFrontUpload}
                    onChange={handleFrontUploadChange}
                    onPreview={handlePreview}
                    disabled={formDisabled}
                  >
                    {<PlusOutlined />}
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <p> Be sure to select above the patient for the Balance Bill being uploaded. </p>
            </Row>
            <Row style={{ marginTop: 15 }}>
              <Col span={24}>
                <Form.Item name="note" label="Notes">
                  <Input.TextArea allowClear={true} disabled={formDisabled}></Input.TextArea>
                </Form.Item>
              </Col>
            </Row>
            <div style={{ display: 'none' }}>
              <Image.PreviewGroup
                preview={{ visible: previewVisible, onVisibleChange: (vis) => setPreviewVisible(vis) }}
              >
                <Image src={previewImage} />
              </Image.PreviewGroup>
            </div>
            <Button
              type="primary"
              shape="round"
              htmlType="submit"
              size="large"
              disabled={formDisabled}
              title={formDisabled ? 'Releases for all members on bill must be complete before submitting.' : undefined}
            >
              Submit Balance Bill
            </Button>
          </Form>
        )}
      </Spin>
    </Content>
  );
};

export default BalanceBillForm;
