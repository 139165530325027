import { DownOutlined } from '@ant-design/icons';
import { Col, Input, Layout, Row, Spin, Table, Tree, List, Alert, Typography } from 'antd';
import { DataNode } from 'antd/lib/tree';
import React, { useEffect, useState } from 'react';
import LookupsApiService from '../../api/LookupsApiService';
import PermissionService from '../../api/PermissionApiService';
import PageTitle from '../../components/shared/PageTitle';
import PermissionAuditDTO from '../../models/PermissionAuditDTO';
import PermissionGroupDetailDTO from '../../models/PermissionGroupDetailDTO';

const PermissionAuditPage = () => {
  const [loading, setLoading] = useState(true);
  const [permissions, setPermissions] = useState<PermissionAuditDTO[]>([]);
  const [permissionGroups, setPermissionGroups] = useState<PermissionGroupDetailDTO[]>([]);
  const [filter, setFilter] = useState<string | undefined>();

  useEffect(() => {
    LookupsApiService.getPermissionGroups().then((res) => setPermissionGroups(res));
    PermissionService.permissionAudit().then((res) => {
      setPermissions(res);
      setLoading(false);
    });
  }, []);

  const data = filter
    ? permissions.filter(
        (p) =>
          ((p.name ?? '\t') + p.description).toLowerCase().includes(filter) ||
          p.uniqueUsers.filter((u) => u.toLowerCase().includes(filter)).length > 0 ||
          Object.keys(p.permissionGroupUsers)?.filter((k) =>
            permissionGroups.find((p) => k === p.id.toString() && p.name && p.name?.toLowerCase().includes(filter))
          ).length > 0
      )
    : permissions;

  return (
    <Layout.Content className="content-padding">
      <Row gutter={24}>
        <Col flex={0}>
          <PageTitle title="Permission Audit" />
        </Col>
        <Col flex={1} style={{ textAlign: 'right' }}>
          <Input.Search onSearch={(v) => setFilter(v ? v.toLowerCase() : undefined)} allowClear />
        </Col>
      </Row>
      <Spin spinning={loading}>
        {filter ? (
          <Alert
            type="warning"
            style={{ marginBottom: 15 }}
            message={<>Filter active. Filtering permissions, groups, &amp; users.</>}
          />
        ) : null}
        <Table
          columns={[
            {
              title: 'Permission',
              dataIndex: 'name',
            },
            {
              title: 'Description',
              dataIndex: 'description',
            },
            {
              title: 'Permission Groups',
              dataIndex: 'groupCount',
              render: (v, r) => Object.keys(r.permissionGroupUsers ?? {}).length,
            },
            {
              title: 'User Count',
              dataIndex: 'userCount',
              render: (v, r) => (r.uniqueUsers ?? []).length,
            },
          ]}
          rowKey="id"
          expandable={{
            // eslint-disable-next-line react/display-name
            expandedRowRender: (record) => {
              const treeData: DataNode[] = Object.keys(record.permissionGroupUsers)?.map((k) => {
                return {
                  title: permissionGroups.find((g) => g.id.toString() === k)?.name,
                  key: k,
                  children: record.permissionGroupUsers[k]?.map((u: any) => {
                    return { title: u } as DataNode;
                  }) as DataNode[],
                };
              });

              return (
                <Row gutter={12}>
                  <Col span={12}>
                    <Typography.Title level={4}>Groups &amp; Users</Typography.Title>
                    <Tree selectable={false} showIcon={false} switcherIcon={<DownOutlined />} treeData={treeData} />
                  </Col>
                  <Col span={12}>
                    <Typography.Title level={4}>Unique Users</Typography.Title>
                    <List size="small" dataSource={record.uniqueUsers} renderItem={(v) => <List.Item>{v}</List.Item>} />
                  </Col>
                </Row>
              );
            },
            rowExpandable: (record) => record.name !== 'Not Expandable',
            expandRowByClick: true,
          }}
          dataSource={data}
          pagination={false}
        />
      </Spin>
    </Layout.Content>
  );
};

export default PermissionAuditPage;
