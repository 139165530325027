import * as React from 'react';
import HistoryUtil from '../utils/HistoryUtil';

// NOTE: Until react provides a hook for componentDidCatch, this has to stay a class component.
//   or https://www.npmjs.com/package/use-error-boundary could be investigated to implement the error boundary.

interface UnexpectedErrorState {
  error: Error | null;
  errorInfo: { componentStack: string } | null;
}

class UnexpectedError extends React.Component<unknown, UnexpectedErrorState> {
  constructor(props: unknown) {
    super(props);
    const defaultState = { error: null, errorInfo: null };
    this.state = defaultState;
    HistoryUtil.listen(() => {
      if (this.state.error) {
        console.log('Error history change!');
        this.setState(defaultState);
      }
    });
  }
  static getDerivedStateFromError(error: Error | null) {
    return { error };
  }

  componentDidCatch(error: Error | null, errorInfo: { componentStack: string }) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });

    // You can also log error messages to an error reporting service here
  }
  render() {
    if (this.state.errorInfo) {
      // Error path
      let msg = this.state.error && this.state.error.message && this.state.error.message.toString();
      if (!msg) {
        msg = this.state.error && this.state.error.toString();
      }
      return (
        <div style={{ padding: '24px' }}>
          <h2>An unexpected error has occurred.</h2>
          {msg}
          {DEBUG ? (
            <div style={{ whiteSpace: 'pre-wrap' }}>
              <br />
              {this.state.errorInfo.componentStack}
            </div>
          ) : null}
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default UnexpectedError;
