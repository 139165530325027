import { DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Empty, Form, FormInstance, Input, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import LookupService from '../../../api/LookupsApiService';
import AccessRole from '../../../consts/AccessRole';
import PermissionGroupDetailDTO from '../../../models/PermissionGroupDetailDTO';
import UserDetailDTO from '../../../models/UserDetailDTO';
import PermissionGroupHelperButton from '../../shared/EntityAccess/PermissionGroupHelperButton';
interface UserDetailSecurityProps {
  details: UserDetailDTO;
  form: FormInstance<any>;
  editable: boolean;
}

const UserDetailSecurity = (props: UserDetailSecurityProps) => {
  const [permissionGroups, setPermissionGroups] = useState<PermissionGroupDetailDTO[]>([]);

  const currentIds: number[] = props.form.getFieldValue('claimDocPermissionGroups')?.map((p: any) => p.id) ?? [];
  const addGroupList = permissionGroups.filter((p) => !currentIds.includes(p.id));

  const addEntry = (add: (defaultValue?: any, insertIndex?: number | undefined) => void) => {
    const id = props.form.getFieldValue('claimDocPermissionGroups')['newEntryId'];
    if (id) {
      const newUser = addGroupList.find((u) => u.id === id);
      if (newUser) {
        add({ id: id, name: newUser.name });
      }
    }
  };

  useEffect(() => {
    LookupService.getPermissionGroups(null, AccessRole.CLAIMDOC).then((groups) => {
      setPermissionGroups(groups);
    });
  }, []);

  const getAllPermissionGroupIds = () => {
    const permissionGroupIds: number[] = [];
    props.form.getFieldValue('claimDocPermissionGroups').forEach((x: any) => permissionGroupIds.push(x.id ?? null));

    return permissionGroupIds;
  };

  return (
    <>
      <Form.List name="claimDocPermissionGroups">
        {(fields, { add, remove }) => (
          <>
            <Row align="bottom" gutter={12}>
              {props.editable ? (
                <>
                  <Col>
                    <Form.Item name="newEntryId" label="Permission Group">
                      <Select placeholder="Search" showSearch={true} style={{ width: 500 }} optionFilterProp="children">
                        {addGroupList.map((x) => (
                          <Select.Option key={x.id} value={x.id}>
                            {x.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item label="&nbsp;">
                      <Button onClick={() => addEntry(add)} type="primary" shape="round">
                        Add
                      </Button>
                    </Form.Item>
                  </Col>
                </>
              ) : null}
              <Col>
                <Form.Item label="&nbsp;">
                  <PermissionGroupHelperButton permissionGroupIds={getAllPermissionGroupIds()}>
                    View Permission Groups
                  </PermissionGroupHelperButton>
                </Form.Item>
              </Col>
            </Row>

            {fields.map(({ key, name, ...restField }) => {
              return (
                <Row key={key} gutter={12} align="middle">
                  <Col xs={5}>
                    <Form.Item hidden {...restField} name={[name, 'id']}>
                      <Input readOnly={true} />
                    </Form.Item>
                    <Form.Item {...restField} name={[name, 'name']}>
                      <Input readOnly={true} />
                    </Form.Item>
                  </Col>
                  {/* <Col xs={8}>
                            <Form.Item {...restField} name={[name, 'groupId']} fieldKey={[fieldKey, 'groupId']}>
                              <Select allowClear={true} placeholder="Select Permission Group">
                                {permissionGroups.map((p) => (
                                  <Select.Option key={p.id} value={p.id}>
                                    {p.name}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col> */}
                  <Col>
                    {props.editable ? (
                      <Form.Item>
                        <DeleteOutlined onClick={() => remove(name)} />
                      </Form.Item>
                    ) : null}
                  </Col>
                </Row>
              );
            })}
            {fields.length == 0 ? <Empty style={{ maxWidth: 300 }} /> : null}
            <p>Note: Some permission groups may not be able to be removed due to them being unmanaged.</p>
          </>
        )}
      </Form.List>
      <br />
    </>
  );
};

export default UserDetailSecurity;
