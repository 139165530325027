import moment from 'moment';
// All globals must be added to the custom_typings\index.ts file so typescript knows about them.

const currency5 = new Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 5,
  minimumFractionDigits: 5,
}).format;
const currency2 = new Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
}).format;
const quantity0 = new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
}).format;
const quantity2 = new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
}).format;
const quantity5 = new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 5,
  minimumFractionDigits: 5,
}).format;

export default function addGlobals() {
  // tslint:disable-next-line:no-string-literal
  global['FORMAT'] = {
    currency5: (value: number) => currency5(roundTo(value, 5)),
    currency2: (value: number) => currency2(roundTo(value, 2)),
    quantity0: (value: number) => quantity0(roundTo(value, 0)),
    quantity2: (value: number) => quantity2(roundTo(value, 2)),
    quantity5: (value: number) => quantity5(roundTo(value, 5)),
  };

  // tslint:disable-next-line:no-string-literal
  global['tryParseInt'] = function (value: any, defaultValue: number) {
    let retValue: number = defaultValue;
    if (value != null) {
      if (!isNaN(value) && typeof value === 'string' && value !== '') {
        retValue = parseInt(value, 10);
      } else if (typeof value === 'number') {
        retValue = Math.floor(value);
      }
      if (!isNaN(retValue)) {
        return retValue;
      }
    }
    return defaultValue;
  };

  // tslint:disable-next-line:no-string-literal
  global['roundTo'] = function (num: number, digits: number) {
    digits = digits || 0;
    const multiplicator = Math.pow(10, digits);
    num = Math.round((num + 0.000001) * multiplicator) / multiplicator;
    return num;
  };

  // tslint:disable-next-line:no-string-literal
  global['zeroPad'] = function (val: any, digits: number) {
    return ('0'.repeat(digits) + val).substr(-digits, digits);
  };

  // tslint:disable-next-line:no-string-literal
  global['stripOffset'] = function (val: moment.Moment | string | null | undefined) {
    let str = '';
    if (val == null) {
      return val;
    } else if (moment.isMoment(val)) {
      str = val.format();
    } else {
      str = val;
    }
    return str.replace(/^(.+)(((-|\+)\d?\d:\d\d)|[zZ])$/, '$1');
  };

  // tslint:disable-next-line:no-string-literal
  global['deepEqual'] = function (a: any, b: any) {
    const keys1 = Object.keys(a);
    const keys2 = Object.keys(b);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (const key of keys1) {
      const val1 = a[key];
      const val2 = b[key];
      const areObjects = val1 instanceof Object && val2 instanceof Object;
      if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
        return false;
      }
    }
    return true;
  };
}
