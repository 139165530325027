import { Button, notification, Row, Col, Space } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import DataManagementApiService from '../../api/DataManagementApiService';
import ProviderService from '../../api/ProviderApiService';
import DataTable, { DataTableColumnProps, FilterType } from '../../components/shared/DataTable/DataTable';
import DataTableColumnUtil from '../../components/shared/DataTable/DataTableColumnUtil';
import PageTitle from '../../components/shared/PageTitle';
import PermissionGuard from '../../components/shared/PermissionGuard';
import Permission from '../../consts/Permission';
import Routes from '../../consts/Routes';
import ProviderDTO from '../../models/ProviderDTO';
import TableRequestDTO from '../../models/TableRequestDTO';
import TableResponseDTO from '../../models/TableResponseDTO';
import HistoryUtil from '../../utils/HistoryUtil';
import MobileUtil from '../../utils/MobileUtil';

const ProvidersListPage = () => {
  const [tableData, setTableData] = useState<ProviderDTO[]>([]);
  const [loading, setLoading] = useState(false);
  const dataTableRef = React.useRef<DataTable<any>>(null);

  const isMobile = () => MobileUtil.getMediaQuery()?.matches;

  const tableColumns = [
    { ...DataTableColumnUtil.Number('Id', 'claimDocId'), filterType: FilterType.NONE },
    { ...DataTableColumnUtil.Text('Name', 'name'), filterType: FilterType.NONE },
    { ...DataTableColumnUtil.Text('Number', 'npi'), filterType: FilterType.NONE },
    { ...DataTableColumnUtil.Text('Specialty', 'specialty'), filterType: FilterType.NONE },
    {
      title: '',
      dataIndex: '',
      width: '40px',
      // eslint-disable-next-line react/display-name
      render: (rowData: ProviderDTO) => (
        <Link
          to={Routes.generate(Routes.ADMIN_PROVIDER_DETAILS, {
            id: rowData.id,
          })}
        >
          <Button shape="round" ghost>
            Details
          </Button>
        </Link>
      ),
    },
  ] as DataTableColumnProps<any>[];

  const syncProviders = () => {
    setLoading(true);
    DataManagementApiService.executeProviderSync()
      .then(() => {
        notification.success({ message: 'Sync Complete' });
      })
      .catch(() => {
        notification.error({ message: 'Sync Failed' });
      })
      .finally(() => {
        setLoading(false);
        dataTableRef.current?.refresh();
      });
  };

  const goToDetails = (id: number) => {
    HistoryUtil.push(
      Routes.generate(Routes.ADMIN_PROVIDER_DETAILS, {
        id: id,
      })
    );
  };

  const fetchTableData = (
    requestState: TableRequestDTO,
    checkEcho: () => boolean,
    callback: (response: TableResponseDTO<ProviderDTO>) => void
  ) => {
    setLoading(true);

    ProviderService.getDataTable(requestState)
      .then((results) => {
        if (!checkEcho()) {
          return;
        }
        setLoading(false);
        setTableData(results.results || []);

        callback(results);
      })
      .catch((error: any) => {
        setLoading(false);
        notification.error({
          message: error.message,
          description: error.description,
        });
      });
  };

  return (
    <Content className="content-padding">
      <Row>
        <Col xs={24}>
          <Row className="content-header" justify="space-between">
            <Col xs={24} lg={10} xl={13} xxl={14}>
              <PageTitle title="Providers" />
            </Col>
            <Col>
              <Space direction="horizontal">
                <PermissionGuard permissionId={Permission.ETL_TPA_EXECUTESYNC}>
                  <Button type="primary" shape="round" disabled={loading} onClick={syncProviders}>
                    Sync Providers
                  </Button>
                </PermissionGuard>
              </Space>
            </Col>
          </Row>
          <DataTable
            ref={dataTableRef}
            serverSide={true}
            globalSearch={true}
            globalSearchPlaceholder="Search Providers..."
            tableProps={{
              rowKey: 'id',
              size: 'large',
              loading: loading,
              sortDirections: ['ascend', 'descend'],
              scroll: { x: 575 },
              onRow: (record: ProviderDTO) => {
                return {
                  onDoubleClick: () => {
                    if (!isMobile()) {
                      goToDetails(record.id);
                    }
                  },
                  onClick: () => {
                    if (isMobile()) {
                      goToDetails(record.id);
                    }
                  },
                };
              },
            }}
            columns={tableColumns}
            data={tableData}
            fetchData={fetchTableData}
            styleOptions={{
              alternatingRowHighlight: true,
            }}
          />
        </Col>
      </Row>
    </Content>
  );
};

export default ProvidersListPage;
