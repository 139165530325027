import BaseApi from './BaseApi';
import LookupTableDTO from '../models/LookupTableDTO';
import LookupsApiBaseService from './LookupsApiBaseService';

export class LookupsApiBase extends BaseApi {
  protected fromLocal(cache: LookupTableDTO[], name: string, include?: number | null): Promise<LookupTableDTO[]> {
    let promise = Promise.resolve<LookupTableDTO[]>([]);
    if (include != null && !cache.find((a) => a.id === include)) {
      promise = LookupsApiBaseService.getOne(name, include).then((r) => {
        if (!cache.find((a) => a.id === include)) {
          cache.push(r);
        }
        return cache;
      });
    } else {
      promise = Promise.resolve<LookupTableDTO[]>(cache);
    }
    return promise.then((r) => this.filterAndSort(r, include));
  }
  protected filterAndSort(r: LookupTableDTO[], include?: number | null): LookupTableDTO[] {
    return r
      .filter((f) => f.isActive || f.id === include)
      .sort((a, b) => {
        let s = a.displayOrder - b.displayOrder;
        if (s === 0) {
          s = a.name?.localeCompare(b.name ?? '') ?? 0;
        }
        return s;
      });
  }
}
export default LookupsApiBase;
