import { Button, Modal, notification, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useState } from 'react';
import BrokerApiService from '../../../api/BrokerApiService';
import ParentSecurityUserSettingsDTO from '../../../models/ParentSecurityUserSettingsDTO';
import TableRequestDTO from '../../../models/TableRequestDTO';
import TableResponseDTO from '../../../models/TableResponseDTO';
import DataTable, { FilterType } from '../../shared/DataTable/DataTable';
import DataTableColumnUtil from '../../shared/DataTable/DataTableColumnUtil';
import InviteUsers from '../../shared/InviteUsers';
import UserApiService from '../../../api/UserApiService';
import InviteUsersEditForm from '../../shared/InviteUsersEditForm';
interface BrokerInviteUsersProps {
  brokerId: number;
}

const BrokerInviteUsers = (props: BrokerInviteUsersProps) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<ParentSecurityUserSettingsDTO[]>([]);
  const [selectedUser, setSelectedUser] = useState<ParentSecurityUserSettingsDTO>();
  const [openModal, setOpenModal] = useState(false);
  const dtRef = React.useRef<DataTable<ParentSecurityUserSettingsDTO>>(null);
  const [editForm] = useForm();

  const submit = (values: any) => {
    if (values.accounts && values.accounts.length > 0) {
      setLoading(true);
      BrokerApiService.inviteUsers(props.brokerId, values.accounts)
        .then(() => {
          notification.success({ message: 'Users Invited' });
          form.resetFields();
          dtRef.current?.refresh();
        })
        .catch(() => {
          notification.error({ message: 'Error Inviting Users' });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const fetchTableData = (
    requestState: TableRequestDTO,
    checkEcho: () => boolean,
    callback: (response: TableResponseDTO<ParentSecurityUserSettingsDTO>) => void
  ) => {
    setLoading(true);
    BrokerApiService.simpleUserList(props.brokerId, requestState)
      .then((results) => {
        if (!checkEcho()) {
          return;
        }
        setUsers(results.results || []);
        setLoading(false);
        callback(results);
      })
      .catch((error: any) => {
        setLoading(false);
        notification.error({
          message: error.message,
          description: error.description,
        });
      });
  };

  const submitInvitedUserChange = () => {
    editForm.validateFields().then((model) => {
      UserApiService.saveInvitedUserEdit(model)
        .then((result) => {
          if (!result) {
            notification.error({ message: 'Edit Failed', description: 'An error occurred while saving.' });
          } else {
            dtRef.current?.refresh();
          }
        })
        .catch(() => {
          setLoading(false);
          notification.error({ message: 'Edit Failed', description: 'An error occurred while saving.' });
        })
        .finally(() => {
          setLoading(false);
          setOpenModal(false);
          setSelectedUser(undefined);
        });
    });
  };

  return (
    <Spin spinning={loading}>
      <Modal
        open={openModal}
        title="Edit Invited User"
        onCancel={() => {
          setSelectedUser(undefined);
          setOpenModal(false);
        }}
        onOk={submitInvitedUserChange}
        okText="Save"
      >
        <InviteUsersEditForm form={editForm} initialValues={selectedUser} />
      </Modal>
      <InviteUsers form={form} submit={submit} showAdmin={false} />
      <hr />
      <DataTable
        title="Current Users"
        ref={dtRef}
        serverSide={true}
        globalSearch={true}
        globalSearchPlaceholder="Search Users"
        tableProps={{
          rowKey: 'id',
          loading: loading,
        }}
        columns={[
          DataTableColumnUtil.Text<ParentSecurityUserSettingsDTO>('First Name', 'firstName'),
          DataTableColumnUtil.Text<ParentSecurityUserSettingsDTO>('Last Name', 'lastName'),
          DataTableColumnUtil.Text<ParentSecurityUserSettingsDTO>('Email', 'username'),
          DataTableColumnUtil.Text<ParentSecurityUserSettingsDTO>('Phone Number', 'phoneNumber'),
          DataTableColumnUtil.BooleanYesNo<ParentSecurityUserSettingsDTO>(
            'MFA Required',
            'requireMfa',
            undefined,
            FilterType.BooleanRadio,
            { align: 'left' }
          ),
          DataTableColumnUtil.BooleanYesNo<ParentSecurityUserSettingsDTO>(
            'Registered',
            'registered',
            undefined,
            FilterType.BooleanRadio,
            { align: 'left' }
          ),
          DataTableColumnUtil.DateTime<ParentSecurityUserSettingsDTO>('Last Logged In', 'lastLoggedInOn', undefined, {
            align: 'left',
          }),
          {
            title: '',
            dataIndex: '',
            width: '40px',
            // eslint-disable-next-line react/display-name
            render: (rowData: ParentSecurityUserSettingsDTO) => (
              <Button
                shape="round"
                ghost
                onClick={() => {
                  setSelectedUser(rowData);
                  setOpenModal(true);
                }}
                disabled={rowData.registered}
              >
                Edit
              </Button>
            ),
          },
        ]}
        data={users}
        fetchData={fetchTableData}
        styleOptions={{
          alternatingRowHighlight: true,
        }}
      />
    </Spin>
  );
};

export default BrokerInviteUsers;
