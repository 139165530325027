import { useContext } from 'react';
import { AuthenticationContext } from '../auth/AuthenticationContext';

function useHasPermission() {
  const authContext = useContext(AuthenticationContext);

  return (id: number) => {
    return authContext?.user?.userHasPermission(id) ?? false;
  };
}

export default useHasPermission;
