import React, { useContext } from 'react';
import { Redirect } from 'react-router';
import Routes from '../consts/Routes';
import HistoryUtil from '../utils/HistoryUtil';
import { AuthenticationContext } from './AuthenticationContext';
import UnAuthorizedComponent from './Unauthorized';
/* eslint-disable react/display-name */

interface AuthorizeProps {
  requiredPermissions?: number[] | null;
}

const Authorize = (props: React.PropsWithChildren<AuthorizeProps>) => {
  if (!props.children) {
    throw 'Missing child component(s).';
  }

  const authContext = useContext(AuthenticationContext);
  const user = authContext.user;

  if (user?.isGuestAccount ?? false) {
    return <Redirect to={Routes.generate(Routes.LOGIN)} />;
  }

  if (!authContext.user) {
    const url = HistoryUtil.location.pathname + HistoryUtil.location.search;
    return <Redirect to={Routes.generate(Routes.LOGIN, {}, { returnUrl: url })} />;
  }

  if (!authContext.user.accessRoleId) {
    const url = HistoryUtil.location.pathname + HistoryUtil.location.search;
    return <Redirect to={Routes.generate(Routes.GATE, {}, { returnUrl: url })} />;
  }

  let authorized = false;

  if ((props.requiredPermissions?.length ?? 0) <= 0) {
    authorized = true;
  } else {
    let hasAll = false;
    for (let i = 0, len = props.requiredPermissions?.length ?? 0; i < len; i++) {
      hasAll = (props.requiredPermissions && authContext.user.userHasPermission(props.requiredPermissions[i])) ?? false;
      if (!hasAll) {
        break;
      }
    }
    authorized = hasAll;
  }

  if (authorized) {
    return <>{props.children}</>;
  } else {
    return <UnAuthorizedComponent />;
  }
};

export default Authorize;
