import { Button, Form, FormInstance, Select } from 'antd';
import React, { useEffect } from 'react';
import LookupTableDTO from '../../models/LookupTableDTO';
import Language from '../../consts/Language';

const { Option } = Select;

interface UserPreferencesFormProps {
  form: FormInstance<any>;
  languages: LookupTableDTO[];
  onSubmit: () => void;
  loading: boolean;
  initialValues?: any;
}

const UserPreferencesForm: React.FC<UserPreferencesFormProps> = (props) => {
  useEffect(() => {
    props.form.resetFields();
  }, []);

  const initialValues = {
    languageId: Language.ENGLISH,
    ...props.initialValues,
  };

  return (
    <Form form={props.form} initialValues={initialValues} layout="vertical" onFinish={props.onSubmit}>
      <Form.Item
        name="languageId"
        label="Language"
        rules={[{ required: true, message: 'Language is required' }]}
        tooltip="Where available"
      >
        <Select>
          {props.languages.map((l) => (
            <Option key={l.id} value={l.id}>
              {l.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" shape="round" block disabled={props.loading}>
          Change Preferences
        </Button>
      </Form.Item>
    </Form>
  );
};

export default UserPreferencesForm;
