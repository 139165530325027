import AccountApiService from '../api/AccountApiService';

export const ValidatePassword = async (e: any): Promise<string[] | undefined> => {
  if (e.target.value !== null && e.target.value !== '') {
    const res = await AccountApiService.validatePassword(e.target.value);

    if (res.succeeded) {
      return undefined;
    }

    return res.errors;
  }

  return undefined;
};
