import React, { useState } from 'react';
import moment from 'moment';
import { Col, Input, Row } from 'antd';
import useFocus from '../../hooks/useFocus';

interface SeparatedDatePickerProps {
  value?: moment.Moment | null;
  onChange?: (value?: moment.Moment | null) => void;
  placeholder?: boolean;
  disabled?: boolean;
  longMonth?: boolean;
}

const SeparatedDatePicker = (props: SeparatedDatePickerProps) => {
  const [monthVal, setMonthVal] = useState<string | undefined>(props.value?.format('M'));
  const [dayVal, setDayVal] = useState<string | undefined>(props.value?.format('D'));
  const [yearVal, setYearVal] = useState<string | undefined>(props.value?.format('YYYY'));

  const [dayRef, setDayFocus] = useFocus();
  const [yearRef, setYearFocus] = useFocus();

  const monthChanged = (value?: string) => {
    if (value && !/^\d+$/.test(value)) {
      return;
    }
    setMonthVal(value ?? undefined);
    triggerChange({ month: value });
    if ((value ?? '').length == 2) {
      setDayFocus();
    }
  };
  const dayChanged = (value?: string) => {
    if (value && !/^\d+$/.test(value)) {
      return;
    }
    setDayVal(value ?? undefined);
    triggerChange({ day: value });
    if ((value ?? '').length == 2) {
      setYearFocus();
    }
  };

  const yearChanged = (value?: string) => {
    if (value && !/^\d+$/.test(value)) {
      return;
    }
    setYearVal(value ?? undefined);
    triggerChange({ year: value });
  };

  const triggerChange = (changedValue: { month?: string; day?: string; year?: string }) => {
    if (props.onChange) {
      const year = changedValue.year ?? yearVal;
      const month = changedValue.month ?? monthVal;
      const day = changedValue.day ?? dayVal;

      const dateStr = `${year}-${month}-${day}`;
      const res = moment(dateStr, 'YYYY-M-D');
      if (!moment.isMoment(res)) {
        return;
      }

      props.onChange(res);
    }
  };

  return (
    <Input.Group>
      <Row gutter={8}>
        <Col span={7}>
          <Input
            disabled={props.disabled}
            onChange={(e) => monthChanged(e.target.value)}
            placeholder={props.placeholder === true ? 'MM' : undefined}
            value={monthVal}
          />
        </Col>
        <Col span={7}>
          <Input
            disabled={props.disabled}
            ref={dayRef}
            onChange={(e) => dayChanged(e.target.value)}
            placeholder={props.placeholder === true ? 'DD' : undefined}
            value={dayVal}
          />
        </Col>
        <Col span={10}>
          <Input
            placeholder={props.placeholder === true ? 'YYYY' : undefined}
            ref={yearRef}
            onChange={(e) => yearChanged(e.target.value)}
            disabled={props.disabled}
            value={yearVal}
          />
        </Col>
      </Row>
    </Input.Group>
  );
};

export default SeparatedDatePicker;
