import React, { useContext, useEffect } from 'react';
import {
  AzureMap,
  AzureMapDataSourceProvider,
  AzureMapFeature,
  AzureMapHtmlMarker,
  AzureMapLayerProvider,
  AzureMapPopup,
  AzureMapsContext,
  AzureMapsProvider,
  ControlOptions,
  IAzureCustomControls,
  IAzureMapImageSprite,
  IAzureMapOptions,
  IAzureMapsContextProps,
} from 'react-azure-maps';
import { AuthenticationType } from 'azure-maps-control';
import { CustomMapControl } from './CustomMapControl';

const BubbleDataSourceProviderId = 'POINT_DATA';
const MarkerLayerProviderId = 'MARKER_LAYER';

export interface CustomControl {
  buttonText: string;
  action: (map: any) => void;
}

export interface PopupOptions {
  visible: boolean;
  position: number[];
  content: any;
}

export interface MapMarker {
  id: string;
  position: number[];
  icon?: string;
}

interface AzureMapProps {
  subscriptionKey: string;
  customControls?: CustomControl[];
  showZoomControl?: boolean;
  markers?: MapMarker[];
  userLocation?: number[];
  mapBounds?: number[];
  searchRadius?: number;
  customIcons?: IAzureMapImageSprite[];
  markerClicked?: (target: any) => void;
  popup?: PopupOptions;
  zoomTo?: { position: number[]; level: number };
}

const MapController = (props: AzureMapProps) => {
  return (
    <>
      <AzureMapsProvider>
        <Map {...props} />
      </AzureMapsProvider>
    </>
  );
};

const Map = (props: AzureMapProps) => {
  const { mapRef } = useContext<IAzureMapsContextProps>(AzureMapsContext);

  const options: IAzureMapOptions = {
    authOptions: {
      authType: AuthenticationType.subscriptionKey,
      subscriptionKey: props.subscriptionKey,
    },
  };

  useEffect(() => {
    if (mapRef && props.mapBounds) {
      mapRef.setCamera({ bounds: props.mapBounds });
    }
  }, [props.mapBounds]);

  useEffect(() => {
    if (props.zoomTo) {
      mapRef?.setCamera({
        center: props.zoomTo.position,
        zoom: props.zoomTo.level,
        type: 'ease',
        duration: 800,
      });
    }
  }, [props.zoomTo]);

  const controls = [];
  if (props.showZoomControl) {
    controls.push({
      controlName: 'ZoomControl',
      options: { position: 'bottom-right' } as ControlOptions,
    });
  }

  const customControls: IAzureCustomControls[] = [];
  if (props.customControls) {
    props.customControls.forEach((c) => {
      customControls.push({
        control: new CustomMapControl(c.buttonText, () => {
          c.action(mapRef);
        }),
        controlOptions: { position: 'bottom-left' } as ControlOptions,
      });
    });
  }

  const getLocationFeatures = () => {
    const features = [];
    if (props.userLocation) {
      features.push(
        <AzureMapHtmlMarker
          key={'user_location'}
          markerContent={
            <div>
              <div className="user-location">
                <div className="user-location-center" />
              </div>
            </div>
          }
          options={{ position: props.userLocation }}
        />
      );

      if (props.searchRadius) {
        features.push(
          <AzureMapFeature
            key={`search_radius_${props.searchRadius}`}
            id={`search_radius_${props.searchRadius}`}
            type="Point"
            variant="shape"
            coordinate={props.userLocation}
            setCoords={props.userLocation}
            properties={{ radius: props.searchRadius, subType: 'Circle' }}
          />
        );
      }
    }

    return features;
  };

  const clusterClicked = (e: any) => {
    if (e && e.shapes && e.shapes.length > 0 && e.shapes[0].properties.cluster) {
      //Get the clustered point from the event.
      const cluster = e.shapes[0];

      //Get the cluster expansion zoom level. This is the zoom level at which the cluster starts to break apart.
      e.map.sources
        .getById(BubbleDataSourceProviderId)
        .getClusterExpansionZoom(cluster.properties.cluster_id)
        .then(function (zoom: any) {
          //Update the map camera to be centered over the cluster.
          e.map.setCamera({
            center: cluster.geometry.coordinates,
            zoom: zoom,
            type: 'ease',
            duration: 200,
          });
        });
    }
  };

  const markerClicked = (e: any) => {
    if (!props.markerClicked) {
      return;
    }
    if (e && e.shapes && e.shapes.length > 0 && e.shapes[0]) {
      const target = e.shapes[0];
      props.markerClicked(target);
    }
  };

  const handleMapClick = () => {
    if (props.markerClicked) {
      props.markerClicked(undefined);
    }
  };

  return (
    <AzureMap
      options={options}
      controls={controls}
      customControls={customControls}
      imageSprites={props.customIcons}
      events={{
        click: handleMapClick,
      }}
    >
      <AzureMapDataSourceProvider
        id={BubbleDataSourceProviderId}
        options={{
          cluster: true,
          clusterRadius: 45,
          clusterMaxZoom: 15,
        }}
      >
        <AzureMapLayerProvider
          id={'BubbleLayer LayerProvider'}
          options={{
            radius: ['step', ['get', 'point_count'], 16, 4, 24, 8, 32, 16, 40, 32, 48],
            color: 'rgba(210, 41, 45, 0.5)',
            strokeWidth: 0,
            filter: ['has', 'point_count'],
          }}
          type="BubbleLayer"
          events={{
            click: clusterClicked,
          }}
        ></AzureMapLayerProvider>
        <AzureMapLayerProvider
          id={'BubbleLayer2 LayerProvider'}
          options={{
            iconOptions: {
              image: 'none', //Hide the icon image.
            },
            textOptions: {
              textField: ['get', 'point_count_abbreviated'],
              offset: [0, 0.4],
            },
          }}
          type="SymbolLayer"
        ></AzureMapLayerProvider>
        <AzureMapLayerProvider
          id={MarkerLayerProviderId}
          options={{
            filter: ['!', ['has', 'point_count']], //Filter out clustered points from this layer.
            iconOptions: {
              image: ['get', 'icon'],
            },
          }}
          events={{ click: markerClicked }}
          type="SymbolLayer"
        ></AzureMapLayerProvider>

        {(props.markers ?? []).map((m) => {
          return (
            <AzureMapFeature
              key={m.id}
              id={m.id?.toString()}
              type="Point"
              coordinate={m.position}
              properties={{
                title: 'Pin',
                icon: m.icon,
                marker: 'Marker',
              }}
            />
          );
        })}
      </AzureMapDataSourceProvider>
      <AzureMapDataSourceProvider id="USER_DATA">
        <AzureMapLayerProvider
          id={'BubbleLayer5 LayerProvider'}
          type="LineLayer"
          options={{ strokeColor: '#D2292D', strokeOpacity: 0.5 }}
        />
        <AzureMapLayerProvider
          id={'BubbleLayer6 LayerProvider'}
          type="PolygonLayer"
          options={{
            fillColor: '#DD2222',
            fillOpacity: 0.01,
          }}
        />
        {getLocationFeatures()}
      </AzureMapDataSourceProvider>
      <AzureMapPopup
        isVisible={props.popup?.visible ?? false}
        options={{
          position: props.popup?.position ?? [0, 0],
          pixelOffset: [0, -60],
          closeButton: false,
        }}
        popupContent={props.popup?.content ?? <></>}
      />
    </AzureMap>
  );
};

export default React.memo(MapController);
