// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Constants.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
export default class FamilyRelationshipTypes {
    public static readonly PRIMARY: number = 1;
    public static readonly SPOUSE: number = 2;
    public static readonly CHILD: number = 3;
    public static readonly STEPCHILD: number = 5;
    public static readonly FOSTERCHILD: number = 6;
    public static readonly COURTWARD: number = 7;
    public static readonly UNKNOWN: number = 9;
    public static readonly MINORDEPOFMINORDEP: number = 17;
    public static readonly LIFEPARTNER: number = 20;
    public static readonly MISSING: number = 99;
}
