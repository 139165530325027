import { InboxOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Layout, notification, Row, Space, UploadFile } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Dragger from 'antd/lib/upload/Dragger';
import React, { useState } from 'react';
import OcrApiService from '../../api/OcrApiService';
import OcrLoading from '../../components/ocr/OcrLoading';
import OcrPreviewTable from '../../components/ocr/OcrPreviewTable';
import PageTitle from '../../components/shared/PageTitle';
import OcrPreviewDTO from '../../models/OcrPreviewDTO';
import OcrUploadDTO from '../../models/OcrUploadDTO';
import FileDownload from '../../utils/FileDownload';
import FileUploadUtil from '../../utils/HasFileUploadUtil';

const DocScanPage = () => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [processing, setProcessing] = useState(false);
  const [previewing, setPreviewing] = useState(false);

  const [previewData, setPreviewData] = useState<OcrPreviewDTO>(OcrPreviewDTO.create());

  const [form] = useForm();

  const handleFrontUploadChange = ({ fileList }: { fileList: any }) => {
    setFileList([...fileList]);
  };

  const handleBeforeUpload = (file: UploadFile) => {
    setFileList([...fileList, file]);
    return false;
  };

  const handlePreviewHeaderChange = (headerIndex: number, value: any) => {
    const tmpDto = previewData;
    tmpDto.columnNames[headerIndex] = value;
    setPreviewData(tmpDto);
  };

  const handleProcessFile = () => {
    if (fileList.length == 0) {
      notification.error({ message: 'No files were uploaded.' });
      return;
    }

    form.validateFields().then((model) => {
      const previewFile = !!model.preview;
      setPreviewing(previewFile);

      if (previewFile) {
        setProcessing(true);
        const dto = OcrUploadDTO.create({ file: fileList.at(0)?.originFileObj });
        const request = FileUploadUtil(dto, 'request', ['file']);
        OcrApiService.previewFile(request)
          .then((data) => {
            setPreviewData(data);
          })
          .catch((err) => {
            notification.error({ message: err.message });
            handleCancelPreview();
          })
          .finally(() => {
            setProcessing(false);
          });
      } else {
        processFile(false);
      }
    });
  };

  const processFile = (overrideColumnHeaders: boolean) => {
    const dto = OcrUploadDTO.create({
      file: fileList.at(0)?.originFileObj,
      previewOptions: overrideColumnHeaders ? previewData : null,
    });
    const request = FileUploadUtil(dto, 'request', ['file']);
    setProcessing(true);
    OcrApiService.processFile(request)
      .then((res) => {
        FileDownload.downloadBase64StringAsFile(res.fileName || '', 'text/csv', res.base64String || '');
      })
      .catch((err) => {
        notification.error({ message: err.message });
      })
      .finally(() => {
        setFileList([]);
        setProcessing(false);
        setPreviewing(false);
        setPreviewData(OcrPreviewDTO.create());
      });
  };

  const handleCancelPreview = () => {
    setPreviewing(false);
    setFileList([]);
    form.resetFields();
    setPreviewData(OcrPreviewDTO.create());
  };

  return (
    <Layout>
      <Layout.Content className="content-padding content-padding-xl">
        <Row style={{ justifyContent: 'space-between' }}>
          <PageTitle title="DOC Scan" />
          {!previewing && (
            <Button type="primary" shape="round" size="large" onClick={() => handleProcessFile()} disabled={processing}>
              Process File
            </Button>
          )}
          {previewing && (
            <Space>
              <Button shape="round" size="large" onClick={() => handleCancelPreview()} disabled={processing}>
                Cancel
              </Button>
              <Button type="primary" shape="round" size="large" onClick={() => processFile(true)} disabled={processing}>
                Continue
              </Button>
            </Space>
          )}
        </Row>
        <OcrLoading loading={processing} />
        {!processing && !previewing && (
          <>
            <Form form={form} layout="vertical">
              <Form.Item name="preview" valuePropName="checked">
                <Checkbox> Preview Results </Checkbox>
              </Form.Item>
            </Form>
            <Dragger
              accept=".pdf"
              maxCount={1}
              fileList={fileList}
              beforeUpload={handleBeforeUpload}
              onChange={handleFrontUploadChange}
              style={{ animation: 'fadeIn .5s' }}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag a file to this area to upload (Max: 100MB)</p>
              <p className="ant-upload-hint">Files will be processed and downloaded to your computer.</p>
            </Dragger>
          </>
        )}
        {!processing && previewing && <OcrPreviewTable data={previewData} onHeaderChange={handlePreviewHeaderChange} />}
      </Layout.Content>
    </Layout>
  );
};

export default DocScanPage;
