import { Breadcrumb, Col, Layout, Menu, notification, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { Route, useParams } from 'react-router';
import { Link, NavLink, Switch } from 'react-router-dom';
import MailroomApiService from '../../api/MailroomApiService';
import MailroomAccountDetailView from '../../components/mailroom/MailroomAccountDetailView';
import MailroomAccountFileSetupList from '../../components/mailroom/MailroomAccountFileSetupList';
import PageTitle from '../../components/shared/PageTitle';
import Permission from '../../consts/Permission';
import Routes from '../../consts/Routes';
import useHasPermission from '../../hooks/useHasPermission';
import MailroomAccountDetailDTO from '../../models/MailroomAccountDetailDTO';

interface RouteProps {
  id: string;
  tab?: string;
}

const MailroomAccountDetailPage = () => {
  const routeProps = useParams<RouteProps>();
  const [loading, setLoading] = useState(true);

  const [selectedTab, setSelectedTab] = useState(routeProps.tab || 'details');

  const idChanged = () => {
    const parsed = parseInt(routeProps.id);
    if (!isNaN(parsed)) {
      return parsed;
    }
    return null;
  };

  const [mailroomAccountId, setMailroomAccountId] = useState<number | null>(idChanged());
  const [mailroomAccountDetail, setMailroomAccountDetail] = useState<MailroomAccountDetailDTO>(
    MailroomAccountDetailDTO.create()
  );
  const hasPermission = useHasPermission();

  const fetchMailroomAccount = (id: number | null) => {
    if (id != null) {
      MailroomApiService.getMailroomAccountDetail(id)
        .then((connection) => {
          setMailroomAccountDetail(connection);
        })
        .catch((err) => {
          setMailroomAccountDetail(MailroomAccountDetailDTO.create());
          notification.error({ message: 'Failed to load mailroom account data.', description: err?.message });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    setMailroomAccountId(idChanged());
  }, [routeProps.id]);

  useEffect(() => {
    setSelectedTab(routeProps.tab || 'details');
  }, [routeProps.tab]);

  useEffect(() => {
    fetchMailroomAccount(mailroomAccountId);
  }, [mailroomAccountId]);

  const displayName = !loading
    ? mailroomAccountId == null
      ? 'New Mailroom Account'
      : `${mailroomAccountDetail?.account ?? ''} - ${mailroomAccountDetail?.partner ?? ''}`.trim()
    : null;

  const tabs = [];

  if (hasPermission(Permission.UTILITIES_MAILROOM_ACCOUNT_VIEW)) {
    tabs.push(
      <Menu.Item key="details">
        <NavLink to={Routes.generate(Routes.MAILROOM_ACCOUNT_DETAIL, { id: mailroomAccountId || 'new' })}>
          Details
        </NavLink>
      </Menu.Item>
    );
  }

  if (hasPermission(Permission.UTILITIES_MAILROOM_ACCOUNT_VIEW) && mailroomAccountId != null) {
    tabs.push(
      <Menu.Item key="fileSetups">
        <NavLink to={Routes.generate(Routes.MAILROOM_ACCOUNT_DETAIL_FILESETUPS, { id: mailroomAccountId })}>
          File Setups
        </NavLink>
      </Menu.Item>
    );
  }

  return (
    <Layout.Content className="content-padding">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={Routes.generate(Routes.MAILROOM_ACCOUNT_LIST)}>Mailroom Accounts</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{displayName}</Breadcrumb.Item>
      </Breadcrumb>
      <PageTitle title={displayName} style={{ marginTop: '0.5em', marginBottom: '0.5em' }} />
      <Row>
        <Col xs={24}>
          <Menu mode="horizontal" style={{ marginBottom: 15 }} selectedKeys={[selectedTab]}>
            {tabs}
          </Menu>

          <Switch>
            <Route
              path={Routes.MAILROOM_ACCOUNT_DETAIL_FILESETUPS}
              render={() => <MailroomAccountFileSetupList accountId={mailroomAccountId ?? 0} />}
            />

            <Route
              path={Routes.MAILROOM_ACCOUNT_DETAIL}
              render={() => (
                <MailroomAccountDetailView
                  accountDto={mailroomAccountDetail}
                  refreshDto={(id) => fetchMailroomAccount(id)}
                />
              )}
            />
          </Switch>
        </Col>
      </Row>
    </Layout.Content>
  );
};

export default MailroomAccountDetailPage;
