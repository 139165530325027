import { CloseCircleOutlined, DeleteOutlined, EditOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Input, Modal, notification, Row, Select, Space, Spin, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import Text from 'antd/lib/typography/Text';
import React, { useEffect, useState } from 'react';
import ClientApiService from '../../../api/ClientApiService';
import ContactType from '../../../consts/ContactType';
import Permission from '../../../consts/Permission';
import Routes from '../../../consts/Routes';
import ClientDetailDTO from '../../../models/ClientDetailDTO';
import ContactDTO from '../../../models/ContactDTO';
import HistoryUtil from '../../../utils/HistoryUtil';
import PageStayPrompt from '../../../utils/PageStayPrompt';
import Renderers from '../../../utils/Renderers';
import ContactInfo from '../../shared/ContactInfo';
import MaskedInput from 'antd-mask-input';
import PermissionGuard from '../../shared/PermissionGuard';
import confirmModal from '../../../utils/Modal';

interface ClientDetailViewProps {
  clientId: number;
  readonly?: boolean;
}

const ClientDetailView = (props: ClientDetailViewProps) => {
  const [loading, setLoading] = useState(false);
  const [clientDetails, setClientDetails] = useState<ClientDetailDTO>(ClientDetailDTO.create());
  const [form] = useForm();
  const [contactForm] = useForm();
  const [editingNotes, setEditingNotes] = useState(false);
  const [editingContact, setEditingContact] = useState<ContactDTO | undefined>(undefined);

  const fetchDetails = (id: number) => {
    setLoading(true);
    ClientApiService.getClientDetails(id)
      .then((res) => {
        setClientDetails(res);
      })
      .catch((err) => {
        notification.error({
          message: 'Error loading client',
          description: err?.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchDetails(props.clientId);
  }, [props.clientId]);

  useEffect(() => {
    contactForm?.resetFields();
  }, [editingContact]);

  const saveNote = () => {
    setLoading(true);

    const note = form.getFieldValue('note');
    ClientApiService.saveNotes(props.clientId, note)
      .then(() => {
        notification.success({ message: 'Notes Saved' });
        setClientDetails({ ...clientDetails, notes: note });
        setEditingNotes(false);
      })
      .catch(() => {
        notification.success({ message: 'Failed to Save', description: 'Please refresh and try again.' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const saveContact = (dto: ContactDTO) => {
    setLoading(true);

    let promise = null;

    if (editingContact?.id === 0) {
      promise = ClientApiService.addContact(props.clientId, dto);
    } else {
      if (editingContact?.id != null) {
        promise = ClientApiService.updateContact(props.clientId, editingContact.id, dto);
      }
    }

    if (promise) {
      setLoading(true);
      promise
        .then((res) => {
          notification.success({ message: 'Contact Saved' });
          setClientDetails({
            ...clientDetails,
            contacts: [
              ...(clientDetails.contacts ?? []).filter((c) => c.id != editingContact?.id),
              ContactDTO.create({ ...dto, id: res.id ?? editingContact?.id ?? 0 }),
            ].sort((a, b) => a.displayOrder - b.displayOrder),
          });
          setEditingContact(undefined);
        })
        .catch(() => {
          notification.error({ message: 'Failed to save contact', description: 'Please refresh and try again.' });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const deleteContact = (id: number) => {
    setLoading(true);
    ClientApiService.deleteContact(props.clientId, id)
      .then(() => {
        notification.success({ message: 'Contact Removed' });
        setClientDetails({
          ...clientDetails,
          contacts: [...(clientDetails.contacts ?? []).filter((c) => c.id != id)],
        });
      })
      .catch(() => {
        notification.error({ message: 'Failed to delete contact', description: 'Please refresh and try again.' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteClient = () => {
    setLoading(true);
    ClientApiService.deleteClient(props.clientId)
      .then(() => {
        notification.success({ message: 'Client Deleted' });
        HistoryUtil.replace(Routes.ADMIN_CLIENTS);
      })
      .catch(() => {
        setLoading(false);
        notification.error({ message: 'Failed to delete.' });
      });
  };

  return (
    <Spin spinning={loading}>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} xl={8}>
          <Card className="ant-card-background ant-card-shadow" bordered={false}>
            <Typography.Title level={3}>Details</Typography.Title>
            <Row gutter={[16, 16]}>
              <Col flex={0}>
                <strong>Client ID:</strong>
                <br />
                <strong>Client Code:</strong>
                <br />
                <strong>Phone:</strong>
                <br />
                <strong>Website:</strong>
              </Col>
              <Col flex={1}>
                {clientDetails?.clientId}
                <br />
                {clientDetails?.clientCode}
                <br />

                {clientDetails?.phoneNumber ? Renderers.phoneNumberLink(clientDetails.phoneNumber) : null}
                <br />
                {clientDetails?.url ? Renderers.urlLink(clientDetails.url) : null}
                <br />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} sm={12} xl={8}>
          <Card className="ant-card-background ant-card-shadow" bordered={false} style={{ height: '100%' }}>
            <Typography.Title level={3}>Address</Typography.Title>
            <Text style={{ whiteSpace: 'pre-wrap' }}>{Renderers.address(clientDetails)}</Text>
          </Card>
        </Col>
        <Col xs={24} xl={16}>
          <Card className="ant-card-background ant-card-shadow" bordered={false}>
            <Row align="bottom" style={{ paddingBottom: '0.5em' }}>
              <Col flex={0}>
                <Typography.Title level={3} style={{ marginBottom: 0 }}>
                  Contacts
                </Typography.Title>
              </Col>
              <Col flex={1} style={{ textAlign: 'right' }}>
                <PermissionGuard permissionId={Permission.ADMIN_CLIENT_DETAILS_EDIT}>
                  <Button
                    icon={<PlusOutlined />}
                    type="link"
                    disabled={!!props.readonly}
                    onClick={() => {
                      setEditingContact(ContactDTO.create({ contactTypeId: ContactType.CLIENT }));
                    }}
                  />
                </PermissionGuard>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              {clientDetails.contacts
                ?.sort((a, b) => a.displayOrder - b.displayOrder)
                .map((c) => (
                  <Col xs={24} sm={10} key={c.id}>
                    <ContactInfo
                      card
                      {...c}
                      addon={
                        <PermissionGuard permissionId={Permission.ADMIN_CLIENT_DETAILS_EDIT}>
                          <Space direction="horizontal">
                            <Button
                              icon={<EditOutlined />}
                              type="link"
                              disabled={!!props.readonly}
                              onClick={() => {
                                setEditingContact(c);
                              }}
                            />
                            <Button
                              icon={<DeleteOutlined />}
                              onClick={() =>
                                confirmModal(
                                  'Are you sure you want to delete this contact?',
                                  () => deleteContact(c.id),
                                  null,
                                  'Yes, Delete'
                                )
                              }
                              type="link"
                              disabled={!!props.readonly}
                            />
                          </Space>
                        </PermissionGuard>
                      }
                    />
                  </Col>
                ))}
            </Row>
          </Card>
        </Col>
        <Col xs={24} xl={16}>
          <Card className="ant-card-background ant-card-shadow" bordered={false}>
            <Row align="bottom" style={{ paddingBottom: '0.5em' }}>
              <Col flex={0}>
                <Typography.Title level={3} style={{ marginBottom: 0 }}>
                  Notes
                </Typography.Title>
              </Col>
              <Col flex={1} style={{ textAlign: 'right' }}>
                <PermissionGuard permissionId={Permission.ADMIN_CLIENT_DETAILS_EDIT}>
                  <Space direction="horizontal">
                    {!editingNotes ? (
                      <Button
                        icon={<EditOutlined />}
                        type="link"
                        disabled={!!props.readonly}
                        onClick={() => setEditingNotes(true)}
                      />
                    ) : (
                      <>
                        <Button icon={<SaveOutlined />} type="link" onClick={() => saveNote()} />
                        <Button icon={<CloseCircleOutlined />} type="link" onClick={() => setEditingNotes(false)} />
                      </>
                    )}
                  </Space>
                </PermissionGuard>
              </Col>
            </Row>

            {!editingNotes ? (
              <div style={{ minHeight: 140, whiteSpace: 'pre-wrap' }}>{clientDetails?.notes}</div>
            ) : (
              <>
                <PageStayPrompt when={true} />
                <Form initialValues={{ note: clientDetails?.notes }} form={form}>
                  <FormItem name="note">
                    <Input.TextArea rows={6} />
                  </FormItem>
                </Form>
              </>
            )}
          </Card>
        </Col>
      </Row>
      {editingContact ? (
        <>
          <Modal
            maskClosable={false}
            visible={true}
            destroyOnClose={true}
            onCancel={() => {
              setEditingContact(undefined);
            }}
            onOk={() => {
              const contact = contactForm.getFieldsValue();
              contactForm.validateFields().then(() => {
                saveContact(contact);
              });
            }}
          >
            <Form form={contactForm} initialValues={editingContact} layout="vertical">
              <Form.Item
                label="Contact Type"
                name="contactTypeId"
                required
                rules={[
                  {
                    required: true,
                    message: 'Required',
                  },
                ]}
              >
                <Select>
                  <Select.Option value={ContactType.CLIENT}>Client</Select.Option>
                  <Select.Option value={ContactType.TPA}>TPA</Select.Option>
                  <Select.Option value={ContactType.BROKER}>Broker</Select.Option>
                  <Select.Option value={ContactType.CLAIMDOC}>ClaimDOC</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item label="Title" name="title">
                <Input />
              </Form.Item>
              <Form.Item
                label="Name"
                name="name"
                required
                rules={[
                  {
                    required: true,
                    message: 'Required',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Phone Number"
                name="phone"
                rules={[
                  {
                    pattern: /^\d{10}$/,
                    message: 'Please a valid 10 digit phone number',
                  },
                ]}
                getValueFromEvent={(e) => {
                  return e.target.value.replace(/[^0-9]/g, '');
                }}
              >
                <MaskedInput mask="(000) 000-0000" type="tel" placeholder={'(201) 555-5555'} />
              </Form.Item>
              <Form.Item
                name="email"
                label="Email Address"
                rules={[
                  {
                    type: 'email',
                    message: 'Valid email address required.',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item name="url" label="Website URL">
                <Input />
              </Form.Item>
            </Form>
          </Modal>
        </>
      ) : null}

      {props.clientId && (
        <PermissionGuard permissionId={Permission.ADMIN_CLIENT_DETAILS_EDIT}>
          <Button
            style={{ marginTop: 15 }}
            onClick={() => confirmModal('Are you sure you want to delete this client?', () => deleteClient())}
            danger
            disabled={!!props.readonly}
          >
            Delete Client
          </Button>
        </PermissionGuard>
      )}
    </Spin>
  );
};

export default ClientDetailView;
