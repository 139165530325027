import { Typography } from 'antd';
import React, { useEffect } from 'react';

interface PageTitleProps {
  style?: React.CSSProperties;
  hide?: boolean | null;
  title?: string | null;
}

const PageTitle = (props: PageTitleProps) => {
  useEffect(() => {
    document.title = 'ClaimDOC Portal' + (props.title ? ' - ' + props.title : '');
  }, [props.title]);

  const show = !(props.hide || false);
  if (show) {
    return (
      <Typography.Title level={1} className="page-title" style={props.style}>
        {props.title}
      </Typography.Title>
    );
  } else {
    return null;
  }
};
export default PageTitle;
