import { Button, Checkbox, Form, Modal, notification } from 'antd';
import React, { useState } from 'react';
import MailroomApiService from '../../api/MailroomApiService';
import Permission from '../../consts/Permission';
import useHasPermission from '../../hooks/useHasPermission';
import MailroomFileSetupListDTO from '../../models/MailroomFileSetupListDTO';
import TableRequestDTO from '../../models/TableRequestDTO';
import TableResponseDTO from '../../models/TableResponseDTO';
import DataTable, { DataTableColumnProps } from '../shared/DataTable/DataTable';
import DataTableColumnUtil from '../shared/DataTable/DataTableColumnUtil';

interface MailroomAccountFileSetupProps {
  accountId: number;
}

const MailroomAccountFileSetupList = (props: MailroomAccountFileSetupProps) => {
  const [loading, setLoading] = useState(false);
  const [fileSetups, setFileSetups] = useState<MailroomFileSetupListDTO[]>([]);
  const [selectedFileSetup, setSelectedFileSetup] = useState<MailroomFileSetupListDTO>();
  const [form] = Form.useForm();
  const [openModal, setOpenModal] = useState(false);
  const hasPermission = useHasPermission();
  const dtRef = React.useRef<DataTable<MailroomFileSetupListDTO>>(null);

  const readOnly = !hasPermission(Permission.UTILITIES_MAILROOM_ACCOUNT_EDIT);

  const tableColumns: DataTableColumnProps<MailroomFileSetupListDTO>[] = [
    DataTableColumnUtil.Text('File Type', 'fileType'),
    DataTableColumnUtil.Text('Source', 'source'),
    DataTableColumnUtil.Text('Source Url', 'inUrl'),
    DataTableColumnUtil.Text('Destination', 'destination'),
    DataTableColumnUtil.Text('Destination Url', 'outUrl'),
    DataTableColumnUtil.BooleanYesNo('Active', 'active'),
    DataTableColumnUtil.BooleanYesNo('Incoming', 'incoming'),
  ];

  if (hasPermission(Permission.UTILITIES_MAILROOM_ACCOUNT_EDIT)) {
    tableColumns.push({
      title: '',
      dataIndex: '',
      width: '40px',
      render: (rowData: MailroomFileSetupListDTO) => (
        <Button
          shape="round"
          disabled={loading || readOnly}
          onClick={() => {
            setOpenModal(true);
            setSelectedFileSetup(rowData);
          }}
          ghost
        >
          Run
        </Button>
      ),
    });
  }

  const runFileSetup = () => {
    setLoading(true);

    if (selectedFileSetup === undefined) {
      form.resetFields();
      setOpenModal(false);
      notification.error({
        message: 'File Setup failed to queue',
      });
    } else {
      form
        .validateFields()
        .then((values) => {
          MailroomApiService.runMailroomFileSetup(selectedFileSetup, values.includeProcessedFiles)
            .then((result) => {
              setLoading(false);

              if (result) {
                notification.success({
                  message: 'File Setup queued to run',
                });
              } else {
                notification.error({
                  message: 'File Setup failed to queue',
                });
              }
            })
            .catch((error: any) => {
              setLoading(false);
              notification.error({
                message: error.message,
                description: error.description,
              });
            });
        })
        .finally(() => {
          form.resetFields();
          setOpenModal(false);
        });
    }
  };

  const fetchTableData = (
    requestState: TableRequestDTO,
    checkEcho: () => boolean,
    callback: (response: TableResponseDTO<MailroomFileSetupListDTO>) => void
  ) => {
    setLoading(true);

    MailroomApiService.getMailroomFileSetupList(requestState, props.accountId)
      .then((results) => {
        if (!checkEcho()) {
          return;
        }
        setLoading(false);
        setFileSetups(results.results || []);

        callback(results);
      })
      .catch((error: any) => {
        setLoading(false);
        notification.error({
          message: error.message,
          description: error.description,
        });
      });
  };

  return (
    <>
      <Modal
        open={openModal}
        title="Select Run Option(s)"
        onCancel={() => {
          form.resetFields();
          setOpenModal(false);
        }}
        onOk={() => runFileSetup()}
        okText="Run"
        destroyOnClose
      >
        <Form form={form}>
          <Form.Item name="includeProcessedFiles" valuePropName="checked" noStyle>
            <Checkbox>Include Processed Files</Checkbox>
          </Form.Item>
        </Form>
      </Modal>
      <DataTable
        title="File Setups"
        ref={dtRef}
        serverSide={true}
        globalSearch={true}
        globalSearchPlaceholder="Search File Setups"
        tableProps={{
          rowKey: 'id',
          loading: loading,
        }}
        columns={tableColumns}
        data={fileSetups}
        fetchData={fetchTableData}
        styleOptions={{
          alternatingRowHighlight: true,
        }}
      />
    </>
  );
};

export default MailroomAccountFileSetupList;
