import { Col, Divider, Layout, Row } from 'antd';
import React, { useContext } from 'react';

import { AuthenticationContext } from '../../auth/AuthenticationContext';
import ClaimDocMemberSupport from '../../components/contact/ClaimDocMemberSupport';
import ContactView from '../../components/contact/ContactView';
import WelcomeUser from '../../components/contact/WelcomeUser';
import AccessRole from '../../consts/AccessRole';

const DashboardPage = () => {
  const authContext = useContext(AuthenticationContext);

  const user = authContext.user;
  const roleId = user?.accessRoleId;
  const entityId = user?.accessEntityId;

  return (
    <Layout.Content className="content-padding">
      <Row>
        <Col xxl={10} xl={16} lg={20} xs={24}>
          <WelcomeUser />
          <Divider className="divider-branded" />
          {roleId == AccessRole.EMPLOYER ? <ContactView showContacts={true} clientId={entityId ?? 0} /> : null}
          <ClaimDocMemberSupport />
        </Col>
      </Row>
    </Layout.Content>
  );
};

export default DashboardPage;
