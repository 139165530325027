// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import LoginDTO from '../models/LoginDTO';
import RegisterDTO from '../models/RegisterDTO';
import ResetPasswordDTO from '../models/ResetPasswordDTO';
import ChangePasswordDTO from '../models/ChangePasswordDTO';
import ChangeUsernameDTO from '../models/ChangeUsernameDTO';
import UserPreferenceDTO from '../models/UserPreferenceDTO';
import LoginResultDTO from '../models/LoginResultDTO';
import RegisterResultDTO from '../models/RegisterResultDTO';
import ResultDTO from '../models/ResultDTO';
import ProfileDTO from '../models/ProfileDTO';
import UserSecurityDTO from '../models/UserSecurityDTO';

export class AccountApiService extends BaseApi {

    // post: api/Account/Login
    public login(model: LoginDTO): Promise<LoginResultDTO> {
        let url = `api/Account/Login`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, LoginResultDTO>(model, 'post', url, true, false);
    }

    // get: api/Account/ExternalLogin?provider=${encodeURIComponent(provider)}&login=${encodeURIComponent(login)}&returnUrl=${encodeURIComponent(returnUrl)}
    public externalLogin(provider: string, login?: string | null, returnUrl?: string | null): Promise<void> {
        let url = `api/Account/ExternalLogin`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (provider != null) {
            url += `${prefix}provider=${encodeURIComponent(provider)}`;
            prefix = '&';
        }
        if (login != null) {
            url += `${prefix}login=${encodeURIComponent(login || '')}`;
            prefix = '&';
        }
        if (returnUrl != null) {
            url += `${prefix}returnUrl=${encodeURIComponent(returnUrl || '')}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, void>(null, 'get', url, true, false);
    }

    // get: api/Account/ExternalLoginCallback?returnUrl=${encodeURIComponent(returnUrl)}&remoteError=${encodeURIComponent(remoteError)}
    public externalLoginCallback(returnUrl?: string | null, remoteError?: string | null): Promise<void> {
        let url = `api/Account/ExternalLoginCallback`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (returnUrl != null) {
            url += `${prefix}returnUrl=${encodeURIComponent(returnUrl || '')}`;
            prefix = '&';
        }
        if (remoteError != null) {
            url += `${prefix}remoteError=${encodeURIComponent(remoteError || '')}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, void>(null, 'get', url, true, false);
    }

    // post: api/Account/Register
    public register(model: RegisterDTO): Promise<RegisterResultDTO> {
        let url = `api/Account/Register`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, RegisterResultDTO>(model, 'post', url, true, false);
    }

    // post: api/Account/RegisterCallback?code=${encodeURIComponent(code)}&type=${encodeURIComponent(type)}&email=${encodeURIComponent(email)}
    public registerCallback(code: string, type: string, email: string): Promise<RegisterDTO> {
        let url = `api/Account/RegisterCallback`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (code != null) {
            url += `${prefix}code=${encodeURIComponent(code)}`;
            prefix = '&';
        }
        if (type != null) {
            url += `${prefix}type=${encodeURIComponent(type)}`;
            prefix = '&';
        }
        if (email != null) {
            url += `${prefix}email=${encodeURIComponent(email)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, RegisterDTO>(null, 'post', url, true, false);
    }

    // get: api/Account/ConfirmEmail?userId=${userId}&code=${encodeURIComponent(code)}
    public confirmEmail(userId: number, code: string): Promise<ResultDTO> {
        let url = `api/Account/ConfirmEmail`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (userId != null) {
            url += `${prefix}userId=${userId}`;
            prefix = '&';
        }
        if (code != null) {
            url += `${prefix}code=${encodeURIComponent(code)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ResultDTO>(null, 'get', url, true, false);
    }

    // post: api/Account/ResendConfirmEmail?email=${encodeURIComponent(email)}
    public resendConfirmEmail(email: string): Promise<void> {
        let url = `api/Account/ResendConfirmEmail`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (email != null) {
            url += `${prefix}email=${encodeURIComponent(email)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, void>(null, 'post', url, true, false);
    }

    // post: api/Account/ForgotPassword?email=${encodeURIComponent(email)}&isSupportRequest=${isSupportRequest}
    public forgotPassword(email: string, isSupportRequest?: boolean | null): Promise<void> {
        let url = `api/Account/ForgotPassword`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (email != null) {
            url += `${prefix}email=${encodeURIComponent(email)}`;
            prefix = '&';
        }
        if (isSupportRequest != null) {
            url += `${prefix}isSupportRequest=${isSupportRequest}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, void>(null, 'post', url, true, false);
    }

    // post: api/Account/CanResetPassword?userId=${userId}&code=${encodeURIComponent(code)}
    public canResetPassword(userId: number, code: string): Promise<ResultDTO> {
        let url = `api/Account/CanResetPassword`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (userId != null) {
            url += `${prefix}userId=${userId}`;
            prefix = '&';
        }
        if (code != null) {
            url += `${prefix}code=${encodeURIComponent(code)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ResultDTO>(null, 'post', url, true, false);
    }

    // post: api/Account/ResetPassword?userId=${userId}&code=${encodeURIComponent(code)}
    public resetPassword(userId: number, code: string, model: ResetPasswordDTO): Promise<ResultDTO> {
        let url = `api/Account/ResetPassword`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (userId != null) {
            url += `${prefix}userId=${userId}`;
            prefix = '&';
        }
        if (code != null) {
            url += `${prefix}code=${encodeURIComponent(code)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ResultDTO>(model, 'post', url, true, false);
    }

    // post: api/Account/ValidatePassword?password=${encodeURIComponent(password)}
    public validatePassword(password: string): Promise<ResultDTO> {
        let url = `api/Account/ValidatePassword`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (password != null) {
            url += `${prefix}password=${encodeURIComponent(password)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ResultDTO>(null, 'post', url, true, false);
    }

    // post: api/Account/ChangePassword
    public changePassword(model: ChangePasswordDTO): Promise<ResultDTO> {
        let url = `api/Account/ChangePassword`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ResultDTO>(model, 'post', url, true, false);
    }

    // get: api/Account/Logout
    public logout(): Promise<void> {
        let url = `api/Account/Logout`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, void>(null, 'get', url, true, false);
    }

    // get: api/Account/GetCurrentUser
    public getCurrentUser(): Promise<UserSecurityDTO> {
        let url = `api/Account/GetCurrentUser`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, UserSecurityDTO>(null, 'get', url, true, false);
    }

    // post: api/Account/SetAccessRole?accessRoleId=${accessRoleId}&accessEntityId=${accessEntityId}
    public setAccessRole(accessRoleId: number, accessEntityId?: number | null): Promise<void> {
        let url = `api/Account/SetAccessRole`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (accessRoleId != null) {
            url += `${prefix}accessRoleId=${accessRoleId}`;
            prefix = '&';
        }
        if (accessEntityId != null) {
            url += `${prefix}accessEntityId=${accessEntityId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, void>(null, 'post', url, true, false);
    }

    // post: api/Account/RequestUsernameChange
    public requestUsernameChange(model: ChangeUsernameDTO): Promise<ResultDTO> {
        let url = `api/Account/RequestUsernameChange`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ResultDTO>(model, 'post', url, true, false);
    }

    // post: api/Account/ConfirmEmailChange?userId=${userId}&code=${encodeURIComponent(code)}&email=${encodeURIComponent(email)}
    public confirmEmailChange(userId: number, code: string, email: string): Promise<ResultDTO> {
        let url = `api/Account/ConfirmEmailChange`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (userId != null) {
            url += `${prefix}userId=${userId}`;
            prefix = '&';
        }
        if (code != null) {
            url += `${prefix}code=${encodeURIComponent(code)}`;
            prefix = '&';
        }
        if (email != null) {
            url += `${prefix}email=${encodeURIComponent(email)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ResultDTO>(null, 'post', url, true, false);
    }

    // get: api/Account/CheckPinAuth
    public checkPinAuth(): Promise<boolean> {
        let url = `api/Account/CheckPinAuth`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, boolean>(null, 'get', url, true, false);
    }

    // post: api/Account/SetPinAuth?pin=${encodeURIComponent(pin)}&password=${encodeURIComponent(password)}
    public setPinAuth(pin: string, password?: string | null): Promise<LoginResultDTO> {
        let url = `api/Account/SetPinAuth`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (pin != null) {
            url += `${prefix}pin=${encodeURIComponent(pin)}`;
            prefix = '&';
        }
        if (password != null) {
            url += `${prefix}password=${encodeURIComponent(password || '')}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, LoginResultDTO>(null, 'post', url, true, false);
    }

    // post: api/Account/RequestUserPin?email=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}
    public requestUserPin(email: string, password: string): Promise<ResultDTO> {
        let url = `api/Account/RequestUserPin`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (email != null) {
            url += `${prefix}email=${encodeURIComponent(email)}`;
            prefix = '&';
        }
        if (password != null) {
            url += `${prefix}password=${encodeURIComponent(password)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ResultDTO>(null, 'post', url, true, false);
    }

    // get: api/Account/Ping
    public ping(): Promise<string> {
        let url = `api/Account/Ping`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, string>(null, 'get', url, true, false);
    }

    // get: api/Account/Profile
    public getProfileDTO(): Promise<ProfileDTO> {
        let url = `api/Account/Profile`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ProfileDTO>(null, 'get', url, true, false);
    }

    // post: api/Account/ChangeMFAPhone?phoneNumber=${encodeURIComponent(phoneNumber)}&code=${encodeURIComponent(code)}
    public changeMfaPhone(phoneNumber: string, code?: string | null): Promise<boolean> {
        let url = `api/Account/ChangeMFAPhone`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (phoneNumber != null) {
            url += `${prefix}phoneNumber=${encodeURIComponent(phoneNumber)}`;
            prefix = '&';
        }
        if (code != null) {
            url += `${prefix}code=${encodeURIComponent(code || '')}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, boolean>(null, 'post', url, true, false);
    }

    // post: api/Account/DisableMFA
    public disableMFA(): Promise<boolean> {
        let url = `api/Account/DisableMFA`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, boolean>(null, 'post', url, true, false);
    }

    // get: api/Account/CheckMemberAccountEligible
    public checkMemberAccountEligible(): Promise<boolean> {
        let url = `api/Account/CheckMemberAccountEligible`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, boolean>(null, 'get', url, true, false);
    }

    // get: api/Account/GetUserPreferences
    public getUserPreferences(): Promise<UserPreferenceDTO> {
        let url = `api/Account/GetUserPreferences`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, UserPreferenceDTO>(null, 'get', url, true, false);
    }

    // post: api/Account/SaveUserPreferences
    public saveUserPreferences(dto: UserPreferenceDTO): Promise<boolean> {
        let url = `api/Account/SaveUserPreferences`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, boolean>(dto, 'post', url, true, false);
    }
}
var service = new AccountApiService();
export default service;
