import React, { useEffect, useRef, useState } from 'react';
import DataTable, { FilterType } from '../../shared/DataTable/DataTable';
import ClientDetailDTO from '../../../models/ClientDetailDTO';
import ClientApiService from '../../../api/ClientApiService';
import { notification } from 'antd';
import DataTableColumnUtil from '../../shared/DataTable/DataTableColumnUtil';
import Renderers from '../../../utils/Renderers';

interface ClientDivisionListProps {
  clientId: number;
}

const ClientDivisionList: React.FC<ClientDivisionListProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [divisions, setDivisions] = useState<ClientDetailDTO[]>([]);
  const dtRef = useRef<DataTable<ClientDetailDTO>>(null);

  useEffect(() => {
    setLoading(true);
    ClientApiService.getClientDivisions(props.clientId)
      .then((res) => {
        setDivisions(res);
        dtRef.current?.resetTable();
      })
      .catch(() => {
        notification.error({ message: 'Failed to retrieve divisions' });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <DataTable
      ref={dtRef}
      columns={[
        { ...DataTableColumnUtil.Text<ClientDetailDTO>('Client Id', 'clientId'), filterType: FilterType.NONE },
        { ...DataTableColumnUtil.Text<ClientDetailDTO>('Sub Code', 'subCode'), filterType: FilterType.NONE },
        {
          title: 'Name',
          dataIndex: 'name',
          defaultSortOrder: 'ascend',
        },
        {
          title: 'Location',
          dataIndex: 'state',
          render: (v, r) => `${r.city ?? ''}${r.city && r.state ? ',' : ''} ${r.state ?? ''}`,
        },
        {
          title: 'Phone Number',
          dataIndex: 'phoneNumber',
          render: (v) => Renderers.phoneNumber(v),
        },
      ]}
      globalSearch={true}
      data={divisions}
      tableProps={{
        rowKey: 'clientId',
        size: 'large',
        loading: loading,
        sortDirections: ['ascend', 'descend'],
        scroll: { x: 575 },
      }}
      styleOptions={{
        alternatingRowHighlight: true,
      }}
    />
  );
};

export default ClientDivisionList;
