// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';


export class CommunicationTesterApiService extends BaseApi {

    // post: api/communication-tester/Email?toAddress=${encodeURIComponent(toAddress)}
    public email(toAddress: string): Promise<void> {
        let url = `api/communication-tester/Email`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (toAddress != null) {
            url += `${prefix}toAddress=${encodeURIComponent(toAddress)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, void>(null, 'post', url, true, false);
    }

    // post: api/communication-tester/Sms?tenDigit=${encodeURIComponent(tenDigit)}
    public sms(tenDigit: string): Promise<void> {
        let url = `api/communication-tester/Sms`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (tenDigit != null) {
            url += `${prefix}tenDigit=${encodeURIComponent(tenDigit)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, void>(null, 'post', url, true, false);
    }
}
var service = new CommunicationTesterApiService();
export default service;
