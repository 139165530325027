import { Alert, Button, Col, Form, Input, Modal, notification, Result, Row, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import React, { useContext, useEffect, useState } from 'react';
import AccountApiService from '../api/AccountApiService';
import PinAccessApiService from '../api/PinAccessApiService';
import { AuthenticationContext } from './AuthenticationContext';
import useUrlQuery from '../hooks/useUrlQuery';
import LoginResultDTO from '../models/LoginResultDTO';
import LoginLayout from '../pages/account/LoginLayout';
import { QuestionCircleOutlined } from '@ant-design/icons';

interface PinWrapperProps {
  pin?: string;
}

const PinWrapper = (props: React.PropsWithChildren<unknown>) => {
  const [urlParams, removeUrlParam] = useUrlQuery<PinWrapperProps>();

  const [showForgot, setShowForgot] = useState(false);
  const [forgotSubmitted, setForgotSubmitted] = useState(false);
  const [loading, setLoading] = useState(urlParams?.pin !== undefined);
  const [pinSet, setPinSet] = useState(false);
  const [error, setError] = useState<any>();
  const [checking, setChecking] = useState(true);
  const [requiresPassword, setRequiresPassword] = useState(false);

  const [form] = useForm();

  const authContext = useContext(AuthenticationContext);

  useEffect(() => {
    checkExisting();
  }, []);

  useEffect(() => {
    if (urlParams.pin) {
      form.setFieldsValue({ pin: urlParams.pin });
      removeUrlParam('pin');
      // delay this so page can load before submitting
      setTimeout(() => form.submit(), 750);
    }
  }, [urlParams.pin]);

  useEffect(() => {
    if (!authContext.user) {
      setPinSet(false);
    }
  }, [authContext.user]);

  const checkExisting = async () => {
    AccountApiService.checkPinAuth()
      .then((res) => {
        if (res === true) {
          setPinSet(true);
          setLoading(false);
        } else {
          setPinSet(false);
          setLoading(false);
        }
      })
      .catch(() => {
        setPinSet(false);
        setLoading(false);
      })
      .finally(() => {
        setChecking(false);
      });
  };

  const onSubmit = (values: any) => {
    setLoading(true);
    setError(null);
    authContext.pinLogin(values.pin, values.password ?? null).then(
      (response: LoginResultDTO) => {
        if (response.succeeded) {
          setPinSet(true);
          setLoading(false);
        } else {
          if (response.requiresTwoFactor) {
            setRequiresPassword(true);
          } else {
            let error = '';
            if (response.isLockedOut) {
              error = 'PIN has expired.';
            } else if (response.isNotAllowed) {
              error = 'PIN is inactive.';
            } else {
              error = 'Invalid PIN.';
            }
            setError(error);
          }

          setPinSet(false);
          setLoading(false);
        }
      },
      (error2: any) => {
        setError(error2.message);
        setLoading(false);
      }
    );
  };

  const submitForgot = (values: any) => {
    setLoading(true);
    PinAccessApiService.resetGuestPIN(values)
      .then(() => {
        notification.success({ message: 'Request Submitted' });
        setForgotSubmitted(true);
      })
      .catch(() => {
        notification.error({ message: 'There was an issue submitting', description: 'Please try again.' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderPinForm = () => {
    let alert: JSX.Element | null = null;
    if (error) {
      const message = error;
      alert = (
        <Alert
          message="Error"
          description={message}
          type="error"
          showIcon={true}
          style={{ marginBottom: '12px', whiteSpace: 'pre-wrap' }}
        />
      );
    }

    if (checking) {
      return null;
    }

    return (
      <LoginLayout
        cardTitle="Welcome To ClaimDOC!"
        prompt={showForgot ? 'Please enter your information below:' : 'Please enter your PIN below:'}
      >
        <Spin spinning={loading} tip={showForgot ? undefined : 'Checking PIN'}>
          <Row justify="center">
            <Col xs={24} sm={18}>
              {alert}

              {showForgot ? (
                forgotSubmitted ? (
                  <>
                    <Result
                      status="success"
                      title="Request Submitted"
                      subTitle="Please check your email for an update."
                      extra={[
                        <Button
                          key="return"
                          onClick={() => {
                            setForgotSubmitted(false);
                            setShowForgot(false);
                          }}
                          style={{ width: '100%' }}
                          size="large"
                          shape="round"
                        >
                          Return to PIN Login
                        </Button>,
                      ]}
                    />
                  </>
                ) : (
                  <>
                    <Form form={form} layout="vertical" onFinish={submitForgot} requiredMark={false}>
                      <FormItem
                        name="firstName"
                        label="First Name"
                        required
                        rules={[{ required: true, message: 'Required.' }]}
                      >
                        <Input />
                      </FormItem>

                      <FormItem
                        name="lastName"
                        label="Last Name"
                        required
                        rules={[{ required: true, message: 'Required.' }]}
                      >
                        <Input />
                      </FormItem>

                      <FormItem
                        name="email"
                        label="Email"
                        required
                        rules={[
                          { required: true, message: 'Required.' },
                          { type: 'email', message: 'Email is invalid' },
                        ]}
                      >
                        <Input />
                      </FormItem>

                      <FormItem
                        name="employer"
                        label="Employer"
                        required
                        rules={[{ required: true, message: 'Required.' }]}
                      >
                        <Input />
                      </FormItem>
                      <FormItem>
                        <Button type="primary" htmlType="submit" block size="large" shape="round">
                          Submit
                        </Button>
                      </FormItem>
                    </Form>
                    <div style={{ textAlign: 'center', marginBottom: 0 }}>
                      <Button size="large" shape="round" block onClick={() => setShowForgot(false)}>
                        Return to PIN Login
                      </Button>
                    </div>
                  </>
                )
              ) : (
                <>
                  <Form form={form} layout="vertical" onFinish={onSubmit} requiredMark={false}>
                    <FormItem name="pin" label="PIN" required={true} rules={[{ required: true, message: 'Required.' }]}>
                      <Input.Password autoFocus={true} />
                    </FormItem>

                    {requiresPassword ? (
                      <>
                        <FormItem
                          name="password"
                          label="Password"
                          rules={[
                            {
                              required: true,
                              message: 'Required',
                            },
                          ]}
                        >
                          <Input.Password autoFocus={true} />
                        </FormItem>
                        <Button
                          block
                          type="link"
                          style={{ marginBottom: 15 }}
                          onClick={() => {
                            Modal.info({
                              icon: <QuestionCircleOutlined />,
                              title: 'Forgot PIN Password?',
                              okButtonProps: { shape: 'round' },
                              content:
                                'If you forgot your password, sign in with your generic PIN and request a new individual PIN with the email you used previously.',
                            });
                          }}
                        >
                          Forgot Password?
                        </Button>
                      </>
                    ) : null}

                    <FormItem>
                      <Button type="primary" htmlType="submit" block size="large" shape="round">
                        Submit
                      </Button>
                    </FormItem>

                    <FormItem>
                      <Button onClick={() => setShowForgot(true)} block size="large" shape="round">
                        I do not know my PIN
                      </Button>
                    </FormItem>
                  </Form>
                </>
              )}
            </Col>
          </Row>
        </Spin>
      </LoginLayout>
    );
  };

  if (pinSet) {
    return <>{props.children}</>;
  } else {
    return renderPinForm();
  }
};

export default PinWrapper;
