// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import ResultDTO from './ResultDTO';

interface LoginResultDTO extends ResultDTO { 
    isLockedOut: boolean;
    isNotAllowed: boolean;
    requiresTwoFactor: boolean;
    username: string | null;
    firstName: string | null;
    lastName: string | null;
    claimDocMemberId: string | null;
    requiresMfaSetup: boolean;
    phoneNumber: string | null;
}
const LoginResultDTO = {
    create: (initValues?: Partial<LoginResultDTO> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            isLockedOut: false,
            isNotAllowed: false,
            requiresTwoFactor: false,
            username: null,
            firstName: null,
            lastName: null,
            claimDocMemberId: null,
            requiresMfaSetup: false,
            phoneNumber: null,
        },
        initValues);
    }
};

export default LoginResultDTO;