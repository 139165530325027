import { Button, Col, Row, Select, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import LookupsApiService from '../../../api/LookupsApiService';
import Role from '../../../consts/Role';
import ClientDetailDTO from '../../../models/ClientDetailDTO';
import ParentSecuritySettingsDTO from '../../../models/ParentSecuritySettingsDTO';
import ParentSecurityUserSettingsDTO from '../../../models/ParentSecurityUserSettingsDTO';
import PermissionGroupDetailDTO from '../../../models/PermissionGroupDetailDTO';
import TableRequestDTO from '../../../models/TableRequestDTO';
import TableResponseDTO from '../../../models/TableResponseDTO';
import PageStayPrompt from '../../../utils/PageStayPrompt';
import DataTable, { DataTableColumnProps, FilterType } from '../DataTable/DataTable';
import DataTableColumnUtil from '../DataTable/DataTableColumnUtil';
import PermissionGroupHelperButton from './PermissionGroupHelperButton';
import confirmModal from '../../../utils/Modal';

interface ParentSecurityManagerProps {
  settings: ParentSecuritySettingsDTO;
  onSave?: (settings: ParentSecuritySettingsDTO) => void;
  title: string;
  forClient?: boolean;
  readOnly?: boolean;
  emptyText: string;
  tableLoading: boolean;
  tableData: ParentSecurityUserSettingsDTO[];
  fetchData: (
    requestState: TableRequestDTO,
    checkEcho: () => boolean,
    callback: (response: TableResponseDTO<ParentSecurityUserSettingsDTO>) => void
  ) => void;
  onRemoveUser?: (id: number) => void;
  dtRef?: React.RefObject<DataTable<ParentSecurityUserSettingsDTO>>;
  roleId?: number;
  clientDivisions?: ClientDetailDTO[];
}

const ParentSecurityManager = (props: ParentSecurityManagerProps) => {
  const [changesPending, setChangesPending] = useState(false);
  const [permissionGroups, setPermissionGroups] = useState<PermissionGroupDetailDTO[]>([]);
  const [memberPermissionGroups, setMemberPermissionGroups] = useState<PermissionGroupDetailDTO[]>([]);
  const dtRef = props.dtRef ?? React.useRef<DataTable<ParentSecurityUserSettingsDTO>>(null);

  const [baseEmployerId, setBaseEmployerId] = useState<number | null>(props.settings.employerPermissionGroupId);
  const [baseEmployeeId, setBaseEmployeeId] = useState<number | null>(props.settings.employeePermissionGroupId);
  const [baseId, setBaseId] = useState<number | null>(props.settings.basePermissionGroupId);

  const [changeRecords, setChangeRecords] = useState<
    { id: number; permissionGroupId: number | undefined; divisionId: number | undefined }[]
  >([]);

  const confirmRemoveUser = (id: number) => {
    if (props.onRemoveUser) {
      userChangePermissionGroup(id);
      props.onRemoveUser(id);
      setChangesPending(false);
    }
  };

  const tableColumns: DataTableColumnProps<ParentSecurityUserSettingsDTO>[] = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      defaultSortOrder: 'ascend',
      sorter: {},
      filterType: FilterType.Text,
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      defaultSortOrder: 'ascend',
      sorter: {},
      filterType: FilterType.Text,
    },
    {
      title: 'Email',
      dataIndex: 'username',
      defaultSortOrder: 'ascend',
      sorter: {},
      filterType: FilterType.Text,
    },
    DataTableColumnUtil.BooleanYesNo<ParentSecurityUserSettingsDTO>(
      'MFA Required',
      'requireMfa',
      undefined,
      FilterType.BooleanRadio,
      { align: 'left' }
    ),
    DataTableColumnUtil.BooleanYesNo<ParentSecurityUserSettingsDTO>(
      'Registered',
      'registered',
      undefined,
      FilterType.BooleanRadio,
      { align: 'left' }
    ),
    DataTableColumnUtil.DateTime<ParentSecurityUserSettingsDTO>('Last Logged In', 'lastLoggedInOn', undefined, {
      align: 'left',
    }),
    {
      title: 'Addon Permission Group',
      dataIndex: 'permissionGroupId',
      // eslint-disable-next-line react/display-name
      render: (v: any, r: ParentSecurityUserSettingsDTO) => {
        return (
          <Select
            showSearch
            allowClear={true}
            placeholder="Select Permission Group"
            disabled={props.readOnly !== false}
            optionFilterProp="children"
            style={{ width: 400 }}
            value={getUserPermissionGroupValue(r.id)}
            onChange={(v) => userChangePermissionGroup(r.id, v)}
          >
            {props.forClient && r.role == 'Employee'
              ? memberPermissionGroups.map((p) => (
                  <Select.Option key={p.id} value={p.id}>
                    {p.name}
                  </Select.Option>
                ))
              : permissionGroups.map((p) => (
                  <Select.Option key={p.id} value={p.id}>
                    {p.name}
                  </Select.Option>
                ))}
          </Select>
        );
      },
    },
  ];

  if (props.forClient) {
    tableColumns.splice(2, 0, {
      title: 'Role',
      dataIndex: 'role',
      defaultSortOrder: 'ascend',
      sorter: {},
    });
  }

  if (props.forClient && (props.clientDivisions?.length ?? 0) > 1) {
    tableColumns.splice(3, 0, {
      title: 'Division',
      dataIndex: 'divisionId',
      render: (v: any, r: ParentSecurityUserSettingsDTO) => {
        return (
          <Select
            showSearch
            disabled={props.readOnly !== false}
            optionFilterProp="children"
            style={{ width: 300 }}
            value={getUserDivisionValue(r.id)}
            onChange={(v) => userChangeDivision(r.id, v)}
          >
            {props.clientDivisions?.map((c) => (
              <Select.Option key={c.id} value={c.id}>
                {c.name}
              </Select.Option>
            ))}
          </Select>
        );
      },
    });
  }

  if (props.onRemoveUser) {
    tableColumns.push({
      dataIndex: 'remove',
      title: '',
      render: (v: any, r: ParentSecurityUserSettingsDTO) => (
        <Button
          onClick={() =>
            confirmModal('Are you sure you want to remove this user association?', () => confirmRemoveUser(r.id))
          }
          type="ghost"
          danger
          size="small"
          shape="round"
          disabled={props.readOnly !== false}
        >
          Remove
        </Button>
      ),
    });
  }

  const saveChanges = () => {
    if (props.onSave) {
      const model = { ...props.settings };
      model.basePermissionGroupId = baseId;
      model.employerPermissionGroupId = baseEmployerId;
      model.employeePermissionGroupId = baseEmployeeId;
      model.userSettings = model.userSettings ?? [];

      changeRecords.forEach((v) => {
        const userId = v.id;
        let setting = model.userSettings?.find((u) => u.id === userId);
        if (!setting) {
          setting = ParentSecurityUserSettingsDTO.create({ id: userId });
          model.userSettings?.push(setting);
        }
        const changeRecord = changeRecords.find((r) => r.id === userId);
        setting.permissionGroupId = changeRecord?.permissionGroupId ?? null;
        setting.divisionId = changeRecord?.divisionId ?? null;
      });

      props.onSave(model);
    }
  };

  const reset = () => {
    setBaseEmployerId(props.settings.employerPermissionGroupId);
    setBaseEmployeeId(props.settings.employeePermissionGroupId);
    setBaseId(props.settings.basePermissionGroupId);
    dtRef.current?.refresh();
    setChangesPending(false);
    setChangeRecords([]);
  };

  useEffect(() => {
    if (props.forClient) {
      LookupsApiService.getPermissionGroups(null, Role.MEMBER).then((res) => {
        setMemberPermissionGroups(res);
      });
    }
    LookupsApiService.getPermissionGroups(null, props.roleId).then((res) => {
      setPermissionGroups(res);
    });
  }, []);

  useEffect(() => {
    reset();
  }, [props.settings]);

  const userChangePermissionGroup = (id: number, groupId?: number) => {
    if (!changesPending) {
      setChangesPending(true);
    }
    const existing = [...changeRecords];
    const indexToUpdate = existing.findIndex((e) => e.id === id);
    if (indexToUpdate === -1) {
      const currRecord = props.tableData.find((s) => s.id === id);
      existing.push({ id: id, permissionGroupId: groupId, divisionId: currRecord?.divisionId ?? undefined });
    } else {
      existing[indexToUpdate].permissionGroupId = groupId;
    }

    setChangeRecords(existing);
  };

  const getUserPermissionGroupValue = (id: number) => {
    const tableRecord = props.tableData.find((r) => r.id === id);
    const changeRecord = changeRecords.find((r) => r.id === id);

    let res = null as any;

    if (changeRecord) {
      res = (changeRecord.permissionGroupId ?? null) as any;
    } else if (tableRecord) {
      res = (tableRecord.permissionGroupId ?? null) as any;
    }
    return res;
  };

  const userChangeDivision = (id: number, divisionId?: number) => {
    if (!changesPending) {
      setChangesPending(true);
    }
    const existing = [...changeRecords];
    const indexToUpdate = existing.findIndex((e) => e.id === id);
    if (indexToUpdate === -1) {
      const currRecord = props.tableData.find((s) => s.id === id);
      existing.push({ id: id, permissionGroupId: currRecord?.permissionGroupId ?? undefined, divisionId: divisionId });
    } else {
      existing[indexToUpdate].divisionId = divisionId;
    }

    setChangeRecords(existing);
  };

  const getUserDivisionValue = (id: number) => {
    const tableRecord = props.tableData.find((r) => r.id === id);
    const changeRecord = changeRecords.find((r) => r.id === id);

    let res = null as any;

    if (changeRecord) {
      res = (changeRecord.divisionId ?? null) as any;
    } else if (tableRecord) {
      res = (tableRecord.divisionId ?? null) as any;
    }
    return res;
  };

  const getAllPermissionGroupIds = () => {
    const permissionGroupIds = [baseEmployeeId, baseEmployerId, baseId];
    changeRecords.forEach((x) => permissionGroupIds.push(x.permissionGroupId ?? null));

    props.tableData.forEach((x) => {
      if (changeRecords.findIndex((y) => y.id == x.id) > -1) {
        permissionGroupIds.push(changeRecords.find((y) => y.id == x.id)?.permissionGroupId ?? null);
      } else {
        permissionGroupIds.push(x.permissionGroupId ?? null);
      }
    });

    return permissionGroupIds;
  };

  return (
    <>
      <PageStayPrompt when={changesPending} />

      <Row align="bottom" gutter={12}>
        <Col xs={12}>
          <h2>{props.title}</h2>
        </Col>
        <Col style={{ textAlign: 'right' }}>
          <Space>
            <PermissionGroupHelperButton permissionGroupIds={getAllPermissionGroupIds()}>
              View Permission Groups
            </PermissionGroupHelperButton>
            {props.readOnly === false ? (
              <>
                <Button
                  onClick={() =>
                    confirmModal('Are you sure you want to reset all changes?', () => reset(), changesPending)
                  }
                  htmlType="button"
                  ghost
                  shape="round"
                >
                  Reset
                </Button>

                <Button onClick={saveChanges} type="primary" shape="round" disabled={!changesPending}>
                  Save
                </Button>
              </>
            ) : null}
          </Space>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          {props.forClient === true ? (
            <>
              <Space direction="vertical">
                {/* employerPermissionGroupId */}
                <strong>Base Employer Permission Group</strong>
                <Select
                  placeholder="Select Permission Group"
                  showSearch={true}
                  style={{ width: 500 }}
                  allowClear
                  optionFilterProp="children"
                  disabled={props.readOnly !== false}
                  value={baseEmployerId ?? undefined}
                  onChange={(v) => {
                    setChangesPending(true);
                    setBaseEmployerId(v);
                  }}
                >
                  {permissionGroups.map((x) => (
                    <Select.Option key={x.id} value={x.id}>
                      {x.name}
                    </Select.Option>
                  ))}
                </Select>
                <span className="ant-form-item-explain">Permission group employers will inherit.</span>

                <strong>Base Employee Permission Group</strong>
                {/* employeePermissionGroupId */}
                <Select
                  placeholder="Select Permission Group"
                  showSearch={true}
                  style={{ width: 500 }}
                  allowClear
                  optionFilterProp="children"
                  disabled={props.readOnly !== false}
                  value={baseEmployeeId ?? undefined}
                  onChange={(v) => {
                    setChangesPending(true);
                    setBaseEmployeeId(v);
                  }}
                >
                  {memberPermissionGroups.map((x) => (
                    <Select.Option key={x.id} value={x.id}>
                      {x.name}
                    </Select.Option>
                  ))}
                </Select>
                <span className="ant-form-item-explain">Permission group all employees will inherit from.</span>
              </Space>
            </>
          ) : (
            <Space direction="vertical">
              {/* basePermissionGroupId */}
              <strong>Base Permission Group</strong>
              <Select
                placeholder="Select Permission Group"
                showSearch={true}
                style={{ width: 500 }}
                allowClear
                optionFilterProp="children"
                disabled={props.readOnly !== false}
                value={baseId ?? undefined}
                onChange={(v) => {
                  setChangesPending(true);
                  setBaseId(v);
                }}
              >
                {permissionGroups.map((x) => (
                  <Select.Option key={x.id} value={x.id}>
                    {x.name}
                  </Select.Option>
                ))}
              </Select>
              <span className="ant-form-item-explain">Permission Group all users will inherit from.</span>
            </Space>
          )}
        </Col>
      </Row>
      <br />

      <h3>User Addon Permission Groups {props.forClient ? null : '(Applies to All Clients)'}</h3>
      <DataTable
        ref={dtRef}
        serverSide={true}
        globalSearch={true}
        globalSearchPlaceholder="Search Users"
        tableProps={{
          rowKey: 'id',
          loading: props.tableLoading,
        }}
        columns={tableColumns}
        data={props.tableData}
        fetchData={props.fetchData}
        styleOptions={{
          alternatingRowHighlight: true,
        }}
      />
    </>
  );
};

export default ParentSecurityManager;
