import React from 'react';
import { Link } from 'react-router-dom';
import useUrlQuery from '../hooks/useUrlQuery';

interface UnauthorizedPageRouteProps {
  mobileview?: string;
}

const Unauthorized = () => {
  const [routeQuery] = useUrlQuery<UnauthorizedPageRouteProps>();

  return (
    <div style={{ padding: '24px' }}>
      <h2>401 - Unauthorized</h2>
      <p>
        You are not authorized to go to this page. Please contact an administrator if you feel you got here by mistake.
      </p>
      {routeQuery.mobileview ? null : <Link to="/">Go to Homepage</Link>}
    </div>
  );
};

export default Unauthorized;
