import { Modal, notification, Spin, Space } from 'antd';
import moment from 'moment-timezone';
import React, { useEffect, useState, useContext } from 'react';
import PnfDTO from '../../models/PnfDTO';
import ProviderNominationService from '../../api/ProviderNominationsApiService';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import CardButton from '../shared/CardButton/CardButton';
import { AuthenticationContext } from '../../auth/AuthenticationContext';
import PinAccessTypes from '../../consts/PinAccessTypes';
import { SetAppointmentTimeZones } from '../../pages/tools/ProviderNominationPage';

const useDraftSaving = () => {
  const authContext = useContext(AuthenticationContext);
  const isUser =
    (authContext?.user?.isGuestAccount ?? true) === false ||
    (authContext?.user?.guestAccountType === PinAccessTypes.INDIVIDUAL ?? false);

  const [checkingDraft, setCheckingDraft] = useState(isUser);
  const [draftDto, setDraftDto] = useState<PnfDTO | undefined>();
  const [holdingDraftDto, setHoldingDraftDto] = useState<PnfDTO | undefined>();
  const [lastSave, setLastSave] = useState<moment.Moment | null>(null);
  const [savingDraft, setSavingDraft] = useState(false);
  const [draftModalVisible, setDraftModalVisible] = useState(false);

  const transformDto = (dto: PnfDTO) => {
    if (dto.primaryMember?.dateOfBirth) {
      dto.primaryMember.dateOfBirth = moment.utc(dto.primaryMember.dateOfBirth);
    }
    if (dto.familyMembers) {
      dto.familyMembers.forEach((m) => {
        if (m.dateOfBirth) {
          m.dateOfBirth = moment.utc(m.dateOfBirth);
        }
      });
    }
    if (dto.providers) {
      dto.providers.forEach((p) => {
        if (p.appointments) {
          p.appointments.forEach((a) => {
            if (a.appointmentDate) {
              if (a.appointmentTimeZone) {
                // .tz() will stop the moment from converting to the users local timezone
                a.appointmentDate = moment(a.appointmentDate).tz(a.appointmentTimeZone);
              } else {
                a.appointmentDate = moment(a.appointmentDate);
              }
            }
          });
        }
      });
    }

    return dto;
  };

  useEffect(() => {
    if (isUser) {
      ProviderNominationService.getDraftOrPrePopulate()
        .then((dto) => {
          if (dto?.isPrePopulateRecord) {
            setDraftDto(transformDto(dto));
            return;
          }
          if (dto) {
            setLastSave(dto.lastUpdated ? moment(dto.lastUpdated) : null);
            dto = transformDto(dto);
            setHoldingDraftDto(dto);
            setDraftModalVisible(true);
          }
        })
        .finally(() => {
          setCheckingDraft(false);
        });
    }
  }, []);

  const loadDraftDto = () => {
    setDraftDto(holdingDraftDto);
    setHoldingDraftDto(undefined);
    setCheckingDraft(false);
    setDraftModalVisible(false);
  };

  const startNew = () => {
    ProviderNominationService.deleteDraft().then(() => {
      ProviderNominationService.getDraftOrPrePopulate().then((dto) => {
        if (dto?.isPrePopulateRecord) {
          setDraftDto(transformDto(dto));
          setHoldingDraftDto(undefined);
          setCheckingDraft(false);
          setDraftModalVisible(false);
        } else {
          setDraftDto(undefined);
          setHoldingDraftDto(undefined);
          setCheckingDraft(false);
          setDraftModalVisible(false);
        }
      });
    });
  };

  const saveDraft = (pnf: PnfDTO) => {
    // Only save if it's not already saving
    if (savingDraft) {
      return;
    }

    SetAppointmentTimeZones(pnf);

    setSavingDraft(true);
    ProviderNominationService.saveDraft(pnf)
      .then((res) => {
        if (!res.succeeded) {
          throw res;
        }
        setLastSave(moment());
      })
      .catch((error) => {
        let msg = 'There was an error saving the PNF.';
        if (error?.errors) {
          msg = error.errors.join('\n');
        } else if (error.message) {
          msg = error.message;
        }
        notification.error({ message: msg });
      })
      .finally(() => {
        setSavingDraft(false);
      });

    transformDto(pnf);
  };

  const lastSaveDisplay = () => {
    if (!lastSave) {
      return null;
    }
    const currentDay = moment().dayOfYear();
    const lastSaveDay = lastSave.dayOfYear();

    const saveLabel = 'Last Save:';

    if (savingDraft) {
      return (
        <>
          {saveLabel} <Spin size="small" />
        </>
      );
    }

    return (
      <>
        {saveLabel} {lastSave.format(currentDay !== lastSaveDay ? 'lll' : 'LTS')}
      </>
    );
  };

  const promptModal = (
    <Modal
      open={draftModalVisible}
      title={null}
      width={850}
      closable={false}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      footer={null}
      className="draft-modal"
    >
      <div style={{ textAlign: 'center' }}>
        <h2 style={{ maxWidth: 600, margin: 'auto', marginBottom: 25 }}>
          You have a draft PNF saved. Would you like to continue where you left off or start a new PNF submission?
        </h2>
      </div>

      <Space size={[8, 16]} style={{ width: '100%', justifyContent: 'center' }} wrap>
        <CardButton title="Start Over" icon={<PlusOutlined />} minWidth={350} extra={<>&nbsp;</>} onClick={startNew} />
        <CardButton
          title="Continue Draft"
          icon={<EditOutlined />}
          minWidth={350}
          extra={lastSaveDisplay()}
          onClick={loadDraftDto}
        />
      </Space>
    </Modal>
  );

  return (
    isUser
      ? [checkingDraft, draftDto, lastSaveDisplay, promptModal, saveDraft]
      : [false, undefined, () => null, undefined, undefined]
  ) as [boolean, PnfDTO | undefined, () => React.ReactNode, React.ReactNode | undefined, (() => void) | undefined];
};

export default useDraftSaving;
