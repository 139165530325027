import * as React from 'react';

export default class SvgIcons {
  public static readonly UsrLocation = () => (
    <svg fill="none" width="1em" viewBox="0 0 16 16">
      <path
        // tslint:disable-next-line:max-line-length
        d="M7 0C3.1346 0 0 2.93 0 6.54333C0 10.2133 3.0625 12.722 7 16C10.9375 12.722 14 10.2133 14 6.54333C14 2.93 10.8654 0 7 0ZM7.7 11.9547V9.33333H6.3V11.9547C3.7674 11.6527 1.7647 9.74467 1.4483 7.33333H4.2V6H1.4483C1.7647 3.58867 3.7681 1.68067 6.3 1.37933V4H7.7V1.37933C10.2319 1.68067 12.2353 3.58867 12.5517 6H9.8V7.33333H12.5517C12.2353 9.74467 10.2319 11.6527 7.7 11.9547Z"
        fill="#D2292D"
      />
    </svg>
  );

  public static readonly Directions = () => (
    <svg fill="none" width="2em" viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // tslint:disable-next-line:max-line-length
        d="M9.42207 0.587467L15.4248 6.57963C16.2075 7.36096 16.2075 8.62922 15.4248 9.41055L9.42207 15.4027C8.64131 16.1821 7.3769 16.1821 6.59614 15.4027L0.593384 9.41055C-0.189331 8.62922 -0.189331 7.36096 0.593383 6.57963L6.59614 0.587469C7.3769 -0.191916 8.6413 -0.191917 9.42207 0.587467ZM5.78377 10.7471H4.00476V6.33806H9.40312V4.13354L12.4704 7.19538L9.40312 10.2572V8.05269H5.78377V10.7471Z"
        fill="black"
      />
    </svg>
  );

  public static readonly MarkerIndividual = () => (
    <svg fill="none" width="2em" viewBox="0 0 11 16">
      <path
        d="M5.5 0C2.61388 0 0 2.26867 0 5.068C0 7.86667 2.38494 11.208 5.5 16C8.61506 11.208 11 7.86667 11 5.068C11 2.26867 8.38681 0 5.5 0Z"
        // fill="#D2292D"
        fill="#1934B5"
      />
      <path
        // tslint:disable-next-line:max-line-length
        d="M6.95833 4.45854C6.95833 5.26375 6.30542 5.91688 5.5 5.91688C4.69458 5.91688 4.04167 5.26375 4.04167 4.45854C4.04167 3.65292 4.69458 3 5.5 3C6.30542 3 6.95833 3.65292 6.95833 4.45854V4.45854ZM6.62542 5.95437C6.31167 6.19125 5.9225 6.33354 5.5 6.33354C5.07708 6.33354 4.68771 6.19083 4.37354 5.95396C3.52521 6.3275 3 7.49062 3 8H8C8 7.49521 7.45833 6.33208 6.62542 5.95437V5.95437Z"
        fill="white"
      />
    </svg>
  );

  public static readonly MarkerClinic = () => (
    <svg fill="none" width="2em" viewBox="0 0 11 16">
      <path
        d="M5.5 0C2.61388 0 0 2.26867 0 5.068C0 7.86667 2.38494 11.208 5.5 16C8.61506 11.208 11 7.86667 11 5.068C11 2.26867 8.38681 0 5.5 0Z"
        // fill="#D2292D"
        fill="#1934B5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // tslint:disable-next-line-max-line-length
        d="M8 8H3V7.58333H3.20833V4.875C3.58187 4.62271 3.93417 4.42646 4.27417 4.28625C4.38542 4.85771 4.89854 5.29958 5.51604 5.29167C6.12312 5.28396 6.62313 4.84021 6.72729 4.28021C7.06813 4.42083 7.41979 4.61917 7.79167 4.875V7.58333H8V8ZM5.08333 7.79167H5.91667V6.95833H5.08333V7.79167ZM4.04167 7.79167H4.875V6.95833H4.04167V7.79167ZM6.125 7.79167H6.95833V6.95833H6.125V7.79167ZM4.04167 6.54167H4.45833V6.125H4.04167V6.54167ZM4.875 6.54167H5.29167V6.125H4.875V6.54167ZM5.70833 6.54167H6.125V6.125H5.70833V6.54167ZM6.54167 6.54167H6.95833V6.125H6.54167V6.54167ZM4.04167 5.91667H4.45833V5.5H4.04167V5.91667ZM4.875 5.91667H5.29167V5.5H4.875V5.91667ZM5.70833 5.91667H6.125V5.5H5.70833V5.91667ZM6.54167 5.91667H6.95833V5.5H6.54167V5.91667ZM5.5 3C6.075 3 6.54167 3.46667 6.54167 4.04167C6.54167 4.61667 6.075 5.08333 5.5 5.08333C4.925 5.08333 4.45833 4.61667 4.45833 4.04167C4.45833 3.46667 4.925 3 5.5 3ZM5.70833 3.41667H5.29167V3.83333H4.875V4.25H5.29167V4.66667H5.70833V4.25H6.125V3.83333H5.70833V3.41667Z"
        fill="white"
      />
    </svg>
  );

  public static readonly BalanceBills = () => (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 16">
      <path
        d="M4.346 6.74c-.147.125-.32.172-.563.196V5.62c.292.096.487.196.611.292a.593.593 0 0 1 .172.415.626.626 0 0 1-.22.415Zm-1.05-3.443a.977.977 0 0 0-.514.195.596.596 0 0 0-.196.415c0 .172.048.292.148.391.096.096.291.196.563.292V3.297Zm11.479 2.785v8.11A1.804 1.804 0 0 1 12.967 16H1.807A1.804 1.804 0 0 1 0 14.192V1.808C0 .806.806 0 1.808 0h6.984c.707 0 1.05.196 1.489.659l3.811 3.859c.511.487.683.806.683 1.564ZM6.74 4.274h2.686c.319 0 .563-.243.563-.538V3.32a.55.55 0 0 0-.563-.54H6.74v1.493ZM3.297 5.496v1.44c-.44-.071-.855-.267-1.27-.634l-.538.634c.538.463 1.15.735 1.808.807v.534h.463V7.74c.514-.024.902-.172 1.22-.44.292-.267.464-.634.464-1.05 0-.438-.124-.757-.391-.977-.268-.22-.683-.415-1.246-.539h-.024V3.34c.392.048.735.22 1.05.44l.487-.683c-.487-.32-1.002-.515-1.54-.539v-.415h-.463v.367c-.463.024-.855.172-1.15.44a1.406 1.406 0 0 0-.463 1.049c0 .415.148.734.391.978.248.2.639.395 1.202.519Zm10.037 7.914a.55.55 0 0 0-.563-.539H1.951a.527.527 0 0 0-.538.539v.415c0 .291.243.539.539.539h10.82c.29 0 .562-.244.562-.54v-.414Zm0-3.373a.55.55 0 0 0-.563-.538H1.951a.527.527 0 0 0-.538.538v.415c0 .292.243.54.539.54h10.82c.29 0 .562-.244.562-.54v-.415Zm0-3.372a.55.55 0 0 0-.563-.539h-6.03v1.489h6.034c.292 0 .563-.244.563-.539v-.411h-.004Z"
        fill="#000"
      />
    </svg>
  );
}
