import DeepFreeze from '../utils/DeepFreeze';
import UserSecurityDTO from './UserSecurityDTO';
import '../extensions/ArrayExtensions';

export default class UserSecurity {
  public readonly id: number;

  public readonly userName: string | null;

  public readonly firstName: string | null;
  public readonly lastName: string | null;

  public readonly accessRoleId: number | null;
  public readonly accessEntityId: number | null;
  public readonly multiRole: boolean;
  public readonly isGuestAccount: boolean;
  public readonly guestAccountType?: string;
  public readonly requirePasswordChange: boolean;

  public readonly userPermissions: number[];
  public readonly clientPermissions: { [key: number]: number[] };

  constructor(userinfo: UserSecurityDTO) {
    this.id = userinfo.id;
    this.userName = userinfo.userName;
    this.firstName = userinfo.firstName;
    this.lastName = userinfo.lastName;
    this.accessRoleId = userinfo.accessRoleId;
    this.accessEntityId = userinfo.accessEntityId;
    this.requirePasswordChange = userinfo.requirePasswordChange;
    this.userPermissions = DeepFreeze(userinfo.permissions);
    this.clientPermissions = DeepFreeze(userinfo.clientPermissions);
    this.multiRole = userinfo.isMultiRole;
    this.isGuestAccount = userinfo.isGuestAccount;
    this.guestAccountType = userinfo.guestAccountType ?? undefined;
  }

  userHasPermission(permissionId: number) {
    const permissions: number[] = []; 
   permissions.pushAll( this.userPermissions);

    if (this.clientPermissions) {
      for (const [key, value] of Object.entries(this.clientPermissions)) {
        if (key in this.clientPermissions && typeof value == 'object') {
          permissions.pushAll( value);
        }
      }
    }
    const res = permissions.includes(permissionId);
    return res;
  }
}
