import { Button, Checkbox, Col, Form, Row, Select, Space, Spin } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import LookupService from '../../../api/LookupsApiService';
import ClientAccessDTO from '../../../models/ClientAccessDTO';
import LookupTableDTO from '../../../models/LookupTableDTO';
import PermissionGroupDetailDTO from '../../../models/PermissionGroupDetailDTO';
import TableRequestDTO from '../../../models/TableRequestDTO';
import TableResponseDTO from '../../../models/TableResponseDTO';
import UserClientAccessDTO from '../../../models/UserClientAccessDTO';
import HistoryUtil from '../../../utils/HistoryUtil';
import PageStayPrompt from '../../../utils/PageStayPrompt';
import DataTable, { DataTableColumnProps, FilterType } from '../DataTable/DataTable';
import DatePicker2, { AcceptedFormats } from '../DatePicker2';
import PermissionGroupHelperButton from './PermissionGroupHelperButton';
import DataTableColumnUtil from '../DataTable/DataTableColumnUtil';
import confirmModal from '../../../utils/Modal';

export interface AccessEditorProps {
  entityId?: number;
  entityName?: string;

  entitySelected?: (id: number) => void;

  showDates?: boolean;
  startDate?: moment.Moment;
  endDate?: moment.Moment;

  showRegistered?: boolean;

  entityList?: LookupTableDTO[];

  userData?: UserClientAccessDTO[];

  loading: boolean;
  showCancel: boolean;
  showReset?: boolean;

  backUrl?: string;
  editUrl?: (id: number) => string;
  listText?: string;

  entitySearch?: {
    entitySearchLabel: string;
    entitySearchPlaceholder: string;
  };

  saveButtonText: string;

  userAdd?: {
    userAddSearchLabel: string;
    userAddSearchPlaceholder: string;
    userAddText: string;
  };

  accessFilter?: {
    showFilter: boolean;
    initialValue: string; // true / false
  };

  readOnly?: boolean;

  onSave?: (dto: ClientAccessDTO, cb?: (success: boolean, id?: number) => void) => void;

  fetchData: (
    requestState: TableRequestDTO,
    checkEcho: () => boolean,
    callback: (response: TableResponseDTO<any>) => void
  ) => void;

  dataTableTitle?: string;

  roleId: number;
}

const AccessEditor = (props: AccessEditorProps) => {
  const [form] = Form.useForm();
  const [changesPending, setChangesPending] = useState(false);
  const [tableHidden, setTableHidden] = useState(props.entityId === undefined);
  const [permissionGroups, setPermissionGroups] = useState<PermissionGroupDetailDTO[]>([]);
  const [changeRecords, setChangeRecords] = useState<{ [id: number]: UserClientAccessDTO }>({});
  const dtRef = React.useRef<DataTable<UserClientAccessDTO>>(null);

  const disabledDate = (current: moment.Moment) => {
    const model = form.getFieldsValue();
    return !current.isAfter(model.startDate, 'day');
  };

  const initVals = { startDate: props.startDate ?? moment(), endDate: props.endDate };

  const formChanged = () => {
    if (!changesPending) {
      setChangesPending(true);
    }
  };

  const onCancel = () => {
    if (props.backUrl) {
      HistoryUtil.push(props.backUrl);
    }
  };

  const clientSelected = (value: any) => {
    setTableHidden(false);
    if (props.entitySelected) {
      props.entitySelected(value);
    }
  };

  useEffect(() => {
    LookupService.getPermissionGroups(null, props.roleId).then((groups) => {
      setPermissionGroups(groups);
    });
  }, []);

  useEffect(() => {
    setTableHidden(props.entityId === undefined);
    resetChanges();
  }, [props.entityId]);

  const resetChanges = () => {
    form.resetFields();
    setChangesPending(false);
    dtRef.current?.refresh();
    setChangeRecords({});
  };

  const saveChanges = (values: any) => {
    if (props.onSave) {
      const res = ClientAccessDTO.create({
        id: props.entityId,
        users: Object.values(changeRecords),
        startDate: values.startDate ? values.startDate.format() : null,
        endDate: values.endDate ? values.endDate.format() : null,
      });

      props.onSave(res, (success, id) => {
        if (success) {
          setChangesPending(false);
          dtRef.current?.refresh();
          setChangeRecords({});
          if (props.editUrl && id) {
            HistoryUtil.replace(props.editUrl(id));
          }
        }
      });
    }
  };

  const userChange = (id: number, groupId: number | undefined | null, hasAccess: boolean | null) => {
    if (!changesPending) {
      setChangesPending(true);
    }

    const existing = { ...changeRecords };

    if (!(id in existing)) {
      const tableRecord = props.userData?.find((u) => u.userId == id);
      existing[id] = UserClientAccessDTO.create(tableRecord);
    }

    if (groupId !== null) {
      existing[id].permissionGroupId = groupId ?? null;
    }

    if (hasAccess !== null) {
      existing[id].hasAccess = hasAccess ?? false;
    }

    if (!existing[id].hasAccess) {
      existing[id].permissionGroupId = null;
    }

    setChangeRecords(existing);
  };

  const getUserValue = (id: number): UserClientAccessDTO | undefined => {
    const tableRecord = props.userData?.find((r) => r.userId === id);
    const hasChangeRecord = id in changeRecords;

    let res = null as any;

    if (hasChangeRecord) {
      res = changeRecords[id];
    } else if (tableRecord) {
      res = tableRecord;
    }
    return res;
  };

  const getTableColumns = (): DataTableColumnProps<UserClientAccessDTO>[] => {
    const columns: DataTableColumnProps<UserClientAccessDTO>[] = [];

    columns.push({
      title: 'First Name',
      dataIndex: 'firstName',
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      filterType: FilterType.Text,
    });

    columns.push({
      title: 'Last Name',
      dataIndex: 'lastName',
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      filterType: FilterType.Text,
    });

    columns.push({
      title: 'Email',
      dataIndex: 'userName',
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      filterType: FilterType.Text,
    });

    const mfaRequiredCol = DataTableColumnUtil.BooleanYesNo<UserClientAccessDTO>(
      'MFA Required',
      'requireMfa',
      undefined,
      FilterType.BooleanRadio,
      { align: 'left' }
    );
    columns.push(mfaRequiredCol);

    const registeredCol = DataTableColumnUtil.BooleanYesNo<UserClientAccessDTO>(
      'Registered',
      'registered',
      undefined,
      FilterType.BooleanRadio,
      { align: 'left' }
    );
    columns.push(registeredCol);

    const lastLoggedInOnCol = DataTableColumnUtil.DateTime<UserClientAccessDTO>(
      'Last Logged In',
      'lastLoggedInOn',
      undefined,
      {
        align: 'left',
      }
    );
    columns.push(lastLoggedInOnCol);

    columns.push({
      title: 'Has Access To This Client',
      dataIndex: 'hasAccess',
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend'],
      filterType: props.accessFilter?.showFilter ? FilterType.BooleanRadio : undefined,
      initialFilterValue: props.accessFilter?.initialValue,
      // eslint-disable-next-line react/display-name
      render: (v, r) => {
        return (
          <Checkbox
            disabled={props.readOnly}
            checked={getUserValue(r.userId)?.hasAccess ?? false}
            onChange={(evt) => userChange(r.userId, null, evt.target.checked)}
          />
        );
      },
    });

    columns.push({
      title: 'Addon Permission Group',
      dataIndex: 'groupId',
      // eslint-disable-next-line react/display-name
      render: (v, r) => {
        if (!(getUserValue(r.userId)?.hasAccess ?? false)) {
          return null;
        }
        return (
          <Select
            showSearch
            allowClear={true}
            placeholder="Select Permission Group"
            disabled={props.readOnly !== false}
            optionFilterProp="children"
            style={{ width: 350 }}
            value={getUserValue(r.userId)?.permissionGroupId as any}
            onChange={(v) => userChange(r.userId, v, null)}
          >
            {permissionGroups.map((p) => (
              <Select.Option key={p.id} value={p.id}>
                {p.name}
              </Select.Option>
            ))}
          </Select>
        );
      },
    });

    return columns;
  };

  const getAllPermissionGroupIds = () => {
    const permissionGroupIds: (number | null)[] = [];
    Object.values(changeRecords).forEach((x) => permissionGroupIds.push(x.permissionGroupId ?? null));
    props.userData?.forEach((x) => {
      if (Object.values(changeRecords).findIndex((y) => y.userId == x.userId) > -1) {
        permissionGroupIds.push(
          Object.values(changeRecords).find((y) => y.userId == x.userId)?.permissionGroupId ?? null
        );
      } else {
        permissionGroupIds.push(x.permissionGroupId ?? null);
      }
    });

    return permissionGroupIds;
  };

  return (
    <Spin spinning={props.loading}>
      {props.backUrl ? <NavLink to={props.backUrl}>&lsaquo; {props.listText ?? 'Back'}</NavLink> : null}

      <PageStayPrompt when={changesPending} />
      <Form
        form={form}
        layout="vertical"
        onFinish={saveChanges}
        onValuesChange={formChanged}
        style={{ width: '100%' }}
        initialValues={initVals}
      >
        <Row align="middle" gutter={12}>
          <Col xs={12}>
            {props.entityId ? (
              <h2>{props.entityName}</h2>
            ) : props.entitySearch ? (
              <>
                <h3>{props.entitySearch.entitySearchLabel}</h3>
                <Form.Item name="entityId" rules={[{ required: true, message: 'Required.' }]}>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder={props.entitySearch.entitySearchPlaceholder}
                    onChange={clientSelected}
                  >
                    {(props.entityList ?? []).map((add) => (
                      <Select.Option key={add.id} value={add.id}>
                        {add.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </>
            ) : null}
          </Col>
          <Col style={{ textAlign: 'right' }}>
            <Space>
              <Form.Item>
                <PermissionGroupHelperButton permissionGroupIds={getAllPermissionGroupIds()}>
                  View Permission Groups
                </PermissionGroupHelperButton>
              </Form.Item>
              {props.readOnly === false ? (
                <Space>
                  {props.showCancel ? (
                    <Form.Item>
                      <Button htmlType="button" ghost shape="round" onClick={onCancel}>
                        Cancel
                      </Button>
                    </Form.Item>
                  ) : null}

                  {props.showReset === true ? (
                    <Form.Item>
                      <Button
                        onClick={() =>
                          confirmModal(
                            'Are you sure you want to reset all changes?',
                            () => resetChanges(),
                            changesPending
                          )
                        }
                        htmlType="button"
                        shape="round"
                        ghost
                      >
                        Reset
                      </Button>
                    </Form.Item>
                  ) : null}

                  <Form.Item>
                    <Button htmlType="submit" type="primary" shape="round" disabled={!changesPending}>
                      {props.saveButtonText}
                    </Button>
                  </Form.Item>
                </Space>
              ) : null}
            </Space>
          </Col>
        </Row>
        {props.showDates ? (
          <Row>
            <Col>
              <Form.Item
                name="startDate"
                label="Access Start Date"
                rules={[{ required: true, message: 'Start Date is required.' }]}
              >
                <DatePicker2
                  format={AcceptedFormats}
                  style={{ marginRight: '12px' }}
                  disabled={props.readOnly !== false}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="endDate" label="Access End Date" rules={[{ required: false }]}>
                <DatePicker2
                  format={AcceptedFormats}
                  style={{ marginBottom: '24px' }}
                  disabledDate={disabledDate}
                  disabled={props.readOnly !== false}
                />
              </Form.Item>
            </Col>
          </Row>
        ) : null}
      </Form>

      {tableHidden ? null : (
        <>
          {props.dataTableTitle ? <h2>{props.dataTableTitle}</h2> : null}
          <DataTable
            ref={dtRef}
            serverSide={true}
            globalSearch={true}
            globalSearchPlaceholder="Search Users"
            tableProps={{
              rowKey: 'userId',
            }}
            columns={getTableColumns()}
            data={props.userData ?? []}
            fetchData={props.fetchData}
            styleOptions={{
              alternatingRowHighlight: true,
            }}
          />
        </>
      )}
    </Spin>
  );
};

export default AccessEditor;
