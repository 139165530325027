import { CheckCircleOutlined, CloseCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Input, Space, Typography } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import React, { useState } from 'react';

const PasswordFormItem = () => {
  const [validated, setValidated] = useState(false);

  const [minLengthMet, setMinLengthSet] = useState(false);
  const [digitMet, setDigitMet] = useState(false);
  const [specialCharMet, setSpecialCharMet] = useState(false);

  const allMet = minLengthMet && digitMet && specialCharMet;

  const rules = [
    {
      key: 'minLength',
      name: 'Minimum length: 12',
      validate: (value: string) => value.length >= 12,
      setValid: setMinLengthSet,
      isValid: minLengthMet,
    },
    {
      key: 'digit',
      name: 'Contains digit: 0-9',
      validate: (value: string) => /\d/.test(value),
      setValid: setDigitMet,
      isValid: digitMet,
    },
    {
      key: 'specialChar',
      name: 'Contains Special: -+_!@#$%^&*.,?',
      validate: (value: string) => /[-+_!@#$%^&*.,?]/.test(value),
      setValid: setSpecialCharMet,
      isValid: specialCharMet,
    },
  ];

  const checkRequirements = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    rules.forEach((rule) => {
      const valid = rule.validate(value);
      rule.setValid(valid);
    });
    return e;
  };

  return (
    <FormItem label="Password" required={true}>
      <FormItem
        name="password"
        noStyle
        rules={[
          {
            required: true,
            message: 'Required',
          },
          () => ({
            async validator(_, value) {
              setValidated(true);
              // No value, only show required
              if (!value) {
                return Promise.resolve();
              }
              if (allMet) {
                return Promise.resolve();
              }
              return Promise.reject();
            },
            message: 'Complexity must be met',
            validateTrigger: 'submit',
          }),
        ]}
      >
        <Input.Password onChangeCapture={checkRequirements} />
      </FormItem>
      <Typography.Paragraph style={{ marginBottom: 0 }}>
        {rules.map((rule) => {
          return (
            <div key={rule.key}>
              <Typography.Text type={validated ? (rule.isValid ? 'success' : 'danger') : 'secondary'}>
                <Space direction="horizontal">
                  <Typography.Text>{rule.name}</Typography.Text>
                  <Typography.Text
                    type={rule.isValid ? 'success' : validated ? (rule.isValid ? 'success' : 'danger') : 'secondary'}
                  >
                    {rule.isValid ? (
                      <CheckCircleOutlined />
                    ) : validated ? (
                      rule.isValid ? (
                        <CheckCircleOutlined />
                      ) : (
                        <CloseCircleOutlined />
                      )
                    ) : (
                      <QuestionCircleOutlined />
                    )}
                  </Typography.Text>
                </Space>
              </Typography.Text>
            </div>
          );
        })}
      </Typography.Paragraph>
    </FormItem>
  );
};

export default PasswordFormItem;
