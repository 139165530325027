import { Button, Col, Layout, Modal, notification, Row, Space } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import DataManagementApiService from '../../api/DataManagementApiService';
import OtherCompanyApiService from '../../api/OtherCompanyApiService';
import DataTable, { DataTableColumnProps } from '../../components/shared/DataTable/DataTable';
import PageTitle from '../../components/shared/PageTitle';
import PermissionGuard from '../../components/shared/PermissionGuard';
import Permission from '../../consts/Permission';
import Routes from '../../consts/Routes';
import OtherCompanyListDTO from '../../models/OtherCompanyListDTO';
import TableRequestDTO from '../../models/TableRequestDTO';
import TableResponseDTO from '../../models/TableResponseDTO';
import TpaListDTO from '../../models/TpaListDTO';
import HistoryUtil from '../../utils/HistoryUtil';
import MobileUtil from '../../utils/MobileUtil';
import Renderers from '../../utils/Renderers';
import DataTableButtonUtil from '../../components/shared/DataTable/DataTableButtonUtil';
import OtherCompanyDetailForm from '../../components/admin/OtherCompany/OtherCompanyDetailForm';
import { useForm } from 'antd/lib/form/Form';
import OtherCompanyDetailDTO from '../../models/OtherCompanyDetailDTO';
import useHasPermission from '../../hooks/useHasPermission';

const Content = Layout.Content;

const OtherCompanyListPage = () => {
  const hasPermission = useHasPermission();

  const [tableData, setTableData] = useState<OtherCompanyListDTO[]>([]);
  const [loading, setLoading] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [saving, setSaving] = useState(false);
  const [form] = useForm();
  const dataTableRef = React.useRef<DataTable<any>>(null);

  const isMobile = () => MobileUtil.getMediaQuery()?.matches;

  const tableColumns = [
    {
      title: 'Id',
      dataIndex: 'claimDocId',
      sorter: {},
    },
    {
      title: 'Code',
      dataIndex: 'code',
      sorter: {},
    },
    {
      title: 'Name',
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      sorter: {},
    },
    {
      title: 'Location',
      dataIndex: 'location',
      sorter: {},
      renderDataTransform: (value: any) => (value != null && value.trim().length > 1 ? value : ''),
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      render: (v) => Renderers.phoneNumber(v),
    },
    {
      title: 'Term Date',
      dataIndex: 'termDate',
      render: (v) => Renderers.shortDate(v),
    },
    {
      title: '',
      dataIndex: '',
      width: '40px',
      // eslint-disable-next-line react/display-name
      render: (rowData: OtherCompanyListDTO) => (
        <Link
          to={Routes.generate(Routes.ADMIN_OTHERCOMPANY_DETAILS, {
            id: rowData.id,
          })}
        >
          <Button shape="round" ghost>
            Details
          </Button>
        </Link>
      ),
    },
  ] as DataTableColumnProps<any>[];

  const syncOtherCompanies = () => {
    setLoading(true);
    DataManagementApiService.executeOtherCompanySync()
      .then(() => {
        notification.success({ message: 'Sync Complete' });
      })
      .catch(() => {
        notification.error({ message: 'Sync Failed' });
      })
      .finally(() => {
        setLoading(false);
        dataTableRef.current?.refresh();
      });
  };

  const goToOtherCompanyDetails = (otherCompanyId: number) => {
    HistoryUtil.push(
      Routes.generate(Routes.ADMIN_OTHERCOMPANY_DETAILS, {
        id: otherCompanyId,
      })
    );
  };

  const handleAddOtherCompany = () => {
    form.validateFields().then((model) => {
      const dto: OtherCompanyDetailDTO = { ...model };
      setSaving(true);
      OtherCompanyApiService.editOtherCompany(dto)
        .then((res) => {
          if (res) {
            notification.success({ message: 'Saved successfully.' });
          } else {
            notification.error({ message: 'Error while while saving.' });
          }
        })
        .catch(() => {
          notification.error({ message: 'Error while while saving.' });
        })
        .finally(() => {
          dataTableRef?.current?.refresh();
          setShowAdd(false);
          setSaving(false);
        });
    });
  };

  const fetchOtherCompanyTableData = (
    requestState: TableRequestDTO,
    checkEcho: () => boolean,
    callback: (response: TableResponseDTO<TpaListDTO>) => void
  ) => {
    setLoading(true);

    OtherCompanyApiService.getOtherCompanyList(requestState)
      .then((results) => {
        if (!checkEcho()) {
          return;
        }
        setLoading(false);
        setTableData(results.results || []);

        callback(results);
      })
      .catch((error: any) => {
        setLoading(false);
        notification.error({
          message: error.message,
          description: error.description,
        });
      });
  };

  const actionButtons = [];
  if (hasPermission(Permission.ADMIN_OTHERCOMPANY_DETAILS_EDIT)) {
    actionButtons.push(DataTableButtonUtil.Primary('Add', () => setShowAdd(true)));
  }

  return (
    <Content className="content-padding">
      <Row>
        <Col xs={24}>
          <Row className="content-header" justify="space-between">
            <Col xs={24} lg={10} xl={13} xxl={14}>
              <PageTitle title="Other Companies" />
            </Col>
            <Col>
              <Space direction="horizontal">
                {0 > 1 && ( // Hide until this is implemented
                  <PermissionGuard permissionId={Permission.ETL_OTHERCOMPANY_EXECUTESYNC}>
                    <Button type="primary" shape="round" disabled={loading} onClick={syncOtherCompanies}>
                      Sync Other Companies
                    </Button>
                  </PermissionGuard>
                )}
              </Space>
            </Col>
          </Row>
          <Modal
            title="Add Other Company"
            open={showAdd}
            okText="Save"
            okButtonProps={{
              disabled: saving,
              loading: saving,
            }}
            cancelButtonProps={{
              disabled: saving,
            }}
            onOk={() => handleAddOtherCompany()}
            onCancel={() => setShowAdd(false)}
          >
            <OtherCompanyDetailForm form={form} />
          </Modal>
          <DataTable
            ref={dataTableRef}
            buttonBar={actionButtons}
            serverSide={true}
            globalSearch={true}
            globalSearchPlaceholder="Search Other Companies..."
            tableProps={{
              rowKey: 'id',
              size: 'large',
              loading: loading,
              sortDirections: ['ascend', 'descend'],
              scroll: { x: 575 },
              onRow: (record: OtherCompanyListDTO) => {
                return {
                  onDoubleClick: () => {
                    if (!isMobile()) {
                      goToOtherCompanyDetails(record.id);
                    }
                  },
                  onClick: () => {
                    if (isMobile()) {
                      goToOtherCompanyDetails(record.id);
                    }
                  },
                };
              },
            }}
            columns={tableColumns}
            data={tableData}
            fetchData={fetchOtherCompanyTableData}
            styleOptions={{
              alternatingRowHighlight: true,
            }}
          />
        </Col>
      </Row>
    </Content>
  );
};

export default OtherCompanyListPage;
