import { Col, Form, FormInstance, InputNumber, InputNumberProps, Row, Select, Typography } from 'antd';
import React, { useContext } from 'react';
import { AuthenticationContext } from '../../auth/AuthenticationContext';
import Role from '../../consts/Role';
import RelationDTO from '../../models/RelationDTO';
import FormValidation from '../../utils/FormValidation';

const { Option } = Select;

interface PlanFormProps {
  form: FormInstance<any>;
  role: number;
  clients?: RelationDTO[];
  initialValues?: any;
}

const PlanForm: React.FC<PlanFormProps> = (props) => {
  const authCtx = useContext(AuthenticationContext);
  const role = authCtx.user?.accessRoleId || -1;

  const initialValues = {
    ...props.initialValues,
  };

  const commonProps: InputNumberProps = {
    keyboard: false,
    controls: false,
    style: { width: '100%' },
    min: 0,
  };

  const decimalProps: InputNumberProps = {
    precision: 2,
    prefix: '$',
    min: 0,
  };

  const copayHasValue = !!Form.useWatch('copay', props.form);
  const coinsuranceHasValue = !!Form.useWatch('coinsurancePercent', props.form);
  const singleDeductible = Form.useWatch('singleDeductible', props.form);
  const familyDeductible = Form.useWatch('familyDeductible', props.form);
  const singleOop = Form.useWatch('singleOutOfPocketMax', props.form);
  const familyOop = Form.useWatch('familyOutOfPocketMax', props.form);

  return (
    <Form form={props.form} initialValues={initialValues} layout="vertical" onBlur={() => props.form.validateFields()}>
      {role !== Role.MEMBER && (
        <Form.Item name="clientId" label="Client" rules={[{ required: true, message: 'Client is required' }]}>
          <Select showSearch allowClear optionFilterProp="children">
            {(props.clients || []).map((c) => (
              <Option key={c.id} value={c.id}>
                {c.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}
      <Typography.Title level={3}>Plan Information</Typography.Title>
      <Row style={{ marginBottom: '20px' }}>
        <Typography.Text type="secondary" style={{ fontSize: '16px' }}>
          This information is usually found in your Employee Benefits Enrollment Package. This is used to calculate
          out-of-pocket costs but is not required to receive pricing. You may need to contact your Benefits
          Administrator for current information.
        </Typography.Text>
      </Row>
      <Row gutter={12}>
        <Col xs={24} sm={12}>
          <Form.Item name="copay" label="Copay Amount">
            <InputNumber {...commonProps} {...decimalProps} disabled={coinsuranceHasValue} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item name="coinsurancePercent" label="Coinsurance Amount %">
            <InputNumber {...commonProps} min={0} max={100} precision={0} addonAfter="%" disabled={copayHasValue} />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Row gutter={12}>
            <Col xs={24} sm={12}>
              <Form.Item name="singleDeductible" label="Deductible Single">
                <InputNumber
                  {...commonProps}
                  {...decimalProps}
                  onBlur={() => {
                    if (singleDeductible) {
                      const satisfied = props.form.getFieldValue('singleDeductibleAmountSatisfied');
                      if (singleDeductible < satisfied) {
                        props.form.setFieldValue('singleDeductibleAmountSatisfied', singleDeductible);
                      }
                    } else {
                      props.form.setFieldValue('singleDeductibleAmountSatisfied', undefined);
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="familyDeductible"
                label="Deductible Family"
                rules={[
                  FormValidation.GreaterThanOrEqual(
                    'singleDeductible',
                    'Family deductible must be greater than single deductible'
                  ),
                ]}
              >
                <InputNumber
                  {...commonProps}
                  {...decimalProps}
                  onBlur={() => {
                    if (familyDeductible) {
                      const satisfied = props.form.getFieldValue('familyDeductibleAmountSatisfied');
                      if (familyDeductible < satisfied) {
                        props.form.setFieldValue('familyDeductibleAmountSatisfied', familyDeductible);
                      }
                    } else {
                      props.form.setFieldValue('familyDeductibleAmountSatisfied', undefined);
                    }
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <Row gutter={12}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="singleOutOfPocketMax"
                label="Out-of-Pocket Max Single"
                rules={[
                  FormValidation.GreaterThanOrEqual(
                    'singleDeductible',
                    'Single out of pocket max cannot be less than single deductible'
                  ),
                  FormValidation.GreaterThanOrEqual('copay', 'Single out of pocket max cannot be less than copay'),
                ]}
              >
                <InputNumber
                  {...commonProps}
                  {...decimalProps}
                  onBlur={() => {
                    if (singleOop) {
                      const satisfied = props.form.getFieldValue('singleOutOfPocketMaxSatisfied');
                      if (singleOop < satisfied) {
                        props.form.setFieldValue('singleOutOfPocketMaxSatisfied', singleOop);
                      }
                    } else {
                      props.form.setFieldValue('singleOutOfPocketMaxSatisfied', undefined);
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="familyOutOfPocketMax"
                label="Out-of-Pocket Max Family"
                rules={[
                  FormValidation.GreaterThanOrEqual(
                    'singleOutOfPocketMax',
                    'Family out of pocket max must be greater than single out of pocket max'
                  ),
                  FormValidation.GreaterThanOrEqual(
                    'familyDeductible',
                    'Family out of pocket max cannot be less than family deductible'
                  ),
                ]}
              >
                <InputNumber
                  {...commonProps}
                  {...decimalProps}
                  onBlur={() => {
                    if (familyOop) {
                      const satisfied = props.form.getFieldValue('familyOutOfPocketMaxSatisfied');
                      if (familyOop < satisfied) {
                        props.form.setFieldValue('familyOutOfPocketMaxSatisfied', familyOop);
                      }
                    } else {
                      props.form.setFieldValue('familyOutOfPocketMaxSatisfied', undefined);
                    }
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <br />
      <Typography.Title level={3}>Patient Information</Typography.Title>
      <Row style={{ marginBottom: '20px' }}>
        <Typography.Text type="secondary" style={{ fontSize: '16px' }}>
          This information pertains to your plan utilization during the current plan year. This is used to calculate
          out-of-pocket costs but is not required to receive pricing. You may need to contact your Benefits
          Administrator for current information.
        </Typography.Text>
      </Row>
      <Row gutter={12}>
        <Col xs={24}>
          <Row gutter={12}>
            <Col xs={24} sm={12}>
              <Form.Item name="singleDeductibleAmountSatisfied" label="Deductible Single Satisfied">
                <InputNumber {...commonProps} {...decimalProps} disabled={!singleDeductible} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item name="familyDeductibleAmountSatisfied" label="Deductible Family Satisfied">
                <InputNumber {...commonProps} {...decimalProps} disabled={!familyDeductible} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <Row gutter={12}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="singleOutOfPocketMaxSatisfied"
                label="Out-of-Pocket Max Single Satisfied"
                rules={[
                  FormValidation.GreaterThanOrEqual(
                    'singleDeductibleAmountSatisfied',
                    'Single out of pocket max satisfied cannot be less than single deductible satisfied'
                  ),
                ]}
              >
                <InputNumber {...commonProps} {...decimalProps} disabled={!singleOop} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="familyOutOfPocketMaxSatisfied"
                label="Out-of-Pocket Max Family Satisfied"
                rules={[
                  FormValidation.GreaterThanOrEqual(
                    'familyDeductibleAmountSatisfied',
                    'Family out of pocket max satisfied cannot be less than family deductible satisfied'
                  ),
                ]}
              >
                <InputNumber {...commonProps} {...decimalProps} disabled={!familyOop} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default PlanForm;
