// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import DocumentListDTO from '../models/DocumentListDTO';
import PNFListDTO from '../models/PNFListDTO';
import BalanceBillListDTO from '../models/BalanceBillListDTO';

export class DocumentApiService extends BaseApi {

    // post: api/document/GetMRFList
    public getMRFList(tableRequest: TableRequestDTO): Promise<TableResponseDTO<DocumentListDTO>> {
        let url = `api/document/GetMRFList`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<DocumentListDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/document/GetPNFList
    public getPNFList(tableRequest: TableRequestDTO): Promise<TableResponseDTO<PNFListDTO>> {
        let url = `api/document/GetPNFList`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<PNFListDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/document/GetBalanceBillList
    public getBalanceBillList(tableRequest: TableRequestDTO): Promise<TableResponseDTO<BalanceBillListDTO>> {
        let url = `api/document/GetBalanceBillList`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<BalanceBillListDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/document/GetPNFDocument?referenceNumber=${encodeURIComponent(referenceNumber)}
    public getPNFDocument(referenceNumber: string): Promise<string> {
        let url = `api/document/GetPNFDocument`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (referenceNumber != null) {
            url += `${prefix}referenceNumber=${encodeURIComponent(referenceNumber)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, string>(null, 'post', url, true, false);
    }

    // post: api/document/GetBalanceBillDocument?id=${id}&fileId=${fileId}
    public getBalanceBillDocument(id: number, fileId: number): Promise<string> {
        let url = `api/document/GetBalanceBillDocument`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (id != null) {
            url += `${prefix}id=${id}`;
            prefix = '&';
        }
        if (fileId != null) {
            url += `${prefix}fileId=${fileId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, string>(null, 'post', url, true, false);
    }
}
var service = new DocumentApiService();
export default service;
