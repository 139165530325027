// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import PinAccessDTO from '../models/PinAccessDTO';
import ResetGuestPinDTO from '../models/ResetGuestPinDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import LookupTableDTO from '../models/LookupTableDTO';
import ResultDTO from '../models/ResultDTO';

export class PinAccessApiService extends BaseApi {

    // post: api/pin-access/GetPinAccessList
    public getPinAccessList(tableRequest: TableRequestDTO): Promise<TableResponseDTO<PinAccessDTO>> {
        let url = `api/pin-access/GetPinAccessList`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<PinAccessDTO>>(tableRequest, 'post', url, true, false);
    }

    // get: api/pin-access/GetPinDetails?id=${id}
    public getPinDetails(id: number): Promise<PinAccessDTO> {
        let url = `api/pin-access/GetPinDetails`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (id != null) {
            url += `${prefix}id=${id}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, PinAccessDTO>(null, 'get', url, true, false);
    }

    // get: api/pin-access/Clients
    public clients(): Promise<LookupTableDTO[]> {
        let url = `api/pin-access/Clients`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, LookupTableDTO[]>(null, 'get', url, true, false);
    }

    // get: api/pin-access/ParentPins?currentPinId=${currentPinId}
    public parentPins(currentPinId?: number | null | null): Promise<LookupTableDTO[]> {
        let url = `api/pin-access/ParentPins`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (currentPinId != null) {
            url += `${prefix}currentPinId=${currentPinId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, LookupTableDTO[]>(null, 'get', url, true, false);
    }

    // post: api/pin-access/SavePinDetails
    public savePin(dto: PinAccessDTO): Promise<number> {
        let url = `api/pin-access/SavePinDetails`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, number>(dto, 'post', url, true, false);
    }

    // post: api/pin-access/ResetGuestPIN
    public resetGuestPIN(dto: ResetGuestPinDTO): Promise<ResultDTO> {
        let url = `api/pin-access/ResetGuestPIN`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ResultDTO>(dto, 'post', url, true, false);
    }
}
var service = new PinAccessApiService();
export default service;
