// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import AddressDTO from './AddressDTO';
import PnfAppointmentDTO from './PnfAppointmentDTO';

interface PnfProviderInfoDTO { 
    id: string | null;
    npi: string | null;
    confirmedNpi: boolean;
    autoFillOption: string | null;
    practitionerFirstName: string | null;
    practitionerLastName: string | null;
    facilityName: string | null;
    address: AddressDTO | null;
    phoneNumber: string | null;
    website: string | null;
    note: string | null;
    providerTypeId: number;
    appointments: PnfAppointmentDTO[] | null;
}
const PnfProviderInfoDTO = {
    create: (initValues?: Partial<PnfProviderInfoDTO> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            id: "00000000-0000-0000-0000-000000000000",
            npi: null,
            confirmedNpi: false,
            autoFillOption: null,
            practitionerFirstName: null,
            practitionerLastName: null,
            facilityName: null,
            address: null,
            phoneNumber: null,
            website: null,
            note: null,
            providerTypeId: 0,
            appointments: [],
        },
        initValues);
    }
};

export default PnfProviderInfoDTO;