// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';

interface TableRequestFilterDTO { 
    columnProp: string | null;
    filter: string | null;
}
const TableRequestFilterDTO = {
    create: (initValues?: Partial<TableRequestFilterDTO> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            columnProp: null,
            filter: null,
        },
        initValues);
    }
};

export default TableRequestFilterDTO;