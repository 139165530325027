// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import ContactDTO from '../models/ContactDTO';
import ClientAccessDTO from '../models/ClientAccessDTO';
import UserClientAccessDTO from '../models/UserClientAccessDTO';
import ParentSecuritySettingsDTO from '../models/ParentSecuritySettingsDTO';
import ClientFeatureFlagsDTO from '../models/ClientFeatureFlagsDTO';
import InviteUserDTO from '../models/InviteUserDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import ClientListDTO from '../models/ClientListDTO';
import ClientDetailDTO from '../models/ClientDetailDTO';
import SaveClientDetailResultDTO from '../models/SaveClientDetailResultDTO';
import ResultDTO from '../models/ResultDTO';
import TpaListDTO from '../models/TpaListDTO';
import DpcListDTO from '../models/DpcListDTO';
import OtherCompanyListDTO from '../models/OtherCompanyListDTO';
import BrokerListDTO from '../models/BrokerListDTO';
import ParentSecurityUserSettingsDTO from '../models/ParentSecurityUserSettingsDTO';

export class ClientApiService extends BaseApi {

    // post: api/client/GetClientList
    public getClientList(tableRequest: TableRequestDTO): Promise<TableResponseDTO<ClientListDTO>> {
        let url = `api/client/GetClientList`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<ClientListDTO>>(tableRequest, 'post', url, true, false);
    }

    // get: api/client/GetClientDivisionList?clientId=${clientId}&includeParent=${includeParent}
    public getClientDivisions(clientId: number, includeParent?: boolean | null): Promise<ClientDetailDTO[]> {
        let url = `api/client/GetClientDivisionList`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (clientId != null) {
            url += `${prefix}clientId=${clientId}`;
            prefix = '&';
        }
        if (includeParent != null) {
            url += `${prefix}includeParent=${includeParent}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ClientDetailDTO[]>(null, 'get', url, true, false);
    }

    // post: api/client/GetClientName?clientId=${clientId}
    public getClientName(clientId: number): Promise<string> {
        let url = `api/client/GetClientName`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (clientId != null) {
            url += `${prefix}clientId=${clientId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, string>(null, 'post', url, true, false);
    }

    // post: api/client/GetClientDetails?clientId=${clientId}
    public getClientDetails(clientId: number): Promise<ClientDetailDTO> {
        let url = `api/client/GetClientDetails`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (clientId != null) {
            url += `${prefix}clientId=${clientId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ClientDetailDTO>(null, 'post', url, true, false);
    }

    // post: api/client/SaveNotes?clientId=${clientId}
    public saveNotes(clientId: number, note: string): Promise<SaveClientDetailResultDTO> {
        let url = `api/client/SaveNotes`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (clientId != null) {
            url += `${prefix}clientId=${clientId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveClientDetailResultDTO>(note, 'post', url, true, false);
    }

    // delete: api/client/${clientId}
    public deleteClient(clientId: number): Promise<void> {
        let url = `api/client/${clientId}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, void>(null, 'delete', url, true, false);
    }

    // get: api/client/${clientId}/contacts/${contactId}
    public getContacts(clientId: number, contactId?: number | null | null): Promise<ContactDTO[]> {
        let url = `api/client/${clientId}/contacts/${contactId}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ContactDTO[]>(null, 'get', url, true, false);
    }

    // put: api/client/${clientId}/contacts/${contactId}
    public updateContact(clientId: number, contactId: number, dto: ContactDTO): Promise<ResultDTO> {
        let url = `api/client/${clientId}/contacts/${contactId}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ResultDTO>(dto, 'put', url, true, false);
    }

    // post: api/client/${clientId}/contacts
    public addContact(clientId: number, dto: ContactDTO): Promise<ResultDTO> {
        let url = `api/client/${clientId}/contacts`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ResultDTO>(dto, 'post', url, true, false);
    }

    // delete: api/client/${clientId}/contacts/${contactId}
    public deleteContact(clientId: number, contactId: number): Promise<ResultDTO> {
        let url = `api/client/${clientId}/contacts/${contactId}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ResultDTO>(null, 'delete', url, true, false);
    }

    // post: api/client/GetTpasForClient?clientId=${clientId}
    public getTpasForClient(clientId: number): Promise<TpaListDTO[]> {
        let url = `api/client/GetTpasForClient`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (clientId != null) {
            url += `${prefix}clientId=${clientId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TpaListDTO[]>(null, 'post', url, true, false);
    }

    // get: api/client/GetTpaClientAccess?clientId=${clientId}&tpaId=${tpaId}
    public getTpaClientAccess(clientId: number, tpaId?: number | null | null): Promise<ClientAccessDTO> {
        let url = `api/client/GetTpaClientAccess`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (clientId != null) {
            url += `${prefix}clientId=${clientId}`;
            prefix = '&';
        }
        if (tpaId != null) {
            url += `${prefix}tpaId=${tpaId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ClientAccessDTO>(null, 'get', url, true, false);
    }

    // post: api/client/GetTpaUserClientAccesses?clientId=${clientId}&tpaId=${tpaId}
    public getTpaUserClientAccesses(clientId: number, tpaId: number, tableRequest: TableRequestDTO): Promise<TableResponseDTO<UserClientAccessDTO>> {
        let url = `api/client/GetTpaUserClientAccesses`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (clientId != null) {
            url += `${prefix}clientId=${clientId}`;
            prefix = '&';
        }
        if (tpaId != null) {
            url += `${prefix}tpaId=${tpaId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<UserClientAccessDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/client/SaveTpaClientAccess?tpaId=${tpaId}
    public saveTpaClientAccess(tpaId: number, dto: ClientAccessDTO): Promise<ResultDTO> {
        let url = `api/client/SaveTpaClientAccess`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (tpaId != null) {
            url += `${prefix}tpaId=${tpaId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ResultDTO>(dto, 'post', url, true, false);
    }

    // post: api/client/DeleteTpaClientAccess?clientId=${clientId}&tpaId=${tpaId}
    public deleteTpaClientAccess(clientId: number, tpaId: number): Promise<ResultDTO> {
        let url = `api/client/DeleteTpaClientAccess`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (clientId != null) {
            url += `${prefix}clientId=${clientId}`;
            prefix = '&';
        }
        if (tpaId != null) {
            url += `${prefix}tpaId=${tpaId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ResultDTO>(null, 'post', url, true, false);
    }

    // post: api/client/GetDpcsForClient?clientId=${clientId}
    public getDpcsForClient(clientId: number): Promise<DpcListDTO[]> {
        let url = `api/client/GetDpcsForClient`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (clientId != null) {
            url += `${prefix}clientId=${clientId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, DpcListDTO[]>(null, 'post', url, true, false);
    }

    // get: api/client/GetDpcClientAccess?clientId=${clientId}&dpcId=${dpcId}
    public getDpcClientAccess(clientId: number, dpcId?: number | null | null): Promise<ClientAccessDTO> {
        let url = `api/client/GetDpcClientAccess`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (clientId != null) {
            url += `${prefix}clientId=${clientId}`;
            prefix = '&';
        }
        if (dpcId != null) {
            url += `${prefix}dpcId=${dpcId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ClientAccessDTO>(null, 'get', url, true, false);
    }

    // post: api/client/GetDpcUserClientAccesses?clientId=${clientId}&dpcId=${dpcId}
    public getDpcUserClientAccesses(clientId: number, dpcId: number, tableRequest: TableRequestDTO): Promise<TableResponseDTO<UserClientAccessDTO>> {
        let url = `api/client/GetDpcUserClientAccesses`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (clientId != null) {
            url += `${prefix}clientId=${clientId}`;
            prefix = '&';
        }
        if (dpcId != null) {
            url += `${prefix}dpcId=${dpcId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<UserClientAccessDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/client/SaveDpcClientAccess?dpcId=${dpcId}
    public saveDpcClientAccess(dpcId: number, dto: ClientAccessDTO): Promise<ResultDTO> {
        let url = `api/client/SaveDpcClientAccess`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (dpcId != null) {
            url += `${prefix}dpcId=${dpcId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ResultDTO>(dto, 'post', url, true, false);
    }

    // post: api/client/DeleteDpcClientAccess?clientId=${clientId}&dpcId=${dpcId}
    public deleteDpcClientAccess(clientId: number, dpcId: number): Promise<ResultDTO> {
        let url = `api/client/DeleteDpcClientAccess`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (clientId != null) {
            url += `${prefix}clientId=${clientId}`;
            prefix = '&';
        }
        if (dpcId != null) {
            url += `${prefix}dpcId=${dpcId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ResultDTO>(null, 'post', url, true, false);
    }

    // post: api/client/GetOtherCompaniesForClient?clientId=${clientId}
    public getOtherCompaniesForClient(clientId: number): Promise<OtherCompanyListDTO[]> {
        let url = `api/client/GetOtherCompaniesForClient`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (clientId != null) {
            url += `${prefix}clientId=${clientId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, OtherCompanyListDTO[]>(null, 'post', url, true, false);
    }

    // get: api/client/GetOtherCompanyClientAccess?clientId=${clientId}&otherCompanyId=${otherCompanyId}
    public getOtherCompanyClientAccess(clientId: number, otherCompanyId?: number | null | null): Promise<ClientAccessDTO> {
        let url = `api/client/GetOtherCompanyClientAccess`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (clientId != null) {
            url += `${prefix}clientId=${clientId}`;
            prefix = '&';
        }
        if (otherCompanyId != null) {
            url += `${prefix}otherCompanyId=${otherCompanyId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ClientAccessDTO>(null, 'get', url, true, false);
    }

    // post: api/client/GetOtherCompanyUserClientAccesses?clientId=${clientId}&otherCompanyId=${otherCompanyId}
    public getOtherCompanyUserClientAccesses(clientId: number, otherCompanyId: number, tableRequest: TableRequestDTO): Promise<TableResponseDTO<UserClientAccessDTO>> {
        let url = `api/client/GetOtherCompanyUserClientAccesses`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (clientId != null) {
            url += `${prefix}clientId=${clientId}`;
            prefix = '&';
        }
        if (otherCompanyId != null) {
            url += `${prefix}otherCompanyId=${otherCompanyId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<UserClientAccessDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/client/SaveOtherCompanyClientAccess?otherCompanyId=${otherCompanyId}
    public saveOtherCompanyClientAccess(otherCompanyId: number, dto: ClientAccessDTO): Promise<ResultDTO> {
        let url = `api/client/SaveOtherCompanyClientAccess`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (otherCompanyId != null) {
            url += `${prefix}otherCompanyId=${otherCompanyId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ResultDTO>(dto, 'post', url, true, false);
    }

    // post: api/client/DeleteOtherCompanyClientAccess?clientId=${clientId}&otherCompanyId=${otherCompanyId}
    public deleteOtherCompanyClientAccess(clientId: number, otherCompanyId: number): Promise<ResultDTO> {
        let url = `api/client/DeleteOtherCompanyClientAccess`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (clientId != null) {
            url += `${prefix}clientId=${clientId}`;
            prefix = '&';
        }
        if (otherCompanyId != null) {
            url += `${prefix}otherCompanyId=${otherCompanyId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ResultDTO>(null, 'post', url, true, false);
    }

    // post: api/client/GetBrokersForClient?clientId=${clientId}
    public getBrokersForClient(clientId: number): Promise<BrokerListDTO[]> {
        let url = `api/client/GetBrokersForClient`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (clientId != null) {
            url += `${prefix}clientId=${clientId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, BrokerListDTO[]>(null, 'post', url, true, false);
    }

    // post: api/client/DeleteBrokerClientAccess?clientId=${clientId}&brokerId=${brokerId}
    public deleteBrokerClientAccess(clientId: number, brokerId: number): Promise<ResultDTO> {
        let url = `api/client/DeleteBrokerClientAccess`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (clientId != null) {
            url += `${prefix}clientId=${clientId}`;
            prefix = '&';
        }
        if (brokerId != null) {
            url += `${prefix}brokerId=${brokerId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ResultDTO>(null, 'post', url, true, false);
    }

    // get: api/client/GetBrokerClientAccess?clientId=${clientId}&brokerId=${brokerId}
    public getBrokerClientAccess(clientId: number, brokerId?: number | null | null): Promise<ClientAccessDTO> {
        let url = `api/client/GetBrokerClientAccess`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (clientId != null) {
            url += `${prefix}clientId=${clientId}`;
            prefix = '&';
        }
        if (brokerId != null) {
            url += `${prefix}brokerId=${brokerId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ClientAccessDTO>(null, 'get', url, true, false);
    }

    // post: api/client/GetBrokerUserClientAccesses?clientId=${clientId}&brokerId=${brokerId}
    public getBrokerUserClientAccesses(clientId: number, brokerId: number, tableRequest: TableRequestDTO): Promise<TableResponseDTO<UserClientAccessDTO>> {
        let url = `api/client/GetBrokerUserClientAccesses`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (clientId != null) {
            url += `${prefix}clientId=${clientId}`;
            prefix = '&';
        }
        if (brokerId != null) {
            url += `${prefix}brokerId=${brokerId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<UserClientAccessDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/client/SaveBrokerClientAccess?brokerId=${brokerId}
    public saveBrokerClientAccess(brokerId: number, dto: ClientAccessDTO): Promise<ResultDTO> {
        let url = `api/client/SaveBrokerClientAccess`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (brokerId != null) {
            url += `${prefix}brokerId=${brokerId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ResultDTO>(dto, 'post', url, true, false);
    }

    // post: api/client/GetClaimDocSupportUsersForClient?clientId=${clientId}
    public getClaimDocSupportUsersForClient(clientId: number, tableRequest: TableRequestDTO): Promise<TableResponseDTO<UserClientAccessDTO>> {
        let url = `api/client/GetClaimDocSupportUsersForClient`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (clientId != null) {
            url += `${prefix}clientId=${clientId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<UserClientAccessDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/client/SaveClaimDocSupportUserClientAccess?clientId=${clientId}
    public saveClaimDocSupportUserClientAccess(clientId: number, accessChanges: UserClientAccessDTO[]): Promise<ResultDTO> {
        let url = `api/client/SaveClaimDocSupportUserClientAccess`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (clientId != null) {
            url += `${prefix}clientId=${clientId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ResultDTO>(accessChanges, 'post', url, true, false);
    }

    // get: api/client/GetSecuritySettings?id=${id}
    public getSecuritySettings(id: number): Promise<ParentSecuritySettingsDTO> {
        let url = `api/client/GetSecuritySettings`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (id != null) {
            url += `${prefix}id=${id}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ParentSecuritySettingsDTO>(null, 'get', url, true, false);
    }

    // post: api/client/GetUserSecuritySettings?clientId=${clientId}
    public getUserSecuritySettings(clientId: number, tableRequest: TableRequestDTO): Promise<TableResponseDTO<ParentSecurityUserSettingsDTO>> {
        let url = `api/client/GetUserSecuritySettings`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (clientId != null) {
            url += `${prefix}clientId=${clientId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<ParentSecurityUserSettingsDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/client/SaveSecuritySettings?id=${id}
    public saveSecuritySettings(id: number, settings: ParentSecuritySettingsDTO): Promise<boolean> {
        let url = `api/client/SaveSecuritySettings`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (id != null) {
            url += `${prefix}id=${id}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, boolean>(settings, 'post', url, true, false);
    }

    // post: api/client/GetClientFeatureFlagsWithKey?clientId=${clientId}&roleName=${encodeURIComponent(roleName)}
    public getClientFeatureFlagsWithRoleName(clientId: number, roleName: string): Promise<ClientFeatureFlagsDTO> {
        let url = `api/client/GetClientFeatureFlagsWithKey`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (clientId != null) {
            url += `${prefix}clientId=${clientId}`;
            prefix = '&';
        }
        if (roleName != null) {
            url += `${prefix}roleName=${encodeURIComponent(roleName)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ClientFeatureFlagsDTO>(null, 'post', url, true, false);
    }

    // post: api/client/SaveClientFeatureFlags
    public saveClientFeatureFlags(clientFeatureFlag: ClientFeatureFlagsDTO): Promise<SaveClientDetailResultDTO> {
        let url = `api/client/SaveClientFeatureFlags`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveClientDetailResultDTO>(clientFeatureFlag, 'post', url, true, false);
    }

    // delete: api/client/RemoveUserSecurity?clientId=${clientId}&userId=${userId}
    public removeUserSecurity(clientId: number, userId: number): Promise<boolean> {
        let url = `api/client/RemoveUserSecurity`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (clientId != null) {
            url += `${prefix}clientId=${clientId}`;
            prefix = '&';
        }
        if (userId != null) {
            url += `${prefix}userId=${userId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, boolean>(null, 'delete', url, true, false);
    }

    // post: api/client/InviteUsers?clientId=${clientId}
    public inviteUsers(clientId: number, users: InviteUserDTO[]): Promise<boolean> {
        let url = `api/client/InviteUsers`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (clientId != null) {
            url += `${prefix}clientId=${clientId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, boolean>(users, 'post', url, true, false);
    }

    // post: api/client/SimpleUserList?clientId=${clientId}
    public simpleUserList(clientId: number, tableRequest: TableRequestDTO): Promise<TableResponseDTO<ParentSecurityUserSettingsDTO>> {
        let url = `api/client/SimpleUserList`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (clientId != null) {
            url += `${prefix}clientId=${clientId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<ParentSecurityUserSettingsDTO>>(tableRequest, 'post', url, true, false);
    }
}
var service = new ClientApiService();
export default service;
