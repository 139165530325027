// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import TableRequestDTO from './TableRequestDTO';
import SupportMemberSearchFilterDTO from './SupportMemberSearchFilterDTO';

interface SupportMemberSearchRequestDTO { 
    tableRequest: TableRequestDTO | null;
    filter: SupportMemberSearchFilterDTO | null;
}
const SupportMemberSearchRequestDTO = {
    create: (initValues?: Partial<SupportMemberSearchRequestDTO> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            tableRequest: null,
            filter: null,
        },
        initValues);
    }
};

export default SupportMemberSearchRequestDTO;