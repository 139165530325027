import { EditOutlined, ExclamationCircleFilled, InfoCircleOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Modal, notification, Row, Space, Tooltip, Typography } from 'antd';
import React, { CSSProperties, useEffect, useState } from 'react';
import PricingToolApiService from '../../api/PricingToolApiService';
import CostEstimateDTO from '../../models/CostEstimateDTO';
import PricingToolDTO from '../../models/PricingToolDTO';
import { PricingSteps } from '../../pages/tools/PricingToolPage';
import Renderers from '../../utils/Renderers';
import DataTable, { DataTableColumnProps, FilterType } from '../shared/DataTable/DataTable';
import DataTableColumnUtil from '../shared/DataTable/DataTableColumnUtil';

interface CostEstimateResultProps {
  dto: PricingToolDTO;
  estimates: CostEstimateDTO[];
  error: string | undefined;

  onFetchEstimates: (newEstimates: CostEstimateDTO[], errorMessage: string | undefined) => void;
  shouldQuery: boolean;

  onAcknowledgementAccept: () => void;
  acknowledgementAccepted: boolean;

  goToStep: (step: PricingSteps) => void;
}

const panels = {
  service: 'service',
  provider: 'provider',
  plan: 'plan',
};

const CostEstimateResults: React.FC<CostEstimateResultProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const dtRef = React.useRef<DataTable<CostEstimateDTO>>(null);
  const dtRefInvisible = React.useRef<DataTable<CostEstimateDTO>>(null);

  const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]);

  useEffect(() => {
    if (!props.acknowledgementAccepted) {
      Modal.confirm({
        title: 'Acknowledgement',
        content: (
          <>
            I acknowledge this is an estimate and my actual total cost (patient responsibility) may be different than
            the amount shown in the following estimate. Professional fees, such as physician, radiologist,
            anesthesiologist and pathologist fees, may not be included in this estimate. Insurance benefit information
            (where applicable) is based on information provided by you as of the date of this estimate. The estimate is
            based solely on primary insurance coverage and does not reflect any additional insurance coverage and/or
            coordination of benefits.
          </>
        ),
        cancelButtonProps: { style: { display: 'none' } },
        okText: 'Accept',
        width: '70%',
        icon: <ExclamationCircleFilled style={{ color: 'red' }} />,
        onOk: () => {
          props.onAcknowledgementAccept();
          fetchEstimates();
        },
      });
    } else if (props.acknowledgementAccepted && props.shouldQuery) {
      fetchEstimates();
    }
  }, []);

  const fetchEstimates = () => {
    setLoading(true);
    PricingToolApiService.getCostEstimate(props.dto)
      .then((res) => {
        let noResultsMsg;
        if (!res || res.length < 1) {
          noResultsMsg = 'No estimates were found for this service / provider combination.';
          notification.error({ message: noResultsMsg });
        }
        props.onFetchEstimates(res, noResultsMsg);
      })
      .catch((err) => {
        let message = 'No estimates were found for this service / provider combination.';
        if (err.message.includes('No affiliations')) {
          message = 'Unable to find selected provider';
        } else if (err.message.includes('No matching professional')) {
          message = 'No matching provider rates available for the selected service in the provider zip code';
        } else if (err.message.includes('No matching facility')) {
          message = 'No matching facility rates available for the selected service in the provider zip code';
        } else if (err.message.includes('The selected professional')) {
          message = 'Chosen service not typically performed by the selected provider.';
        }
        props.onFetchEstimates([], message);
        notification.error({ message: message });
      })
      .finally(() => {
        setLoading(false);
        dtRef.current?.refresh();
        dtRefInvisible.current?.refresh();
      });
  };

  const tableCols: DataTableColumnProps<CostEstimateDTO>[] = [
    {
      title: 'Institution',
      dataIndex: 'institutionName',
      width: '20%',
      sorter: true,
    },
    DataTableColumnUtil.Address(
      'Address',
      'addressLine1',
      (rowData) => ({
        line1: rowData.institutionAddressLine1,
        city: rowData.institutionCity,
        state: rowData.institutionState,
        zip: rowData.institutionZipCode,
      }),
      undefined,
      {
        width: '20%',
        filterType: FilterType.NONE,
        sorter: (a, b) => {
          if (!a?.institutionAddressLine1 || !b?.institutionAddressLine1) {
            return 0;
          }

          if (a.institutionAddressLine1 > b.institutionAddressLine1) {
            return 1;
          }

          if (b.institutionAddressLine1 > a.institutionAddressLine1) {
            return -1;
          }

          return 0;
        },
      }
    ),
    {
      title: 'Phone',
      dataIndex: 'institutionPhone',
      render: (v) => Renderers.phoneNumber(v),
      sorter: true,
    },
    {
      title: 'Insurance Coverage',
      dataIndex: 'insuranceCoverage',
      sorter: true,
    },
    {
      title: 'Out of Pocket',
      dataIndex: 'outOfPocketCost',
      sorter: true,
    },
    {
      title: 'Total',
      dataIndex: 'totalCost',
      sorter: true,
      defaultSortOrder: 'descend',
    },
  ];

  const renderExpandedRow = (rowData: CostEstimateDTO) => {
    const npiMatches = rowData.institutionNpi === rowData.professionalNpi;
    const parsedInstitutionCost = parseInt((rowData.institutionCost ?? '0').replace(/[^0-9]/g, ''));
    return (
      <Row>
        <Col xs={2}></Col>
        <Col xs={11}>
          {!(npiMatches && parsedInstitutionCost === 0) && (
            <>
              <h3>
                <b>Institution</b>
              </h3>
              NPI: {rowData.institutionNpi}
              <br />
              Cost: {rowData.institutionCost}
              <br />
              Overall Rating:{' '}
              {rowData.institutionOverallRating
                ? `${roundTo(rowData.institutionOverallRating, 2)} / 5`
                : 'Unavailable'}{' '}
              <Tooltip title="This rating is derived from Medicare and verified healthcare quality datasets and provides a comprehensive evaluation of an institution's quality and performance. It takes into consideration critical factors such as patient outcomes, patient experience, and adherence to best practices.">
                <InfoCircleOutlined style={{ fontSize: 'small' }} />
              </Tooltip>
            </>
          )}
        </Col>
        <Col xs={11}>
          <h3>
            <b>Provider</b>
          </h3>
          NPI: {rowData.professionalNpi}
          <br />
          Cost: {rowData.professionalCost}
          <br />
          Overall Rating:{' '}
          {rowData.professionalOverallRating
            ? `${roundTo(rowData.professionalOverallRating, 2)} / 5`
            : 'Unavailable'}{' '}
          <Tooltip title="This score is a composite measure derived from the Medicare Final Merit-based Incentive Payment System (MIPS) Score and industry-standard healthcare quality scorecards. Medicare Final MIPS is a program that evaluates healthcare providers based on various performance measures, including quality of care, clinical outcomes and efficiency.">
            <InfoCircleOutlined style={{ fontSize: 'small' }} />
          </Tooltip>
        </Col>
      </Row>
    );
  };

  const renderEditButton = (step: PricingSteps) => (
    <Button
      type="link"
      icon={<EditOutlined />}
      onClick={() => {
        props.goToStep(step);
      }}
    />
  );

  const renderExpandButton = (panelProps: any) => (
    <RightOutlined rotate={panelProps.isActive ? 90 : 0} style={{ fontSize: '12px' }} />
  );

  const renderDataTable = (isVisible: boolean) => (
    <DataTable<CostEstimateDTO>
      ref={isVisible ? dtRef : dtRefInvisible}
      columns={tableCols}
      data={props.estimates}
      serverSide={false}
      wrapperClassName={isVisible ? 'pricing-tool-results' : 'invisible-pricing-tool-results'}
      tableProps={{
        rowKey: 'uniqueId',
        loading: loading,
        sortDirections: ['ascend', 'descend'],
        scroll: isVisible
          ? {
              x: props.estimates?.length > 0 ? 760 : undefined,
              y: props.estimates?.length > 0 ? '52.5vh' : undefined,
            }
          : {},
        rowClassName: (_, index) => (index % 2 === 0 ? 'override-alternate-row-light' : 'override-alternate-row-dark'),
        pagination: false,
        expandable: {
          rowExpandable: () => true,
          expandedRowRender: renderExpandedRow,
          expandedRowClassName: (_, index) =>
            index % 2 === 0 ? 'override-alternate-row-light' : 'override-alternate-row-dark',
          onExpandedRowsChange: (expandedKeys) => setExpandedRowKeys([...expandedKeys]),
          expandedRowKeys: expandedRowKeys,
        },
        locale: {
          emptyText: (
            <>
              {loading ? (
                <></>
              ) : (
                <>
                  <Typography.Text>{props.error}</Typography.Text>
                  {props.acknowledgementAccepted && (
                    <>
                      <br />
                      <Typography.Text>Search for a different</Typography.Text>&nbsp;
                      <Typography.Link onClick={() => props.goToStep(PricingSteps.Service)}>service</Typography.Link>
                      &nbsp;
                      <Typography.Text>or</Typography.Text>&nbsp;
                      <Typography.Link onClick={() => props.goToStep(PricingSteps.Provider)}>provider</Typography.Link>.
                    </>
                  )}
                </>
              )}
            </>
          ),
        },
      }}
    />
  );

  const panelTitleStyle: CSSProperties = { fontSize: '16px' };

  return (
    <>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Collapse className="collapse-card" expandIcon={renderExpandButton}>
          <Collapse.Panel
            forceRender
            header={
              <Typography.Text style={panelTitleStyle}>
                <b>Service Description:</b>&nbsp;
                {props.dto.service?.display}
              </Typography.Text>
            }
            extra={renderEditButton(PricingSteps.Service)}
            key={panels.service}
          >
            <Row>
              <Col flex={1}>
                <b>Billing Code:</b> {props.dto.service?.billingCode}
                <br />
                <b>Billing Code Type:</b> {props.dto.service?.billingCodeType}
              </Col>
            </Row>
          </Collapse.Panel>
        </Collapse>
        <Collapse className="collapse-card" expandIcon={renderExpandButton}>
          <Collapse.Panel
            forceRender
            header={
              <Typography.Text style={panelTitleStyle}>
                <b>Provider Name:</b> {props.dto.provider?.name}
              </Typography.Text>
            }
            extra={renderEditButton(PricingSteps.Provider)}
            key={panels.provider}
          >
            <Row gutter={[12, 12]} justify="space-between">
              <Col flex={1}>
                <b>Address Line 1:</b> {props.dto.provider?.address1}
                <br />
                <b>Address Line 2:</b> {props.dto.provider?.address2}
                <br />
                <b>City, State, and Postal Code:</b> {props.dto.provider?.city}, {props.dto.provider?.state}{' '}
                {props.dto.provider?.zipcode}
                <br />
                <b>NPI:</b> {props.dto.provider?.npi}
                <br />
                <b>Specialty:</b> {props.dto.provider?.specialty}
              </Col>
            </Row>
          </Collapse.Panel>
        </Collapse>
        <Collapse className="collapse-card" expandIcon={renderExpandButton}>
          <Collapse.Panel
            forceRender
            header={
              <Typography.Text style={panelTitleStyle}>
                <Row gutter={12}>
                  <Col xs={24} md={12}>
                    <b>Plan Copay:</b>&nbsp;
                    {Renderers.currency((props.dto.planInformation?.copay || '0').toString())}
                  </Col>
                  <Col xs={24} md={12}>
                    <b>Plan Coinsurance:</b>&nbsp;
                    {Renderers.percentage((props.dto.planInformation?.coinsurancePercent || '0').toString())}
                  </Col>
                </Row>
              </Typography.Text>
            }
            extra={renderEditButton(PricingSteps.Plan)}
            key={panels.plan}
          >
            <Row gutter={[12, 12]}>
              <Col xs={24} md={12}>
                <b>
                  <u>Deductible</u>
                </b>
                <br />
                <b>Single: </b>
                {Renderers.currency((props.dto.planInformation?.singleDeductible || '0').toString())}
                <br />
                <b>Family: </b> {Renderers.currency((props.dto.planInformation?.familyDeductible || '0').toString())}
              </Col>
              <Col xs={24} md={12}>
                <b>
                  <u>Out-of-Pocket</u>
                </b>
                <br />
                <b>Single: </b>
                {Renderers.currency((props.dto.planInformation?.singleOutOfPocketMax || '0').toString())}
                <br />
                <b>Family: </b>
                {Renderers.currency((props.dto.planInformation?.familyOutOfPocketMax || '0').toString())}
              </Col>
            </Row>
            <Row gutter={12} style={{ paddingTop: '16px' }}>
              <Col xs={24} md={12}>
                <b>
                  <u>Deductible Satisfied</u>
                </b>
                <br />
                <b>Single: </b>
                {Renderers.currency((props.dto.planInformation?.singleDeductibleAmountSatisfied || '0').toString())}
                <br />
                <b>Family: </b>
                {Renderers.currency((props.dto.planInformation?.familyDeductibleAmountSatisfied || '0').toString())}
              </Col>
              <Col xs={24} md={12}>
                <b>
                  <u>Out-of-Pocket Satisfied</u>
                </b>
                <br />
                <b>Single: </b>
                {Renderers.currency((props.dto.planInformation?.singleOutOfPocketMaxSatisfied || '0').toString())}
                <br />
                <b>Family: </b>
                {Renderers.currency((props.dto.planInformation?.familyOutOfPocketMaxSatisfied || '0').toString())}
              </Col>
            </Row>
          </Collapse.Panel>
        </Collapse>
      </Space>
      {/* Spaces are separated here to ensure we get a page break for the print */}
      <Space direction="vertical" style={{ width: '100%' }}>
        <div style={{ padding: '20px 0px' }}>
          <Typography.Title level={3}>Pricing Estimates</Typography.Title>
          <Typography.Text type="secondary">
            The Pricing Estimate Total is the estimated cost for the selected procedure performed by the selected
            provider in the facility. Out-of-pocket costs are calculated from the plan information entered. The
            insurance coverage amount is the total minus the out-of-pocket. Any procedure performed in a facility
            outside of the doctor’s office will have separate institution and provider costs, which can be viewed by
            clicking the + sign.
          </Typography.Text>
        </div>
      </Space>
      {props.acknowledgementAccepted && renderDataTable(true)}
      {/*
       * Put an invisible data table on the screen... this will be the version we print
       * Doing this because Antd's implementation of a fixed header (which happen when we set scroll) will not add the headers at the top of each page
       * The downside to doing this is we must keep track of the expanded keys in order to upkeep the printable table.
       */}
      {renderDataTable(false)}
      {!loading && (
        <>
          <Typography.Text type="secondary" style={{ float: 'right' }}>
            Total {props.estimates.length ?? 0} estimates
          </Typography.Text>
          <br />
        </>
      )}
    </>
  );
};

export default CostEstimateResults;
