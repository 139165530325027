import { notification } from 'antd';
import React, { useEffect, useState } from 'react';
import ClientService from '../../../api/ClientApiService';
import Permission from '../../../consts/Permission';
import Routes from '../../../consts/Routes';
import useHasPermission from '../../../hooks/useHasPermission';
import TpaListDTO from '../../../models/TpaListDTO';
import AccessList, { AccessData } from '../../shared/EntityAccess/AccessList';

interface TpaAccessListProps {
  clientId: number;
  readonly?: boolean;
}

const TpaAccessList = (props: TpaAccessListProps) => {
  const [loading, setLoading] = useState(true);
  const [tpaList, setTpaList] = useState<TpaListDTO[]>([]);
  const hasPermission = useHasPermission();

  const fetch = (id: number) => {
    setLoading(true);
    ClientService.getTpasForClient(id)
      .then((res) => {
        setTpaList(res);
      })
      .catch(() => {
        // TODO: notification
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteTpaClientAccess = (tpaId: number) => {
    setLoading(true);
    ClientService.deleteTpaClientAccess(props.clientId, tpaId)
      .then((results) => {
        if (results.succeeded) {
          notification.success({ message: 'TPA successfully removed from client.' });
          fetch(props.clientId);
        } else {
          throw '';
        }
      })
      .catch(() => {
        notification.error({ message: 'An error occurred while trying to remove TPA from client.' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetch(props.clientId);
  }, []);

  const listData = tpaList.map((t) => {
    return {
      id: t.id,
      name: t.name,
      location: t.location,
      phoneNumber: t.phoneNumber,
      isActive: t.isActive,
      endDate: t.endDate,
      startDate: t.startDate,
    } as AccessData;
  });

  return (
    <AccessList
      abbreviation="TPA"
      addText="Assign New TPA"
      fullName="third party administrator"
      tableData={listData}
      onDelete={deleteTpaClientAccess}
      editUrl={(id?: number) =>
        Routes.generate(Routes.ADMIN_CLIENT_DETAILS_TPAS, { id: props.clientId, tpaId: id?.toString() ?? 'new' })
      }
      readOnly={!hasPermission(Permission.ADMIN_CLIENT_TPA_EDIT) || !!props.readonly}
      loading={loading}
    />
  );
};

export default TpaAccessList;
