import { Button, Col, Layout, notification, Row, Space } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import DataManagementApiService from '../../api/DataManagementApiService';
import TpaApiService from '../../api/TpaApiService';
import DataTable, { DataTableColumnProps } from '../../components/shared/DataTable/DataTable';
import PageTitle from '../../components/shared/PageTitle';
import PermissionGuard from '../../components/shared/PermissionGuard';
import Permission from '../../consts/Permission';
import Routes from '../../consts/Routes';
import TableRequestDTO from '../../models/TableRequestDTO';
import TableResponseDTO from '../../models/TableResponseDTO';
import TpaListDTO from '../../models/TpaListDTO';
import HistoryUtil from '../../utils/HistoryUtil';
import MobileUtil from '../../utils/MobileUtil';
import Renderers from '../../utils/Renderers';

const Content = Layout.Content;

const TpaListPage = () => {
  const [tableData, setTableData] = useState<TpaListDTO[]>([]);
  const [loading, setLoading] = useState(false);
  const dataTableRef = React.useRef<DataTable<any>>(null);

  const isMobile = () => MobileUtil.getMediaQuery()?.matches;

  const tableColumns = [
    {
      title: 'Id',
      dataIndex: 'claimDocId',
      sorter: {},
    },
    {
      title: 'Code',
      dataIndex: 'code',
      sorter: {},
    },
    {
      title: 'Name',
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      sorter: {},
    },
    {
      title: 'Location',
      dataIndex: 'location',
      sorter: {},
      renderDataTransform: (value: any) => (value != null && value.trim().length > 1 ? value : ''),
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      render: (v) => Renderers.phoneNumber(v),
    },
    {
      title: 'Term. Date',
      dataIndex: 'termDate',
      sorter: {},
      render: (v) => Renderers.shortDate(v),
    },
    {
      title: '',
      dataIndex: '',
      width: '40px',
      // eslint-disable-next-line react/display-name
      render: (rowData: TpaListDTO) => (
        <Link
          to={Routes.generate(Routes.ADMIN_TPA_DETAILS, {
            id: rowData.id,
          })}
        >
          <Button shape="round" ghost>
            Details
          </Button>
        </Link>
      ),
    },
  ] as DataTableColumnProps<any>[];

  const syncTpas = () => {
    setLoading(true);
    DataManagementApiService.executeTpaSync()
      .then(() => {
        notification.success({ message: 'Sync Complete' });
      })
      .catch(() => {
        notification.error({ message: 'Sync Failed' });
      })
      .finally(() => {
        setLoading(false);
        dataTableRef.current?.refresh();
      });
  };

  const goToTpaDetails = (tpaId: number) => {
    HistoryUtil.push(
      Routes.generate(Routes.ADMIN_TPA_DETAILS, {
        id: tpaId,
      })
    );
  };

  const fetchTpaTableData = (
    requestState: TableRequestDTO,
    checkEcho: () => boolean,
    callback: (response: TableResponseDTO<TpaListDTO>) => void
  ) => {
    setLoading(true);

    TpaApiService.getTpaList(requestState)
      .then((results) => {
        if (!checkEcho()) {
          return;
        }
        setLoading(false);
        setTableData(results.results || []);

        callback(results);
      })
      .catch((error: any) => {
        setLoading(false);
        notification.error({
          message: error.message,
          description: error.description,
        });
      });
  };

  return (
    <Content className="content-padding">
      <Row>
        <Col xs={24}>
          <Row className="content-header" justify="space-between">
            <Col xs={24} lg={10} xl={13} xxl={14}>
              <PageTitle title="TPAs" />
            </Col>
            <Col>
              <Space direction="horizontal">
                <PermissionGuard permissionId={Permission.ETL_TPA_EXECUTESYNC}>
                  <Button type="primary" shape="round" disabled={loading} onClick={syncTpas}>
                    Sync TPAs
                  </Button>
                </PermissionGuard>
              </Space>
            </Col>
          </Row>
          <DataTable
            ref={dataTableRef}
            serverSide={true}
            globalSearch={true}
            globalSearchPlaceholder="Search TPAs..."
            tableProps={{
              rowKey: 'id',
              size: 'large',
              loading: loading,
              sortDirections: ['ascend', 'descend'],
              scroll: { x: 575 },
              onRow: (record: TpaListDTO) => {
                return {
                  onDoubleClick: () => {
                    if (!isMobile()) {
                      goToTpaDetails(record.id);
                    }
                  },
                  onClick: () => {
                    if (isMobile()) {
                      goToTpaDetails(record.id);
                    }
                  },
                };
              },
            }}
            columns={tableColumns}
            data={tableData}
            fetchData={fetchTpaTableData}
            styleOptions={{
              alternatingRowHighlight: true,
            }}
          />
        </Col>
      </Row>
    </Content>
  );
};

export default TpaListPage;
