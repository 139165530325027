// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import FeatureFlagDTO from './FeatureFlagDTO';

interface OtherCompanyClientFeatureFlagsDTO { 
    otherCompanyId: number | null;
    featureFlags: FeatureFlagDTO[] | null;
}
const OtherCompanyClientFeatureFlagsDTO = {
    create: (initValues?: Partial<OtherCompanyClientFeatureFlagsDTO> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            otherCompanyId: null,
            featureFlags: [],
        },
        initValues);
    }
};

export default OtherCompanyClientFeatureFlagsDTO;