import { useCallback, useEffect, useState } from 'react';

const breakpoints = {
  xs: 480,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
};

function useIsWidthBroken(breakpoint: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl') {
  const widthCompare = breakpoints[breakpoint];
  const [isMobile, setIsMobile] = useState(window.innerWidth < widthCompare);

  const handler = useCallback(() => {
    setIsMobile(window.innerWidth < widthCompare);
  }, [setIsMobile]);

  useEffect(() => {
    window.addEventListener('resize', handler);

    return () => {
      window.removeEventListener('resize', handler);
    };
  }, []);

  return isMobile;
}

export default useIsWidthBroken;
