import { DeleteOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, Form, FormInstance, Row, Select } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import momentTz from 'moment-timezone';
import React, { RefObject, useContext, useEffect, useState } from 'react';
import { AuthenticationContext } from '../../../../auth/AuthenticationContext';
import AccessRole from '../../../../consts/AccessRole';
import ProviderGenderPreferences from '../../../../consts/ProviderGenderPreferences';
import AppointmentTimeframeOptions from '../../../../consts/ProviderNominationAppointmentTimeframeOptions';
import HasAppointmentOptions from '../../../../consts/ProviderNominationHasAppointmentOptions';
import DatePicker2 from '../../../shared/DatePicker2';
import confirmModal from '../../../../utils/Modal';

const { Option } = Select;

export interface MemberLookup {
  id: string;
  displayName: string;
}

interface AppointmentFormListItemProps {
  index: number;
  formRef: RefObject<FormInstance<any>>;
  familyMembers: MemberLookup[];
  formListRemove: (num: number | number[]) => void;
  formListName: number;
  restField: any;
  numberOfAppts: number;
}

type TimeZoneOption = {
  name: string;
  friendlyName: string;
  abbr: string;
};

const AppointmentFormListItem = (props: AppointmentFormListItemProps) => {
  const [timeZones, setTimeZones] = useState<TimeZoneOption[]>([]);
  const [hasAppointmentOption, setHasAppointmentOption] = useState(HasAppointmentOptions.NO);

  const authContext = useContext(AuthenticationContext);
  const isClaimDocEmployee = authContext.user?.accessRoleId === AccessRole.CLAIMDOC;

  useEffect(() => {
    const formValues = props.formRef.current?.getFieldsValue();
    const thisAppointment = formValues.appointments.at(props.formListName);
    if (thisAppointment) {
      getTimeZones(thisAppointment?.appointmentDate);
      setHasAppointmentOption(thisAppointment?.hasAppointment);
    } else {
      getTimeZones(null);
    }
  }, []);

  const handleAppointmentChange = (appointment: moment.Moment | null) => {
    getTimeZones(appointment);
  };

  const getTimeZones = (appointment: moment.Moment | null) => {
    const timeZonesUS = momentTz.tz.zonesForCountry('US');
    const timeZoneOptions: TimeZoneOption[] = [];
    timeZonesUS.forEach((t) => {
      // Get only the base time zones
      if (t.split('/').length > 2) {
        return;
      }

      const zone = momentTz.tz.zone(t);
      timeZoneOptions.push({
        name: zone?.name,
        friendlyName: zone?.name.replaceAll('_', ' '),
        abbr: zone?.abbr(appointment?.valueOf() ?? 0),
      } as TimeZoneOption);

      setTimeZones(timeZoneOptions);
    });
  };

  const handleHasAppointmentChange = (hasAppointment: string) => {
    setHasAppointmentOption(hasAppointment);
  };

  return (
    <Card className="ant-card-background" style={{ marginBottom: 15, paddingBottom: 0 }}>
      <Row gutter={12} align="bottom">
        <Form.Item name="id" />
        <Col xs={{ order: 1, span: 19 }} md={{ order: 1, span: 14 }}>
          <Form.Item
            {...props.restField}
            label="Family Member"
            name={[props.formListName, 'familyMemberId']}
            rules={[{ required: true, message: 'Required' }]}
          >
            <Select showSearch optionFilterProp="children" dropdownMatchSelectWidth={false}>
              {props.familyMembers?.map((m) => (
                <Select.Option key={m.id ?? ''} value={m.id ?? ''}>
                  {m.displayName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={{ order: 2, span: 24 }} md={{ order: 1, span: 7 }}>
          <Form.Item
            {...props.restField}
            label="Patient Type"
            name={[props.formListName, 'existing']}
            rules={[{ required: true, message: 'Required' }]}
          >
            <Select>
              <Option value={true}>Existing</Option>
              <Option value={false}>New Patient</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={{ order: 1, span: 5 }} md={{ order: 1, span: 3 }}>
          {props.numberOfAppts > 1 && (
            <FormItem style={{ textAlign: 'center' }}>
              <Button
                onClick={() =>
                  confirmModal('Are you sure you want to delete this appointment?', () =>
                    props.formListRemove(props.formListName)
                  )
                }
                icon={<DeleteOutlined />}
                type="link"
                shape="round"
                size="large"
              />
            </FormItem>
          )}
        </Col>
        <Col xs={{ order: 3, span: 19 }} md={{ order: 3, span: 14 }}>
          <Form.Item
            {...props.restField}
            label="Do you have an appointment?"
            name={[props.formListName, 'hasAppointment']}
            rules={[{ required: true, message: 'Required' }]}
          >
            <Select onChange={(v) => handleHasAppointmentChange(v)}>
              <Select.Option key={HasAppointmentOptions.NO} value={HasAppointmentOptions.NO}>
                {HasAppointmentOptions.NO}
              </Select.Option>
              <Select.Option key={HasAppointmentOptions.NOBUTNEEDONE} value={HasAppointmentOptions.NOBUTNEEDONE}>
                {HasAppointmentOptions.NOBUTNEEDONE}
              </Select.Option>
              <Select.Option key={HasAppointmentOptions.YES} value={HasAppointmentOptions.YES}>
                {HasAppointmentOptions.YES}
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
        {hasAppointmentOption === HasAppointmentOptions.YES ? (
          <>
            <Col xs={{ order: 4, span: 24 }} md={{ order: 4, span: 12 }}>
              <Form.Item
                {...props.restField}
                label="Appointment Date &amp; Time"
                name={[props.formListName, 'appointmentDate']}
                rules={[{ required: true, message: 'Required' }]}
              >
                <DatePicker2
                  dropdownAlign={{
                    offset: [0, -8],
                  }}
                  format="MM-DD-YYYY hh:mm A"
                  showTime
                  minuteStep={15}
                  allowClear
                  onChange={(v) => handleAppointmentChange(v)}
                />
              </Form.Item>
            </Col>
            <Col xs={{ order: 4, span: 24 }} md={{ order: 4, span: 12 }}>
              <Form.Item
                name={[props.formListName, 'appointmentTimeZone']}
                label="Appointment Time Zone"
                rules={[{ required: true, message: 'Required' }]}
              >
                <Select showSearch optionFilterProp="children" dropdownMatchSelectWidth={false}>
                  {timeZones.map((t, i) => (
                    <Select.Option key={i} value={t?.name}>
                      {t?.friendlyName} ({t?.abbr})
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </>
        ) : null}
        {hasAppointmentOption === HasAppointmentOptions.NOBUTNEEDONE ? (
          <Col xs={{ order: 4, span: 19 }} md={{ order: 4, span: 14 }}>
            <Form.Item
              {...props.restField}
              label="Appointment Timeframe"
              name={[props.formListName, 'appointmentTimeframe']}
              rules={[{ required: true, message: 'Required' }]}
            >
              <Select>
                <Select.Option
                  key={AppointmentTimeframeOptions.LESSTHAN30DAYS}
                  value={AppointmentTimeframeOptions.LESSTHAN30DAYS}
                >
                  {AppointmentTimeframeOptions.LESSTHAN30DAYS}
                </Select.Option>
                <Select.Option
                  key={AppointmentTimeframeOptions.GREATERTHAN30DAYS}
                  value={AppointmentTimeframeOptions.GREATERTHAN30DAYS}
                >
                  {AppointmentTimeframeOptions.GREATERTHAN30DAYS}
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
        ) : null}
        <Col xs={{ order: 5, span: 24 }} md={{ order: 5, span: 12 }}>
          <Form.Item name={[props.formListName, 'genderPreference']} label="Gender Preference">
            <Select showSearch>
              <Select.Option value={ProviderGenderPreferences.NONE}>{ProviderGenderPreferences.NONE}</Select.Option>
              <Select.Option value={ProviderGenderPreferences.MALE}>{ProviderGenderPreferences.MALE}</Select.Option>
              <Select.Option value={ProviderGenderPreferences.FEMALE}>{ProviderGenderPreferences.FEMALE}</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        {isClaimDocEmployee && (
          <Col xs={{ order: 6, span: 24 }} md={{ order: 6, span: 12 }}>
            <Form.Item name={[props.formListName, 'isUrgent']} valuePropName="checked">
              <Checkbox>This nomination is urgent</Checkbox>
            </Form.Item>
          </Col>
        )}
      </Row>
    </Card>
  );
};

export default AppointmentFormListItem;
