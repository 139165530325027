import { DownOutlined, PhoneOutlined } from '@ant-design/icons';
import { Button, Layout, Menu, Popover } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import HeaderContactContent from '../components/layout/HeaderContactContent';
import useUrlQuery from '../hooks/useUrlQuery';

const { Header } = Layout;
interface PublicLayout2Props {}

interface LayoutRouteProps {
  mobileview?: string;
}

const PublicLayout = (props: React.PropsWithChildren<PublicLayout2Props>) => {
  const [routeQuery] = useUrlQuery<LayoutRouteProps>();

  const shouldRenderHeader = () => {
    if (routeQuery?.mobileview) {
      return false;
    }
    return true;
  };

  const getPageContentClassName = () => {
    let className = 'page-content';
    if (!shouldRenderHeader()) {
      className += ' mobile-page-content';
    }

    return className;
  };

  return (
    <Layout style={{ height: '100%' }}>
      {shouldRenderHeader() ? (
        <Header className="public">
          <Link to="/" className="header-logo">
            <img src={process.env.PUBLIC_URL + '/claimdoc-logo_simple.svg'} alt="ClaimDOC" />
            <img src={process.env.PUBLIC_URL + '/claimdoc-logo_icon.svg'} alt="ClaimDOC" className="header-logo-icon" />
          </Link>
          <Menu mode="horizontal">
            <Menu.Item key="assistance">
              <Popover
                placement="bottomRight"
                content={HeaderContactContent}
                trigger="click"
                overlayClassName="header-contact-content"
              >
                <Button type="link" style={{ height: 'unset' }}>
                  <PhoneOutlined /> ClaimDOC Assistance <DownOutlined />
                </Button>
              </Popover>
            </Menu.Item>
          </Menu>
        </Header>
      ) : null}
      <Layout.Content>
        <Layout>
          <Layout.Content id="app-main" className={getPageContentClassName()}>
            {props.children}
          </Layout.Content>
        </Layout>
      </Layout.Content>
    </Layout>
  );
};

export default PublicLayout;
