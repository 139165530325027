import { Affix, Button, Col, Layout, notification, Row, Spin } from 'antd';
import Sider from 'antd/lib/layout/Sider';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import ProviderApiService from '../../api/ProviderApiService';
import '../../components/provider/Map.less';
import MapLegend from '../../components/provider/MapLegend';
import { MAP_LOCATION, MY_LOCATION, ProviderQuery, ProviderSearch } from '../../components/provider/ProviderSearch';
import ProviderSearchResults from '../../components/provider/ProviderSearchResults';
import SearchResultCard from '../../components/provider/SearchResultCard';
import AzureMap, { MapMarker, PopupOptions } from '../../components/shared/AzureMaps/AzureMap';
import PageTitle from '../../components/shared/PageTitle';
import Permission from '../../consts/Permission';
import ProviderType from '../../consts/ProviderType';
import useHasPermission from '../../hooks/useHasPermission';
import ProviderDTO from '../../models/ProviderDTO';
import ProviderSearchQueryDTO from '../../models/ProviderSearchQueryDTO';
import ProviderSearchResultDTO from '../../models/ProviderSearchResultDTO';
import { AuthenticationContext } from '../../auth/AuthenticationContext';
import AccessRole from '../../consts/AccessRole';

interface ProviderMapPageProps {
  onProviderSelected?: (provider: (ProviderDTO | undefined)[]) => void;
  onProviderSearch?: (loading: boolean) => void;
  isImplementationPage?: boolean;
  onStartPnfClick?: () => void;
}
const Content = Layout.Content;

const STANDARD_ZOOM = 16;

const MILES_TO_METERS = 1609.344;

const DEFAULT_PAGE_NUMBER = 1;

const MAX_PROVIDERS = 5;

const ProviderMapPage = (props: ProviderMapPageProps) => {
  const hasLinkPermission = useHasPermission()(Permission.TOOLS_PROVIDERDIRECTORY_LINKING);
  const hasAgreementPermission = useHasPermission()(Permission.TOOLS_PROVIDERDIRECTORY_VIEWAGREEMENT);
  const authCtx = useContext(AuthenticationContext);

  const [subscriptionKey, setSubscriptionKey] = useState<string | undefined>(undefined);
  const [usrLoc, setUsrLoc] = useState<number[] | undefined>(undefined);
  const [usrRadius, setUsrRadius] = useState<number>(20);
  const [loading, setLoading] = useState(false);
  const [sortOrder, setSortOrder] = useState(1);
  const [searchResults, setSearchResults] = useState<ProviderDTO[] | undefined>(undefined);
  const [totalRecords, setTotalRecords] = useState(0);
  const [mapData, setMapData] = useState<MapMarker[]>([]);
  const [isMobile, setIsMobile] = useState(false);
  const [mapBounds, setMapBounds] = useState<number[] | undefined>(undefined);

  const [selected, setSelected] = useState<ProviderDTO | undefined>(undefined);
  const [selectedProviders, setSelectedProviders] = useState<(ProviderDTO | undefined)[]>([]);
  const [mapSearchLocation, setMapSearchLocation] = useState<number[] | undefined>();

  const [popupOptions, setPopupOptions] = useState<PopupOptions>({ visible: false, position: [], content: null });

  const [currQueryDTO, setCurrQueryDTO] = useState<ProviderSearchQueryDTO | undefined>(undefined);

  const isServicePartnerRole = [AccessRole.BROKER, AccessRole.TPA, AccessRole.DPC, AccessRole.OTHERCOMPANY].includes(
    authCtx.user?.accessRoleId ?? -1
  );
  const [pageNumber, setPageNumber] = useState<number | undefined>(
    isServicePartnerRole ? DEFAULT_PAGE_NUMBER : undefined
  );
  const [pageSize, setPageSize] = useState<number | undefined>(undefined);

  const searchProviders = async (locationField: string, query: ProviderQuery) => {
    setSelected(undefined);
    setSearchResults(undefined);
    setMapData([]);
    setSortOrder(query.sortBy);
    let pageNum: number | undefined;
    if (isServicePartnerRole) {
      pageNum = DEFAULT_PAGE_NUMBER;
      setPageNumber(DEFAULT_PAGE_NUMBER);
    }

    const queryDTO = generateSearchQuery(locationField, query, pageNum);
    searchProvidersApiCall(queryDTO);
    setCurrQueryDTO(queryDTO);
  };

  const searchProvidersApiCall = (queryDTO: ProviderSearchQueryDTO) => {
    setLoading(true);

    if (props.onProviderSearch) {
      props.onProviderSearch(true);
    }

    ProviderApiService.searchProviders(queryDTO)
      .then((res) => {
        processSearchResult(res, queryDTO.distance);
        if (props.onProviderSearch) {
          props.onProviderSearch(false);
        }
      })
      .catch((err) => {
        notification.error({
          message: err.message,
          description: err.description,
        });

        setLoading(false);
        setUsrLoc(undefined);
      });
  };

  const generateSearchQuery = (
    locationField: string,
    query: ProviderQuery,
    pageNum?: number
  ): ProviderSearchQueryDTO => {
    let lat: number | undefined;
    let long: number | undefined;
    let address: string | undefined;
    if ((locationField === MY_LOCATION || locationField === MAP_LOCATION) && query.relativeLocation) {
      lat = query.relativeLocation[1];
      long = query.relativeLocation[0];
      address = undefined;
    } else {
      lat = undefined;
      long = undefined;
      address = locationField;
    }

    return ProviderSearchQueryDTO.create({
      distance: query.searchRadius,
      latitude: lat,
      longitude: long,
      address: address,
      providerTypeId: query.providerType,
      providerSpecialty: query.providerSpecialty,
      filterString: query.providersFilter,
      sortBy: query.sortBy,
      agreementCode: query.agreementType,
      agreementType: query.agreementSubType,
      npi: query.npi,
      pageNumber: pageNum,
    });
  };

  const processSearchResult = (res: ProviderSearchResultDTO | null, radius: number) => {
    if (res) {
      res.results = res.results ?? [];
      const resLat: number = res.searchCenterLatitude;
      const resLong: number = res.searchCenterLongitude;
      const resultsRefs = {};
      res.results?.forEach((res) => {
        resultsRefs[res.id] = React.createRef();
      });
      setSubscriptionKey(res.subscriptionKey ?? undefined);
      setUsrLoc([resLong, resLat]);
      setUsrRadius(radius);
      setLoading(false);
      setMapBounds(computeBounds(resLong, resLat, radius * 0.015));
      setTotalRecords(res.totalResults);
      setPageSize(res.pageSize ?? undefined);
      setSearchResults(
        res.results.map((e: ProviderDTO) => {
          return {
            ...e,
            // We only care to show the first 5 digits
            zipcode: e.zipcode?.substring(0, 5) ?? '',
          } as ProviderDTO;
        })
      );
      setMapData(
        res.results.map((provider) => {
          return {
            position: [provider.long, provider.lat],
            id: provider.id.toString(),
            icon: provider.providerType == ProviderType.CLINIC ? 'clinic' : 'doctor',
          };
        })
      );
    } else {
      setLoading(false);
    }
  };

  const generatePopupForId = (id: number): JSX.Element | undefined => {
    const results = searchResults ?? [];
    const dataSearchResults: ProviderDTO[] = results.filter((val) => val.id == id);
    return !isMobile && dataSearchResults && dataSearchResults.length === 1 ? (
      <SearchResultCard
        key="popup"
        provider={dataSearchResults[0]}
        isMultipleProvidersSelected={props.isImplementationPage ? true : false}
        isProviderSelectedFromMap={selected?.id == id}
        useMapStyle={true}
      />
    ) : undefined;
  };

  const computeBounds = (long: number, lat: number, boundsOffset: number) => {
    return [long - boundsOffset, lat - boundsOffset, long + boundsOffset, lat + boundsOffset];
  };

  const markerClicked = useCallback(
    (e: any) => {
      if (!e) {
        setSelected(undefined);
        setPopupOptions({ visible: true, position: [-144, 23], content: '' });
        return;
      }
      const markerStrId = e.data?.id;
      const position = e.data?.geometry?.coordinates;
      if (!markerStrId || !position) {
        return;
      }
      const markerId = parseInt(markerStrId);
      const provider = searchResults?.find((p) => p.id == markerId);
      setSelected(provider);
    },
    [selected, popupOptions, setSelected, setPopupOptions, searchResults]
  );

  useEffect(() => {
    if (!selected) {
      setPopupOptions({ ...popupOptions, visible: false });
      return;
    }
    const content = generatePopupForId(selected.id);
    setPopupOptions({ visible: true, position: [selected.long, selected.lat], content: content });
  }, [selected]);

  const searchHereAction = useCallback((e) => {
    setMapSearchLocation(e.getCamera().center);
  }, []);

  const constructMap = (useMobile: boolean) => {
    const map = (
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={24}
        xl={12}
        xxl={16}
        style={{
          height: useMobile ? '40vh' : '100%',
        }}
      >
        {subscriptionKey && (
          <AzureMap
            subscriptionKey={subscriptionKey}
            showZoomControl
            customControls={
              currQueryDTO?.npi
                ? []
                : [
                    {
                      buttonText: 'Search Here',
                      action: searchHereAction,
                    },
                  ]
            }
            markers={mapData}
            mapBounds={mapBounds}
            userLocation={usrLoc}
            searchRadius={currQueryDTO?.npi ? 0 : usrRadius * MILES_TO_METERS}
            markerClicked={markerClicked}
            popup={popupOptions}
            zoomTo={
              selected
                ? {
                    level: STANDARD_ZOOM,
                    position: [selected.long, selected.lat],
                  }
                : undefined
            }
            customIcons={[
              {
                id: 'clinic',
                icon: process.env.PUBLIC_URL + '/icon-marker-clinic-blue.png',
              },
              {
                id: 'doctor',
                icon: process.env.PUBLIC_URL + '/icon-marker-individual-blue.png',
              },
            ]}
          />
        )}
      </Col>
    );
    return useMobile ? <Affix offsetTop={64}>{map}</Affix> : map;
  };

  const onPageChange = (page: number) => {
    if (currQueryDTO) {
      const newQueryDto = { ...currQueryDTO, pageNumber: page };
      searchProvidersApiCall(newQueryDto);
      setCurrQueryDTO(newQueryDto);
    }

    setSelected(undefined);
    setSearchResults(undefined);
    setMapData([]);
    setPageNumber(page);
  };

  const renderStartPnfButton = () => {
    if (selectedProviders && selectedProviders.length > 0 && !loading) {
      return (
        <Button type="primary" shape="round" size="large" onClick={props.onStartPnfClick}>
          Review Selected Providers
        </Button>
      );
    }
  };

  const onProviderSelected = (provider: ProviderDTO | undefined) => {
    setSelected(provider);

    const providers = selectedProviders;

    if (!props.isImplementationPage) {
      providers.removeAll(providers);
    }

    if (providers.findIndex((x) => x?.id == provider?.id) > -1) {
      providers.remove(providers.find((x) => x?.id == provider?.id));
    } else if (providers.length < MAX_PROVIDERS) {
      providers.push(provider);
    }

    setSelectedProviders([...providers]);
    if (props.onProviderSelected) {
      props.onProviderSelected(providers);
    }
  };

  return (
    <Layout>
      <Content style={{ height: '100%' }} className="content-padding">
        <Row style={{ height: '100%' }}>
          <Col xs={24} sm={24} md={24}>
            <Row className="content-header" justify="space-between">
              <Col>
                <PageTitle title={props.isImplementationPage ? 'Implementation' : 'Provider Directory'} />
              </Col>
              {isMobile ? null : renderStartPnfButton()}
              {subscriptionKey && !isMobile ? <MapLegend mobile={true} /> : null}
            </Row>
            <Row style={{ height: '94%' }} gutter={12}>
              <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={8}>
                <Spin spinning={loading}>
                  <ProviderSearch
                    isMobile={isMobile}
                    hasLinkPermission={hasLinkPermission}
                    hasAgreementPermission={hasAgreementPermission}
                    searchProviders={searchProviders}
                    resultsCount={searchResults?.length ?? -1}
                    locationSearch={mapSearchLocation}
                    selectedCompassUrl={selected?.compassLink ?? undefined}
                  />
                </Spin>
                {subscriptionKey && isMobile ? <MapLegend mobile={true} /> : null}
                {isMobile ? constructMap(true) : null}
                <ProviderSearchResults
                  isMobile={isMobile}
                  providers={searchResults}
                  selected={selected}
                  selectedProviders={[...selectedProviders]}
                  sortBy={sortOrder}
                  resultClick={onProviderSelected}
                  isImplementationPage={props.isImplementationPage}
                  totalCount={totalRecords}
                  pagination={
                    isServicePartnerRole
                      ? {
                          currentPage: pageNumber ?? 0,
                          pageSize: pageSize ?? 0,
                          onPageChange: onPageChange,
                        }
                      : undefined
                  }
                />
              </Col>
              {!isMobile ? constructMap(isMobile) : null}
            </Row>
          </Col>
        </Row>
        <Sider
          trigger={null}
          collapsible={true}
          collapsed={isMobile}
          width={248}
          collapsedWidth={0}
          breakpoint="xl"
          onBreakpoint={(broken) => {
            setIsMobile(broken);
          }}
          style={{ display: 'none' }}
        />

        <p style={{ textAlign: 'center', paddingBottom: 0, marginBottom: 0, paddingTop: 25 }}>
          <strong>Disclaimer:</strong> The information and resources provided on this page are for informational
          purposes only. ClaimDOC does not endorse or validate any individual provider or facility, or the content
          provided on any linked site.
        </p>
      </Content>
    </Layout>
  );
};

export function GetLongLatLocation(callback: (result: number[] | null) => any) {
  navigator.geolocation.getCurrentPosition(
    (result: any) => callback([result.coords.longitude, result.coords.latitude]),
    () => callback(null)
  );
}

export default ProviderMapPage;
