import { QuestionCircleOutlined } from '@ant-design/icons';
import { Checkbox, Col, Form, FormInstance, Row, Tooltip } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { useContext, useEffect, useState } from 'react';
import { AuthenticationContext } from '../../../../auth/AuthenticationContext';
import AccessRole from '../../../../consts/AccessRole';
import PnfServicePartnerDTO from '../../../../models/PnfServicePartnerDTO';
import Role from '../../../../consts/Role';
import FeatureFlagDTO from '../../../../models/FeatureFlagDTO';
import { HasConfigurationValue, HasConfigurationValues } from '../../../../utils/ConfigurationUtil';
import FeatureFlag from '../../../../consts/FeatureFlag';
import FeatureFlagApiService  from '../../../../api/FeatureFlagApiService';

interface ServicePartnerFormProps {
  formRef: FormInstance<any>;
  clientId?: number;
  dto: PnfServicePartnerDTO;
  onChange?: (values: any) => void;
}

const ServicePartnerForm = (props: ServicePartnerFormProps) => {
  const [notifyMember, setNotifyMember] = useState(props.dto.notifyMember);
  const [notifySubmitter, setNotifySubmitter] = useState(props.dto.notifySubmitter);

  const authContext = useContext(AuthenticationContext);
  const isClaimDocEmployee = authContext.user?.accessRoleId === AccessRole.CLAIMDOC;
  const [featureFlags, setFeatureFlags] = useState<FeatureFlagDTO[]>();
  const user = authContext.user;
  const roleId = user?.accessRoleId;

  useEffect(() => {
    loadClientFeatureFlags();
  }, [props.clientId]);

  const loadClientFeatureFlags = () => {
    FeatureFlagApiService.getClientFeatureFlagsWithRoleId(props.clientId ?? 0, roleId ?? 0).then((res) => {
      let featureFlags: FeatureFlagDTO[] = [];
      switch (roleId) {
        case Role.BROKER:
          featureFlags =
            res.brokerClientFeatureFlags?.find((x) => x.brokerId == user?.accessEntityId)?.featureFlags ?? [];
          setFeatureFlags(featureFlags);
          break;
        case Role.CLAIMDOC:
          featureFlags = res.claimDocUserFeatureFlags?.find((x) => x.clientId == props.clientId)?.featureFlags ?? [];
          setFeatureFlags(featureFlags);
          setNotifyMember(
            HasConfigurationValue(featureFlags, FeatureFlag.SERVICE_PROVIDERS_ACKNOWLEDGEMENT_OF_RECEIPT_MEMBER)
          );
          setNotifySubmitter(
            HasConfigurationValue(featureFlags, FeatureFlag.SERVICE_PROVIDERS_ACKNOWLEDGEMENT_OF_RECEIPT_SUBMITTER)
          );
          break;
        case Role.EMPLOYER:
          featureFlags = res.employerFeatureFlags?.find((x) => x.clientId == props.clientId)?.featureFlags ?? [];
          setFeatureFlags(featureFlags);
          break;
        case Role.DPC:
          featureFlags = res.dpcClientFeatureFlags?.find((x) => x.dpcId == user?.accessEntityId)?.featureFlags ?? [];
          setFeatureFlags(featureFlags);
          break;
        case Role.OTHERCOMPANY:
          featureFlags =
            res.otherCompanyClientFeatureFlags?.find((x) => x.otherCompanyId == user?.accessEntityId)?.featureFlags ??
            [];
          setFeatureFlags(featureFlags);
          break;
        case Role.TPA:
          featureFlags = res.tpaClientFeatureFlags?.find((x) => x.tpaId == user?.accessEntityId)?.featureFlags ?? [];
          setFeatureFlags(featureFlags);
          break;
      }
    });
  };

  const handleNotifyMemberChange = (e: CheckboxChangeEvent) => {
    if (notifyMember) {
      props.formRef.setFieldValue('pdfAttachMember', false);
    }
    setNotifyMember(e.target.checked);
  };

  const handleNotifySubmitterChange = (e: CheckboxChangeEvent) => {
    if (notifySubmitter) {
      props.formRef.setFieldValue('pdfAttachSubmitter', false);
    }
    setNotifySubmitter(e.target.checked);
  };

  return (
    <>
      {!HasConfigurationValues(featureFlags, [
        FeatureFlag.SERVICE_PROVIDERS_ACKNOWLEDGEMENT_OF_RECEIPT_MEMBER,
        FeatureFlag.SERVICE_PROVIDERS_ACKNOWLEDGEMENT_OF_RECEIPT_SUBMITTER,
        FeatureFlag.SERVICE_PROVIDERS_ACKNOWLEDGEMENT_OF_RECEIPT_HIDE_QUESTION,
      ]) &&
        HasConfigurationValue(
          featureFlags,
          FeatureFlag.SERVICE_PROVIDERS_ACKNOWLEDGEMENT_OF_RECEIPT_ALLOW_SELECTION
        ) && (
          <>
            <strong>Acknowledgement of receipt to be sent to?</strong> (Optional)
            <Row gutter={[12, 0]}>
              <Col xs={12} sm={6}>
                <Form.Item name="notifyMember" valuePropName="checked">
                  <Checkbox onChange={handleNotifyMemberChange}>Member</Checkbox>
                </Form.Item>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Item name="notifySubmitter" valuePropName="checked">
                  <Checkbox onChange={handleNotifySubmitterChange}>
                    Submitter{' '}
                    <Tooltip
                      overlayStyle={{ maxWidth: 'fit-content' }}
                      placement="right"
                      title={
                        <>
                          First Name: {props.formRef.getFieldValue('firstName')}
                          <br />
                          Last Name: {props.formRef.getFieldValue('lastName')}
                          <br />
                          Company: {props.formRef.getFieldValue('company')}
                          <br />
                          Email: {props.formRef.getFieldValue('email')}
                        </>
                      }
                    >
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      {isClaimDocEmployee &&
        ((notifyMember ?? props.dto.notifyMember) || (notifySubmitter ?? props.dto.notifySubmitter)) &&
        !HasConfigurationValues(featureFlags, [
          FeatureFlag.SERVICE_PROVIDERS_NOMINATION_DETAILS_DEPENDENT_ON_ACKNOWLEDGEMENT_OF_RECEIPT_MEMBER,
          FeatureFlag.SERVICE_PROVIDERS_NOMINATION_DETAILS_DEPENDENT_ON_ACKNOWLEDGEMENT_OF_RECEIPT_SUBMITTER,
          FeatureFlag.SERVICE_PROVIDERS_NOMINATION_DETAILS_DEPENDENT_ON_ACKNOWLEDGEMENT_OF_RECEIPT_HIDE_QUESTION,
        ]) &&
        HasConfigurationValue(
          featureFlags,
          FeatureFlag.SERVICE_PROVIDERS_NOMINATION_DETAILS_DEPENDENT_ON_ACKNOWLEDGEMENT_OF_RECEIPT_ALLOW_SELECTION
        ) && (
          <>
            <strong>Attach nomination details to the email notification.</strong> (Does Include PHI)
            <Row gutter={[12, 0]}>
              <Col xs={12} sm={6}>
                <Form.Item name="pdfAttachMember" valuePropName="checked">
                  <Checkbox disabled={!(props.dto.notifyMember || notifyMember)}>Member</Checkbox>
                </Form.Item>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Item name="pdfAttachSubmitter" valuePropName="checked">
                  <Checkbox disabled={!(props.dto.notifySubmitter || notifySubmitter)}>Submitter</Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      {!HasConfigurationValues(featureFlags, [
        FeatureFlag.SERVICE_PROVIDERS_NOMINATION_ACCESSIBLE_IN_DOCUMENTS_MEMBER,
        FeatureFlag.SERVICE_PROVIDERS_NOMINATION_ACCESSIBLE_IN_DOCUMENTS_SUBMITTER,
        FeatureFlag.SERVICE_PROVIDERS_NOMINATION_ACCESSIBLE_IN_DOCUMENTS_HIDE_QUESTION,
      ]) &&
        HasConfigurationValue(
          featureFlags,
          FeatureFlag.SERVICE_PROVIDERS_NOMINATION_ACCESSIBLE_IN_DOCUMENTS_ALLOW_SELECTION
        ) && (
          <>
            <strong>Nomination accessible in documents view</strong>
            <Row gutter={[12, 0]}>
              <Col xs={12} sm={6}>
                <Form.Item name="accessToDocumentsMember" valuePropName="checked">
                  <Checkbox>Member</Checkbox>
                </Form.Item>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Item name="accessToDocumentsSubmitter" valuePropName="checked">
                  <Checkbox>Submitter </Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      {!HasConfigurationValues(featureFlags, [
        FeatureFlag.SERVICE_PROVIDERS_FOLLOW_UP_COMMUNICATION_MEMBER,
        FeatureFlag.SERVICE_PROVIDERS_FOLLOW_UP_COMMUNICATION_SUBMITTER,
        FeatureFlag.SERVICE_PROVIDERS_FOLLOW_UP_COMMUNICATION_HIDE_QUESTION,
      ]) &&
        HasConfigurationValue(featureFlags, FeatureFlag.SERVICE_PROVIDERS_FOLLOW_UP_COMMUNICATION_ALLOW_SELECTION) && (
          <>
            <strong>Follow up communication</strong>
            <Row gutter={[12, 0]}>
              <Col xs={12} sm={6}>
                <Form.Item name="followUpCommunicationMember" valuePropName="checked">
                  <Checkbox>Member</Checkbox>
                </Form.Item>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Item name="followUpCommunicationSubmitter" valuePropName="checked">
                  <Checkbox>Submitter </Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
    </>
  );
};

export default ServicePartnerForm;
