// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import UserClientAccessDTO from './UserClientAccessDTO';
import LookupTableDTO from './LookupTableDTO';

interface ClientAccessDTO { 
    id: number | null;
    name: string | null;
    startDate: moment.Moment | string | null;
    endDate: moment.Moment | string | null;
    users: UserClientAccessDTO[] | null;
    entities: LookupTableDTO[] | null;
}
const ClientAccessDTO = {
    create: (initValues?: Partial<ClientAccessDTO> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            id: null,
            name: null,
            startDate: null,
            endDate: null,
            users: [],
            entities: [],
        },
        initValues);
    }
};

export default ClientAccessDTO;