import { AutoComplete, AutoCompleteProps, Col, Form, Row, Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import ProviderApiService from '../../api/ProviderApiService';
import ProviderSearchDTO from '../../models/ProviderSearchDTO';
import { debounce } from 'lodash';
import ProviderType from '../../consts/ProviderType';
import ProviderAutoCompleteFilterDTO from '../../models/ProviderAutoCompleteFilterDTO';

interface NpiAutoCompleteProps {
  onSelect: (selected: ProviderSearchDTO) => void;
  onClear?: () => void;
  formItemName: string;
  placeholder?: string;
  providerType?: number;
  readOnly?: boolean;
  initialValues?: any;
  disableSearchOnNpiOnly?: boolean;
}

const NpiAutoComplete: React.FC<NpiAutoCompleteProps & AutoCompleteProps> = (props) => {
  const [providers, setProviders] = useState<ProviderSearchDTO[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState<ProviderSearchDTO | undefined>(undefined);

  const [filter, setFilter] = useState<ProviderAutoCompleteFilterDTO | undefined>(undefined);

  // Grabs the form instance component is wrapped in
  const form = Form.useFormInstance();

  const searchApiCall = (filter: ProviderAutoCompleteFilterDTO, autoSelect?: boolean) => {
    ProviderApiService.autoCompleteProviders(filter).then((res) => {
      setProviders(res);
      setLoading(false);

      if (autoSelect) {
        const selProvider = res.find((p) => p.npi === props.initialValues?.npi);
        if (selProvider) {
          setSelectedProvider(selProvider);
        }
      }
    });
  };

  useEffect(() => {
    if (filter) {
      debouncedSearch(filter);
    }
  }, [filter]);

  useEffect(() => {
    if (props.initialValues && props.providerType) {
      if (props.initialValues?.npi) {
        const tmpFilter = ProviderAutoCompleteFilterDTO.create({
          providerTypeId: props.disableSearchOnNpiOnly ? props.providerType : 0,
          npi: props.initialValues.npi,
        });
        searchApiCall(tmpFilter, true);
      }
    }
  }, []);

  const debouncedSearch = useCallback(debounce(searchApiCall, 500), []);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, []);

  const handleSearch = (searchString: string) => {
    if (selectedProvider) {
      setSelectedProvider(undefined);
      if (props.onClear) {
        props.onClear();
      }
    }

    if (!searchString || searchString.length < 3 || (props.disableSearchOnNpiOnly && !props.providerType)) {
      if (providers?.length > 0) {
        setProviders([]);
        setLoading(false);
      }
      return;
    }

    setLoading(true);
    const filter = ProviderAutoCompleteFilterDTO.create({
      providerTypeId: props.providerType,
      npi: searchString,
    });
    setFilter(filter);
  };

  const handleSelect = (s: any) => {
    // Override the default action of AutoComplete to set the input to the value prop
    // Instead we want to use the displayValue prop
    if (form) {
      form.setFieldValue(`${props.formItemName}`, s.displayValue);
    }

    const selected = providers.find((f) => f.npi == s.key);
    if (selected) {
      setSelectedProvider(selected);
      props.onSelect(selected);
    }
  };

  const handleClear = () => {
    setProviders([]);
    setSelectedProvider(undefined);
    if (props.onClear) {
      props.onClear();
    }
  };

  const transformOptions = (options: any[]) => {
    return options.map((o) => ({
      key: o.npi,
      // value of option must be unique for auto complete for some reason
      // if not unique it will randomly select one of the options with the same value
      // to get around this when we select we will set the value of the field to the "displayValue" prop
      value: o.npi,
      displayValue: o.npi,
      label: (
        <Row gutter={12} justify="space-between">
          <Col>
            {o.providerTypeId === ProviderType.CLINIC ? `${o.name ?? ''}` : `${o.firstName ?? ''} ${o.lastName ?? ''}`}{' '}
          </Col>
          <Col>{o.npi ? `NPI #${o.npi}` : ''}</Col>
          {o.address1 ? (
            <Col>
              {o.address1} {o.city}, {o.stateCode} {o.zipCode}
            </Col>
          ) : undefined}
        </Row>
      ),
    }));
  };

  const opts = loading
    ? [
        ProviderSearchDTO.create({
          id: '',
          name: '',
        }),
      ]
    : providers;

  return (
    <AutoComplete
      {...props}
      allowClear
      maxLength={10}
      open={props.readOnly ? false : undefined}
      placeholder={props.placeholder}
      onSearch={handleSearch}
      onSelect={(_, selected) => handleSelect(selected)}
      onClear={handleClear}
      options={transformOptions(opts)}
      style={{ width: '100%' }}
      dropdownRender={(a) => {
        if (loading) {
          return (
            <div className="center-spinner">
              <Spin spinning={loading}>{a}</Spin>
            </div>
          );
        }
        return a;
      }}
    />
  );
};

export default NpiAutoComplete;
