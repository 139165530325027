import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Router } from 'react-router-dom';
import App from './App';
import NotFoundComponent from './components/NotFoundComponent';
import UnexpectedError from './components/UnexpectedError';
import './index.less';
import PublicLayout from './layouts/PublicLayout';
import reportWebVitals from './reportWebVitals';
import addGlobals from './utils/Globals';
import History from './utils/HistoryUtil';

addGlobals();

ReactDOM.render(
  <React.StrictMode>
    <Router history={History}>
      <Route
        render={(props) =>
          // @ts-ignore
          props.location.state && props.location.state.is404 ? (
            <PublicLayout>
              <NotFoundComponent />
            </PublicLayout>
          ) : (
            <UnexpectedError>
              <App />
            </UnexpectedError>
          )
        }
      />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
