// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import RelationDTO from '../models/RelationDTO';

export class DashboardApiService extends BaseApi {

    // get: api/dashboard/GetUserRelations
    public getUserRelations(): Promise<RelationDTO[]> {
        let url = `api/dashboard/GetUserRelations`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, RelationDTO[]>(null, 'get', url, true, false);
    }
}
var service = new DashboardApiService();
export default service;
