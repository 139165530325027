// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import ShoppableServiceRequestDTO from './ShoppableServiceRequestDTO';
import ShoppableServiceDTO from './ShoppableServiceDTO';
import ProviderSearchQueryDTO from './ProviderSearchQueryDTO';
import ProviderDTO from './ProviderDTO';
import PlanInformationDTO from './PlanInformationDTO';

interface PricingToolDTO { 
    clientId: number | null;
    serviceRequest: ShoppableServiceRequestDTO | null;
    serviceCursor: string | null;
    service: ShoppableServiceDTO | null;
    providerSearchQuery: ProviderSearchQueryDTO | null;
    provider: ProviderDTO | null;
    planInformation: PlanInformationDTO | null;
}
const PricingToolDTO = {
    create: (initValues?: Partial<PricingToolDTO> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            clientId: null,
            serviceRequest: null,
            serviceCursor: null,
            service: null,
            providerSearchQuery: null,
            provider: null,
            planInformation: null,
        },
        initValues);
    }
};

export default PricingToolDTO;