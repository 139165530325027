import { DisconnectOutlined, EditOutlined, ExclamationCircleOutlined, LockOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Input, Modal, notification, Row, Typography } from 'antd';
import React, { FunctionComponent, useState } from 'react';
import AccountApiService from '../../../api/AccountApiService';
import Renderers from '../../../utils/Renderers';

const FormItem = Form.Item;

interface ManageMFAProps {
  twoFactorEnabled: boolean;
  currentPhone?: string | null;
  reload: () => void;
}

const ManageMFA: FunctionComponent<ManageMFAProps> = (props) => {
  const [setupVisible, setSetupVisible] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(props.currentPhone ?? '');
  const [codeSent, setCodeSent] = useState(false);
  const [authCode, setAuthCode] = useState('');
  const [codeSending, setCodeSending] = useState(false);
  const [verifying, setVerifying] = useState(false);

  const sendMFASms = async (phoneNumber: string) => {
    setCodeSending(true);
    const sent = await AccountApiService.changeMfaPhone(phoneNumber, null);
    if (sent) {
      setCodeSent(true);
    } else {
      notification.error({ message: 'Failed to send code.', description: 'There was an issue sending the MFA code.' });
    }

    setCodeSending(false);
  };

  const verifyCode = async (phoneNumber: string, code: string) => {
    try {
      setVerifying(true);
      await AccountApiService.changeMfaPhone(phoneNumber, code);
      notification.success({
        message: 'Two-Factor Authentication Set up',
        description: 'You will now receive a code to log in with the next time you log in!',
      });
      props.reload();
    } catch {
      notification.error({
        message: 'Error Verifying Code / Enabling Two-Factor Authentication',
        description: 'Please contact support if this issue persists.',
      });
    } finally {
      setVerifying(false);
    }
  };

  const cancelSetup = () => {
    setSetupVisible(false);
    setCodeSent(false);
    setPhoneNumber('');
    setAuthCode('');
  };

  const disableMfa = () => {
    Modal.confirm({
      title: 'Are you sure you want to disable two-factor authentication?',
      icon: <ExclamationCircleOutlined />,
      content: 'Two factor authentication is recommended to help secure your account.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        AccountApiService.disableMFA()
          .then(() => {
            notification.success({ message: 'Two-Factor Authentication Disabled' });
            props.reload();
          })
          .catch(() => {
            notification.error({
              message: 'Failed to Disable',
              description: 'If this issue persist, please contact support.',
            });
          });
      },
      onCancel() {
        // pass
      },
    });
  };

  return (
    <>
      <Typography.Title level={3}>Two-Factor Authentication</Typography.Title>

      {setupVisible ? (
        <>
          <Form
            layout="vertical"
            onFinish={() => sendMFASms(phoneNumber)}
            style={{ marginTop: 25, marginBottom: 25 }}
            initialValues={{ ['phoneNumber']: phoneNumber }}
          >
            <Row align="middle" gutter={12}>
              <Col>
                <FormItem name="phoneNumber" label="Phone Number" rules={[{ required: true, message: 'Required' }]}>
                  <Input
                    type="tel"
                    onChange={(e) => setPhoneNumber(e.target.value ?? '')}
                    disabled={codeSending || verifying}
                  />
                </FormItem>
              </Col>
              <Col>
                <FormItem label="&nbsp;">
                  <Button type="primary" htmlType="submit" loading={codeSending} disabled={verifying}>
                    {codeSent ? 'Resend Code' : 'Send Code'}
                  </Button>
                </FormItem>
              </Col>
            </Row>
          </Form>
          {codeSent ? (
            <Form layout="vertical" style={{ marginTop: 50 }} onFinish={() => verifyCode(phoneNumber, authCode)}>
              <Row align="middle" gutter={12}>
                <Col>
                  <FormItem name="token" label="Authorization Code" rules={[{ required: true, message: 'Required' }]}>
                    <Input type="number" onChange={(e) => setAuthCode(e.target.value ?? '')} disabled={verifying} />
                  </FormItem>
                </Col>
                <Col>
                  <FormItem label="&nbsp;">
                    <Button type="primary" htmlType="submit" loading={verifying}>
                      Verify
                    </Button>
                  </FormItem>
                </Col>
              </Row>
            </Form>
          ) : null}

          <Button onClick={() => cancelSetup()}>Cancel Setup</Button>
        </>
      ) : !props.twoFactorEnabled ? (
        <>
          {!setupVisible ? (
            <>
              <Row justify="center">
                <Col>
                  <Card style={{ textAlign: 'center', marginTop: 25, marginBottom: 25 }}>
                    <DisconnectOutlined style={{ fontSize: 50, marginBottom: 15 }} />
                    <br />
                    <b style={{ marginBottom: 15 }}>Your Two-Factor Authentication is not set up.</b>
                    <br />
                    <Button onClick={() => setSetupVisible(true)}>Set Up</Button>
                  </Card>
                </Col>
              </Row>
            </>
          ) : null}
        </>
      ) : (
        <>
          <Row justify="center">
            <Col>
              <Card style={{ textAlign: 'center', marginTop: 25, marginBottom: 25 }}>
                <LockOutlined style={{ fontSize: 50, marginBottom: 15 }} className="text-success" />
                <br />
                <b style={{ marginBottom: 15 }}>Two-Factor Authentication is set up &amp; enabled.</b>
                <br />
                <br />
                <b>Phone Number: {Renderers.phoneNumber(props.currentPhone ?? '')}</b>
              </Card>
            </Col>
          </Row>
          <Row justify="center" gutter={12}>
            <Col style={{ minWidth: 200 }}>
              <Card style={{ textAlign: 'center', marginTop: 25, marginBottom: 25 }}>
                <EditOutlined style={{ fontSize: 50, marginBottom: 15 }} />
                <br />
                <Button onClick={() => setSetupVisible(true)}>Change Number</Button>
              </Card>
            </Col>
            <Col style={{ minWidth: 200 }}>
              <Card style={{ textAlign: 'center', marginTop: 25, marginBottom: 25 }}>
                <DisconnectOutlined style={{ fontSize: 50, marginBottom: 15 }} />
                <br />
                <Button onClick={() => disableMfa()}>Disable</Button>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default ManageMFA;
