// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';

interface FeatureFlagDTO { 
    id: number;
    name: string | null;
    group: string | null;
    subGroup: string | null;
    isDefaultValue: boolean | null;
}
const FeatureFlagDTO = {
    create: (initValues?: Partial<FeatureFlagDTO> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            id: 0,
            name: null,
            group: null,
            subGroup: null,
            isDefaultValue: null,
        },
        initValues);
    }
};

export default FeatureFlagDTO;