import { Card, Col, ColProps, Layout, Row, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React from 'react';
import PageTitle from '../../components/shared/PageTitle';
interface LoginLayoutProps {
  pageTitle?: string;
  cardTitle: string;
  prompt?: React.ReactNode;
  colPropsOverride?: ColProps;
}

const LoginLayout = (props: React.PropsWithChildren<LoginLayoutProps>) => {
  return (
    <Layout>
      <Content className="page-content content-padding">
        <Row justify="center" align="middle">
          <Col className="flex-grow" />
          <Col xxl={8} xl={10} lg={12} md={16} sm={20} xs={24} {...props.colPropsOverride}>
            <Card>
              <img src={process.env.PUBLIC_URL + '/claimdoc-logo.svg'} alt="ClaimDOC" className="full-logo" />
              <div style={{ textAlign: 'center', marginBottom: 20 }}>
                <PageTitle title={props.pageTitle ?? props.cardTitle} hide={true} />
                <Typography.Title level={3} style={{ whiteSpace: 'pre-wrap', marginBottom: '12px' }}>
                  {props.cardTitle}
                </Typography.Title>
                {props.prompt ? <p>{props.prompt}</p> : null}
              </div>
              {props.children}
            </Card>
          </Col>
          <Col className="flex-grow" />
        </Row>
      </Content>
    </Layout>
  );
};

export default LoginLayout;
