import { Button, notification, Tooltip } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import DocumentApiService from '../../api/DocumentApiService';
import BalanceBillListDTO from '../../models/BalanceBillListDTO';
import TableRequestDTO from '../../models/TableRequestDTO';
import TableResponseDTO from '../../models/TableResponseDTO';
import DataTable, { DataTableColumnProps } from '../shared/DataTable/DataTable';
import Routes from '../../consts/Routes';
import { AcceptedFormats } from '../shared/DatePicker2';
import useIsWidthBroken from '../../hooks/useIsWidthBroken';
import useUrlQuery from '../../hooks/useUrlQuery';
import HistoryUtil from '../../utils/HistoryUtil';

interface RouteProps {
  mobileview: string | undefined;
}

const BalanceBillList = () => {
  const [routeQuery] = useUrlQuery<RouteProps>();

  const [loading, setLoading] = useState(false);
  const [balanceBills, setBalanceBills] = useState<BalanceBillListDTO[]>([]);
  const dtRef = React.useRef<DataTable<any>>(null);

  const fetchTableData = (
    requestState: TableRequestDTO,
    checkEcho: () => boolean,
    callback: (response: TableResponseDTO<BalanceBillListDTO>) => void
  ) => {
    setLoading(true);
    DocumentApiService.getBalanceBillList(requestState)
      .then((results) => {
        if (!checkEcho()) {
          return;
        }
        setBalanceBills(results.results || []);
        setLoading(false);
        callback(results);
      })
      .catch((error: any) => {
        setLoading(false);
        notification.error({
          message: error.message,
          description: error.description,
        });
      });
  };

  const isMobile = useIsWidthBroken('sm');

  const openDocument = (rowData: BalanceBillListDTO) => {
    if (routeQuery.mobileview) {
      HistoryUtil.push(
        Routes.generate(Routes.DOCUMENTS_VIEW, {}, { b: rowData.id, d: rowData.documentId, mobileview: 1 })
      );
    } else {
      window.open(Routes.generate(Routes.DOCUMENTS_VIEW, {}, { b: rowData.id, d: rowData.documentId }));
    }
  };

  const tableColumns: DataTableColumnProps<BalanceBillListDTO>[] = [
    {
      title: 'BB-ID',
      dataIndex: 'referenceNumber',
      defaultSortOrder: 'ascend',
      sorter: {},
    },
    {
      title: 'Date Submitted',
      dataIndex: 'submittedOn',
      defaultSortOrder: 'ascend',
      sorter: {},
      renderDataTransform: (value: any) => (value != null ? moment(value).format(AcceptedFormats.at(0)) : null),
    },
    {
      title: 'Submitted For',
      dataIndex: 'submittedFor',
      defaultSortOrder: 'ascend',
      sorter: {},
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      defaultSortOrder: 'ascend',
      sorter: {},
      render: (notes: string) => (
        <Tooltip placement="topLeft" title={notes}>
          {isMobile ? ((notes?.length || 0) > 25 ? notes.substring(0, 25) + '...' : notes || '') : notes}
        </Tooltip>
      ),
    },
    {
      title: '',
      dataIndex: '',
      width: '150px',
      // eslint-disable-next-line react/display-name
      render: (rowData: BalanceBillListDTO) =>
        rowData ? (
          <Button shape="round" ghost onClick={() => openDocument(rowData)}>
            View Document
          </Button>
        ) : null,
    },
  ];

  return (
    <DataTable
      ref={dtRef}
      serverSide={true}
      globalSearch={true}
      globalSearchPlaceholder="Search Balance Bills"
      tableProps={{
        rowKey: 'id',
        loading: loading,
        scroll: { x: 575 },
      }}
      columns={tableColumns}
      data={balanceBills}
      fetchData={fetchTableData}
      styleOptions={{
        alternatingRowHighlight: true,
      }}
    />
  );
};

export default BalanceBillList;
