// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';

interface OtherCompanyDetailDTO { 
    id: number;
    claimDocId: string | null;
    otherCompanyCode: string | null;
    name: string | null;
    phoneNumber: string | null;
    address1: string | null;
    address2: string | null;
    city: string | null;
    state: string | null;
    zipCode: string | null;
    notes: string | null;
    url: string | null;
}
const OtherCompanyDetailDTO = {
    create: (initValues?: Partial<OtherCompanyDetailDTO> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            id: 0,
            claimDocId: null,
            otherCompanyCode: null,
            name: null,
            phoneNumber: null,
            address1: null,
            address2: null,
            city: null,
            state: null,
            zipCode: null,
            notes: null,
            url: null,
        },
        initValues);
    }
};

export default OtherCompanyDetailDTO;