// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import ContactDTO from './ContactDTO';

interface ClientDetailDTO { 
    id: number;
    clientId: string | null;
    clientCode: string | null;
    subCode: string | null;
    name: string | null;
    phoneNumber: string | null;
    address1: string | null;
    address2: string | null;
    city: string | null;
    state: string | null;
    zipCode: string | null;
    notes: string | null;
    url: string | null;
    isTermed: boolean;
    contacts: ContactDTO[] | null;
    divisions: ClientDetailDTO[] | null;
}
const ClientDetailDTO = {
    create: (initValues?: Partial<ClientDetailDTO> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            id: 0,
            clientId: null,
            clientCode: null,
            subCode: null,
            name: null,
            phoneNumber: null,
            address1: null,
            address2: null,
            city: null,
            state: null,
            zipCode: null,
            notes: null,
            url: null,
            isTermed: false,
            contacts: [],
            divisions: [],
        },
        initValues);
    }
};

export default ClientDetailDTO;