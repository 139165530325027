import { Checkbox, Form, FormInstance, Input } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import React, { useEffect } from 'react';
import ParentSecurityUserSettingsDTO from '../../models/ParentSecurityUserSettingsDTO';

interface InviteUsersEditFormProps {
  form: FormInstance<any>;
  initialValues: ParentSecurityUserSettingsDTO | undefined;
}

const InviteUsersEditForm = (props: InviteUsersEditFormProps) => {
  useEffect(() => {
    props.form.resetFields();
    props.form.setFieldsValue(props.initialValues);
  }, [props.initialValues]);

  return (
    <>
      <Form form={props.form} layout="vertical" initialValues={props.initialValues}>
        <Form.Item name="id" hidden />
        <Form.Item label="First Name" name="firstName" rules={[{ required: true, message: 'Required' }]}>
          <Input disabled />
        </Form.Item>
        <Form.Item label="Last Name" name="lastName" rules={[{ required: true, message: 'Required' }]}>
          <Input disabled />
        </Form.Item>
        <Form.Item label="Email" name="username" rules={[{ required: true, message: 'Required' }]}>
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="Phone Number"
          name="phoneNumber"
          rules={[
            {
              pattern: /^\d{10}$/,
              message: 'Please a valid 10 digit phone number',
            },
          ]}
          getValueFromEvent={(e) => {
            return e.target.value.replace(/[^0-9]/g, '');
          }}
        >
          <MaskedInput mask="(000) 000-0000" type="tel" placeholder="(000) 000-0000" />
        </Form.Item>
        <Form.Item label="Require MFA" name="requireMfa" valuePropName="checked" style={{ width: 100 }}>
          <Checkbox />
        </Form.Item>
      </Form>
    </>
  );
};

export default InviteUsersEditForm;
