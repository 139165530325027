import { Modal, Row, Col, Form, Input, notification, Spin, Button, Space, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import Routes from '../../consts/Routes';
import PasswordFormItem from '../../components/shared/PasswordFormItem';
import HistoryUtil from '../../utils/HistoryUtil';
import AccountApiService from '../../api/AccountApiService';
import useHasPermission from '../../hooks/useHasPermission';
import Permission from '../../consts/Permission';
import { AuthenticationContext } from '../../auth/AuthenticationContext';
import LoginLayout from '../account/LoginLayout';
import { PlusOutlined } from '@ant-design/icons';
import CardButton from '../../components/shared/CardButton/CardButton';
import { useForm } from 'antd/lib/form/Form';
import PinAccessTypes from '../../consts/PinAccessTypes';

const { Text } = Typography;

const GuestHome = () => {
  const hasPermission = useHasPermission();
  const authContext = useContext(AuthenticationContext);

  const [userPinModalVisible, setUserPinModalVisible] = useState(false);
  const [requestForm] = useForm();
  const [loading, setLoading] = useState(false);

  const [notif, notifContext] = notification.useNotification();

  useEffect(() => {
    AccountApiService.checkMemberAccountEligible().then((res) => {
      if (res) {
        notif.info({
          message: 'You may be eligible to register for a member account!',
          description: (
            <>
              Please <a href={Routes.generate(Routes.REGISTER)}>click here</a> to register for an account.
            </>
          ),
          placement: 'top',
          duration: 0,
        });
      }
    });
  }, []);

  const canCreateNominationAccount =
    hasPermission(Permission.GENERAL_EMPLOYERPINACCESS_CREATEPERSONALPINS) &&
    (authContext.user?.guestAccountType ?? PinAccessTypes.CLIENT) === PinAccessTypes.CLIENT;

  const submitPinRequest = (values: any) => {
    setLoading(true);
    AccountApiService.requestUserPin(values.email, values.password)
      .then(async (res) => {
        if (!res.succeeded) {
          throw '';
        }

        notification.success({ message: 'An email with your nomination account PIN has been sent!' });
        setUserPinModalVisible(false);
        await authContext.logout();
      })
      .catch(() => {
        notification.error({ message: 'There was an issue requesting a new PIN.' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const individualButton = (
    <CardButton
      title="Create a Nomination Account"
      icon={<PlusOutlined />}
      extra={<>&nbsp;</>}
      style={{ marginLeft: 'auto', marginRight: 'auto' }}
      onClick={() => {
        setUserPinModalVisible(true);
      }}
    />
  );

  const nominateProviderCardButton = (
    <CardButton
      title="Nominate Your Provider"
      icon={<PlusOutlined />}
      extra={<>&nbsp;</>}
      onClick={() => {
        HistoryUtil.push(Routes.generate(Routes.GUEST_PNF));
      }}
    />
  );

  const nominateProviderLink = (
    <Button
      shape="round"
      size="large"
      onClick={() => {
        HistoryUtil.push(Routes.generate(Routes.GUEST_PNF));
      }}
    >
      Nominate Your Provider
    </Button>
  );

  return (
    <>
      <LoginLayout cardTitle="" colPropsOverride={{ sm: 22, md: 18, lg: 14, xl: 12, xxl: 10 }}>
        {notifContext}
        <Row justify="center" style={{ textAlign: 'center' }}>
          <Col sm={20} xl={18}>
            {canCreateNominationAccount ? (
              <Space direction="vertical" size="large" style={{ width: '100%' }}>
                <Text>
                  If you would like to save your personal details and nomination progress for future use, please create
                  a nomination account PIN.
                  <br />
                  If you do not wish to save, click &#8220;Nominate Your Provider&#8221; below.
                </Text>
                <div>{individualButton}</div>
                <div>{nominateProviderLink}</div>
              </Space>
            ) : (
              nominateProviderCardButton
            )}
          </Col>
        </Row>
        <br />
        <Modal
          visible={userPinModalVisible}
          title="Create a Nomination Account"
          onOk={() => requestForm.submit()}
          onCancel={() => setUserPinModalVisible(false)}
          okButtonProps={{ shape: 'round' }}
          cancelButtonProps={{ shape: 'round' }}
          destroyOnClose
        >
          <Spin spinning={loading}>
            <Form form={requestForm} onFinish={submitPinRequest} layout="vertical">
              <p>Please enter the information below and we will send you a PIN for your nomination account.</p>
              <br />
              <Form.Item label="Email Address" name="email" rules={[{ required: true, message: 'Required' }]}>
                <Input />
              </Form.Item>

              <PasswordFormItem />

              <Form.Item
                name="confirm"
                label="Confirm Password"
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('The two passwords that you entered do not match!'));
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Form>
          </Spin>
        </Modal>
      </LoginLayout>
    </>
  );
};

export default GuestHome;
