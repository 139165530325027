import { Alert, Button, Col, Row, Spin } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AccountApiService from '../../api/AccountApiService';
import Routes from '../../consts/Routes';
import useUrlQuery from '../../hooks/useUrlQuery';
import LoginLayout from './LoginLayout';

// TODO: Why does user id get sent in the email?

interface ConfirmEmailPageProps {
  code?: string;
  userId?: string;
}

const ConfirmEmailPage = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [confirmSuccess, setConfirmSucces] = useState(false);
  const [urlQuery] = useUrlQuery<ConfirmEmailPageProps>();

  useEffect(() => {
    if (urlQuery.code && urlQuery.userId) {
      const userId = parseInt(urlQuery.userId);
      if (!isNaN(userId)) {
        checkCode(urlQuery.code, userId);
      }
    }
  }, [urlQuery.code, urlQuery.userId]);

  const checkCode = async (code: string, userId: number) => {
    setLoading(true);
    setConfirmSucces(false);
    try {
      const response = await AccountApiService.confirmEmail(userId, code);
      if (response.succeeded) {
        setLoading(false);
        setConfirmSucces(true);
        setError(null);
      } else {
        throw '';
      }
    } catch {
      setLoading(false);
      setError('Invalid link. Please request another and try again.');
      setConfirmSucces(false);
    }
  };

  let alert: JSX.Element | null = null;
  if (error !== null) {
    alert = (
      <Alert
        message="Error"
        description={error}
        type="error"
        showIcon={true}
        style={{ marginBottom: '12px', whiteSpace: 'pre-wrap' }}
      />
    );
  }
  if (confirmSuccess) {
    const message = 'Email confirmed successfully!';
    alert = (
      <Alert message="Success" description={message} type="success" showIcon={true} style={{ marginBottom: '12px' }} />
    );
  }

  return (
    <LoginLayout cardTitle="Email Confirmation">
      <Row justify="center">
        <Col xs={24} sm={18}>
          {alert}
          <div
            style={{
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {!loading ? (
              <FormItem>
                {!confirmSuccess ? (
                  <Button htmlType="button" size="large" disabled={loading} shape="round" type="primary" block>
                    <Link to={Routes.RESEND_CONFIRMATION_EMAIL}>Resend Confirmation Email</Link>
                  </Button>
                ) : (
                  <Button htmlType="button" size="large" disabled={loading} shape="round" block type="primary">
                    <Link to={Routes.LOGIN}>Go to Login</Link>
                  </Button>
                )}
              </FormItem>
            ) : (
              <Spin size="large" tip="Checking Code" />
            )}
          </div>
        </Col>
      </Row>
    </LoginLayout>
  );
};

export default ConfirmEmailPage;
