import { Button } from 'antd';
import { Control } from 'azure-maps-control';
import React from 'react';
import ReactDOM from 'react-dom';
// Using Control to create a CustomControl for the AzureMap
// See more information and examples here: https://github.com/WiredSolutions/react-azure-maps-playground/tree/master/src/examples/CustomControl

export class CustomMapControl implements Control {
  private container: HTMLDivElement | null = null;
  private onClick: () => void;
  private buttonText: string;

  constructor(buttonText: string, action: () => void) {
    this.onClick = action;
    this.buttonText = buttonText;
  }

  public onAdd(): HTMLElement {
    this.container = document.createElement('div');
    this.container.classList.add('azure-maps-control-container', 'light');
    this.container.style.margin = '10px';
    ReactDOM.render(
      <Button ghost onClick={this.onClick}>
        {this.buttonText}
      </Button>,
      this.container
    );
    return this.container;
  }

  public onRemove(): void {
    if (this.container) {
      this.container.remove();
      this.container = null;
    }
  }
}
