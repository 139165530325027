import { ArrowRightOutlined } from '@ant-design/icons';
import { Button, Col, Layout, Row, Table } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import DashboardService from '../../api/DashboardApiService';
import { AuthenticationContext } from '../../auth/AuthenticationContext';
import ContactView from '../../components/contact/ContactView';
import PageTitle from '../../components/shared/PageTitle';
import AccessRole from '../../consts/AccessRole';
import RelationDTO from '../../models/RelationDTO';

const ClientListPage = () => {
  const [loading, setLoading] = useState(true);

  const [relations, setRelations] = useState<RelationDTO[]>([]);

  const [displayRelation, setDisplayRelation] = useState<RelationDTO | undefined>(undefined);
  const authContext = useContext(AuthenticationContext);

  const user = authContext.user;
  const roleId = user?.accessRoleId;

  useEffect(() => {
    setLoading(true);
    DashboardService.getUserRelations().then((res) => {
      setRelations(res);
      setLoading(false);
    });
  }, []);

  const relationTable = (relations: RelationDTO[]) => {
    if (displayRelation) {
      return (
        <>
          <Button type="link" onClick={() => setDisplayRelation(undefined)}>
            &lt; Back To List
          </Button>
          <h3>{displayRelation.name}</h3>
          <ContactView showContacts={roleId !== AccessRole.MEMBER} clientId={displayRelation.id} />
        </>
      );
    }

    return (
      <Table
        pagination={false}
        rowKey="id"
        columns={[
          { dataIndex: 'name', key: 'id', title: 'Contact' },
          {
            // eslint-disable-next-line react/display-name
            render: (v, r) => (
              <Button onClick={() => setDisplayRelation(r)} type="ghost" shape="circle" icon={<ArrowRightOutlined />} />
            ),
            align: 'right',
          },
        ]}
        dataSource={relations}
      />
    );
  };

  let content = null;
  switch (roleId) {
    case AccessRole.CLAIMDOC:
    case AccessRole.TPA:
    case AccessRole.BROKER:
      content = relationTable(relations);
      break;
  }

  return (
    <Layout.Content className="content-padding">
      <PageTitle title="Client List" />
      <Row>
        <Col xxl={10} xl={16} lg={20} xs={24}>
          {loading ? null : content}
        </Col>
      </Row>
    </Layout.Content>
  );
};

export default ClientListPage;
