import { BackTop, Col, Pagination, Row } from 'antd';
import React, { useCallback } from 'react';
import ProviderDTO from '../../models/ProviderDTO';
import { SortByFormat } from './PricingToolProviderSearchFilters';
import SearchResultCard from './PricingToolProviderSearchResultCard';

interface PricingToolProviderSearchResultsProps {
  isMobile: boolean;
  providers?: ProviderDTO[];
  sortBy?: number;
  selected?: ProviderDTO;
  resultClick: (provider: ProviderDTO) => void;
  totalCount: number;
  pagination?: PaginationProps;
}

interface PaginationProps {
  currentPage: number;
  pageSize: number;
  onPageChange: (page: number) => void;
}

const mobileStyle = { paddingLeft: '6px', paddingRight: '6px' };
const desktopStyle = { height: '52.5vh', overflow: 'auto' };

const PricingToolProviderSearchResults: React.FC<PricingToolProviderSearchResultsProps> = (
  props: PricingToolProviderSearchResultsProps
) => {
  const handleClick = useCallback(
    (providerId) => {
      const prov = (props.providers || []).find((p) => p.id === providerId);
      if (prov) {
        props.resultClick(prov);
      }
    },
    [props.providers]
  );

  const resultsCountRender = () => {
    let paginationStr = '';
    if (props.pagination) {
      const { currentPage, pageSize } = { ...props.pagination };
      paginationStr = `Showing 
      ${currentPage * pageSize - (pageSize - 1)} to 
      ${currentPage * pageSize < props.totalCount ? currentPage * pageSize : props.totalCount} of `;
    }
    const str = `${paginationStr}${props.totalCount} provider${props.totalCount === 1 ? '' : 's'} found`;
    return str;
  };

  const onPageChange = (page: number) => {
    if (props.pagination) {
      props.pagination.onPageChange(page);
    }
  };

  const renderPagination = () => {
    if (!props.providers || !props.pagination) {
      return;
    }

    return (
      <Row justify="center" align="middle" style={{ marginBottom: '5px' }}>
        <Col flex={1} style={{ textAlign: 'center' }}>
          <Pagination
            current={props.pagination.currentPage}
            total={props.totalCount}
            pageSize={props.pagination.pageSize}
            showSizeChanger={false}
            size={props.isMobile ? 'small' : 'default'}
            showLessItems
            onChange={(page: number) => onPageChange(page)}
          />
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Row justify="space-between" style={{ marginTop: props.isMobile ? 5 : 0, paddingLeft: 6, paddingRight: 6 }}>
        <Col>{props.providers ? resultsCountRender() : null}</Col>
        <Col style={{ textAlign: 'right' }}>
          {(props.providers?.length ?? 0) < 2 || props.sortBy === undefined ? null : (
            <span style={{ margin: 0, marginLeft: 5 }}>{SortByFormat(props.sortBy, true)}</span>
          )}
        </Col>
      </Row>
      <div style={props.isMobile ? mobileStyle : desktopStyle}>
        <BackTop />
        {props.providers?.map((provider, i) => (
          <SearchResultCard
            key={`provider_${i}`}
            provider={provider}
            isSelected={provider.id === props.selected?.id}
            onClick={handleClick}
          />
        ))}
        {renderPagination()}
      </div>
    </>
  );
};

export default React.memo(PricingToolProviderSearchResults);
