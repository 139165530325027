import React from 'react';

const PricingToolInstructions = () => {
  return (
    <>
      ClaimDOC provides a cost-containment solution that evolves with the legal and compliance landscape. The Pricing
      Tool was developed to meet the No Surprises Act (NSA) Consumer Tool requirements. This new feature offers our
      clients and partners access to accurate pricing information for common medical procedures.
    </>
  );
};

export default PricingToolInstructions;
