// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import MemberClaimListDTO from '../models/MemberClaimListDTO';
import RelationDTO from '../models/RelationDTO';
import MemberDTO from '../models/MemberDTO';
import MemberClaimDTO from '../models/MemberClaimDTO';

export class ClaimSearchApiService extends BaseApi {

    // post: api/claimsearch/GetMemberClaimList?memberId=${encodeURIComponent(memberId)}&clientId=${clientId}&fromDate=${encodeURIComponent(String(fromDate))}&toDate=${encodeURIComponent(String(toDate))}&onlyInProgressClaims=${onlyInProgressClaims}
    public getMemberClaimList(tableRequest: TableRequestDTO, memberId: string, clientId: number, fromDate: string, toDate: string, onlyInProgressClaims: boolean): Promise<TableResponseDTO<MemberClaimListDTO>> {
        let url = `api/claimsearch/GetMemberClaimList`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (memberId != null) {
            url += `${prefix}memberId=${encodeURIComponent(memberId)}`;
            prefix = '&';
        }
        if (clientId != null) {
            url += `${prefix}clientId=${clientId}`;
            prefix = '&';
        }
        if (fromDate != null) {
            url += `${prefix}fromDate=${encodeURIComponent(fromDate)}`;
            prefix = '&';
        }
        if (toDate != null) {
            url += `${prefix}toDate=${encodeURIComponent(toDate)}`;
            prefix = '&';
        }
        if (onlyInProgressClaims != null) {
            url += `${prefix}onlyInProgressClaims=${onlyInProgressClaims}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<MemberClaimListDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/claimsearch/GetClients
    public getClients(): Promise<RelationDTO[]> {
        let url = `api/claimsearch/GetClients`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, RelationDTO[]>(null, 'post', url, true, false);
    }

    // post: api/claimsearch/GetMembersByLastName?clientId=${clientId}&lastName=${encodeURIComponent(lastName)}
    public getMembersByLastName(clientId: number, lastName: string): Promise<MemberDTO[]> {
        let url = `api/claimsearch/GetMembersByLastName`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (clientId != null) {
            url += `${prefix}clientId=${clientId}`;
            prefix = '&';
        }
        if (lastName != null) {
            url += `${prefix}lastName=${encodeURIComponent(lastName)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, MemberDTO[]>(null, 'post', url, true, false);
    }

    // post: api/claimsearch/GetMemberClaimByClaimId?claimDocClaimId=${encodeURIComponent(claimDocClaimId)}
    public getMemberClaimByClaimId(claimDocClaimId: string): Promise<MemberClaimDTO> {
        let url = `api/claimsearch/GetMemberClaimByClaimId`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (claimDocClaimId != null) {
            url += `${prefix}claimDocClaimId=${encodeURIComponent(claimDocClaimId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, MemberClaimDTO>(null, 'post', url, true, false);
    }
}
var service = new ClaimSearchApiService();
export default service;
