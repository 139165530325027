import { notification } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ClientService from '../../../api/ClientApiService';
import Permission from '../../../consts/Permission';
import Role from '../../../consts/Role';
import Routes from '../../../consts/Routes';
import useHasPermission from '../../../hooks/useHasPermission';
import ClientAccessDTO from '../../../models/ClientAccessDTO';
import LookupTableDTO from '../../../models/LookupTableDTO';
import TableRequestDTO from '../../../models/TableRequestDTO';
import TableResponseDTO from '../../../models/TableResponseDTO';
import UserClientAccessDTO from '../../../models/UserClientAccessDTO';
import AccessEditor, { AccessEditorProps } from '../../shared/EntityAccess/AccessEditor';

interface DpcAccessListProps {
  clientId: number;
  dpcId?: string;
  readonly?: boolean;
}

const dpcIdFromProp = (id?: string) => {
  const parsed = parseInt(id ?? '');
  return isNaN(parsed) ? undefined : parsed;
};

const DpcAccessEditor = (props: DpcAccessListProps) => {
  const [loading, setLoading] = useState(true);
  const [clientAccess, setClientAccess] = useState<ClientAccessDTO>(ClientAccessDTO.create());
  const [dpcId, setDpcId] = useState<number | undefined>(dpcIdFromProp(props.dpcId));
  const [selectedDpcId, setSelectedDpcId] = useState<number | undefined>(undefined);
  const [dpcUsers, setDpcUsers] = useState<UserClientAccessDTO[]>([]);
  const hasPermission = useHasPermission();

  useEffect(() => {
    setDpcId(dpcIdFromProp(props.dpcId));
  }, [props.dpcId]);

  useEffect(() => {
    ClientService.getDpcClientAccess(props.clientId, dpcId)
      .then((res) => {
        setClientAccess(res);
        setLoading(false);
      })
      .catch((err) => {
        notification.error({ message: 'Failed to fetch', description: err.message });
      });
  }, [dpcId]);

  const fetchTableData = (
    requestState: TableRequestDTO,
    checkEcho: () => boolean,
    callback: (response: TableResponseDTO<UserClientAccessDTO>) => void
  ) => {
    setLoading(true);
    ClientService.getDpcUserClientAccesses(props.clientId, dpcId ?? 0, requestState)
      .then((results) => {
        if (!checkEcho()) {
          return;
        }
        setDpcUsers(results.results || []);

        setLoading(false);
        callback(results);
      })
      .catch((error: any) => {
        setLoading(false);
        notification.error({
          message: error.message,
          description: error.description,
        });
      });
  };

  const saveChanges = (values: ClientAccessDTO, cb?: (success: boolean, id?: number) => void) => {
    setLoading(true);
    // Save handler expects client ID to be in the DTO.
    values.id = props.clientId;
    ClientService.saveDpcClientAccess(dpcId ?? selectedDpcId ?? 0, values)
      .then((res) => {
        if (res.succeeded) {
          notification.success({ message: 'DPC Access Saved' });
          ClientService.getDpcClientAccess(props.clientId, dpcId ?? selectedDpcId ?? 0).then((access) => {
            setClientAccess(access);
            if (cb) {
              cb(res.succeeded, dpcId ?? selectedDpcId ?? 0);
            }
          });
        } else {
          throw '';
        }
      })
      .catch(() => {
        notification.error({ message: 'Failed to save DPC Access' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const entitySelected = (id: number) => {
    ClientService.getDpcClientAccess(props.clientId, id)
      .then((res) => {
        setClientAccess({ ...clientAccess, users: res.users });
        setSelectedDpcId(id);
      })
      .catch((err) => {
        notification.error({ message: 'Failed to fetch', description: err.message });
      });
  };

  const dpcProps = {
    entityId: clientAccess.id,
    entityName: clientAccess.name,

    showDates: true,
    startDate: clientAccess.startDate ? moment(clientAccess.startDate) : undefined,
    endDate: clientAccess.endDate ? moment(clientAccess.endDate) : undefined,

    userData: dpcUsers,

    entityList: clientAccess?.entities?.map((t) => {
      return {
        id: t.id,
        name: t.name,
      } as LookupTableDTO;
    }),

    entitySelected: entitySelected,
    fetchData: fetchTableData,

    loading: loading,
    backUrl: Routes.generate(Routes.ADMIN_CLIENT_DETAILS_DPCS, { id: props.clientId }),
    editUrl: (id: number) => Routes.generate(Routes.ADMIN_CLIENT_DETAILS_DPCS, { id: props.clientId, dpcId: id }),
    listText: 'Back to DPCs',
    entitySearch: {
      entitySearchLabel: 'Search For DPC',
      entitySearchPlaceholder: 'Select DPC',
    },
    showCancel: true,

    userAdd: {
      userAddSearchLabel: 'Select which DPC users have access to this client:',
      userAddSearchPlaceholder: 'Select DPC User',
      userAddText: 'Add DPC User',
    },

    saveButtonText: 'Save DPC',
    readOnly: !hasPermission(Permission.ADMIN_CLIENT_DPC_EDIT) || !!props.readonly,
    dataTableTitle: 'User Access Permissions',

    roleId: Role.DPC,

    onSave: saveChanges,
  } as AccessEditorProps;

  return <AccessEditor {...dpcProps} />;
};

export default DpcAccessEditor;
