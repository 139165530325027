import { Card, Col, Row } from 'antd';
import React from 'react';

const WelcomeUser = () => {
  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Card className="intro-callout" bordered={false}>
          <Row align="middle" wrap={false}>
            <Col sm={4} flex="60px" style={{ paddingRight: '15px' }}>
              <img
                src={process.env.PUBLIC_URL + '/claimdoc-logo_icon.svg'}
                alt="ClaimDOC"
                style={{ width: '100%', height: 'auto' }}
              />
            </Col>
            <Col flex="auto">
              <h2 style={{ margin: '0', fontSize: 35 }}>Welcome</h2>
            </Col>
          </Row>
          <img src={process.env.PUBLIC_URL + '/intro-bg.png'} alt="Welcome background" />
        </Card>
      </Col>
    </Row>
  );
};

export default WelcomeUser;
