// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Constants.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
export default class FeatureFlag {
    public static readonly SERVICE_PROVIDERS_ACKNOWLEDGEMENT_OF_RECEIPT_HIDE_QUESTION: number = 1;
    public static readonly SERVICE_PROVIDERS_ACKNOWLEDGEMENT_OF_RECEIPT_ALLOW_SELECTION: number = 2;
    public static readonly SERVICE_PROVIDERS_ACKNOWLEDGEMENT_OF_RECEIPT_MEMBER: number = 3;
    public static readonly SERVICE_PROVIDERS_ACKNOWLEDGEMENT_OF_RECEIPT_SUBMITTER: number = 4;
    public static readonly SERVICE_PROVIDERS_NOMINATION_ACCESSIBLE_IN_DOCUMENTS_HIDE_QUESTION: number = 5;
    public static readonly SERVICE_PROVIDERS_NOMINATION_ACCESSIBLE_IN_DOCUMENTS_ALLOW_SELECTION: number = 6;
    public static readonly SERVICE_PROVIDERS_NOMINATION_ACCESSIBLE_IN_DOCUMENTS_MEMBER: number = 7;
    public static readonly SERVICE_PROVIDERS_NOMINATION_ACCESSIBLE_IN_DOCUMENTS_SUBMITTER: number = 8;
    public static readonly SERVICE_PROVIDERS_FOLLOW_UP_COMMUNICATION_HIDE_QUESTION: number = 9;
    public static readonly SERVICE_PROVIDERS_FOLLOW_UP_COMMUNICATION_ALLOW_SELECTION: number = 10;
    public static readonly SERVICE_PROVIDERS_FOLLOW_UP_COMMUNICATION_MEMBER: number = 11;
    public static readonly SERVICE_PROVIDERS_FOLLOW_UP_COMMUNICATION_SUBMITTER: number = 12;
    public static readonly SERVICE_PROVIDERS_NOMINATION_DETAILS_DEPENDENT_ON_ACKNOWLEDGEMENT_OF_RECEIPT_HIDE_QUESTION: number = 13;
    public static readonly SERVICE_PROVIDERS_NOMINATION_DETAILS_DEPENDENT_ON_ACKNOWLEDGEMENT_OF_RECEIPT_ALLOW_SELECTION: number = 14;
    public static readonly SERVICE_PROVIDERS_NOMINATION_DETAILS_DEPENDENT_ON_ACKNOWLEDGEMENT_OF_RECEIPT_MEMBER: number = 15;
    public static readonly SERVICE_PROVIDERS_NOMINATION_DETAILS_DEPENDENT_ON_ACKNOWLEDGEMENT_OF_RECEIPT_SUBMITTER: number = 16;
}
