import React, { useEffect } from 'react';
import PermissionGroupDetailDTO from '../../../models/PermissionGroupDetailDTO';
import PermissionGroupRelationDTO from '../../../models/PermissionGroupRelationDTO';
import DataTable, { DataTableColumnProps, FilterType } from '../../shared/DataTable/DataTable';

interface PermissionGroupPermissionTableProps {
  group: PermissionGroupDetailDTO;
}

const PermissionGroupRelationshipTable = (props: PermissionGroupPermissionTableProps) => {
  const dataTableRef = React.useRef<DataTable<PermissionGroupRelationDTO>>(null);

  const tableColumns: DataTableColumnProps<PermissionGroupRelationDTO>[] = [
    {
      title: 'Type',
      dataIndex: 'type',
      defaultSortOrder: 'descend',
      sorter: true,
      filterType: FilterType.Text,
    },
    {
      title: 'Relation',
      dataIndex: 'additional',
      defaultSortOrder: 'descend',
      sorter: true,
    },
  ];

  useEffect(() => {
    dataTableRef.current?.refresh();
  }, [props.group]);

  const tableData: PermissionGroupRelationDTO[] = props.group.relations ?? [];

  return (
    <>
      <DataTable
        serverSide={false}
        ref={dataTableRef}
        tableProps={{
          sortDirections: ['ascend', 'descend'],
        }}
        columns={tableColumns}
        data={tableData}
        styleOptions={{
          alternatingRowHighlight: true,
        }}
      />
    </>
  );
};

export default PermissionGroupRelationshipTable;
