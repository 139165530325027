import { Select, SelectProps } from 'antd';
import { SelectValue } from 'antd/lib/select';
import React from 'react';
import UsStateDTO from '../../models/UsStateDTO';

interface StatePickerProps<T extends SelectValue> extends SelectProps<T> {
  usStates?: UsStateDTO[] | null;
}

function StatePicker<T extends SelectValue>(props: StatePickerProps<T>) {
  const { usStates, ...selectProps } = props;

  const states = usStates ?? [];

  return (
    <Select
      showSearch
      optionFilterProp="children"
      filterOption={(input: any, option: any) => {
        if (input.length > 2) {
          return (
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
            option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
          );
        }
        return option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }}
      {...selectProps}
    >
      {states.map((st) => {
        return (
          <Select.Option key={st.code ?? ''} value={st.code ?? ''} label={st.name as any}>
            {st.name}
          </Select.Option>
        );
      })}
    </Select>
  );
}

export default StatePicker;
