import { notification } from 'antd';
import React, { useState } from 'react';
import ClientService from '../../../api/ClientApiService';
import Permission from '../../../consts/Permission';
import Role from '../../../consts/Role';
import useHasPermission from '../../../hooks/useHasPermission';
import ClientAccessDTO from '../../../models/ClientAccessDTO';
import TableRequestDTO from '../../../models/TableRequestDTO';
import TableResponseDTO from '../../../models/TableResponseDTO';
import UserClientAccessDTO from '../../../models/UserClientAccessDTO';
import AccessEditor, { AccessEditorProps } from '../../shared/EntityAccess/AccessEditor';

interface AllowedDomainsEmailsProps {
  clientId: number;
  readonly?: boolean;
}

const ClaimDocAccess = (props: AllowedDomainsEmailsProps) => {
  const [loading, setLoading] = useState(true);
  const [accessList, setAccessList] = useState<UserClientAccessDTO[]>([]);
  const hasPermission = useHasPermission();

  const fetchTableData = (
    requestState: TableRequestDTO,
    checkEcho: () => boolean,
    callback: (response: TableResponseDTO<UserClientAccessDTO>) => void
  ) => {
    setLoading(true);
    ClientService.getClaimDocSupportUsersForClient(props.clientId, requestState)
      .then((results) => {
        if (!checkEcho()) {
          return;
        }
        setAccessList(results.results || []);

        setLoading(false);
        callback(results);
      })
      .catch((error: any) => {
        setLoading(false);
        notification.error({
          message: error.message,
          description: error.description,
        });
      });
  };

  const saveChanges = (values: ClientAccessDTO, cb?: (success: boolean, id?: number) => void) => {
    setLoading(true);
    ClientService.saveClaimDocSupportUserClientAccess(props.clientId, values.users ?? [])
      .then((res) => {
        if (res.succeeded) {
          notification.success({ message: 'ClaimDOC Access Saved' });

          if (cb) {
            cb(true);
          }
        } else {
          throw '';
        }
      })
      .catch(() => {
        notification.error({ message: 'Failed to save ClaimDOC Access' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editorProps = {
    loading: loading,
    saveButtonText: 'Save ClaimDOC Access',
    userData: accessList,
    userAdd: {
      userAddSearchLabel: 'Search for new ClaimDOC Employee to add to client:',
      userAddSearchPlaceholder: 'Search Users',
      userAddText: 'Add User',
    },
    accessFilter: {
      showFilter: true,
      initialValue: 'true',
    },
    entityId: props.clientId,
    entityName: 'ClaimDOC Employee Access',
    readOnly: !hasPermission(Permission.ADMIN_CLIENT_CLAIMDOC_ACCESS_EDIT) || !!props.readonly,
    showReset: true,
    fetchData: fetchTableData,
    onSave: saveChanges,

    roleId: Role.CLAIMDOC,
  } as AccessEditorProps;

  return (
    <>
      <AccessEditor {...editorProps} />
    </>
  );
};

export default ClaimDocAccess;
