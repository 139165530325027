// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import FeatureFlagDTO from './FeatureFlagDTO';

interface EmployerFeatureFlagsDTO { 
    clientId: number | null;
    featureFlags: FeatureFlagDTO[] | null;
}
const EmployerFeatureFlagsDTO = {
    create: (initValues?: Partial<EmployerFeatureFlagsDTO> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            clientId: null,
            featureFlags: [],
        },
        initValues);
    }
};

export default EmployerFeatureFlagsDTO;