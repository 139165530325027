import { Col, Row, Space, Typography, Button, Popover } from 'antd';
import * as React from 'react';
import Renderers from '../../utils/Renderers';

const { Title } = Typography;

const HeaderContactContent = () => {
  return (
    <Row>
      <Col xs={24} md={8}>
        <Space direction="vertical">
          <Title level={5}>Member Advocacy</Title>
          {Renderers.phoneNumberLink('8883307295')}
          <Popover content="membersupport@claim-doc.com">
            <Button htmlType="button" shape="round" style={{ marginTop: '.75em' }} ghost>
              {Renderers.emailLink('membersupport@claim-doc.com', 'Email')}
            </Button>
          </Popover>
        </Space>
      </Col>
      <Col xs={24} md={8}>
        <Space direction="vertical">
          <Title level={5}>Provider Nomination</Title>
          {Renderers.phoneNumberLink('8883307295')}
          <Button htmlType="button" shape="round" style={{ marginTop: '.75em' }} ghost>
            {Renderers.urlLink('https://claim-doc.com/pnf', 'Website')}
          </Button>
        </Space>
      </Col>
      <Col xs={24} md={8}>
        <Space direction="vertical">
          <Title level={5}>Balance Bills</Title>
          {Renderers.phoneNumberLink('8883307295')}
          <Popover content="balancebills@claim-doc.com">
            <Button htmlType="button" shape="round" style={{ marginTop: '.75em' }} ghost>
              {Renderers.emailLink('balancebills@claim-doc.com', 'Email')}
            </Button>
          </Popover>
        </Space>
      </Col>
    </Row>
  );
};

export default HeaderContactContent;
