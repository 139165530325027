import { CopyOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Modal, notification, Row, Space, Spin, Switch } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import React, { useEffect, useState } from 'react';
import MailroomApiService from '../../api/MailroomApiService';
import Permission from '../../consts/Permission';
import Routes from '../../consts/Routes';
import useHasPermission from '../../hooks/useHasPermission';
import MailroomAccountDetailDTO from '../../models/MailroomAccountDetailDTO';
import HistoryUtil from '../../utils/HistoryUtil';
import PageStayPrompt from '../../utils/PageStayPrompt';
import PermissionGuard from '../shared/PermissionGuard';
import confirmModal from '../../utils/Modal';

interface MailroomAccountDetailViewProps {
  accountDto: MailroomAccountDetailDTO | null;
  refreshDto: (id: number | null) => void;
}

const MailroomAccountDetailView = (props: MailroomAccountDetailViewProps) => {
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState<string | null>();
  const [mailroomAccountDetail, setMailroomAccountDetail] = useState<MailroomAccountDetailDTO>(
    props.accountDto || MailroomAccountDetailDTO.create()
  );
  const [changesPending, setChangesPending] = useState(false);
  const [form] = Form.useForm();
  const hasPermission = useHasPermission();
  const requiredRule = { required: true, message: 'Required' };

  const onChange = () => {
    if (!changesPending) {
      setChangesPending(true);
    }
  };

  const onSave = (values: any) => {
    setLoading(true);
    const model = { ...mailroomAccountDetail, ...values } as MailroomAccountDetailDTO;

    MailroomApiService.saveMailroomAccountDetail(model)
      .then((res) => {
        setChangesPending(false);
        props.refreshDto(res);
        notification.success({ message: 'Mailroom Account Saved' });
      })
      .catch((err) => {
        notification.error({ message: err.message, description: err.description });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const provisionAccount = (username: string, baseUrl: string, accountId: number) => {
    if (!mailroomAccountDetail.isProvisioned) {
      setLoading(true);
      setLoadingText('Provisioning the sftp account. This may take 3-5 minutes.');

      MailroomApiService.provisionMailroomAccount(username, baseUrl, accountId)
        .then(() => {
          notification.success({ message: 'Mailroom Account Provisioned' });
          props.refreshDto(mailroomAccountDetail.id);
        })
        .catch(() => {
          notification.error({
            message: 'Account Provisioning Failed',
            description: 'Something went wrong while provisioning the account.',
          });
        })
        .finally(() => {
          setLoading(false);
          setLoadingText(null);
        });
    } else {
      notification.error({
        message: 'Account Provisioning Failed',
        description: 'The account has already been provisioned.',
      });
    }
  };

  const onRegeneratePassword = (username: string) => {
    if (mailroomAccountDetail.isProvisioned) {
      setLoading(true);
      setLoadingText('Generating sftp password. This may take 3-5 minutes.');

      MailroomApiService.regenerateMailroomAccountPassword(username)
        .then((res) => {
          Modal.success({
            style: { top: 250, width: 'fit-content' },
            title: 'Copy generated password.',
            content: (
              <div>
                {res}
                <Button style={{ marginLeft: '10px' }} onClick={() => navigator.clipboard.writeText(res)}>
                  <CopyOutlined />
                </Button>
              </div>
            ),
          });
        })
        .catch(() => {
          notification.error({
            message: 'Password Generation Failed',
            description: 'Something went wrong while generating the password.',
          });
        })
        .finally(() => {
          setLoading(false);
          setLoadingText(null);
        });
    } else {
      notification.error({
        message: 'Password Generation Failed',
        description: 'The account has not been provisioned yet.',
      });
    }
  };

  useEffect(() => {
    form.resetFields();
    setMailroomAccountDetail(props.accountDto || MailroomAccountDetailDTO.create());
    setChangesPending(false);
    form.setFieldsValue(props.accountDto);
  }, [props.accountDto]);

  const readOnly = !hasPermission(Permission.UTILITIES_MAILROOM_ACCOUNT_EDIT);

  const canRegeneratePassword =
    hasPermission(Permission.UTILITIES_MAILROOM_ACCOUNT_AZUREACCESS) && mailroomAccountDetail.isProvisioned;

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSave}
      onValuesChange={onChange}
      style={{ width: '100%' }}
      initialValues={mailroomAccountDetail}
    >
      <Row justify="end">
        <Col>
          <Space>
            <PermissionGuard permissionId={Permission.UTILITIES_MAILROOM_ACCOUNT_AZUREACCESS}>
              <Button
                htmlType="button"
                type="primary"
                shape="round"
                disabled={mailroomAccountDetail.isProvisioned || mailroomAccountDetail.id == 0}
                onClick={() => {
                  provisionAccount(
                    mailroomAccountDetail.sftpUsername ?? '',
                    mailroomAccountDetail.baseUrl ?? '',
                    mailroomAccountDetail.id
                  );
                }}
              >
                Provision Account
              </Button>
            </PermissionGuard>
            <PermissionGuard permissionId={Permission.UTILITIES_MAILROOM_ACCOUNT_EDIT}>
              <Space>
                <Button
                  onClick={() =>
                    confirmModal('Are you sure you want to reset all changes?', () =>
                      props.refreshDto(props.accountDto?.id || null)
                    )
                  }
                  ghost
                  shape="round"
                  disabled={!changesPending}
                >
                  Reset
                </Button>

                <Button
                  ghost
                  htmlType="button"
                  shape="round"
                  onClick={() => HistoryUtil.push(Routes.generate(Routes.MAILROOM_ACCOUNT_LIST))}
                >
                  Cancel
                </Button>
                <Button htmlType="submit" type="primary" shape="round" disabled={!changesPending}>
                  Save Details
                </Button>
              </Space>
            </PermissionGuard>
          </Space>
        </Col>
      </Row>

      <Spin tip={loadingText} spinning={loading}>
        <PageStayPrompt when={changesPending} rootRoute={Routes.generate(Routes.MAILROOM_ACCOUNT_NEW)} />
        <Row gutter={16}>
          <Col sm={6}>
            <FormItem name="account" label="Account" rules={[requiredRule]}>
              <Input disabled={readOnly} />
            </FormItem>
          </Col>
          <Col sm={6}>
            <FormItem name="partner" label="Partner" rules={[requiredRule]}>
              <Input disabled={readOnly} />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col sm={6}>
            <FormItem name="partnerCode" label="Partner Code" rules={[requiredRule]}>
              <Input disabled={readOnly} />
            </FormItem>
          </Col>
          <Col sm={6}>
            <FormItem name="accountType" label="Account Type">
              <Input disabled={readOnly} />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col sm={6}>
            <FormItem
              name="sftpUsername"
              label="Sftp Username"
              rules={[
                requiredRule,
                {
                  pattern: /^[a-z0-9]{3,64}$/,
                  message:
                    'Username must be between 3 and 64 characters long and can only contain lowercase letters and numbers.',
                },
              ]}
              getValueFromEvent={(e) => {
                return e.target.value.replace(/[^a-z0-9]/g, '');
              }}
            >
              <Input minLength={3} maxLength={64} disabled={readOnly || mailroomAccountDetail.id != 0} />
            </FormItem>
          </Col>
          <Col sm={6}>
            <FormItem label="Sftp Password">
              <Button
                htmlType="button"
                shape="round"
                type="primary"
                disabled={!canRegeneratePassword}
                onClick={() => onRegeneratePassword(mailroomAccountDetail.sftpUsername ?? '')}
              >
                Regenerate Password
              </Button>
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col sm={12}>
            <FormItem name="baseUrl" label="Base Url" rules={[requiredRule]}>
              <Input disabled={readOnly || mailroomAccountDetail.id != 0} />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col sm={12}>
            <FormItem name="specialSecurity" label="Special Security">
              <Input disabled={readOnly} />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col sm={12}>
            <FormItem name="contacts" label="Contacts">
              <Input.TextArea disabled={readOnly} />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col sm={12}>
            <FormItem name="notes" label="Notes">
              <Input.TextArea disabled={readOnly} />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormItem name="archived" label="Archived">
              <Switch defaultChecked={mailroomAccountDetail.archived} disabled={readOnly} />
            </FormItem>
          </Col>
        </Row>
      </Spin>
    </Form>
  );
};

export default MailroomAccountDetailView;
