// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import LookupTableDTO from '../models/LookupTableDTO';

export class LookupsApiBaseService extends BaseApi {

    // get: api/lookups/GetOne/${encodeURIComponent(name)}/${id}
    public getOne(name: string, id: number): Promise<LookupTableDTO> {
        let url = `api/lookups/GetOne/${encodeURIComponent(name)}/${id}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, LookupTableDTO>(null, 'get', url, true, false);
    }

    // get: api/lookups/GetActive/${encodeURIComponent(name)}/?include=${include}
    public getActive(name: string, include?: number | null): Promise<LookupTableDTO[]> {
        let url = `api/lookups/GetActive/${encodeURIComponent(name)}/`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (include != null) {
            url += `${prefix}include=${include}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, LookupTableDTO[]>(null, 'get', url, true, false);
    }

    // get: api/lookups/InvalidateCache?name=${encodeURIComponent(name)}
    public invalidateCache(name?: string | null): Promise<void> {
        let url = `api/lookups/InvalidateCache`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (name != null) {
            url += `${prefix}name=${encodeURIComponent(name || '')}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, void>(null, 'get', url, true, false);
    }
}
var service = new LookupsApiBaseService();
export default service;
