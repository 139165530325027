import { Layout } from 'antd';
import React from 'react';
import { Route, Switch } from 'react-router';
import Authorize from '../../auth/Authorize';
import GuestAccessDetailPage from '../../components/admin/GuestAccess/GuestAccessDetailsPage';
import GuestAccessListPage from '../../components/admin/GuestAccess/GuestAccessListPage';
import Permission from '../../consts/Permission';
import Routes from '../../consts/Routes';

const GuestAccessPage = () => {
  return (
    <Layout.Content className="content-padding">
      <Switch>
        <Route path={Routes.ADMIN_GUEST_ACCESS} exact={true}>
          <Authorize requiredPermissions={[Permission.ADMIN_EMPLOYERPINACCESS_VIEW]}>
            <GuestAccessListPage />
          </Authorize>
        </Route>
        <Route path={Routes.ADMIN_GUEST_ACCESS_DETAILS}>
          <Authorize requiredPermissions={[Permission.ADMIN_EMPLOYERPINACCESS_VIEW]}>
            <GuestAccessDetailPage />
          </Authorize>
        </Route>
        <Route path={Routes.ADMIN_GUEST_ACCESS_NEW}>
          <Authorize requiredPermissions={[Permission.ADMIN_CLIENT_DETAILS_EDIT]}>
            <GuestAccessDetailPage />
          </Authorize>
        </Route>
      </Switch>
    </Layout.Content>
  );
};

export default GuestAccessPage;
