import { AutoComplete, notification, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import OcrApiService from '../../api/OcrApiService';
import OcrHeaderOptionDTO from '../../models/OcrHeaderOptionDTO';
import OcrPreviewColDTO from '../../models/OcrPreviewColDTO';
import OcrPreviewDTO from '../../models/OcrPreviewDTO';
import DataTable, { DataTableColumnProps } from '../shared/DataTable/DataTable';

const { Option } = Select;

interface OcrPreviewTableProps {
  data: OcrPreviewDTO;
  onHeaderChange: (colIndex: number, value: any) => void;
}

const OcrPreviewTable: React.FC<OcrPreviewTableProps> = (props) => {
  const [headerOptions, setHeaderOptions] = useState<OcrHeaderOptionDTO[]>([]);

  useEffect(() => {
    OcrApiService.getHeaderOptions()
      .then((options) => {
        setHeaderOptions(options);
      })
      .catch((err) => {
        notification.error({ message: err.message });
      });
  }, []);

  const getTableCols = (): DataTableColumnProps<OcrPreviewColDTO>[] => {
    const cols: DataTableColumnProps<OcrPreviewColDTO>[] = [];
    if (props.data.columnNames.at(0)) {
      cols.push({
        title: (
          <AutoComplete
            style={{ width: '100%' }}
            defaultValue={props.data.columnNames.at(0)}
            onChange={(v) => props.onHeaderChange(0, v)}
          >
            {(headerOptions || []).map((o) => (
              <Option key={o.id} value={o.option}>
                {o.option}
              </Option>
            ))}
          </AutoComplete>
        ),
        dataIndex: 'col0Data',
      });
    }
    if (props.data.columnNames.at(1)) {
      cols.push({
        title: (
          <AutoComplete
            style={{ width: '100%' }}
            defaultValue={props.data.columnNames.at(1)}
            onChange={(v) => props.onHeaderChange(1, v)}
          >
            {(headerOptions || []).map((o) => (
              <Option key={o.id} value={o.option}>
                {o.option}
              </Option>
            ))}
          </AutoComplete>
        ),
        dataIndex: 'col1Data',
      });
    }
    if (props.data.columnNames.at(2)) {
      cols.push({
        title: (
          <AutoComplete
            style={{ width: '100%' }}
            defaultValue={props.data.columnNames.at(2)}
            onChange={(v) => props.onHeaderChange(2, v)}
          >
            {(headerOptions || []).map((o) => (
              <Option key={o.id} value={o.option}>
                {o.option}
              </Option>
            ))}
          </AutoComplete>
        ),
        dataIndex: 'col2Data',
      });
    }
    if (props.data.columnNames.at(3)) {
      cols.push({
        title: (
          <AutoComplete
            style={{ width: '100%' }}
            defaultValue={props.data.columnNames.at(3)}
            onChange={(v) => props.onHeaderChange(3, v)}
          >
            {(headerOptions || []).map((o) => (
              <Option key={o.id} value={o.option}>
                {o.option}
              </Option>
            ))}
          </AutoComplete>
        ),
        dataIndex: 'col3Data',
      });
    }
    if (props.data.columnNames.at(4)) {
      cols.push({
        title: (
          <AutoComplete
            style={{ width: '100%' }}
            defaultValue={props.data.columnNames.at(4)}
            onChange={(v) => props.onHeaderChange(4, v)}
          >
            {(headerOptions || []).map((o) => (
              <Option key={o.id} value={o.option}>
                {o.option}
              </Option>
            ))}
          </AutoComplete>
        ),
        dataIndex: 'col4Data',
      });
    }
    if (props.data.columnNames.at(5)) {
      cols.push({
        title: (
          <AutoComplete
            style={{ width: '100%' }}
            defaultValue={props.data.columnNames.at(5)}
            onChange={(v) => props.onHeaderChange(5, v)}
          >
            {(headerOptions || []).map((o) => (
              <Option key={o.id} value={o.option}>
                {o.option}
              </Option>
            ))}
          </AutoComplete>
        ),
        dataIndex: 'col5Data',
      });
    }
    if (props.data.columnNames.at(6)) {
      cols.push({
        title: (
          <AutoComplete
            style={{ width: '100%' }}
            defaultValue={props.data.columnNames.at(6)}
            onChange={(v) => props.onHeaderChange(6, v)}
          >
            {(headerOptions || []).map((o) => (
              <Option key={o.id} value={o.option}>
                {o.option}
              </Option>
            ))}
          </AutoComplete>
        ),
        dataIndex: 'col6Data',
      });
    }
    if (props.data.columnNames.at(7)) {
      cols.push({
        title: (
          <AutoComplete
            style={{ width: '100%' }}
            defaultValue={props.data.columnNames.at(7)}
            onChange={(v) => props.onHeaderChange(7, v)}
          >
            {(headerOptions || []).map((o) => (
              <Option key={o.id} value={o.option}>
                {o.option}
              </Option>
            ))}
          </AutoComplete>
        ),
        dataIndex: 'col7Data',
      });
    }
    if (props.data.columnNames.at(8)) {
      cols.push({
        title: (
          <AutoComplete
            style={{ width: '100%' }}
            defaultValue={props.data.columnNames.at(8)}
            onChange={(v) => props.onHeaderChange(8, v)}
          >
            {(headerOptions || []).map((o) => (
              <Option key={o.id} value={o.option}>
                {o.option}
              </Option>
            ))}
          </AutoComplete>
        ),
        dataIndex: 'col8Data',
      });
    }
    if (props.data.columnNames.at(9)) {
      cols.push({
        title: (
          <AutoComplete
            style={{ width: '100%' }}
            defaultValue={props.data.columnNames.at(9)}
            onChange={(v) => props.onHeaderChange(9, v)}
          >
            {(headerOptions || []).map((o) => (
              <Option key={o.id} value={o.option}>
                {o.option}
              </Option>
            ))}
          </AutoComplete>
        ),
        dataIndex: 'col9Data',
      });
    }
    return cols;
  };

  return (
    <DataTable<OcrPreviewColDTO>
      columns={getTableCols()}
      data={props.data.data || []}
      tableProps={{
        rowKey: 'ind',
        tableLayout: 'fixed',
        scroll: { x: 1200 },
      }}
      styleOptions={{
        alternatingRowHighlight: true,
      }}
    />
  );
};

export default OcrPreviewTable;
