// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Constants.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
export default class RoleName {
    public static readonly BROKER: string = 'Broker';
    public static readonly TPA: string = 'Tpa';
    public static readonly EMPLOYER: string = 'Employer';
    public static readonly MEMBER: string = 'Member';
    public static readonly CLAIMDOC: string = 'ClaimDoc';
    public static readonly API: string = 'Api';
    public static readonly GUEST: string = 'Guest';
    public static readonly DPC: string = 'Dpc';
    public static readonly OTHERCOMPANY: string = 'Other Company';
    public static readonly UNKNOWN: string = 'Unknown';
}
