import { LockOutlined } from '@ant-design/icons';
import { notification, Space } from 'antd';
import React, { useState } from 'react';
import PermissionService from '../../../api/PermissionApiService';
import Permission from '../../../consts/Permission';
import useHasPermission from '../../../hooks/useHasPermission';
import PermissionGroupListDTO from '../../../models/PermissionGroupListDTO';
import TableRequestDTO from '../../../models/TableRequestDTO';
import TableResponseDTO from '../../../models/TableResponseDTO';
import DataTable, { DataTableColumnProps } from '../../shared/DataTable/DataTable';
import DataTableButtonUtil from '../../shared/DataTable/DataTableButtonUtil';

interface PermissionGroupListProps {
  activeRow?: number | string;
  selectRow: (id?: number) => void;
  formRef: React.RefObject<any>;
}

const tableColumns: DataTableColumnProps<PermissionGroupListDTO>[] = [
  {
    title: 'Group Name',
    dataIndex: 'name',
    defaultSortOrder: 'ascend',
    sorter: {},
    render: (v, r) => (
      <Space direction="horizontal">
        {v}
        {!r.isManaged && <LockOutlined />}
      </Space>
    ),
  },
  {
    title: 'Permissions',
    dataIndex: 'permissionCount',
    defaultSortOrder: 'ascend',
    sorter: {},
  },
];

const PermissionGroupList = (props: PermissionGroupListProps) => {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<PermissionGroupListDTO[]>([]);
  const hasPermission = useHasPermission();

  const fetchTableData = (
    requestState: TableRequestDTO,
    checkEcho: () => boolean,
    callback: (response: TableResponseDTO<PermissionGroupListDTO>) => void
  ) => {
    setLoading(true);
    PermissionService.getPermissionGroupList(requestState)
      .then((results) => {
        if (!checkEcho()) {
          return;
        }
        setTableData(results.results || []);
        setLoading(false);
        callback(results);
      })
      .catch((error: any) => {
        setLoading(false);
        notification.error({
          message: error.message,
          description: error.description,
        });
      });
  };

  const buttonBar: any[] | undefined = hasPermission(Permission.ADMIN_PERMISSIONGROUPS_DETAILS_EDIT)
    ? [
        {
          ...DataTableButtonUtil.Default('New Group', () => {
            props.selectRow();
          }),
          shape: 'round',
        },
      ]
    : undefined;

  return (
    <>
      <DataTable
        ref={props.formRef}
        serverSide={true}
        globalSearch={true}
        globalSearchPlaceholder="Search Groups..."
        buttonBar={buttonBar}
        onRowClick={(r) => props.selectRow(r.id)}
        tableProps={{
          rowKey: 'id',
          loading: loading,
          sortDirections: ['ascend', 'descend'],
          rowClassName: (r) => (props.activeRow && r.id.toString() === props.activeRow.toString() ? 'active' : ''),
        }}
        columns={tableColumns}
        data={tableData}
        fetchData={fetchTableData}
        styleOptions={{
          alternatingRowHighlight: true,
        }}
      />
    </>
  );
};

export default PermissionGroupList;
