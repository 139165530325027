import { useRef, MutableRefObject } from 'react';

const useFocus = (): [MutableRefObject<null>, () => void] => {
  const htmlElRef = useRef(null);
  const setFocus = () => {
    if (htmlElRef.current) {
      try {
        (htmlElRef.current as any).focus();
      } catch {
        // pass
      }
    }
  };

  return [htmlElRef, setFocus];
};

export default useFocus;
