// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';

interface ProviderSearchDTO { 
    id: number;
    claimDocId: number;
    npi: string | null;
    providerTypeId: number;
    name: string | null;
    firstName: string | null;
    lastName: string | null;
    address1: string | null;
    address2: string | null;
    city: string | null;
    stateCode: string | null;
    zipCode: string | null;
    phoneNumber: string | null;
}
const ProviderSearchDTO = {
    create: (initValues?: Partial<ProviderSearchDTO> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            id: 0,
            claimDocId: 0,
            npi: null,
            providerTypeId: 0,
            name: null,
            firstName: null,
            lastName: null,
            address1: null,
            address2: null,
            city: null,
            stateCode: null,
            zipCode: null,
            phoneNumber: null,
        },
        initValues);
    }
};

export default ProviderSearchDTO;