import { notification, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import TpaService from '../../../api/TpaApiService';
import Permission from '../../../consts/Permission';
import Role from '../../../consts/Role';
import useHasPermission from '../../../hooks/useHasPermission';
import ParentSecuritySettingsDTO from '../../../models/ParentSecuritySettingsDTO';
import ParentSecurityUserSettingsDTO from '../../../models/ParentSecurityUserSettingsDTO';
import TableRequestDTO from '../../../models/TableRequestDTO';
import TableResponseDTO from '../../../models/TableResponseDTO';
import DataTable from '../../shared/DataTable/DataTable';
import ParentSecurityManager from '../../shared/EntityAccess/ParentSecurityManager';

interface BrokerSecurityProps {
  tpaId: number;
}

const TpaSecurity = (props: BrokerSecurityProps) => {
  const [settings, setSettings] = useState<ParentSecuritySettingsDTO>(ParentSecuritySettingsDTO.create());
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState<ParentSecurityUserSettingsDTO[]>([]);
  const dtRef = React.useRef<DataTable<ParentSecurityUserSettingsDTO>>(null);
  const hasPermission = useHasPermission();

  const fetch = (id: number) => {
    setLoading(true);
    TpaService.getSecuritySettings(id)
      .then((res) => {
        setSettings(res);
        setLoading(false);
      })
      .catch((err) => {
        notification.error({ message: err.message, description: err.description });
      });
  };

  useEffect(() => {
    fetch(props.tpaId);
  }, []);

  const removeUser = (id: number) => {
    setLoading(true);
    TpaService.removeUserSecurity(props.tpaId, id)
      .then((res) => {
        if (res) {
          notification.success({ message: 'User removed' });
          dtRef.current?.refresh();
        } else {
          throw '';
        }
      })
      .catch(() => {
        notification.error({ message: 'Failed to remove' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSave = (settings: ParentSecuritySettingsDTO) => {
    setLoading(true);
    TpaService.saveSecuritySettings(props.tpaId, settings)
      .then(() => {
        notification.success({ message: 'Security Saved' });
        fetch(props.tpaId);
      })
      .catch((err) => {
        notification.error({ message: err.message, description: err.description });
        setLoading(false);
      });
  };

  const fetchTableData = (
    requestState: TableRequestDTO,
    checkEcho: () => boolean,
    callback: (response: TableResponseDTO<ParentSecurityUserSettingsDTO>) => void
  ) => {
    setLoading(true);
    TpaService.getUserSecuritySettings(props.tpaId, requestState)
      .then((results) => {
        if (!checkEcho()) {
          return;
        }
        setTableData(results.results || []);
        setLoading(false);
        callback(results);
      })
      .catch((error: any) => {
        setLoading(false);
        notification.error({
          message: error.message,
          description: error.description,
        });
      });
  };

  return (
    <Spin spinning={loading}>
      <ParentSecurityManager
        title="TPA Security"
        settings={settings}
        onSave={onSave}
        readOnly={!hasPermission(Permission.ADMIN_TPA_SECURITY_EDIT)}
        emptyText="No TPA Users"
        tableLoading={loading}
        tableData={tableData}
        fetchData={fetchTableData}
        dtRef={dtRef}
        onRemoveUser={removeUser}
        roleId={Role.TPA}
      />
    </Spin>
  );
};

export default TpaSecurity;
