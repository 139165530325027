// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';

interface PnfServicePartnerDTO { 
    notifyMember: boolean;
    notifySubmitter: boolean;
    pdfAttachMember: boolean;
    pdfAttachSubmitter: boolean;
    accessToDocumentsMember: boolean;
    accessToDocumentsSubmitter: boolean;
    followUpCommunicationMember: boolean;
    followUpCommunicationSubmitter: boolean;
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    company: string | null;
}
const PnfServicePartnerDTO = {
    create: (initValues?: Partial<PnfServicePartnerDTO> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            notifyMember: false,
            notifySubmitter: false,
            pdfAttachMember: false,
            pdfAttachSubmitter: false,
            accessToDocumentsMember: false,
            accessToDocumentsSubmitter: false,
            followUpCommunicationMember: false,
            followUpCommunicationSubmitter: false,
            firstName: null,
            lastName: null,
            email: null,
            company: null,
        },
        initValues);
    }
};

export default PnfServicePartnerDTO;