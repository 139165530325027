// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';

interface BrokerDetailDTO { 
    id: number;
    brokerId: string | null;
    brokerCode: string | null;
    name: string | null;
    phoneNumber: string | null;
    notes: string | null;
    url: string | null;
    domain: string | null;
    address1: string | null;
    address2: string | null;
    city: string | null;
    state: string | null;
    zipCode: string | null;
}
const BrokerDetailDTO = {
    create: (initValues?: Partial<BrokerDetailDTO> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            id: 0,
            brokerId: null,
            brokerCode: null,
            name: null,
            phoneNumber: null,
            notes: null,
            url: null,
            domain: null,
            address1: null,
            address2: null,
            city: null,
            state: null,
            zipCode: null,
        },
        initValues);
    }
};

export default BrokerDetailDTO;