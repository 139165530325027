// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';

interface ProviderSearchQueryDTO { 
    distance: number;
    latitude: number | null;
    longitude: number | null;
    address: string | null;
    providerTypeId: number | null;
    providerSpecialty: string[];
    filterString: string | null;
    sortBy: number | null;
    agreementCode: string | null;
    agreementType: string | null;
    npi: string | null;
    client: string | null;
    pageNumber: number | null;
}
const ProviderSearchQueryDTO = {
    create: (initValues?: Partial<ProviderSearchQueryDTO> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            distance: 0,
            latitude: null,
            longitude: null,
            address: null,
            providerTypeId: null,
            providerSpecialty: [],
            filterString: null,
            sortBy: null,
            agreementCode: null,
            agreementType: null,
            npi: null,
            client: null,
            pageNumber: null,
        },
        initValues);
    }
};

export default ProviderSearchQueryDTO;