import { Button, notification } from 'antd';
import React, { useContext, useState } from 'react';
import DocumentApiService from '../../api/DocumentApiService';
import DocumentListDTO from '../../models/DocumentListDTO';
import TableRequestDTO from '../../models/TableRequestDTO';
import TableResponseDTO from '../../models/TableResponseDTO';
import DataTable, { DataTableColumnProps } from '../shared/DataTable/DataTable';
import DataTableColumnUtil from '../shared/DataTable/DataTableColumnUtil';
import { AuthenticationContext } from '../../auth/AuthenticationContext';
import AccessRole from '../../consts/AccessRole';

const MRFList = () => {
  const [loading, setLoading] = useState(false);
  const [mrfs, setMRFs] = useState<DocumentListDTO[]>([]);
  const dtRef = React.useRef<DataTable<any>>(null);

  const authCtx = useContext(AuthenticationContext);
  const accessRole = authCtx.user?.accessRoleId;

  const fetchTableData = (
    requestState: TableRequestDTO,
    checkEcho: () => boolean,
    callback: (response: TableResponseDTO<DocumentListDTO>) => void
  ) => {
    setLoading(true);
    DocumentApiService.getMRFList(requestState)
      .then((results) => {
        if (!checkEcho()) {
          return;
        }
        setMRFs(results.results || []);
        setLoading(false);
        callback(results);
      })
      .catch((error: any) => {
        setLoading(false);
        notification.error({
          message: error.message,
          description: error.description,
        });
      });
  };

  const getTableColumns = (): DataTableColumnProps<DocumentListDTO>[] => {
    const columns: DataTableColumnProps<DocumentListDTO>[] = [
      DataTableColumnUtil.Date('Date', 'creationDate', undefined, { defaultSortOrder: 'descend' }),
      DataTableColumnUtil.Text('File Name', 'fileName'),
      DataTableColumnUtil.Text('File Size', 'size'),
    ];

    if (accessRole === AccessRole.CLAIMDOC) {
      columns.push(DataTableColumnUtil.Text('Tpa', 'tpas'));
    }

    columns.push({
      title: '',
      dataIndex: '',
      width: '40px',
      // eslint-disable-next-line react/display-name
      render: (rowData: DocumentListDTO) => (
        <Button shape="round" ghost>
          <a href={rowData.sasUrl ?? undefined} download>
            Download
          </a>
        </Button>
      ),
    });

    return columns;
  };

  return (
    <DataTable
      ref={dtRef}
      serverSide={true}
      globalSearch={true}
      globalSearchPlaceholder="Search MRFs"
      tableProps={{
        rowKey: 'id',
        loading: loading,
        scroll: { x: 575 },
      }}
      columns={getTableColumns()}
      data={mrfs}
      fetchData={fetchTableData}
      styleOptions={{
        alternatingRowHighlight: true,
      }}
    />
  );
};

export default MRFList;
