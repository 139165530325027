import { Spin } from 'antd';
import React, { createContext, useEffect, useState } from 'react';
import AccountApiService from '../api/AccountApiService';
import LoginDTO from '../models/LoginDTO';
import LoginResultDTO from '../models/LoginResultDTO';
import UserSecurity from '../models/UserSecurity';

interface AuthenticationContextType {
  token?: any;
  user?: UserSecurity;
  logout: () => Promise<boolean>;
  login: (model: LoginDTO) => Promise<LoginResultDTO>;
  pinLogin: (pin: string, password?: string | null) => Promise<LoginResultDTO>;
  refresh: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const AuthenticationContext = createContext<AuthenticationContextType>(undefined!);

export const AuthenticationProvider = (props: React.PropsWithChildren<any>) => {
  const [user, setUser] = useState<UserSecurity | undefined>(undefined);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getUserSecurity();
  }, []);

  const getUserSecurity = async () => {
    setLoading(true);
    await AccountApiService.getCurrentUser()
      .then((res) => {
        if (res) {
          setUser(new UserSecurity(res));
        } else {
          setUser(undefined);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const login = async (model: LoginDTO) => {
    try {
      const res = await AccountApiService.login(model);
      if (res.succeeded && !res.requiresMfaSetup) {
        await getUserSecurity();
      }
      return res;
    } catch (ex) {
      return LoginResultDTO.create({ errors: ex });
    }
  };

  const pinLogin = async (pin: string, password?: string | null) => {
    try {
      const res = await AccountApiService.setPinAuth(pin, password ?? null);
      if (res.succeeded) {
        getUserSecurity();
      }
      return res;
    } catch (ex) {
      return LoginResultDTO.create({ errors: ex });
    }
  };

  const logout = async () => {
    try {
      await AccountApiService.logout();
      setUser(undefined);
      return true;
    } catch {
      return false;
    }
  };

  const refresh = () => {
    getUserSecurity();
  };

  return (
    <AuthenticationContext.Provider
      value={{ logout: logout, login: login, pinLogin: pinLogin, refresh: refresh, user: user }}
    >
      {loading ? <Spin spinning={true} size="large" tip="Loading" className="authSpinner" /> : <>{props.children}</>}
    </AuthenticationContext.Provider>
  );
};
