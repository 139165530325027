import { Content } from 'antd/lib/layout/layout';
import React, { useState } from 'react';
import ProviderNominationService from '../../api/ProviderNominationsApiService';
import ProviderNomination from '../../components/nominate-providers/ProviderNomination';
import useDraftSaving from '../../components/nominate-providers/useDraftSaving';
import PnfDTO from '../../models/PnfDTO';
import { SetAppointmentTimeZones, SetAppointmentReferenceNumbers } from '../tools/ProviderNominationPage';

const PnfPage = () => {
  const [checkingDraft, draftDto, lastSaveDisplay, promptModal, saveDraft] = useDraftSaving();
  const [submittedPnf, setSubmittedPnf] = useState<PnfDTO | undefined>(undefined);

  const submitGuestPnf = async (pnf: PnfDTO, cb?: (success: boolean, error?: any) => void) => {
    let success = false;
    let error: any = null;
    try {
      SetAppointmentTimeZones(pnf);

      const res = await ProviderNominationService.submit(pnf);
      error = res.errors ? { message: res.errors.join(', ') } : null;
      success = res.succeeded;

      SetAppointmentReferenceNumbers(pnf, res.appointmentReferenceNumbers ?? []);

      setSubmittedPnf({ ...pnf, referenceNumber: res.headerReferenceNumber });
    } catch {
      success = false;
      setSubmittedPnf(undefined);
    }

    if (cb) {
      cb(success, error);
    }
  };

  return (
    <Content className="full-height-public">
      {checkingDraft ? null : (
        <div style={{ position: 'relative' }}>
          <div style={{ position: 'absolute', top: 5, right: 5, textAlign: 'right' }}>{lastSaveDisplay()}</div>
          <ProviderNomination
            onSubmit={submitGuestPnf}
            dto={draftDto}
            pnfUpdated={saveDraft}
            submittedPnf={submittedPnf}
          />
        </div>
      )}
      {promptModal}
    </Content>
  );
};

export default PnfPage;
