// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';


export class DataManagementApiService extends BaseApi {

    // post: api/DataManagement/ExecuteProviderSync
    public executeProviderSync(): Promise<boolean> {
        let url = `api/DataManagement/ExecuteProviderSync`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, boolean>(null, 'post', url, true, false);
    }

    // post: api/DataManagement/QueueProviderSync
    public queueProviderSync(): Promise<void> {
        let url = `api/DataManagement/QueueProviderSync`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, void>(null, 'post', url, true, false);
    }

    // post: api/DataManagement/ExecuteNewBrokerSync
    public executeNewBrokerSync(): Promise<boolean> {
        let url = `api/DataManagement/ExecuteNewBrokerSync`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, boolean>(null, 'post', url, true, false);
    }

    // post: api/DataManagement/QueueNewBrokerSync
    public queueNewBrokerSync(): Promise<void> {
        let url = `api/DataManagement/QueueNewBrokerSync`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, void>(null, 'post', url, true, false);
    }

    // post: api/DataManagement/ExecuteTpaSync
    public executeTpaSync(): Promise<boolean> {
        let url = `api/DataManagement/ExecuteTpaSync`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, boolean>(null, 'post', url, true, false);
    }

    // post: api/DataManagement/QueueTpaSync
    public queueTpaSync(): Promise<void> {
        let url = `api/DataManagement/QueueTpaSync`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, void>(null, 'post', url, true, false);
    }

    // post: api/DataManagement/ExecuteDpcSync
    public executeDpcSync(): Promise<boolean> {
        let url = `api/DataManagement/ExecuteDpcSync`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, boolean>(null, 'post', url, true, false);
    }

    // post: api/DataManagement/QueueDpcSync
    public queueDpcSync(): Promise<void> {
        let url = `api/DataManagement/QueueDpcSync`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, void>(null, 'post', url, true, false);
    }

    // post: api/DataManagement/ExecuteOtherCompanySync
    public executeOtherCompanySync(): Promise<boolean> {
        let url = `api/DataManagement/ExecuteOtherCompanySync`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, boolean>(null, 'post', url, true, false);
    }

    // post: api/DataManagement/QueueOtherCompanySync
    public queueOtherCompanySync(): Promise<void> {
        let url = `api/DataManagement/QueueOtherCompanySync`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, void>(null, 'post', url, true, false);
    }

    // post: api/DataManagement/ExecuteClientSync
    public executeClientSync(): Promise<boolean> {
        let url = `api/DataManagement/ExecuteClientSync`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, boolean>(null, 'post', url, true, false);
    }

    // post: api/DataManagement/QueueClientSync
    public queueClientSync(): Promise<void> {
        let url = `api/DataManagement/QueueClientSync`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, void>(null, 'post', url, true, false);
    }

    // post: api/DataManagement/ExecuteFamilyUnitSync
    public executeFamilyUnitSync(): Promise<boolean> {
        let url = `api/DataManagement/ExecuteFamilyUnitSync`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, boolean>(null, 'post', url, true, false);
    }

    // post: api/DataManagement/QueueFamilyUnitSync
    public queueFamilyUnitSync(): Promise<void> {
        let url = `api/DataManagement/QueueFamilyUnitSync`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, void>(null, 'post', url, true, false);
    }

    // get: api/DataManagement/GetBackgroundWorkItemStatus?key=${encodeURIComponent(key)}
    public getBackgroundWorkItemStatus(key: string): Promise<void> {
        let url = `api/DataManagement/GetBackgroundWorkItemStatus`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (key != null) {
            url += `${prefix}key=${encodeURIComponent(key)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, void>(null, 'get', url, true, false);
    }

    // post: api/DataManagement/QueueMemberCardExpire?olderThanDays=${olderThanDays}
    public queueMemberCardExpire(olderThanDays?: number | null | null): Promise<void> {
        let url = `api/DataManagement/QueueMemberCardExpire`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (olderThanDays != null) {
            url += `${prefix}olderThanDays=${olderThanDays}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, void>(null, 'post', url, true, false);
    }

    // post: api/DataManagement/ExecuteClaimDocMemberSync
    public executeClaimDocMemberSync(): Promise<boolean> {
        let url = `api/DataManagement/ExecuteClaimDocMemberSync`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, boolean>(null, 'post', url, true, false);
    }

    // post: api/DataManagement/QueueClaimDocMemberSync
    public queueClaimDocMemberSync(): Promise<void> {
        let url = `api/DataManagement/QueueClaimDocMemberSync`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, void>(null, 'post', url, true, false);
    }

    // post: api/DataManagement/QueueXRefMemberSync
    public queueXRefMemberSync(): Promise<void> {
        let url = `api/DataManagement/QueueXRefMemberSync`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, void>(null, 'post', url, true, false);
    }
}
var service = new DataManagementApiService();
export default service;
