// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import PermissionGroupPermissionDTO from './PermissionGroupPermissionDTO';
import PermissionGroupRelationDTO from './PermissionGroupRelationDTO';

interface PermissionGroupDetailDTO { 
    id: number;
    name: string | null;
    isManaged: boolean;
    roleId: number;
    permissions: PermissionGroupPermissionDTO[] | null;
    permissionIds: number[];
    relations: PermissionGroupRelationDTO[] | null;
}
const PermissionGroupDetailDTO = {
    create: (initValues?: Partial<PermissionGroupDetailDTO> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            id: 0,
            name: null,
            isManaged: false,
            roleId: 0,
            permissions: [],
            permissionIds: [],
            relations: [],
        },
        initValues);
    }
};

export default PermissionGroupDetailDTO;