// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import PnfMemberInfoDTO from './PnfMemberInfoDTO';
import PnfProviderInfoDTO from './PnfProviderInfoDTO';
import ClientDetailDTO from './ClientDetailDTO';
import PnfServicePartnerDTO from './PnfServicePartnerDTO';

interface PnfDTO { 
    id: string | null;
    referenceNumber: string | null;
    statusId: number;
    statusName: string | null;
    guestPinId: number | null;
    createdByUserId: number | null;
    createdByUsername: string | null;
    pnfNote: string | null;
    createdByAccessRole: string | null;
    createdOn: moment.Moment | string;
    lastUpdated: moment.Moment | string;
    submittedOn: moment.Moment | string | null;
    receivedOn: moment.Moment | string | null;
    memberAssociationId: number | null;
    memberAssociationClientId: number | null;
    primaryMember: PnfMemberInfoDTO | null;
    familyMembers: PnfMemberInfoDTO[] | null;
    providers: PnfProviderInfoDTO[] | null;
    clientDetail: ClientDetailDTO | null;
    knownClient: boolean | null;
    servicePartner: PnfServicePartnerDTO | null;
    isPrePopulateRecord: boolean | null;
    includePdf: boolean | null;
}
const PnfDTO = {
    create: (initValues?: Partial<PnfDTO> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            id: "00000000-0000-0000-0000-000000000000",
            referenceNumber: null,
            statusId: 0,
            statusName: null,
            guestPinId: null,
            createdByUserId: null,
            createdByUsername: null,
            pnfNote: null,
            createdByAccessRole: null,
            createdOn: new Date(0).toISOString(),
            lastUpdated: new Date(0).toISOString(),
            submittedOn: null,
            receivedOn: null,
            memberAssociationId: null,
            memberAssociationClientId: null,
            primaryMember: null,
            familyMembers: [],
            providers: [],
            clientDetail: null,
            knownClient: null,
            servicePartner: null,
            isPrePopulateRecord: null,
            includePdf: null,
        },
        initValues);
    }
};

export default PnfDTO;