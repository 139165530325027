// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import IFormFile from './IFormFile';

interface MemberCardDTO { 
    id: number;
    cardName: string | null;
    cardTypeId: number;
    cardTypeName: string | null;
    frontFile: any | null;
    frontPath: string | null;
    frontUrl: string | null;
    frontContentType: string | null;
    deleteFront: boolean;
    backFile: any | null;
    backUrl: string | null;
    backPath: string | null;
    backContentType: string | null;
    deleteBack: boolean;
}
const MemberCardDTO = {
    create: (initValues?: Partial<MemberCardDTO> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            id: 0,
            cardName: null,
            cardTypeId: 0,
            cardTypeName: null,
            frontFile: null,
            frontPath: null,
            frontUrl: null,
            frontContentType: null,
            deleteFront: false,
            backFile: null,
            backUrl: null,
            backPath: null,
            backContentType: null,
            deleteBack: false,
        },
        initValues);
    }
};

export default MemberCardDTO;