// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import ClientAccessDTO from '../models/ClientAccessDTO';
import ParentSecuritySettingsDTO from '../models/ParentSecuritySettingsDTO';
import InviteUserDTO from '../models/InviteUserDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import DpcListDTO from '../models/DpcListDTO';
import DpcDetailDTO from '../models/DpcDetailDTO';
import SaveDpcDetailResultDTO from '../models/SaveDpcDetailResultDTO';
import ClientListDTO from '../models/ClientListDTO';
import ResultDTO from '../models/ResultDTO';
import UserClientAccessDTO from '../models/UserClientAccessDTO';
import ParentSecurityUserSettingsDTO from '../models/ParentSecurityUserSettingsDTO';

export class DpcApiService extends BaseApi {

    // post: api/dpc/GetDpcList
    public getDPCList(tableRequest: TableRequestDTO): Promise<TableResponseDTO<DpcListDTO>> {
        let url = `api/dpc/GetDpcList`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<DpcListDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/dpc/GetDpcDetails?clientId=${clientId}
    public getDPCDetails(clientId: number): Promise<DpcDetailDTO> {
        let url = `api/dpc/GetDpcDetails`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (clientId != null) {
            url += `${prefix}clientId=${clientId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, DpcDetailDTO>(null, 'post', url, true, false);
    }

    // post: api/dpc/SaveDpcNotes?dpcId=${dpcId}
    public saveDPCNotes(dpcId: number, notes: string): Promise<SaveDpcDetailResultDTO> {
        let url = `api/dpc/SaveDpcNotes`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (dpcId != null) {
            url += `${prefix}dpcId=${dpcId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveDpcDetailResultDTO>(notes, 'post', url, true, false);
    }

    // post: api/dpc/GetClientsForDpc?dpcId=${dpcId}
    public getClientsForDpc(dpcId: number): Promise<ClientListDTO[]> {
        let url = `api/dpc/GetClientsForDpc`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (dpcId != null) {
            url += `${prefix}dpcId=${dpcId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ClientListDTO[]>(null, 'post', url, true, false);
    }

    // post: api/dpc/DeleteDpcClientAccess?clientId=${clientId}&dpcId=${dpcId}
    public deleteDpcClientAccess(clientId: number, dpcId: number): Promise<ResultDTO> {
        let url = `api/dpc/DeleteDpcClientAccess`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (clientId != null) {
            url += `${prefix}clientId=${clientId}`;
            prefix = '&';
        }
        if (dpcId != null) {
            url += `${prefix}dpcId=${dpcId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ResultDTO>(null, 'post', url, true, false);
    }

    // post: api/dpc/GetDpcUserClientAccesses?dpcId=${dpcId}&clientId=${clientId}
    public getDpcUserClientAccesses(dpcId: number, clientId: number, tableRequest: TableRequestDTO): Promise<TableResponseDTO<UserClientAccessDTO>> {
        let url = `api/dpc/GetDpcUserClientAccesses`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (dpcId != null) {
            url += `${prefix}dpcId=${dpcId}`;
            prefix = '&';
        }
        if (clientId != null) {
            url += `${prefix}clientId=${clientId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<UserClientAccessDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/dpc/SaveDpcClientAccess?dpcId=${dpcId}
    public saveDpcClientAccess(dpcId: number, dto: ClientAccessDTO): Promise<ResultDTO> {
        let url = `api/dpc/SaveDpcClientAccess`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (dpcId != null) {
            url += `${prefix}dpcId=${dpcId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ResultDTO>(dto, 'post', url, true, false);
    }

    // post: api/dpc/SearchClientsNotLinkedToDpc?dpcId=${dpcId}&searchText=${encodeURIComponent(searchText)}
    public searchClientsNotLinkedToDpc(dpcId: number, searchText: string): Promise<ClientListDTO[]> {
        let url = `api/dpc/SearchClientsNotLinkedToDpc`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (dpcId != null) {
            url += `${prefix}dpcId=${dpcId}`;
            prefix = '&';
        }
        if (searchText != null) {
            url += `${prefix}searchText=${encodeURIComponent(searchText)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ClientListDTO[]>(null, 'post', url, true, false);
    }

    // get: api/dpc/GetSecuritySettings?id=${id}
    public getSecuritySettings(id: number): Promise<ParentSecuritySettingsDTO> {
        let url = `api/dpc/GetSecuritySettings`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (id != null) {
            url += `${prefix}id=${id}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ParentSecuritySettingsDTO>(null, 'get', url, true, false);
    }

    // post: api/dpc/GetUserSecuritySettings?dpcId=${dpcId}
    public getUserSecuritySettings(dpcId: number, tableRequest: TableRequestDTO): Promise<TableResponseDTO<ParentSecurityUserSettingsDTO>> {
        let url = `api/dpc/GetUserSecuritySettings`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (dpcId != null) {
            url += `${prefix}dpcId=${dpcId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<ParentSecurityUserSettingsDTO>>(tableRequest, 'post', url, true, false);
    }

    // delete: api/dpc/RemoveUserSecurity?dpcId=${dpcId}&userId=${userId}
    public removeUserSecurity(dpcId: number, userId: number): Promise<boolean> {
        let url = `api/dpc/RemoveUserSecurity`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (dpcId != null) {
            url += `${prefix}dpcId=${dpcId}`;
            prefix = '&';
        }
        if (userId != null) {
            url += `${prefix}userId=${userId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, boolean>(null, 'delete', url, true, false);
    }

    // post: api/dpc/SaveSecuritySettings?id=${id}
    public saveSecuritySettings(id: number, settings: ParentSecuritySettingsDTO): Promise<boolean> {
        let url = `api/dpc/SaveSecuritySettings`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (id != null) {
            url += `${prefix}id=${id}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, boolean>(settings, 'post', url, true, false);
    }

    // post: api/dpc/InviteUsers?dpcId=${dpcId}
    public inviteUsers(dpcId: number, users: InviteUserDTO[]): Promise<boolean> {
        let url = `api/dpc/InviteUsers`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (dpcId != null) {
            url += `${prefix}dpcId=${dpcId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, boolean>(users, 'post', url, true, false);
    }

    // post: api/dpc/SimpleUserList?dpcId=${dpcId}
    public simpleUserList(dpcId: number, tableRequest: TableRequestDTO): Promise<TableResponseDTO<ParentSecurityUserSettingsDTO>> {
        let url = `api/dpc/SimpleUserList`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (dpcId != null) {
            url += `${prefix}dpcId=${dpcId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<ParentSecurityUserSettingsDTO>>(tableRequest, 'post', url, true, false);
    }

    // delete: api/dpc/${dpcId}
    public deleteDpc(dpcId: number): Promise<boolean> {
        let url = `api/dpc/${dpcId}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, boolean>(null, 'delete', url, true, false);
    }
}
var service = new DpcApiService();
export default service;
