import { Checkbox } from 'antd';
import moment from 'moment';
import * as React from 'react';

export interface Address {
  name?: string | null;
  line1: string | null;
  line2?: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
}

class DataTableCellRendererUtil {
  public static ShortDate = (value: string | moment.Moment) => {
    if (!value) {
      return;
    }

    const date = moment.isMoment(value) ? value : moment(value);

    if (!date.isValid()) {
      return '';
    }

    return <span>{date.format('M/D/YYYY')}</span>;
  }

  public static ShortTime = (value: string | moment.Moment) => {
    if (!value) {
      return;
    }

    let date = moment.isMoment(value) ? value : moment(value);

    if (!date.isValid()) {
      date = moment(value, 'HH:mm:ss');

      if (!date.isValid()) {
        return '';
      }
    }

    return <span>{date.format('h:mm a')}</span>;
  }

  public static LongDate = (value: string | moment.Moment) => {
    if (!value) {
      return;
    }

    const date = moment.isMoment(value) ? value : moment(value);

    if (!date.isValid()) {
      return '';
    }

    return <span>{date.format('ddd, MMM D YYYY')}</span>;
  }

  public static DateAndTime = (value: string | moment.Moment) => {
    if (!value) {
      return;
    }

    const date = moment.isMoment(value) ? value : moment(value);

    if (!date.isValid()) {
      return '';
    }

    return <span>{date.format('M/D/YYYY h:mm a')}</span>;
  }

  public static Currency = (value: string) => {
    if (!value || isNaN(value as any)) {
      return;
    }

    try {
      const num = parseFloat(value);
      return <span>${num.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}</span>;
    } catch (error) {
      return;
    }
  }

  public static Percentage = (value: string) => {
    if (!value || isNaN(value as any)) {
      return;
    }

    try {
      const num = +parseFloat(value).toFixed(2);

      return <span>{num.toString().replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}%</span>;
    } catch (error) {
      return;
    }
  }

  public static Decimal = (value: string | number, decimalPlaces: number) => {
    if ((!value && value !== 0) || isNaN(value as any)) {
      return;
    }

    try {
      const num = typeof value === 'number' ? value : parseFloat(value);
      return <span>{num.toFixed(decimalPlaces)}</span>;
    } catch (error) {
      return;
    }
  }

  public static BooleanYesNo = (value: boolean) => value ? 'Yes' : 'No';

  public static BooleanYesEmpty = (value: boolean) => value ? 'Yes' : '';

  public static BooleanCheckbox = (value: boolean) => <Checkbox disabled={true} checked={value}/>;

  public static Address<T>(transform: (rowData: T) => Address) {
    const addressRender = (value: any, rowData: T) => {
      const address = transform(rowData);
      const hasAddress = address.line1 && address.city && address.state && address.zip;
      return (
        <>
          {address.name ? address.name : ''}
          {address.name && hasAddress ? <br /> : ''}
          {hasAddress ? address.line1 : ''}
          {hasAddress ? <br /> : ''}
          {address.line2 ? address.line2 : ''}
          {address.line2 ? <br /> : ''}
          {hasAddress ? address.city + ', ' + address.state + ', ' + address.zip : ''}
        </>
      );
    };

    return addressRender;
  }

  // public static LinkButtons(links: string | { link: string; icon: JSX.Element }[]) {
  //   if (!links || links.length === 0) {
  //     return;
  //   }

  //   const isString = typeof links === 'string';
  //   const strVal = links as string;
  //   const arrVal = links as { link: string; icon: JSX.Element }[];
  //   return (
  //     <div style={{ display: 'flex', justifyContent: 'space-between' }}>
  //       {isString
  //         ? strVal
  //           ? this.LinkButton(strVal)
  //           : ''
  //         : arrVal.map((link, index) => this.LinkButton(link.link, link.icon, undefined, index))}
  //     </div>
  //   );
  // }

  // public static LinkButton(link: string, icon?: JSX.Element, shape?: ButtonShape, index?: number) {
  //   shape = shape ? shape : !icon ? 'circle' : undefined;
  //   if (!icon) {
  //     icon = <ArrowRightOutlined />;
  //   }

  //   return (
  //     <Link to={link} key={index || -1} style={{ flex: 1 }}>
  //       {shape ? <Button shape={shape} size="small" icon={icon} /> : icon}
  //     </Link>
  //   );
  // }
}

export default DataTableCellRendererUtil;
