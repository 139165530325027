// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import ParentSecurityUserSettingsDTO from './ParentSecurityUserSettingsDTO';

interface ParentSecuritySettingsDTO { 
    entityId: number;
    basePermissionGroupId: number | null;
    employerPermissionGroupId: number | null;
    employeePermissionGroupId: number | null;
    userSettings: ParentSecurityUserSettingsDTO[] | null;
}
const ParentSecuritySettingsDTO = {
    create: (initValues?: Partial<ParentSecuritySettingsDTO> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            entityId: 0,
            basePermissionGroupId: null,
            employerPermissionGroupId: null,
            employeePermissionGroupId: null,
            userSettings: [],
        },
        initValues);
    }
};

export default ParentSecuritySettingsDTO;