// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import NominationListDTO from '../models/NominationListDTO';
import RelationDTO from '../models/RelationDTO';
import NominationDTO from '../models/NominationDTO';
import PnfDTO from '../models/PnfDTO';

export class NominationSearchApiService extends BaseApi {

    // post: api/nominationsearch/GetMemberNominationList?clientId=${clientId}&fromDate=${encodeURIComponent(String(fromDate))}&toDate=${encodeURIComponent(String(toDate))}&lastName=${encodeURIComponent(lastName)}
    public getMemberNominationList(tableRequest: TableRequestDTO, clientId: number, fromDate: string, toDate: string, lastName: string): Promise<TableResponseDTO<NominationListDTO>> {
        let url = `api/nominationsearch/GetMemberNominationList`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (clientId != null) {
            url += `${prefix}clientId=${clientId}`;
            prefix = '&';
        }
        if (fromDate != null) {
            url += `${prefix}fromDate=${fromDate}`;
            prefix = '&';
        }
        if (toDate != null) {
            url += `${prefix}toDate=${toDate}`;
            prefix = '&';
        }
        if (lastName != null) {
            url += `${prefix}lastName=${encodeURIComponent(lastName)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<NominationListDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/nominationsearch/GetClientsNom
    public getClients(): Promise<RelationDTO[]> {
        let url = `api/nominationsearch/GetClientsNom`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, RelationDTO[]>(null, 'post', url, true, false);
    }

    // post: api/nominationsearch/GetMembersByLastName?clientId=${clientId}&lastName=${encodeURIComponent(lastName)}
    public getMembersByLastName(clientId: number, lastName: string): Promise<NominationDTO[]> {
        let url = `api/nominationsearch/GetMembersByLastName`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (clientId != null) {
            url += `${prefix}clientId=${clientId}`;
            prefix = '&';
        }
        if (lastName != null) {
            url += `${prefix}lastName=${encodeURIComponent(lastName)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, NominationDTO[]>(null, 'post', url, true, false);
    }

    // post: api/nominationsearch/GetMemberNominationByNominationId?claimDocNominationId=${encodeURIComponent(claimDocNominationId)}
    public getMemberNominationByNominationId(claimDocNominationId: string): Promise<PnfDTO> {
        let url = `api/nominationsearch/GetMemberNominationByNominationId`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (claimDocNominationId != null) {
            url += `${prefix}claimDocNominationId=${encodeURIComponent(claimDocNominationId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, PnfDTO>(null, 'post', url, true, false);
    }
}
var service = new NominationSearchApiService();
export default service;
