// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface Array<T> {
    distinct(): void;
    pushAll(items: any[]): void;
    remove(item: any): boolean;
    removeAll(items: any[]): void;
  }

  Array.prototype.distinct = function () {
    const duplicates = [] as any[];
  
    for (let i = 0; i < this.length; i++) {
      if (this.indexOf(this[i]) < i) {
        duplicates.push(this[i]);
      }
    }
  
    this.removeAll(duplicates);
  }
  
  Array.prototype.pushAll = function (items: any[]) {
    items.forEach(x => this.push(x));
  }
  
  Array.prototype.remove = function (item: any) {
    if (this.includes(item)) {
      this.splice(this.indexOf(item), 1);
      return true;
    }
    return false;
  }
  
  Array.prototype.removeAll = function (items: any[]) {
    for (let i = 0; i < items.length;) {
      const didRemove = this.remove(items[i]);
      if (!didRemove) {
        i++;
      }
    }
  }