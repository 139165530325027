// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import FeatureFlagDTO from './FeatureFlagDTO';

interface BrokerClientFeatureFlagsDTO { 
    brokerId: number | null;
    featureFlags: FeatureFlagDTO[] | null;
}
const BrokerClientFeatureFlagsDTO = {
    create: (initValues?: Partial<BrokerClientFeatureFlagsDTO> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            brokerId: null,
            featureFlags: [],
        },
        initValues);
    }
};

export default BrokerClientFeatureFlagsDTO;