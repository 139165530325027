// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import OcrResponseDTO from '../models/OcrResponseDTO';
import OcrPreviewDTO from '../models/OcrPreviewDTO';
import OcrHeaderOptionDTO from '../models/OcrHeaderOptionDTO';

export class OcrApiService extends BaseApi {

    // post: api/ocr/processFile
    public processFile(request: FormData): Promise<OcrResponseDTO> {
        let url = `api/ocr/processFile`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, OcrResponseDTO>(request, 'post', url, false, false);
    }

    // post: api/ocr/previewFile
    public previewFile(request: FormData): Promise<OcrPreviewDTO> {
        let url = `api/ocr/previewFile`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, OcrPreviewDTO>(request, 'post', url, false, false);
    }

    // get: api/ocr/getHeaderOptions
    public getHeaderOptions(): Promise<OcrHeaderOptionDTO[]> {
        let url = `api/ocr/getHeaderOptions`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, OcrHeaderOptionDTO[]>(null, 'get', url, true, false);
    }
}
var service = new OcrApiService();
export default service;
