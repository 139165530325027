// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import ClientAccessDTO from '../models/ClientAccessDTO';
import ParentSecuritySettingsDTO from '../models/ParentSecuritySettingsDTO';
import InviteUserDTO from '../models/InviteUserDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import BrokerListDTO from '../models/BrokerListDTO';
import BrokerDetailDTO from '../models/BrokerDetailDTO';
import ResultDTO from '../models/ResultDTO';
import ClientListDTO from '../models/ClientListDTO';
import UserClientAccessDTO from '../models/UserClientAccessDTO';
import ParentSecurityUserSettingsDTO from '../models/ParentSecurityUserSettingsDTO';

export class BrokerApiService extends BaseApi {

    // post: api/broker/GetBrokerList
    public getBrokerList(tableRequest: TableRequestDTO): Promise<TableResponseDTO<BrokerListDTO>> {
        let url = `api/broker/GetBrokerList`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<BrokerListDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/broker/GetBrokerDetails?id=${id}
    public getBrokerDetails(id: number): Promise<BrokerDetailDTO> {
        let url = `api/broker/GetBrokerDetails`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (id != null) {
            url += `${prefix}id=${id}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, BrokerDetailDTO>(null, 'post', url, true, false);
    }

    // post: api/broker/SaveBrokerNotes?brokerId=${brokerId}
    public saveBrokerNotes(brokerId: number, notes: string): Promise<ResultDTO> {
        let url = `api/broker/SaveBrokerNotes`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (brokerId != null) {
            url += `${prefix}brokerId=${brokerId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ResultDTO>(notes, 'post', url, true, false);
    }

    // post: api/broker/GetClientsForBroker?id=${id}
    public getClientsForBroker(id: number): Promise<ClientListDTO[]> {
        let url = `api/broker/GetClientsForBroker`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (id != null) {
            url += `${prefix}id=${id}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ClientListDTO[]>(null, 'post', url, true, false);
    }

    // post: api/broker/SearchClientsNotLinkedToBroker?id=${id}&searchText=${encodeURIComponent(searchText)}
    public searchClientsNotLinkedToBroker(id: number, searchText: string): Promise<ClientListDTO[]> {
        let url = `api/broker/SearchClientsNotLinkedToBroker`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (id != null) {
            url += `${prefix}id=${id}`;
            prefix = '&';
        }
        if (searchText != null) {
            url += `${prefix}searchText=${encodeURIComponent(searchText)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ClientListDTO[]>(null, 'post', url, true, false);
    }

    // post: api/broker/GetBrokerUserClientAccesses?brokerId=${brokerId}&clientId=${clientId}
    public getBrokerUserClientAccesses(brokerId: number, clientId: number, tableRequest: TableRequestDTO): Promise<TableResponseDTO<UserClientAccessDTO>> {
        let url = `api/broker/GetBrokerUserClientAccesses`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (brokerId != null) {
            url += `${prefix}brokerId=${brokerId}`;
            prefix = '&';
        }
        if (clientId != null) {
            url += `${prefix}clientId=${clientId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<UserClientAccessDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/broker/SaveBrokerClientAccess?brokerId=${brokerId}
    public saveBrokerClientAccess(brokerId: number, dto: ClientAccessDTO): Promise<ResultDTO> {
        let url = `api/broker/SaveBrokerClientAccess`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (brokerId != null) {
            url += `${prefix}brokerId=${brokerId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ResultDTO>(dto, 'post', url, true, false);
    }

    // post: api/broker/DeleteBrokerClientAccess?clientId=${clientId}&brokerId=${brokerId}
    public deleteBrokerClientAccess(clientId: number, brokerId: number): Promise<ResultDTO> {
        let url = `api/broker/DeleteBrokerClientAccess`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (clientId != null) {
            url += `${prefix}clientId=${clientId}`;
            prefix = '&';
        }
        if (brokerId != null) {
            url += `${prefix}brokerId=${brokerId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ResultDTO>(null, 'post', url, true, false);
    }

    // get: api/broker/GetSecuritySettings?id=${id}
    public getSecuritySettings(id: number): Promise<ParentSecuritySettingsDTO> {
        let url = `api/broker/GetSecuritySettings`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (id != null) {
            url += `${prefix}id=${id}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ParentSecuritySettingsDTO>(null, 'get', url, true, false);
    }

    // post: api/broker/GetUserSecuritySettings?brokerId=${brokerId}
    public getUserSecuritySettings(brokerId: number, tableRequest: TableRequestDTO): Promise<TableResponseDTO<ParentSecurityUserSettingsDTO>> {
        let url = `api/broker/GetUserSecuritySettings`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (brokerId != null) {
            url += `${prefix}brokerId=${brokerId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<ParentSecurityUserSettingsDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/broker/SaveSecuritySettings?id=${id}
    public saveSecuritySettings(id: number, settings: ParentSecuritySettingsDTO): Promise<boolean> {
        let url = `api/broker/SaveSecuritySettings`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (id != null) {
            url += `${prefix}id=${id}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, boolean>(settings, 'post', url, true, false);
    }

    // delete: api/broker/${brokerId}
    public deleteBroker(brokerId: number): Promise<boolean> {
        let url = `api/broker/${brokerId}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, boolean>(null, 'delete', url, true, false);
    }

    // delete: api/broker/RemoveUserSecurity?brokerId=${brokerId}&userId=${userId}
    public removeUserSecurity(brokerId: number, userId: number): Promise<boolean> {
        let url = `api/broker/RemoveUserSecurity`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (brokerId != null) {
            url += `${prefix}brokerId=${brokerId}`;
            prefix = '&';
        }
        if (userId != null) {
            url += `${prefix}userId=${userId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, boolean>(null, 'delete', url, true, false);
    }

    // post: api/broker/InviteUsers?brokerId=${brokerId}
    public inviteUsers(brokerId: number, users: InviteUserDTO[]): Promise<boolean> {
        let url = `api/broker/InviteUsers`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (brokerId != null) {
            url += `${prefix}brokerId=${brokerId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, boolean>(users, 'post', url, true, false);
    }

    // post: api/broker/SimpleUserList?brokerId=${brokerId}
    public simpleUserList(brokerId: number, tableRequest: TableRequestDTO): Promise<TableResponseDTO<ParentSecurityUserSettingsDTO>> {
        let url = `api/broker/SimpleUserList`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (brokerId != null) {
            url += `${prefix}brokerId=${brokerId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<ParentSecurityUserSettingsDTO>>(tableRequest, 'post', url, true, false);
    }
}
var service = new BrokerApiService();
export default service;
