import { Breadcrumb, Col, Layout, Menu, notification, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, NavLink, Route, Switch, useParams } from 'react-router-dom';
import ClientService from '../../../api/ClientApiService';
import ClientInviteUsers from '../../../components/admin/Clients/ClientInviteUsers';
import Permission from '../../../consts/Permission';
import Routes from '../../../consts/Routes';
import useHasPermission from '../../../hooks/useHasPermission';
import HistoryUtil from '../../../utils/HistoryUtil';
import parseIntOrDefault from '../../../utils/ParseIntOrDefault';
import PageTitle from '../../shared/PageTitle';
import BrokerAccessEditor from './BrokerAccessEditor';
import BrokerAccessList from './BrokerAccessList';
import ClaimDocAccess from './ClaimDocAccess';
import ClientDetailView from './ClientDetailView';
import ClientSecurity from './ClientSecurity';
import DpcAccessEditor from './DpcAccessEditor';
import DpcAccessList from './DpcAccessList';
import OtherCompanyAccessEditor from './OtherCompanyAccessEditor';
import OtherCompanyAccessList from './OtherCompanyAccessList';
import TpaAccessEditor from './TpaAccessEditor';
import TpaAccessList from './TpaAccessList';
import ClientDetailDTO from '../../../models/ClientDetailDTO';
import ClientDivisionList from './ClientDivisionList';
import ClientConfig from './ClientConfig';
const Content = Layout.Content;

interface ClientRouteProps {
  id: string;
  tab?: string;
}

const ClientDetailsPage = () => {
  const routeProps = useParams<ClientRouteProps>();
  const id = parseIntOrDefault(routeProps.id);
  if (id <= 0) {
    HistoryUtil.replace(Routes.ADMIN_CLIENTS);
  }

  const [selectedTab, setSelectedTab] = useState(routeProps.tab || 'about');
  const [clientId, setClientId] = useState(id);
  const [client, setClient] = useState<ClientDetailDTO>(ClientDetailDTO.create());
  const hasPermission = useHasPermission();

  useEffect(() => {
    const id = parseIntOrDefault(routeProps.id);
    if (id <= 0) {
      HistoryUtil.replace(Routes.ADMIN_CLIENTS);
    }
    setClientId(clientId);
    fetchName(clientId);
  }, [routeProps.id]);

  useEffect(() => {
    setSelectedTab(routeProps.tab || 'about');
  }, [routeProps.tab]);

  const fetchName = (id: number) => {
    if (id > 0) {
      ClientService.getClientDetails(id).then((det) => {
        setClient(det);
      });
    } else {
      setClient(ClientDetailDTO.create({ name: 'New Client' }));
    }
  };

  const tabs = [];

  if (hasPermission(Permission.ADMIN_CLIENT_DETAILS_VIEW)) {
    tabs.push(
      <Menu.Item key="about">
        <NavLink to={Routes.generate(Routes.ADMIN_CLIENT_DETAILS, { id: clientId })}>Client Information</NavLink>
      </Menu.Item>
    );
  }

  if (hasPermission(Permission.ADMIN_CLIENT_CONFIG_EDIT)) {
    tabs.push(
      <Menu.Item key="config">
        <NavLink to={Routes.generate(Routes.ADMIN_CLIENT_CONFIG, { id: clientId })}>Client Configuration</NavLink>
      </Menu.Item>
    );
  }

  if (hasPermission(Permission.ADMIN_CLIENT_TPA_VIEW)) {
    tabs.push(
      <Menu.Item key="tpas">
        <NavLink to={Routes.generate(Routes.ADMIN_CLIENT_DETAILS_TPAS, { id: clientId })}>TPAs</NavLink>
      </Menu.Item>
    );
  }

  if (hasPermission(Permission.ADMIN_CLIENT_DPC_VIEW)) {
    tabs.push(
      <Menu.Item key="dpcs">
        <NavLink to={Routes.generate(Routes.ADMIN_CLIENT_DETAILS_DPCS, { id: clientId })}>DPCs</NavLink>
      </Menu.Item>
    );
  }

  if (hasPermission(Permission.ADMIN_CLIENT_OTHERCOMPANY_VIEW)) {
    tabs.push(
      <Menu.Item key="other-companies">
        <NavLink to={Routes.generate(Routes.ADMIN_CLIENT_DETAILS_OTHERCOMPANIES, { id: clientId })}>
          Other Companies
        </NavLink>
      </Menu.Item>
    );
  }

  if (hasPermission(Permission.ADMIN_CLIENT_BROKER_VIEW)) {
    tabs.push(
      <Menu.Item key="brokers">
        <NavLink to={Routes.generate(Routes.ADMIN_CLIENT_DETAILS_BROKERS, { id: clientId })}>Brokers</NavLink>
      </Menu.Item>
    );
  }

  if (hasPermission(Permission.ADMIN_CLIENT_CLAIMDOC_ACCESS_VIEW)) {
    tabs.push(
      <Menu.Item key="claimdoc">
        <NavLink to={Routes.generate(Routes.ADMIN_CLIENT_DETAILS_CLAIMDOC, { id: clientId })}>ClaimDOC Access</NavLink>
      </Menu.Item>
    );
  }

  if (hasPermission(Permission.ADMIN_CLIENT_SECURITY_VIEW)) {
    tabs.push(
      <Menu.Item key="security">
        <NavLink to={Routes.generate(Routes.ADMIN_CLIENT_DETAILS_SECURITY, { id: clientId })}>Security</NavLink>
      </Menu.Item>
    );
  }

  if (hasPermission(Permission.ADMIN_CLIENT_INVITEUSERS)) {
    tabs.push(
      <Menu.Item key="invite">
        <NavLink to={Routes.generate(Routes.ADMIN_CLIENT_INVITE_USERS, { id: clientId })}>Invite Users</NavLink>
      </Menu.Item>
    );
  }

  if (hasPermission(Permission.ADMIN_CLIENT_DETAILS_VIEW) && !client.isTermed && (client.divisions?.length ?? 0) > 0) {
    tabs.push(
      <Menu.Item key="divisions">
        <NavLink to={Routes.generate(Routes.ADMIN_CLIENT_DETAILS_DIVISIONS, { id: clientId })}>Divisions</NavLink>
      </Menu.Item>
    );
  }

  return (
    <Content>
      <Row justify="space-between" style={{ marginBottom: '12px' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={Routes.ADMIN_CLIENTS}>Clients</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{client.name}</Breadcrumb.Item>
        </Breadcrumb>
      </Row>
      <PageTitle title={client.name} style={{ marginTop: '0.5em', marginBottom: '0.5em' }} />
      {client.isTermed && (
        <Typography.Text style={{ color: 'red' }}>
          * This client is terminated, changes cannot be made to terminated clients.
        </Typography.Text>
      )}
      <Row>
        <Col xs={24}>
          <Menu mode="horizontal" style={{ marginBottom: 15 }} selectedKeys={[selectedTab]}>
            {tabs}
          </Menu>

          <Switch>
            <Route
              path={Routes.ADMIN_CLIENT_DETAILS_TPAS}
              render={(props) =>
                props.match.params.tpaId ? (
                  <TpaAccessEditor clientId={clientId} tpaId={props.match.params.tpaId} readonly={client.isTermed} />
                ) : (
                  <TpaAccessList clientId={clientId} readonly={client.isTermed} />
                )
              }
            />
            <Route
              path={Routes.ADMIN_CLIENT_DETAILS_DPCS}
              render={(props) =>
                props.match.params.dpcId ? (
                  <DpcAccessEditor clientId={clientId} dpcId={props.match.params.dpcId} readonly={client.isTermed} />
                ) : (
                  <DpcAccessList clientId={clientId} readonly={client.isTermed} />
                )
              }
            />
            <Route
              path={Routes.ADMIN_CLIENT_DETAILS_OTHERCOMPANIES}
              render={(props) =>
                props.match.params.otherCompanyId ? (
                  <OtherCompanyAccessEditor
                    clientId={clientId}
                    otherCompanyId={props.match.params.otherCompanyId}
                    readonly={client.isTermed}
                  />
                ) : (
                  <OtherCompanyAccessList clientId={clientId} readonly={client.isTermed} />
                )
              }
            />
            <Route
              path={Routes.ADMIN_CLIENT_DETAILS_BROKERS}
              render={(props) =>
                props.match.params.brokerId ? (
                  <BrokerAccessEditor
                    clientId={clientId}
                    brokerId={props.match.params.brokerId}
                    readonly={client.isTermed}
                  />
                ) : (
                  <BrokerAccessList clientId={clientId} readonly={client.isTermed} />
                )
              }
            />
            <Route
              path={Routes.ADMIN_CLIENT_DETAILS_CLAIMDOC}
              render={() => <ClaimDocAccess clientId={clientId} readonly={client.isTermed} />}
            />
            <Route
              path={Routes.ADMIN_CLIENT_DETAILS_SECURITY}
              render={() => <ClientSecurity clientId={clientId} readonly={client.isTermed} />}
            />

            <Route
              path={Routes.ADMIN_CLIENT_INVITE_USERS}
              exact={true}
              render={() => <ClientInviteUsers clientId={clientId} readonly={client.isTermed} />}
            />

            <Route
              path={Routes.ADMIN_CLIENT_CONFIG}
              render={() => <ClientConfig clientId={clientId} readonly={client.isTermed} />}
            />

            <Route
              path={Routes.ADMIN_CLIENT_DETAILS_DIVISIONS}
              exact={true}
              render={() => <ClientDivisionList clientId={clientId} />}
            />

            <Route
              path={Routes.ADMIN_CLIENT_DETAILS}
              exact={true}
              render={() => <ClientDetailView clientId={clientId} readonly={client.isTermed} />}
            />
          </Switch>
        </Col>
      </Row>
    </Content>
  );
};

export const GetListViewLink = (clientId: number, hasPermission: (id: number) => boolean) => {
  let route: string;
  if (hasPermission(Permission.ADMIN_CLIENT_DETAILS_VIEW)) {
    route = Routes.ADMIN_CLIENT_DETAILS;
  } else if (hasPermission(Permission.ADMIN_CLIENT_TPA_VIEW)) {
    route = Routes.ADMIN_CLIENT_DETAILS_TPAS;
  } else if (hasPermission(Permission.ADMIN_CLIENT_BROKER_VIEW)) {
    route = Routes.ADMIN_CLIENT_DETAILS_BROKERS;
  } else if (hasPermission(Permission.ADMIN_CLIENT_SECURITY_VIEW)) {
    route = Routes.ADMIN_CLIENT_DETAILS_SECURITY;
  } else if (hasPermission(Permission.ADMIN_CLIENT_CLAIMDOC_ACCESS_VIEW)) {
    route = Routes.ADMIN_CLIENT_DETAILS_CLAIMDOC;
  } else {
    notification.warn({ message: 'Insufficient Permissions' });
    HistoryUtil.replace(Routes.ADMIN_CLIENTS);
    return '';
  }

  return Routes.generate(route, {
    id: clientId,
  });
};

export default ClientDetailsPage;
