// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';

interface ProviderAutoCompleteFilterDTO { 
    providerTypeId: number;
    orgName: string | null;
    firstName: string | null;
    lastName: string | null;
    npi: string | null;
}
const ProviderAutoCompleteFilterDTO = {
    create: (initValues?: Partial<ProviderAutoCompleteFilterDTO> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            providerTypeId: 0,
            orgName: null,
            firstName: null,
            lastName: null,
            npi: null,
        },
        initValues);
    }
};

export default ProviderAutoCompleteFilterDTO;