import { Button, Modal, notification, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useEffect, useState } from 'react';
import ClientApiService from '../../../api/ClientApiService';
import ParentSecurityUserSettingsDTO from '../../../models/ParentSecurityUserSettingsDTO';
import TableRequestDTO from '../../../models/TableRequestDTO';
import TableResponseDTO from '../../../models/TableResponseDTO';
import DataTable, { DataTableColumnProps, FilterType } from '../../shared/DataTable/DataTable';
import DataTableColumnUtil from '../../shared/DataTable/DataTableColumnUtil';
import InviteUsers from '../../shared/InviteUsers';
import ClientDetailDTO from '../../../models/ClientDetailDTO';
import InviteUsersEditForm from '../../shared/InviteUsersEditForm';
import UserApiService from '../../../api/UserApiService';
interface TpaInviteAdminsProps {
  clientId: number;
  readonly?: boolean;
}

const ClientInviteUsers = (props: TpaInviteAdminsProps) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<ParentSecurityUserSettingsDTO[]>([]);
  const [selectedUser, setSelectedUser] = useState<ParentSecurityUserSettingsDTO>();
  const [openModal, setOpenModal] = useState(false);
  const [clientDivisions, setClientDivisions] = useState<ClientDetailDTO[]>([]);
  const dtRef = React.useRef<DataTable<ParentSecurityUserSettingsDTO>>(null);
  const [editForm] = useForm();

  useEffect(() => {
    fetchClientDivisions();
  }, []);

  const submit = (values: any) => {
    if (values.accounts && values.accounts.length > 0) {
      setLoading(true);
      if (values.divisionClientId) {
        (values.accounts || []).forEach((e: any) => {
          e.divisionClientId = values.divisionClientId;
        });
      }

      ClientApiService.inviteUsers(props.clientId, values.accounts)
        .then(() => {
          notification.success({ message: 'Users Invited' });
          form.resetFields();
          dtRef.current?.refresh();
        })
        .catch(() => {
          notification.error({ message: 'Error Inviting Users' });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const fetchClientDivisions = () => {
    ClientApiService.getClientDivisions(props.clientId, true).then((res) => {
      setClientDivisions(res);
    });
  };

  const fetchTableData = (
    requestState: TableRequestDTO,
    checkEcho: () => boolean,
    callback: (response: TableResponseDTO<ParentSecurityUserSettingsDTO>) => void
  ) => {
    setLoading(true);
    ClientApiService.simpleUserList(props.clientId, requestState)
      .then((results) => {
        if (!checkEcho()) {
          return;
        }
        setUsers(results.results || []);
        setLoading(false);
        callback(results);
      })
      .catch((error: any) => {
        setLoading(false);
        notification.error({
          message: error.message,
          description: error.description,
        });
      });
  };

  const getTableColumns = (): DataTableColumnProps<ParentSecurityUserSettingsDTO>[] => {
    const columns: DataTableColumnProps<ParentSecurityUserSettingsDTO>[] = [];
    columns.push(DataTableColumnUtil.Text<ParentSecurityUserSettingsDTO>('First Name', 'firstName'));
    columns.push(DataTableColumnUtil.Text<ParentSecurityUserSettingsDTO>('Last Name', 'lastName'));
    columns.push(DataTableColumnUtil.Text<ParentSecurityUserSettingsDTO>('Email', 'username'));
    columns.push(DataTableColumnUtil.Text<ParentSecurityUserSettingsDTO>('Phone Number', 'phoneNumber'));
    columns.push(
      DataTableColumnUtil.BooleanYesNo<ParentSecurityUserSettingsDTO>(
        'MFA Required',
        'requireMfa',
        undefined,
        FilterType.BooleanRadio,
        { align: 'left' }
      )
    );

    if (clientDivisions.length > 1) {
      columns.push(DataTableColumnUtil.Text<ParentSecurityUserSettingsDTO>('Division', 'division'));
    }

    columns.push({
      ...DataTableColumnUtil.Text<ParentSecurityUserSettingsDTO>('Role', 'role'),
      filterType: FilterType.NONE,
    });

    columns.push(
      DataTableColumnUtil.BooleanYesNo<ParentSecurityUserSettingsDTO>(
        'Registered',
        'registered',
        undefined,
        FilterType.BooleanRadio,
        { align: 'left' }
      )
    );
    columns.push(
      DataTableColumnUtil.DateTime<ParentSecurityUserSettingsDTO>('Last Logged In', 'lastLoggedInOn', undefined, {
        align: 'left',
      })
    );
    columns.push({
      title: '',
      dataIndex: '',
      width: '40px',
      // eslint-disable-next-line react/display-name
      render: (rowData: ParentSecurityUserSettingsDTO) => (
        <Button
          shape="round"
          ghost
          onClick={() => {
            setSelectedUser(rowData);
            setOpenModal(true);
          }}
          disabled={rowData.registered}
        >
          Edit
        </Button>
      ),
    });

    return columns;
  };

  const submitInvitedUserChange = () => {
    editForm.validateFields().then((model) => {
      UserApiService.saveInvitedUserEdit(model)
        .then((result) => {
          if (!result) {
            notification.error({ message: 'Edit Failed', description: 'An error occurred while saving.' });
          } else {
            dtRef.current?.refresh();
          }
        })
        .catch(() => {
          setLoading(false);
          notification.error({ message: 'Edit Failed', description: 'An error occurred while saving.' });
        })
        .finally(() => {
          setLoading(false);
          setOpenModal(false);
          setSelectedUser(undefined);
        });
    });
  };

  return (
    <Spin spinning={loading}>
      <Modal
        open={openModal}
        title="Edit Invited User"
        onCancel={() => {
          setSelectedUser(undefined);
          setOpenModal(false);
        }}
        onOk={submitInvitedUserChange}
        okText="Save"
      >
        <InviteUsersEditForm form={editForm} initialValues={selectedUser} />
      </Modal>
      <InviteUsers form={form} submit={submit} showAdmin clientOptions={clientDivisions} readonly={!!props.readonly} />
      <hr />
      <DataTable
        title="Current Users"
        ref={dtRef}
        serverSide={true}
        globalSearch={true}
        globalSearchPlaceholder="Search Users"
        tableProps={{
          rowKey: 'id',
          loading: loading,
        }}
        columns={getTableColumns()}
        data={users}
        fetchData={fetchTableData}
        styleOptions={{
          alternatingRowHighlight: true,
        }}
      />
    </Spin>
  );
};

export default ClientInviteUsers;
