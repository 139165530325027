import { Col, Layout, Menu, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { NavLink, Route, Switch, useParams } from 'react-router-dom';
import Routes from '../../consts/Routes';
import MRFList from '../../components/documents/MRFList';
import PageTitle from '../../components/shared/PageTitle';
import Permission from '../../consts/Permission';
import HistoryUtil from '../../utils/HistoryUtil';
import useHasPermission from '../../hooks/useHasPermission';
import useUrlQuery from '../../hooks/useUrlQuery';
import PNFList from '../../components/documents/PNFList';
import BalanceBillList from '../../components/documents/BalanceBillList';

const Content = Layout.Content;

interface DocumentListPageRouteProps {
  tab: string;
  mobileview?: string;
}

const DocumentListPage = () => {
  const routeProps = useParams<DocumentListPageRouteProps>();
  const [routeQuery] = useUrlQuery<DocumentListPageRouteProps>();
  const [selectedTab, setSelectedTab] = useState(routeProps.tab);
  const hasPermission = useHasPermission();

  useEffect(() => {
    let defaultTabRoute = Routes.DOCUMENTS_BASE;
    let defaultTab = '';

    if (routeProps.tab !== ':tab?' && routeProps.tab !== '') {
      if (checkPermissions(routeProps.tab)) {
        defaultTabRoute = Routes.generate(Routes.DOCUMENTS_BASE, { tab: routeProps.tab });
        defaultTab = routeProps.tab;
      } else {
        defaultTabRoute = Routes.UNAUTHORIZED;
      }
    } else {
      if (hasPermission(Permission.DOCUMENTS_MRF_ACCESS)) {
        defaultTabRoute = Routes.generate(Routes.DOCUMENTS_BASE, { tab: 'mrf' });
        defaultTab = 'mrf';
      } else if (hasPermission(Permission.DOCUMENTS_PNF_ACCESS)) {
        defaultTabRoute = Routes.generate(Routes.DOCUMENTS_BASE, { tab: 'pnf' });
        defaultTab = 'pnf';
      } else if (hasPermission(Permission.DOCUMENTS_BALANCEBILL_ACCESS)) {
        defaultTabRoute = Routes.generate(Routes.DOCUMENTS_BASE, { tab: 'balanceBill' });
        defaultTab = 'balanceBill';
      } else {
        defaultTabRoute = Routes.UNAUTHORIZED;
      }
    }

    if (routeQuery.mobileview) {
      defaultTabRoute = defaultTabRoute + '?mobileview=' + routeQuery.mobileview;
    }
    HistoryUtil.push(Routes.generate(defaultTabRoute));
    setSelectedTab(defaultTab);
  }, []);

  useEffect(() => {
    let defaultTabRoute = Routes.DOCUMENTS_BASE;
    let defaultTab = '';

    if (routeProps.tab !== selectedTab && routeProps.tab !== '') {
      if (routeProps.tab !== ':tab?') {
        defaultTabRoute = Routes.generate(Routes.DOCUMENTS_BASE, { tab: routeProps.tab });
        defaultTab = routeProps.tab;
      }

      if (routeQuery.mobileview) {
        defaultTabRoute = defaultTabRoute + '?mobileview=' + routeQuery.mobileview;
      }
      HistoryUtil.push(Routes.generate(defaultTabRoute));
      setSelectedTab(defaultTab);
    } else if (routeProps.tab == ':tab?' || routeProps.tab == '' || routeProps.tab == null) {
      if (hasPermission(Permission.DOCUMENTS_MRF_ACCESS)) {
        defaultTabRoute = Routes.generate(Routes.DOCUMENTS_BASE, { tab: 'mrf' });
        defaultTab = 'mrf';
      } else if (hasPermission(Permission.DOCUMENTS_PNF_ACCESS)) {
        defaultTabRoute = Routes.generate(Routes.DOCUMENTS_BASE, { tab: 'pnf' });
        defaultTab = 'pnf';
      } else if (hasPermission(Permission.DOCUMENTS_BALANCEBILL_ACCESS)) {
        defaultTabRoute = Routes.generate(Routes.DOCUMENTS_BASE, { tab: 'balanceBill' });
        defaultTab = 'balanceBill';
      } else {
        defaultTabRoute = Routes.UNAUTHORIZED;
      }

      if (routeQuery.mobileview) {
        defaultTabRoute = defaultTabRoute + '?mobileview=' + routeQuery.mobileview;
      }

      HistoryUtil.push(Routes.generate(defaultTabRoute));
      setSelectedTab(defaultTab);
    }
  }, [routeProps]);

  const checkPermissions = (tab: string) => {
    if (tab === 'mrf') {
      return hasPermission(Permission.DOCUMENTS_MRF_ACCESS);
    } else if (tab === 'pnf') {
      return hasPermission(Permission.DOCUMENTS_PNF_ACCESS);
    } else if (tab === 'balanceBill') {
      return hasPermission(Permission.DOCUMENTS_BALANCEBILL_ACCESS);
    } else {
      return false;
    }
  };

  const getMobileTitle = () => {
    let title = '';
    if (selectedTab === 'mrf') {
      title = 'Machine Readable Files';
    } else if (selectedTab === 'pnf') {
      title = 'Provider Nominations';
    } else if (selectedTab === 'balanceBill') {
      title = 'Balance Bills';
    }
    return title;
  };

  const menuItems = [];

  if (hasPermission(Permission.DOCUMENTS_MRF_ACCESS)) {
    menuItems.push({
      key: 'mrf',
      label: (
        <NavLink
          to={Routes.generate(
            Routes.DOCUMENTS_BASE,
            { tab: 'mrf' },
            routeQuery.mobileview ? { mobileview: '1' } : undefined
          )}
        >
          Machine Readable Files
        </NavLink>
      ),
    });
  }

  if (hasPermission(Permission.DOCUMENTS_PNF_ACCESS)) {
    menuItems.push({
      key: 'pnf',
      label: (
        <NavLink
          to={Routes.generate(
            Routes.DOCUMENTS_BASE,
            { tab: 'pnf' },
            routeQuery.mobileview ? { mobileview: '1' } : undefined
          )}
        >
          Provider Nominations
        </NavLink>
      ),
    });
  }

  if (hasPermission(Permission.DOCUMENTS_BALANCEBILL_ACCESS)) {
    menuItems.push({
      key: 'balanceBill',
      label: (
        <NavLink
          to={Routes.generate(
            Routes.DOCUMENTS_BASE,
            { tab: 'balanceBill' },
            routeQuery.mobileview ? { mobileview: '1' } : undefined
          )}
        >
          Balance Bills
        </NavLink>
      ),
    });
  }

  return (
    <Content className="content-padding">
      <Row>
        <Col flex={1}>
          {!routeQuery.mobileview ? <PageTitle title="Documents" /> : <PageTitle title={getMobileTitle()} />}
        </Col>
        <Col xs={24}>
          <Menu mode="horizontal" style={{ marginBottom: 15 }} selectedKeys={[selectedTab]} items={menuItems} />
          <Switch>
            <Route path={Routes.generate(Routes.DOCUMENTS_BASE, { tab: 'mrf' })} render={() => <MRFList />} />
            <Route path={Routes.generate(Routes.DOCUMENTS_BASE, { tab: 'pnf' })} render={() => <PNFList />} />
            <Route
              path={Routes.generate(Routes.DOCUMENTS_BASE, { tab: 'balanceBill' })}
              render={() => <BalanceBillList />}
            />
          </Switch>
        </Col>
      </Row>
    </Content>
  );
};

export default DocumentListPage;
