// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import PermissionGroupDetailDTO from '../models/PermissionGroupDetailDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import PermissionGroupListDTO from '../models/PermissionGroupListDTO';
import PermissionAuditDTO from '../models/PermissionAuditDTO';

export class PermissionApiService extends BaseApi {

    // post: api/permissions/GetPermissionGroupList
    public getPermissionGroupList(tableRequest: TableRequestDTO): Promise<TableResponseDTO<PermissionGroupListDTO>> {
        let url = `api/permissions/GetPermissionGroupList`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<PermissionGroupListDTO>>(tableRequest, 'post', url, true, false);
    }

    // get: api/permissions/GetPermissionGroupDetails?permissionGroupId=${permissionGroupId}
    public getPermissionGroupDetails(permissionGroupId: number): Promise<PermissionGroupDetailDTO> {
        let url = `api/permissions/GetPermissionGroupDetails`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (permissionGroupId != null) {
            url += `${prefix}permissionGroupId=${permissionGroupId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, PermissionGroupDetailDTO>(null, 'get', url, true, false);
    }

    // post: api/permissions/UpdatePermissionGroup
    public updatePermissionGroup(dto: PermissionGroupDetailDTO): Promise<number> {
        let url = `api/permissions/UpdatePermissionGroup`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, number>(dto, 'post', url, true, false);
    }

    // post: api/permissions/DeletePermissionGroup?id=${id}
    public deletePermissionGroup(id: number): Promise<boolean> {
        let url = `api/permissions/DeletePermissionGroup`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (id != null) {
            url += `${prefix}id=${id}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, boolean>(null, 'post', url, true, false);
    }

    // get: api/permissions/PermissionAudit
    public permissionAudit(): Promise<PermissionAuditDTO[]> {
        let url = `api/permissions/PermissionAudit`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, PermissionAuditDTO[]>(null, 'get', url, true, false);
    }
}
var service = new PermissionApiService();
export default service;
