// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import LookupTableDTO from './LookupTableDTO';

interface UserSecurityDTO { 
    id: number;
    userName: string | null;
    firstName: string | null;
    lastName: string | null;
    isGuestAccount: boolean;
    guestAccountType: string | null;
    accessRoleId: number | null;
    accessEntityId: number | null;
    accessEntityCollection: Record<number, LookupTableDTO[]>;
    isMultiRole: boolean;
    requirePasswordChange: boolean;
    permissions: number[];
    clientPermissions: Record<number, number[]>;
    stub: LookupTableDTO | null;
}
const UserSecurityDTO = {
    create: (initValues?: Partial<UserSecurityDTO> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            id: 0,
            userName: null,
            firstName: null,
            lastName: null,
            isGuestAccount: false,
            guestAccountType: null,
            accessRoleId: null,
            accessEntityId: null,
            accessEntityCollection: [],
            isMultiRole: false,
            requirePasswordChange: false,
            permissions: [],
            clientPermissions: [],
            stub: null,
        },
        initValues);
    }
};

export default UserSecurityDTO;