import { LockOutlined, MailOutlined, UserAddOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Card, Col, Descriptions, Divider, notification, Row, Spin } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AccountApiService from '../../api/AccountApiService';
import SupportApiService from '../../api/SupportApiService';
import PageTitle from '../../components/shared/PageTitle';
import MemberSupportAccountTypes from '../../consts/MemberSupportAccountTypes';
import Routes from '../../consts/Routes';
import useUrlQuery from '../../hooks/useUrlQuery';
import FullAccountDetailDTO from '../../models/FullAccountDetailDTO';
import PinAccountDetailDTO from '../../models/PinAccountDetailDTO';

interface RouteProps {
  clientId?: string;
  userId?: string;
  pinId?: string;
}

const MemberDetailPage = () => {
  const [routeQuery] = useUrlQuery<RouteProps>();

  const [loading, setLoading] = useState(false);
  const [fullAccount, setFullAccount] = useState<FullAccountDetailDTO | undefined>(undefined);
  const [pinAccount, setPinAccount] = useState<PinAccountDetailDTO | undefined>(undefined);

  const userEmail = fullAccount ? fullAccount.email ?? '' : pinAccount?.email ?? '';

  let pageTitle = '';
  if (fullAccount) {
    pageTitle = `${MemberSupportAccountTypes.FULLACCOUNT} - ${fullAccount.clientName}`;
  }

  if (pinAccount) {
    pageTitle = `${MemberSupportAccountTypes.GUESTPIN} - ${pinAccount.clientName}`;
  }

  useEffect(() => {
    if (routeQuery.clientId) {
      if (routeQuery.userId) {
        fetchFullAccount(Number(routeQuery.clientId), Number(routeQuery.userId));
      } else if (routeQuery.pinId) {
        fetchPinAccount(Number(routeQuery.clientId), Number(routeQuery.pinId));
      }
    }
  }, []);

  const fetchFullAccount = (clientId: number, userId: number) => {
    setLoading(true);
    SupportApiService.getFullAccountDetail(clientId, userId)
      .then((res) => {
        setFullAccount(res);
      })
      .catch((err) => {
        notification.error({ message: err.message });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchPinAccount = (clientId: number, pinId: number) => {
    setLoading(true);
    SupportApiService.getPinAccountDetail(clientId, pinId)
      .then((res) => {
        setPinAccount(res);
      })
      .catch((err) => {
        notification.error({ message: err.message });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const resendOriginalPinAccountEmail = () => {
    if (pinAccount && pinAccount.email && pinAccount.pin) {
      setLoading(true);
      SupportApiService.resendIndividualPinNotificationEmail(pinAccount.email, pinAccount.pin)
        .then(() => {
          notification.success({ message: 'Guest account welcome email sent.' });
        })
        .catch(() => {
          notification.error({ message: 'An error occurred while sending the guest account welcome email.' });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      notification.error({ message: 'Invalid email or pin.' });
    }
  };

  const sendRegisterEmail = () => {
    if (pinAccount && pinAccount.email) {
      setLoading(true);
      SupportApiService.sendRegistrationEmail(pinAccount.email)
        .then(() => {
          notification.success({ message: 'Register email sent.' });
        })
        .catch(() => {
          notification.error({ message: 'An error occurred while sending the registration email.' });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      notification.error({ message: 'Invalid email.' });
    }
  };

  const sendPasswordResetEmail = () => {
    if (fullAccount && fullAccount.email) {
      setLoading(true);
      AccountApiService.forgotPassword(fullAccount.email, true)
        .then(() => {
          notification.success({ message: 'Password reset email sent.' });
        })
        .catch(() => {
          notification.error({ message: 'An error occurred while sending the password reset email.' });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      notification.error({ message: 'Invalid email.' });
    }
  };

  const renderFullAccountDetail = (fullAcc: FullAccountDetailDTO) => {
    return (
      <Descriptions labelStyle={{ fontWeight: 'bold' }}>
        <Descriptions.Item label="Email">{fullAcc.email}</Descriptions.Item>
        <Descriptions.Item label="Phone">{fullAcc.phone}</Descriptions.Item>
        <Descriptions.Item label="User Status">{fullAcc.userStatusName}</Descriptions.Item>
        <Descriptions.Item label="First Name">{fullAcc.firstName}</Descriptions.Item>
        <Descriptions.Item label="Last Name">{fullAcc.lastName}</Descriptions.Item>
        <Descriptions.Item label="Relationship">{fullAcc.relationship}</Descriptions.Item>
        <Descriptions.Item label="Has Registered?">{fullAcc.hasRegistered ? 'Yes' : 'No'}</Descriptions.Item>
        <Descriptions.Item label="Has Confirmed Email?">{fullAcc.hasConfirmedEmail ? 'Yes' : 'No'}</Descriptions.Item>
        <Descriptions.Item label="MFA Enabled?">{fullAcc.hasMfaEnabled ? 'Yes' : 'No'}</Descriptions.Item>
        <Descriptions.Item label="Registered On">
          {fullAcc.endLockoutDate ? moment(fullAcc.registeredOn).format('MM/DD/yyyy hh:mm A') : ''}
        </Descriptions.Item>
        <Descriptions.Item label="Last Successful Login">
          {fullAcc.endLockoutDate ? moment(fullAcc.lastLoggedInOn).format('MM/DD/yyyy hh:mm A') : ''}
        </Descriptions.Item>
        <Descriptions.Item label="Lockout End">
          {fullAcc.endLockoutDate ? moment(fullAcc.endLockoutDate).format('MM/DD/yyyy hh:mm A') : ''}
        </Descriptions.Item>
      </Descriptions>
    );
  };

  const renderFullAccountActions = () => {
    return (
      <Row gutter={12}>
        <Col {...actionCardProps}>
          <Card style={{ textAlign: 'center' }}>
            <LockOutlined {...actionIconProps} />
            <br />
            <Button htmlType="button" disabled={loading} shape="round" onClick={() => sendPasswordResetEmail()}>
              Send Password Reset Email
            </Button>
          </Card>
        </Col>
      </Row>
    );
  };

  const renderPinAccountDetail = (pinAcc: PinAccountDetailDTO) => {
    return (
      <Descriptions labelStyle={{ fontWeight: 'bold' }}>
        <Descriptions.Item label="Account Type">{MemberSupportAccountTypes.GUESTPIN}</Descriptions.Item>
        <Descriptions.Item label="First Name">{pinAcc.firstName}</Descriptions.Item>
        <Descriptions.Item label="Last Name">{pinAcc.lastName}</Descriptions.Item>
        <Descriptions.Item label="Created On">
          {pinAcc.createdOn ? moment(pinAcc.createdOn).format('MM/DD/yyyy hh:mm A') : ''}
        </Descriptions.Item>
      </Descriptions>
    );
  };

  const renderPinAccountActions = () => {
    return (
      <Row gutter={12}>
        <Col {...actionCardProps}>
          <Card style={{ textAlign: 'center' }}>
            <MailOutlined {...actionIconProps} />
            <br />
            <Button htmlType="button" disabled={loading} shape="round" onClick={() => resendOriginalPinAccountEmail()}>
              Resend Welcome Email
            </Button>
          </Card>
        </Col>
        <Col {...actionCardProps}>
          <Card style={{ textAlign: 'center' }}>
            <UserAddOutlined {...actionIconProps} />
            <br />
            <Button htmlType="button" disabled={loading} shape="round" onClick={() => sendRegisterEmail()}>
              Send Registration Email
            </Button>
          </Card>
        </Col>
      </Row>
    );
  };

  const actionCardProps = { xxl: 5, xl: 8, lg: 10, md: 12, sm: 12, xs: 24, style: { marginBottom: 15 } };
  const actionIconProps = { style: { fontSize: 50, marginBottom: 15 } };

  return (
    <Content className="content-padding">
      <Row justify="space-between" style={{ marginBottom: '12px' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={Routes.SUPPORT_MEMBER_SEARCH} style={{ textDecoration: 'underline', color: '#D2292D' }}>
              Client Member Search
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{userEmail}</Breadcrumb.Item>
        </Breadcrumb>
      </Row>
      <PageTitle title={pageTitle} style={{ marginTop: '0.5em', marginBottom: '0.5em' }} />
      <Spin spinning={loading}>
        {fullAccount && (
          <>
            {renderFullAccountDetail(fullAccount)}
            <Divider />
            {renderFullAccountActions()}
          </>
        )}
        {pinAccount && (
          <>
            {renderPinAccountDetail(pinAccount)}
            <Divider />
            {renderPinAccountActions()}
          </>
        )}
      </Spin>
    </Content>
  );
};

export default MemberDetailPage;
