import { BackTop, Button, Card, Col, Pagination, Row } from 'antd';
import React, { useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import Permission from '../../consts/Permission';
import Routes from '../../consts/Routes';
import ProviderDTO from '../../models/ProviderDTO';
import PermissionGuard from '../shared/PermissionGuard';
import { SortByFormat } from './ProviderSearch';
import SearchResultCard from './SearchResultCard';

interface ProviderSearchResultsProps {
  isMobile: boolean;
  providers?: ProviderDTO[];
  sortBy?: number;
  selected?: ProviderDTO;
  selectedProviders?: (ProviderDTO|undefined)[];
  resultClick: (provider: ProviderDTO) => void;
  totalCount: number;
  pagination?: PaginationProps;
  isImplementationPage?: boolean;
}

interface PaginationProps {
  currentPage: number;
  pageSize: number;
  onPageChange: (page: number) => void;
}

const MAX_PROVIDERS = 5

const mobileStyle = { paddingLeft: '6px', paddingRight: '6px' };
const desktopStyle = { height: '52.5vh', overflow: 'auto' };

const ProviderSearchResults: React.FC<ProviderSearchResultsProps> = (props: ProviderSearchResultsProps) => {
  const onResultClickCb = useCallback((provider: ProviderDTO) => props.resultClick(provider), []);

  const resultsCountRender = () => {
    let paginationStr = '';
    if (props.pagination) {
      const { currentPage, pageSize } = { ...props.pagination };
      paginationStr = `Showing 
      ${currentPage * pageSize - (pageSize - 1)} to 
      ${currentPage * pageSize < props.totalCount ? currentPage * pageSize : props.totalCount} of `;
    }
    const str = `${paginationStr}${props.totalCount} provider${props.totalCount === 1 ? '' : 's'} found`;
    return str;
  };

  const onPageChange = (page: number) => {
    if (props.pagination) {
      props.pagination.onPageChange(page);
    }
  };

  const renderPagination = () => {
    if (!props.providers || !props.pagination) {
      return;
    }

    return (
      <Row justify="center" align="middle" style={{ marginBottom: '5px' }}>
        <Col flex={1} style={{ textAlign: 'center' }}>
          <Pagination
            current={props.pagination.currentPage}
            total={props.totalCount}
            pageSize={props.pagination.pageSize}
            showSizeChanger={false}
            size={props.isMobile ? 'small' : 'default'}
            showLessItems
            onChange={(page: number) => onPageChange(page)}
          />
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Row justify="space-between" style={{ marginTop: props.isMobile ? 5 : 0, paddingLeft: 6, paddingRight: 6 }}>
        <Col>{props.providers ? resultsCountRender() : null}</Col>
        <Col style={{ textAlign: 'right' }}>
          {(props.providers?.length ?? 0) < 2 || props.sortBy === undefined ? null : (
            <span style={{ margin: 0, marginLeft: 5 }}>{SortByFormat(props.sortBy, true)}</span>
          )}
        </Col>
      </Row>
      <div style={props.isMobile ? mobileStyle : desktopStyle}>
        <BackTop />
        {props.providers?.map((provider, i) => (
          <SearchResultCard
            key={`provider_${i}`}
            provider={provider}
            isMultipleProvidersSelected={(props.selectedProviders?.findIndex((x: any) => x.id == provider.id) ?? -1) > -1}
            hasMaxProvidersSelected={(props.selectedProviders?.length ?? 0) >= MAX_PROVIDERS}
            isProviderSelectedFromMap={provider.id === props.selected?.id}
            onClick={onResultClickCb}
            isImplementationPage={props.isImplementationPage}
          />
        ))}
        {renderPagination()}
        {props.providers ? (
          <PermissionGuard permissionId={Permission.TOOLS_PROVIDERNOMINATION_ACCESS}>
            <Card
              size="small"
              className="list-card"
              bordered={false}
              headStyle={{ borderBottom: 0 }}
              bodyStyle={{ paddingTop: 0 }}
              style={{ marginBottom: 5 }}
            >
              <Row gutter={12} justify="center" align="middle">
                <Col flex={1} style={{ textAlign: 'center' }}>
                  <h2 style={{ marginBottom: 10 }}>Don&apos;t see your provider?</h2>
                  <NavLink to={Routes.PROVIDER_NOMINATION}>
                    <Button shape="round" type="primary">
                      Submit A Nomination
                    </Button>
                  </NavLink>
                </Col>
              </Row>
            </Card>
          </PermissionGuard>
        ) : null}
      </div>
    </>
  );
};

export default React.memo(ProviderSearchResults);
