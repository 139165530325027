import { CalendarOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row } from 'antd';
import moment from 'moment-timezone';
import React from 'react';
import ProviderType from '../../../../consts/ProviderType';
import PnfDTO from '../../../../models/PnfDTO';
import { formatPhoneNumber } from '../../../../utils/FormatUtils';
import { PnfSteps } from '../../ProviderNomination';
import { firstColProps, secondColProps } from './Review';

interface ProvidersProps {
  pnf: PnfDTO;
  goToStep?: (step: PnfSteps) => void;
  editProvider?: (id: string) => void;
}

const typeName = (id: number) => {
  switch (id) {
    case ProviderType.CLINIC:
      return 'Facility';
    case ProviderType.DOCTOR:
      return 'Practitioner';
  }
};

const Providers = (props: ProvidersProps) => {
  const providers = props.pnf.providers ?? [];

  const getProviderTitle = (providerType: number, practitionerName: string, facilityName: string): string => {
    let title = '';
    if (providerType == ProviderType.CLINIC) {
      title = facilityName + `${practitionerName && ' (' + practitionerName + ')'}`;
    } else if (providerType === ProviderType.DOCTOR) {
      title = practitionerName + `${facilityName && ' (' + facilityName + ')'}`;
    }

    return title;
  };

  return (
    <>
      <h2>Providers</h2>

      {providers.map((p) => {
        const appointments = p.appointments ?? [];

        return (
          <Card
            key={`provider_${p.id}`}
            className="ant-card-background ant-card-shadow"
            bordered={false}
            style={{ marginBottom: 16 }}
          >
            <Row gutter={[12, 12]} justify="space-between" align="middle">
              <Col flex={1}>
                <strong>
                  {getProviderTitle(
                    p.providerTypeId,
                    p.practitionerFirstName && p.practitionerLastName
                      ? `${p.practitionerFirstName} ${p.practitionerLastName}`
                      : '',
                    p.facilityName || ''
                  )}
                </strong>
                <Row>
                  <Col {...firstColProps}>Provider Type:</Col>
                  <Col {...secondColProps}>{typeName(p.providerTypeId)}</Col>
                </Row>
                <Row>
                  <Col {...firstColProps}>Address:</Col>
                  <Col {...secondColProps}>{p.address?.address1}</Col>
                </Row>
                {(p.address?.address2 ?? '').length > 0 ? (
                  <Row>
                    <Col {...firstColProps}>Address 2:</Col>
                    <Col {...secondColProps}>{p.address?.address2}</Col>
                  </Row>
                ) : null}
                <Row>
                  <Col {...firstColProps}>City/State/Zip:</Col>
                  <Col {...secondColProps}>
                    {p.address?.city}, {p.address?.state} {p.address?.zipCode}
                  </Col>
                </Row>
                <Row>
                  <Col {...firstColProps}>Phone:</Col>
                  <Col {...secondColProps}>{formatPhoneNumber(p.phoneNumber ?? '')}</Col>
                </Row>
                {/* <Row>
                  <Col {...firstColProps}>Website:</Col>
                  <Col {...secondColProps}>{p.website}</Col>
                </Row> */}

                {(p.appointments?.length ?? 0) > 0 ? (
                  <>
                    <strong> Appointments</strong>

                    <Row>
                      {appointments.map((a, idx) => {
                        let firstName = '';
                        let lastName = '';
                        let relationship = '';
                        const referenceNumber = a.referenceNumber;
                        const memberInfo = props.pnf.familyMembers?.find((m) => m.id == a.familyMemberId);

                        if (a.familyMemberId === props.pnf.primaryMember?.id) {
                          firstName = props.pnf.primaryMember.firstName ?? '';
                          lastName = props.pnf.primaryMember.lastName ?? '';
                        } else if (memberInfo) {
                          firstName = memberInfo.firstName ?? '';
                          lastName = memberInfo.lastName ?? '';
                          relationship = `(${memberInfo.primaryRelationship ?? ''})`;
                        } else {
                          return null;
                        }

                        return (
                          <Col xs={24} key={`apt_${idx}`} style={{ paddingTop: '5px' }}>
                            {a.referenceNumber && (
                              <div>
                                Ref #: <span style={{ textDecoration: 'underline' }}>{referenceNumber}</span>
                              </div>
                            )}
                            <div>
                              {firstName} {lastName} {relationship}
                            </div>
                            <div>Patient Type: {a.existing ? 'Existing' : 'New'}</div>
                            <div>
                              <CalendarOutlined />{' '}
                              {a.appointmentDate ? (
                                <>
                                  {a.appointmentTimeZone
                                    ? moment(a.appointmentDate).tz(a.appointmentTimeZone).format('MM/DD/YYYY hh:mm A')
                                    : moment(a.appointmentDate).format('MM/DD/YYYY hh:mm A')}{' '}
                                  ({a.appointmentTimeZone && a.appointmentTimeZone.replace('_', ' ')})
                                </>
                              ) : (
                                'No Appt.'
                              )}
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  </>
                ) : null}
              </Col>
              <Col flex={0}>
                {props.editProvider ? (
                  <Button
                    type="link"
                    icon={<EditOutlined />}
                    onClick={() => {
                      if (props.editProvider) {
                        props.editProvider(p.id ?? '');
                        if (props.goToStep) {
                          props.goToStep(PnfSteps.HealthProviderInfo);
                        }
                      }
                    }}
                    size="large"
                  />
                ) : null}
              </Col>
            </Row>
          </Card>
        );
      })}
    </>
  );
};

export default Providers;
