// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';

interface ContactDTO { 
    id: number;
    displayOrder: number;
    title: string | null;
    name: string | null;
    email: string | null;
    phone: string | null;
    url: string | null;
    contactTypeId: number;
}
const ContactDTO = {
    create: (initValues?: Partial<ContactDTO> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            id: 0,
            displayOrder: 0,
            title: null,
            name: null,
            email: null,
            phone: null,
            url: null,
            contactTypeId: 0,
        },
        initValues);
    }
};

export default ContactDTO;