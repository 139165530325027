// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';

interface MailroomFileDetailDTO { 
    id: number;
    account: string | null;
    status: string | null;
    uploadDate: moment.Moment | string;
    processedDate: moment.Moment | string | null;
    fileName: string | null;
    notes: string | null;
    inFilePath: string | null;
    outFilePath: string | null;
    size: string | null;
    type: string | null;
    url: string | null;
    source: string | null;
    destination: string | null;
    incoming: boolean;
    archiveType: string | null;
    archiveFullPath: string | null;
    newFileName: string | null;
}
const MailroomFileDetailDTO = {
    create: (initValues?: Partial<MailroomFileDetailDTO> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            id: 0,
            account: null,
            status: null,
            uploadDate: new Date(0).toISOString(),
            processedDate: null,
            fileName: null,
            notes: null,
            inFilePath: null,
            outFilePath: null,
            size: null,
            type: null,
            url: null,
            source: null,
            destination: null,
            incoming: false,
            archiveType: null,
            archiveFullPath: null,
            newFileName: null,
        },
        initValues);
    }
};

export default MailroomFileDetailDTO;