import { notification } from 'antd';
import React, { useEffect, useState } from 'react';
import ClientService from '../../../api/ClientApiService';
import Permission from '../../../consts/Permission';
import Routes from '../../../consts/Routes';
import useHasPermission from '../../../hooks/useHasPermission';
import BrokerListDTO from '../../../models/BrokerListDTO';
import AccessList, { AccessData } from '../../shared/EntityAccess/AccessList';

interface BrokerAccessListProps {
  clientId: number;
  readonly?: boolean;
}

const BrokerAccessList = (props: BrokerAccessListProps) => {
  const [loading, setLoading] = useState(true);
  const [brokerList, setBrokerList] = useState<BrokerListDTO[]>([]);
  const hasPermission = useHasPermission();

  const fetch = (id: number) => {
    setLoading(true);
    ClientService.getBrokersForClient(id)
      .then((res) => {
        setBrokerList(res);
      })
      .catch(() => {
        // TODO: notification
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteBrokerClientAccess = (brokerId: number) => {
    setLoading(true);
    ClientService.deleteBrokerClientAccess(props.clientId, brokerId)
      .then((results) => {
        if (results.succeeded) {
          notification.success({ message: 'Broker successfully removed from client.' });
          fetch(props.clientId);
        } else {
          throw '';
        }
      })
      .catch(() => {
        notification.error({ message: 'An error occurred while trying to remove TPA from client.' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetch(props.clientId);
  }, []);

  const listData = brokerList.map((t) => {
    return {
      id: t.id,
      name: t.name,
      location: t.location,
      phoneNumber: t.phoneNumber,
      isActive: t.isActive,
      endDate: t.endDate,
      startDate: t.startDate,
    } as AccessData;
  });

  return (
    <AccessList
      abbreviation="Broker"
      addText="Assign New Broker"
      fullName="Broker"
      tableData={listData}
      onDelete={deleteBrokerClientAccess}
      editUrl={(id?: number) =>
        Routes.generate(Routes.ADMIN_CLIENT_DETAILS_BROKERS, { id: props.clientId, brokerId: id?.toString() ?? 'new' })
      }
      loading={loading}
      readOnly={!hasPermission(Permission.ADMIN_CLIENT_BROKER_EDIT) || !!props.readonly}
    />
  );
};

export default BrokerAccessList;
