import { notification } from 'antd';
import React, { useEffect, useState } from 'react';
import ClientService from '../../../api/ClientApiService';
import Permission from '../../../consts/Permission';
import Routes from '../../../consts/Routes';
import useHasPermission from '../../../hooks/useHasPermission';
import OtherCompanyListDTO from '../../../models/OtherCompanyListDTO';
import AccessList, { AccessData } from '../../shared/EntityAccess/AccessList';

interface OtherCompanyAccessListProps {
  clientId: number;
  readonly?: boolean;
}

const OtherCompanyAccessList = (props: OtherCompanyAccessListProps) => {
  const [loading, setLoading] = useState(true);
  const [otherCompanyList, setOtherCompanyList] = useState<OtherCompanyListDTO[]>([]);
  const hasPermission = useHasPermission();

  const fetch = (id: number) => {
    setLoading(true);
    ClientService.getOtherCompaniesForClient(id)
      .then((res) => {
        setOtherCompanyList(res);
      })
      .catch(() => {
        // TODO: notification
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteOtherCompanyClientAccess = (otherCompanyId: number) => {
    setLoading(true);
    ClientService.deleteOtherCompanyClientAccess(props.clientId, otherCompanyId)
      .then((results) => {
        if (results.succeeded) {
          notification.success({ message: 'Other Company successfully removed from client.' });
          fetch(props.clientId);
        } else {
          throw '';
        }
      })
      .catch(() => {
        notification.error({ message: 'An error occurred while trying to remove Other Company from client.' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetch(props.clientId);
  }, []);

  const listData = otherCompanyList.map((t) => {
    return {
      id: t.id,
      name: t.name,
      location: t.location,
      phoneNumber: t.phoneNumber,
      isActive: t.isActive,
      endDate: t.endDate,
      startDate: t.startDate,
    } as AccessData;
  });

  return (
    <AccessList
      abbreviation="Other Company"
      addText="Assign New Other Company"
      fullName="Other Company"
      tableData={listData}
      onDelete={deleteOtherCompanyClientAccess}
      editUrl={(id?: number) =>
        Routes.generate(Routes.ADMIN_CLIENT_DETAILS_OTHERCOMPANIES, {
          id: props.clientId,
          otherCompanyId: id?.toString() ?? 'new',
        })
      }
      readOnly={!hasPermission(Permission.ADMIN_CLIENT_OTHERCOMPANY_EDIT) || !!props.readonly}
      loading={loading}
    />
  );
};

export default OtherCompanyAccessList;
