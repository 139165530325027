import { DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { AutoComplete, Button, Col, Input, Row, Space, Spin, Table, Tag, Typography } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import confirmModal from '../../../utils/Modal';

export interface AccessData {
  id: number;
  name: string;
  location: string;
  phoneNumber: string;
  startDate?: moment.Moment;
  endDate?: moment.Moment;
  isActive?: boolean;
}

export interface AccessAddData {
  id: number;
  name: string;
}

interface AccessListProps {
  fullName: string;
  abbreviation: string;
  addText?: string;
  tableData?: AccessData[];
  loading: boolean;
  onDelete?: (id: number) => void;
  editUrl?: (id?: number) => string;
  readOnly?: boolean;
}

const AccessList = (props: AccessListProps) => {
  const [filter, setFilter] = useState<string | undefined>(undefined);

  const tableData = (props.tableData ?? []).filter(
    (d) => filter === undefined || d.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
  );

  const confirmDelete = (id: number) => {
    if (props.onDelete) {
      props.onDelete(id);
    }
  };

  const columnDefinitions: any[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a: any, b: any) => {
        a = a.name || '';
        b = b.name || '';
        return a.localeCompare(b);
      },
    },
    {
      title: 'Location',
      dataIndex: 'location',
      sorter: (a: any, b: any) => {
        a = a.location || '';
        b = b.location || '';
        return a.localeCompare(b);
      },
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      sorter: (a: any, b: any) => {
        a = a.startDate || '';
        b = b.startDate || '';
        return a.localeCompare(b);
      },
      render: (value: moment.Moment | string | null) => {
        return value != null ? moment(stripOffset(value)).format('MM/DD/YYYY') : null;
      },
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      sorter: (a: any, b: any) => {
        a = a.endDate || '';
        b = b.endDate || '';
        return a.localeCompare(b);
      },
      render: (value: moment.Moment | string | null) => {
        return value != null ? moment(stripOffset(value)).format('MM/DD/YYYY') : null;
      },
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      // eslint-disable-next-line react/display-name
      render: (value: boolean) => (value ? <Tag color="green">Active</Tag> : <Tag color="red">Expired</Tag>),
    },
    {
      title: 'Actions',
      dataIndex: '',
      // eslint-disable-next-line react/display-name
      render: (rowData: any) => (
        <Space>
          {props.editUrl ? (
            <NavLink to={props.editUrl(rowData.id)}>
              <Button htmlType="button" shape="round" ghost>
                {props.readOnly === false ? 'Edit Access' : 'View Access'}
              </Button>
            </NavLink>
          ) : null}

          {props.onDelete && props.readOnly === false ? (
            <DeleteOutlined
              onClick={() =>
                confirmModal(
                  `Are you sure you want to remove this ${props.abbreviation}?`,
                  () => confirmDelete(rowData.id),
                  null,
                  'Yes'
                )
              }
              style={{ paddingLeft: '12px' }}
              title={`Remove ${props.abbreviation}`}
            />
          ) : null}
        </Space>
      ),
    },
  ];

  return (
    <Spin spinning={props.loading}>
      <Row justify="space-between">
        <Col xs={8}>
          <AutoComplete
            style={{ width: '100%' }}
            onSelect={(e: string | undefined) => setFilter(e)}
            options={(props.tableData ?? []).map((d) => {
              return { value: d.name, label: d.name };
            })}
          >
            <Input.Search
              enterButton={<Button icon={<SearchOutlined />} ghost />}
              placeholder={`Search ${props.abbreviation}s...`}
              onSearch={(val) => setFilter(val)}
            />
          </AutoComplete>
        </Col>
        <Col style={{ textAlign: 'right' }}>
          {props.editUrl && props.readOnly === false ? (
            <NavLink to={props.editUrl()}>
              <Button type="primary" shape="round">
                {props.addText ?? 'Add'}
              </Button>
            </NavLink>
          ) : null}
        </Col>
      </Row>

      <div>
        <Typography.Text>
          {tableData.length !== (props.tableData ?? []).length
            ? `${tableData.length} of ${(props.tableData ?? []).length} ${props.fullName}${
                (props.tableData ?? []).length > 1 ? 's' : ''
              }`
            : `${tableData.length} ${props.fullName}${tableData.length > 1 ? 's' : ''}`}
        </Typography.Text>
      </div>
      <Table
        columns={columnDefinitions}
        dataSource={tableData}
        style={{ width: '100%' }}
        pagination={{ pageSize: 15 }}
        scroll={{ x: 575 }}
      />
    </Spin>
  );
};

export default AccessList;
