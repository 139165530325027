// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the LookupApiTemplate.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
import LookupsApiBase from './LookupsApiBase';
import UsStateDTO from '../models/UsStateDTO';
import ContactTypeDTO from '../models/ContactTypeDTO';
import LookupTableDTO from '../models/LookupTableDTO';
import ProviderSpecialtyDTO from '../models/ProviderSpecialtyDTO';
import PermissionDTO from '../models/PermissionDTO';
import PermissionGroupDetailDTO from '../models/PermissionGroupDetailDTO';

export class LookupsApiService extends LookupsApiBase {

    // get: api/lookups/GetUsStates
    public getUsStates(): Promise<UsStateDTO[]> {
        let url = `api/lookups/GetUsStates`;
        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, UsStateDTO[]>(null, 'get', url, true, false);
    }

    // get: api/lookups/GetContactTypes
    public getContactTypes(): Promise<ContactTypeDTO[]> {
        let url = `api/lookups/GetContactTypes`;
        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ContactTypeDTO[]>(null, 'get', url, true, false);
    }

    private _getLanguages: LookupTableDTO[] = null as any;
    // get: api/lookups/GetlLanguages?include=${include}
    public getLanguages(include?: number | null): Promise<LookupTableDTO[]> {
        if(this._getLanguages != null) {
            return this.fromLocal(this._getLanguages, "Language", include);
        }
        else {
            let url = `api/lookups/GetlLanguages`;
            let prefix = url.indexOf('?') > 0 ? '&' : '?';
            if (include != null) {
                 url += `${prefix}include=${include}`;
                 prefix = '&';
            }
            url = url.replace(/null|undefined/gi, '');
            return this.SendRequest<any, LookupTableDTO[]>(null, 'get', url, true, false).
                   then(r=> {this._getLanguages = r; return this._getLanguages.slice(0);});
        }

    }

    private _getProviderTypes: LookupTableDTO[] = null as any;
    // get: api/lookups/GetProviderTypes?include=${include}
    public getProviderTypes(include?: number | null): Promise<LookupTableDTO[]> {
        if(this._getProviderTypes != null) {
            return this.fromLocal(this._getProviderTypes, "ProviderType", include);
        }
        else {
            let url = `api/lookups/GetProviderTypes`;
            let prefix = url.indexOf('?') > 0 ? '&' : '?';
            if (include != null) {
                 url += `${prefix}include=${include}`;
                 prefix = '&';
            }
            url = url.replace(/null|undefined/gi, '');
            return this.SendRequest<any, LookupTableDTO[]>(null, 'get', url, true, false).
                   then(r=> {this._getProviderTypes = r; return this._getProviderTypes.slice(0);});
        }

    }

    // get: api/lookups/GetProviderSpecialties
    public getProviderSpecialties(): Promise<ProviderSpecialtyDTO[]> {
        let url = `api/lookups/GetProviderSpecialties`;
        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, ProviderSpecialtyDTO[]>(null, 'get', url, true, false);
    }

    // get: api/lookups/GetPermissions?filter=${encodeURIComponent(filter)}
    public getPermissions(filter?: string | null): Promise<PermissionDTO[]> {
        let url = `api/lookups/GetPermissions`;
            let prefix = url.indexOf('?') > 0 ? '&' : '?';
            if (filter != null) {
                 url += `${prefix}filter=${encodeURIComponent(filter || '')}`;
                 prefix = '&';
            }
        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, PermissionDTO[]>(null, 'get', url, true, false);
    }

    // get: api/lookups/GetPermissionGroups?filter=${encodeURIComponent(filter)}&role=${role}
    public getPermissionGroups(filter?: string | null, role?: number | null): Promise<PermissionGroupDetailDTO[]> {
        let url = `api/lookups/GetPermissionGroups`;
            let prefix = url.indexOf('?') > 0 ? '&' : '?';
            if (filter != null) {
                 url += `${prefix}filter=${encodeURIComponent(filter || '')}`;
                 prefix = '&';
            }
            if (role != null) {
                 url += `${prefix}role=${role}`;
                 prefix = '&';
            }
        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, PermissionGroupDetailDTO[]>(null, 'get', url, true, false);
    }

    // post: api/lookups/GetPermissionGroupByPermissionGroupIds?filter=${encodeURIComponent(filter)}
    public getPermissionGroupByPermissionGroupIds(permissionGroupIds: (number | null)[], filter?: string | null): Promise<PermissionGroupDetailDTO[]> {
        let url = `api/lookups/GetPermissionGroupByPermissionGroupIds`;
            let prefix = url.indexOf('?') > 0 ? '&' : '?';
            if (filter != null) {
                 url += `${prefix}filter=${encodeURIComponent(filter || '')}`;
                 prefix = '&';
            }
        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, PermissionGroupDetailDTO[]>(permissionGroupIds, 'post', url, true, false);
    }

    private _getReportTypes: LookupTableDTO[] = null as any;
    // get: api/lookups/GetReportTypes?include=${include}
    public getReportTypes(include?: number | null): Promise<LookupTableDTO[]> {
        if(this._getReportTypes != null) {
            return this.fromLocal(this._getReportTypes, "ReportType", include);
        }
        else {
            let url = `api/lookups/GetReportTypes`;
            let prefix = url.indexOf('?') > 0 ? '&' : '?';
            if (include != null) {
                 url += `${prefix}include=${include}`;
                 prefix = '&';
            }
            url = url.replace(/null|undefined/gi, '');
            return this.SendRequest<any, LookupTableDTO[]>(null, 'get', url, true, false).
                   then(r=> {this._getReportTypes = r; return this._getReportTypes.slice(0);});
        }

    }

    // get: api/lookups/GetAgreementTypes
    public getAgreementTypes(): Promise<LookupTableDTO[]> {
        let url = `api/lookups/GetAgreementTypes`;
        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, LookupTableDTO[]>(null, 'get', url, true, false);
    }

    // get: api/lookups/GetAgreementCodes
    public getAgreementCodes(): Promise<LookupTableDTO[]> {
        let url = `api/lookups/GetAgreementCodes`;
        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, LookupTableDTO[]>(null, 'get', url, true, false);
    }
}
var service = new LookupsApiService();
export default service;
