import { Button, Card, Col, Form, Input, Row, Select, Space, Typography, notification } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import PricingToolApiService from '../../api/PricingToolApiService';
import ShoppableServiceDTO from '../../models/ShoppableServiceDTO';
import DataTable, { DataTableColumnProps } from '../shared/DataTable/DataTable';
import ShoppableServiceResponseDTO from '../../models/ShoppableServiceResponseDTO';
import ShoppableServiceRequestDTO from '../../models/ShoppableServiceRequestDTO';
import BillingCodeTypes from '../../consts/BillingCodeTypes';
import { useForm } from 'antd/lib/form/Form';
import DataTableColumnUtil from '../shared/DataTable/DataTableColumnUtil';

const { Option } = Select;

interface ShoppableServiceFormProps {
  selected: string[];
  initialRequest: ShoppableServiceRequestDTO | undefined;
  cursor: string | undefined;
  services: ShoppableServiceDTO[];

  onSearch: (request: ShoppableServiceRequestDTO, result: ShoppableServiceResponseDTO, addToList: boolean) => void;
  onSelect: (records: ShoppableServiceDTO[]) => void;
}

const ShoppableServiceForm: React.FC<ShoppableServiceFormProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [formRef] = useForm();
  const tableRef = useRef<DataTable<ShoppableServiceDTO>>(null);

  const billingCodeHasValue = !!Form.useWatch('billingCode', formRef);

  useEffect(() => {
    tableRef?.current?.resetTable();
  }, [props.services]);

  useEffect(() => {
    formRef?.resetFields();
  }, [props.initialRequest]);

  const getTableColumns = (): DataTableColumnProps<any>[] => {
    const billingCodeType = DataTableColumnUtil.Text('Billing Code Type', 'billingCodeType');
    const billingCode = DataTableColumnUtil.Text('Billing Code', 'billingCode');
    const description = DataTableColumnUtil.Text('Description', 'display');

    return [billingCodeType, billingCode, description];
  };

  const handleSearch = () => {
    formRef.validateFields().then((model) => {
      const dto = ShoppableServiceRequestDTO.create({ ...model });
      fetchServices(dto, false);
    });
  };

  const handleLoadMore = () => {
    const dto = ShoppableServiceRequestDTO.create({ ...props.initialRequest, cursor: props.cursor });
    fetchServices(dto, true);
  };

  const fetchServices = (request: ShoppableServiceRequestDTO, loadMore: boolean) => {
    setLoading(true);
    PricingToolApiService.getShoppableServices(request)
      .then((result) => {
        props.onSearch(request, result, loadMore);
      })
      .catch(() => {
        notification.error({
          message: 'Failed to fetch services',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Card className="ant-card-background ant-card-shadow active">
        <Form form={formRef} initialValues={props.initialRequest} layout="vertical">
          <Row gutter={12}>
            <Col xs={24} sm={12}>
              <Form.Item name="serviceQueryString" label="Service">
                <Input />
              </Form.Item>
              <Form.Item
                name="billingCodeType"
                label="Billing Code Type"
                rules={[
                  { required: billingCodeHasValue, message: 'Billing Code Type is required with a billing code' },
                ]}
              >
                <Select allowClear showSearch optionFilterProp="children">
                  <Option value={BillingCodeTypes.CPT}>{BillingCodeTypes.CPT}</Option>
                  <Option value={BillingCodeTypes.HCPCS}>{BillingCodeTypes.HCPCS}</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item name="taxonomyCode" label="Taxonomy Code">
                <Input />
              </Form.Item>
              <Form.Item name="billingCode" label="Billing Code">
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Row justify="space-between">
          <Row style={{ marginTop: 'auto', marginBottom: 'auto' }}>
            {props.services?.length > 0 && (
              <>
                <Typography.Text>
                  Can&apos;t find your service? Use the above filters to narrow your search
                  {props.cursor && (
                    <>
                      &nbsp;or&nbsp;
                      <Typography.Link style={{ marginTop: 'auto', marginBottom: 'auto' }} onClick={handleLoadMore}>
                        load more
                      </Typography.Link>
                    </>
                  )}
                  .
                </Typography.Text>
              </>
            )}
          </Row>
          <Space style={{ marginLeft: 'auto' }}>
            <Button shape="round" type="primary" onClick={handleSearch}>
              Search
            </Button>
          </Space>
        </Row>
      </Card>
      <br />
      <DataTable<ShoppableServiceDTO>
        ref={tableRef}
        columns={getTableColumns()}
        serverSide={false}
        data={props.services}
        tableProps={{
          rowKey: 'billingCode',
          locale: {
            emptyText: 'No services available',
          },
          rowSelection: {
            type: 'radio',
            onChange: (_, records) => {
              props.onSelect(records);
            },
            selectedRowKeys: props.selected,
          },
          scroll: {
            x: 300,
          },
          sortDirections: ['ascend', 'descend'],
          loading: loading,
          size: 'middle',
          pagination: {
            defaultPageSize: 10,
            showSizeChanger: false,
            showLessItems: true,
            style: { flexWrap: 'nowrap' },
          },
        }}
        styleOptions={{
          alternatingRowHighlight: true,
        }}
      />
    </>
  );
};

export default ShoppableServiceForm;
