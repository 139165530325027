import { Button, notification } from 'antd';
import Title from 'antd/lib/typography/Title';
import moment from 'moment';
import React, { useState } from 'react';
import ClaimSearchApiService from '../../api/ClaimSearchApiService';
import MemberClaimListDTO from '../../models/MemberClaimListDTO';
import MemberClaimSearchFormDTO from '../../models/MemberClaimSearchFormDTO';
import TableRequestDTO from '../../models/TableRequestDTO';
import TableResponseDTO from '../../models/TableResponseDTO';
import DataTable, { DataTableColumnProps } from '../shared/DataTable/DataTable';

interface ClaimSearchListProps {
  claimSearchDTO: MemberClaimSearchFormDTO;
  openDetails: (claimId: string) => void;
}

const ClaimSearchList = (props: ClaimSearchListProps) => {
  const [loading, setLoading] = useState(false);
  const [claims, setClaims] = useState<MemberClaimListDTO[]>([]);

  const claimSearchDataTableRef = React.createRef<DataTable<any>>();

  const tableColumns: DataTableColumnProps<MemberClaimListDTO>[] = [
    {
      title: 'Patient Name',
      dataIndex: 'patientName',
      defaultSortOrder: 'ascend',
      sorter: {},
    },
    {
      title: 'Service Date',
      dataIndex: 'serviceDate',
      defaultSortOrder: 'ascend',
      sorter: {},
      renderDataTransform: (value: any) => (value != null ? moment(value).format('MM/DD/YYYY') : null),
    },
    {
      title: 'Provider Name',
      dataIndex: 'providerName',
      defaultSortOrder: 'ascend',
      sorter: {},
    },
    {
      title: 'Relationship Code',
      dataIndex: 'relationshipCode',
      defaultSortOrder: 'ascend',
      sorter: {},
    },
    {
      title: 'Status',
      dataIndex: 'status',
      defaultSortOrder: 'ascend',
      sorter: {},
    },
    {
      title: '',
      dataIndex: '',
      render: (rowData: MemberClaimListDTO) => (
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        <Button shape="round" ghost onClick={(e) => props.openDetails(rowData.claimDocClaimID ?? '')}>
          Details
        </Button>
      ),
    },
  ];

  const fetchTableData = (
    requestState: TableRequestDTO,
    checkEcho: () => boolean,
    callback: (response: TableResponseDTO<MemberClaimListDTO>) => void
  ) => {
    const memberId = props.claimSearchDTO.memberId ?? '';
    const fromDate = props.claimSearchDTO.fromDate
      ? moment(props.claimSearchDTO.fromDate)?.toDate().toDateString()
      : '';
    const toDate = props.claimSearchDTO.toDate ? moment(props.claimSearchDTO.toDate)?.toDate().toDateString() : '';
    setLoading(true);
    ClaimSearchApiService.getMemberClaimList(
      requestState,
      memberId,
      props.claimSearchDTO.clientId,
      fromDate,
      toDate,
      props.claimSearchDTO.onlyInProgressClaims
    )
      .then((results) => {
        if (!checkEcho()) {
          return;
        }
        setClaims(results.results || []);
        setLoading(false);
        callback(results);
      })
      .catch((error: any) => {
        setLoading(false);
        notification.error({
          message: error.message,
          description: error.description,
        });
      });
  };

  return (
    <>
      <Title level={3}>Results</Title>
      <DataTable
        ref={claimSearchDataTableRef}
        serverSide={true}
        globalSearch={true}
        globalSearchPlaceholder="Search Claims"
        tableProps={{
          rowKey: 'id',
          loading: loading,
        }}
        columns={tableColumns}
        data={claims}
        fetchData={fetchTableData}
        styleOptions={{
          alternatingRowHighlight: true,
        }}
      />
    </>
  );
};

export default ClaimSearchList;
