// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the Models.tst template. 
// !!Do not modify this file or change may be lost!!
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';

interface ResultDTO { 
    succeeded: boolean;
    id: number | null;
    guidId: string | null;
    refId: string | null;
    errors: string[];
    results: string[];
}
const ResultDTO = {
    create: (initValues?: Partial<ResultDTO> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            succeeded: false,
            id: null,
            guidId: null,
            refId: null,
            errors: [],
            results: [],
        },
        initValues);
    }
};

export default ResultDTO;